import {
  MISC_CHANGE_HEIGHT,
  MISC_CHANGE_WIDTH,
  MISC_GET_REPORTRULES,
  MISC_GET_REPORTRULES_LOADING,
  MISC_REPORTGEN_DATA,
  MISC_REPORTGEN_LOADING,
} from "../actions/types";

const initalState = {
  width: 795,
  height: 100,
  reportRuels: null,
  reportRuelsLoading: false,
  reportGenLoading: false,
  reportGenData: null,
};

export default function misc(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case MISC_REPORTGEN_DATA:
      return { ...state, reportGenLoading: false, reportGenData: payload };
    case MISC_REPORTGEN_LOADING:
      return { ...state, reportGenLoading: payload };
    case MISC_GET_REPORTRULES:
      return { ...state, reportRuels: payload, reportRuelsLoading: false };
    case MISC_GET_REPORTRULES_LOADING:
      return { ...state, reportRuelsLoading: payload };
    case MISC_CHANGE_WIDTH:
      return { ...state, width: payload };
    case MISC_CHANGE_HEIGHT:
      return { ...state, height: payload };
    default:
      return state;
  }
}
