import {
  CONFIGTEMP_CLEARALL,
  CONFIGTEMP_GET_ALL,
  CONFIGTEMP_GET_SINGLE,
  CONFIGTEMP_LOADING,
  CONFIGTEMP_MODALADDRESS_CLOSE,
  CONFIGTEMP_MODALADDRESS_OPEN,
  CONFIGTEMP_MODALALARMCOPY_CLOSE,
  CONFIGTEMP_MODALALARMCOPY_OPEN,
  CONFIGTEMP_MODALALARM_CLOSE,
  CONFIGTEMP_MODALALARM_OPEN,
  CONFIGTEMP_MODALBATCH_CLOSE,
  CONFIGTEMP_MODALBATCH_OPEN,
  CONFIGTEMP_MODALEXTRADETAILS_CLOSE,
  CONFIGTEMP_MODALEXTRADETAILS_OPEN,
  CONFIGTEMP_MODALNEWSNMP_CLOSE,
  CONFIGTEMP_MODALNEWSNMP_OPEN,
  CONFIGTEMP_MODALNEW_CLOSE,
  CONFIGTEMP_MODALNEW_OPEN,
  CONFIGTEMP_MODAL_IMPORT,
} from "../../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalVis: false,
  modalBatchaVis: false,
  modalAddressVis: false,
  modalAlarmVis: false,
  modalAlarmCopyVis: false,
  modalBatchData: null,
  modalAddressData: null,
  modalAlarmData: null,
  modalAlarmCopyData: null,
  isEdit: false,
  modalSNMPData: null,
  modalSNMPVis: null,
  modalExtraDetailsVis: false,
  modalExtraDetailsData: null,
  modalImportTemplateVis: false,
};

export default function templates(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONFIGTEMP_MODAL_IMPORT:
      return {
        ...state,
        modalImportTemplateVis: payload,
      };
    case CONFIGTEMP_GET_SINGLE:
      return {
        ...state,
        editData: { ...payload },
      };
    case CONFIGTEMP_MODALNEW_CLOSE:
      return {
        ...state,
        modalVis: false,
        editData: null,
        isEdit: false,
      };
    case CONFIGTEMP_MODALALARMCOPY_OPEN:
      return {
        ...state,
        modalAlarmCopyVis: true,
        modalAlarmCopyData: payload,
      };
    case CONFIGTEMP_MODALALARMCOPY_CLOSE:
      return {
        ...state,
        modalAlarmCopyVis: false,
        modalAlarmCopyData: null,
      };
    case CONFIGTEMP_MODALALARM_OPEN:
      return {
        ...state,
        modalAlarmVis: true,
        modalAlarmData: payload,
      };
    case CONFIGTEMP_MODALALARM_CLOSE:
      return {
        ...state,
        modalAlarmVis: false,
        modalAlarmData: null,
      };
    case CONFIGTEMP_MODALADDRESS_OPEN:
      return {
        ...state,
        modalAddressVis: true,
        modalAddressData: payload,
      };
    case CONFIGTEMP_MODALNEWSNMP_CLOSE:
      return {
        ...state,
        modalSNMPVis: false,
        modalSNMPData: null,
      };
    case CONFIGTEMP_MODALEXTRADETAILS_CLOSE:
      return {
        ...state,
        modalExtraDetailsVis: false,
        modalExtraDetailsData: null,
      };
    case CONFIGTEMP_MODALEXTRADETAILS_OPEN:
      return {
        ...state,
        modalExtraDetailsVis: true,
        modalExtraDetailsData: payload,
      };
    case CONFIGTEMP_MODALNEWSNMP_OPEN:
      return {
        ...state,
        modalSNMPVis: true,
        modalSNMPData: payload,
      };
    case CONFIGTEMP_MODALADDRESS_CLOSE:
      return {
        ...state,
        modalAddressVis: false,
        modalAddressData: null,
      };
    case CONFIGTEMP_MODALBATCH_OPEN:
      return {
        ...state,
        modalBatchaVis: true,
        modalBatchData: payload,
      };
    case CONFIGTEMP_MODALBATCH_CLOSE:
      return {
        ...state,
        modalBatchaVis: false,
        modalBatchData: null,
      };
    case CONFIGTEMP_MODALNEW_OPEN:
      return {
        ...state,
        modalVis: true,
        isEdit: payload !== null ? true : false,
      };
    case CONFIGTEMP_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    case CONFIGTEMP_GET_ALL:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case CONFIGTEMP_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
