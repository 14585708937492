import {
    TASK_ADD_COLLABORATOR,
    TASK_GET_ALL,
    TASK_GET_SINGLE,
    TASK_LIST_LOADING,
    TASK_SET_SELECTED,
    TASK_UPDATE_SELECTED_TASK,
    TASK_UPDATE,
    TASK_ADD_MULTIPLE_COLLABORATOR,
    TASK_REMOVE_COLLABORATOR,
    TASK_CREATE,
    TASK_ADD_ATTACHMENTS,
    TASK_GET_ATTACHMENTS,
    GET_TASK_CHAT,
    TASK_CHAT_LOADED,
    UPDATE_TASK_ATTACHMENT_DETAILS, DELETE_TASK_ATTACHMENT, GET_USER_TASKS
} from "../actions/types";

const initialState = {
    loading: false,
    tasks: null,
    selectedTask: null,
    selectedTaskAttachments: [],
    loaded: false,
    taskChat: null,
    taskChatLoaded: false,
}

export default function tasks(state = initialState, action){

    const { type, payload } = action;

    switch (type){
        case TASK_LIST_LOADING:
            return { ...state, loading: payload, loaded: false };
        case TASK_CREATE:
            return { ...state, loaded: true };
        case TASK_ADD_ATTACHMENTS:
            return { ...state, loaded: true };
        case UPDATE_TASK_ATTACHMENT_DETAILS:
            return { ...state, loading: false};
        case DELETE_TASK_ATTACHMENT:
            return { ...state, loading: false};
        case TASK_GET_SINGLE:
            return { ...state, selectedTask: payload, loading: false };
        case TASK_GET_ALL:
            return { ...state, tasks: payload, loading: false, loaded: true };
        case GET_USER_TASKS:
            return { ...state, tasks: payload, loading: false, loaded: true };
        case TASK_UPDATE:
            return { ...state, loading: false };
        case TASK_SET_SELECTED:
            return { ...state, selectedTask: payload, loading: false };
        case TASK_GET_ATTACHMENTS:
            return { ...state, selectedTaskAttachments: payload, loading: false };
        case TASK_UPDATE_SELECTED_TASK:
            return { ...state, selectedTask: payload, loading: false };
        case TASK_ADD_COLLABORATOR:
            return { ...state, loading: false };
        case TASK_REMOVE_COLLABORATOR:
            return { ...state, loading: false };
        case TASK_ADD_MULTIPLE_COLLABORATOR:
            return { ...state, loading: false };
        case GET_TASK_CHAT:
            return { ...state, taskChat: payload, taskChatLoaded: true };
        case TASK_CHAT_LOADED:
            return { ...state, taskChatLoaded: payload };
        default:
            return state;
    }

}
