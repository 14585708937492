import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd componetn
import { Table, Space, Popconfirm, Tooltip, Button } from "antd";
import { DeleteOutlined, RightOutlined } from "@ant-design/icons";

// external functions
import { EditViewRole, DeleteSpecificRole } from "../../../actions/roles";

// actual Component
const RolesTable = ({
  permissions,
  isAdmin,
  data,
  loading,
  pageWidth,
  EditViewRole,
  DeleteSpecificRole,
}) => {
  const handleDelete = (record) => {
    DeleteSpecificRole(record._id);
    EditViewRole(null);
  };

  const handleEdit = (record) => {
    EditViewRole(record);
  };

  const columns = [
    {
      title: "Role",
      key: "role",
      width: "25%",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      responsive: ["lg"],
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "10%",
      align: "right",
      render: (text, record) => (
        <Space size="middle">
          <Fragment>
            {permissions?.delete | isAdmin ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record)}
              >
                <Tooltip title="Delete this role">
                  <a href="#!" style={{ color: "red" }}>
                    <DeleteOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            ) : (
              <></>
            )}
            <Tooltip title="Edit/view role">
              <Button
                shape="circle"
                icon={<RightOutlined />}
                onClick={() => handleEdit(record)}
                size="small"
              />
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
      loading={loading}
      columns={columns}
      dataSource={data?.map((item) => {
        item.key = item._id;

        return item;
      })}
      size="small"
      pagination={false}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 20em)" : "150px",
      }}
    ></Table>
  );
};

RolesTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  EditViewRole: PropTypes.func.isRequired,
  DeleteSpecificRole: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.roles.data,
  loading: state.roles.loading,
});

export default connect(mapStateToProps, { EditViewRole, DeleteSpecificRole })(
  RolesTable
);
