import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// andt Components
import { PageHeader, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external components
import FilteredList from "./ExtensionList";

// external functions
import { loadAllUsers, clearUsersState } from "../../../../actions/users";

// actual component
const Index = ({ permissions, isAdmin, loadAllUsers, clearUsersState, usersData }) => {
	const [pageWidth, setPageWidth] = useState(window.innerWidth);

	const handleWindowSizeChange = () => {
		setPageWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		loadAllUsers();

		// clean up the effect by removing it completely
		return () => {
			clearUsersState();
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, [loadAllUsers, clearUsersState]);

	return (
		<Fragment>
			<PageHeader
				ghost={false}
				onBack={() => window.history.back()}
				title="Extension List"
				subTitle="View all personel and client details"
				extra={[
					<Link to={{ pathname: `/admin/users/create` }}>
						<Button
							key="NewUser"
							type="primary"
							disabled={isAdmin ? false : !permissions?.write}
							icon={<PlusCircleOutlined />}>
							{pageWidth > 795 && "New User"}
						</Button>
					</Link>,
				]}></PageHeader>

			<FilteredList />
		</Fragment>
	);
};

Index.propTypes = {
	permissions: PropTypes.object,
	loadAllUsers: PropTypes.func,
	clearUsersState: PropTypes.func,
	isAdmin: PropTypes.bool,
	usersData: PropTypes.array,
};

const mapStateToProps = (state) => ({
	isAdmin: state.auth.isAdmin,
	usersData: state.users.data,
	permissions: state.auth?.permissions?.filter((item) => item.name === "Extension List")[0],
});

export default connect(mapStateToProps, { loadAllUsers, clearUsersState })(Index);
