import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external components
import PermissionsTable from "./permissionTable";
import PermissionsCard from "./permissionCard";
import ModalAddNew from "./modals/ModalAddNew";

// external functions
import {
  clearPermisionsState,
  loadAllPermissions,
  ModalAddPremOpen,
} from "../../../actions/permissions";

// actual component
const Index = ({
  permissions,
  isAdmin,
  clearPermisionsState,
  loadAllPermissions,
  ModalAddPremOpen,
  pageWidth,
}) => {
  useEffect(() => {
    loadAllPermissions();

    // clean up the effect by removing it completely
    return () => {
      clearPermisionsState();
    };
  }, [clearPermisionsState, loadAllPermissions]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Permissions"
        subTitle={pageWidth >= 795 && "Manage User Permissions types"}
        extra={[
          <Button
            key="NewUser"
            type="primary"
            disabled={isAdmin ? false : !permissions?.write}
            icon={<PlusCircleOutlined />}
            onClick={ModalAddPremOpen}
          >
            {pageWidth >= 795 && "New Permission"}
          </Button>,
        ]}
      ></PageHeader>

      <ModalAddNew pageWidth={pageWidth} isAdmin={isAdmin} />

      <Row>
        <Col xs={24} md={11}>
          <PermissionsTable
            pageWidth={pageWidth}
            isAdmin={isAdmin}
            permissions={permissions}
          />
        </Col>
        <Col xs={0} md={1}></Col>
        <Col xs={24} md={12}>
          <PermissionsCard
            pageWidth={pageWidth}
            isAdmin={isAdmin}
            permissions={permissions}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  clearPermisionsState: PropTypes.func.isRequired,
  loadAllPermissions: PropTypes.func.isRequired,
  ModalAddPremOpen: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Permission Management"
  )[0],
});

export default connect(mapStateToProps, {
  clearPermisionsState,
  loadAllPermissions,
  ModalAddPremOpen,
})(Index);
