import "./style.scss";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  List,
  Modal,
  PageHeader,
  Select,
  Spin,
  Typography,
} from "antd";
import { IChat } from "../interfaces/IChat";
import { ChangeEvent, useEffect, useState, useCallback } from "react";
import { loadAllUsers } from "../../../actions/users";
import { ISimpleUser } from "../../../interfaces/shared/ISimpleUser";
import { createChat } from "../../../actions/chat";

const { Text } = Typography;

const CreatePersonal = (props: {
  currentUser: any;
  users: Array<any>;
  chatList: IChat[];
  createChat: Function;
  isVisible: boolean;
  chatType: string;
  handleOk: Function;
  handleCancel: Function;
  loadAllUsers: Function;
  isCreatingChat: boolean;
}) => {
  const { loadAllUsers } = props;
  const [groupName, setGroupName] = useState<string>("");
  const [groupMembers, setGroupMembers] = useState<ISimpleUser[]>([]);

  const [searchText, setSearchText] = useState<string>("");
  const [userList, setUserList] = useState<any[]>([]);

  const [selectedUser, setSelectedUser] = useState<any>(null);

  const _setUserList = useCallback((): void => {
    let users = [...props.users].filter(
      (user: any) => user._id !== props.currentUser._id
    );

    // Get rid of existing users if chat is p2p
    if (props.chatType === "personal") {
      let existingUsers: string[] = [];
      for (let i = 0; i < props.chatList.length; i++) {
        let chat: IChat = props.chatList[i];
        if (chat.type === "personal" && !chat.attached_to_task) {
          let mm = chat.members.map((member: ISimpleUser) => member.id);
          existingUsers.push(...mm);
        }
      }

      //@ts-ignore
      existingUsers = [...new Set(existingUsers)];

      for (let x = 0; x < existingUsers.length; x++) {
        let containedInList: number = users.findIndex(
          (a) => a._id === existingUsers[x]
        );
        if (containedInList !== -1) {
          users = [...users].filter((b) => b._id !== existingUsers[x]);
        }
      }
    }

    let filteredUsers: any[] = [];
    if (props.currentUser.role.name !== "Administrator") {
      let mptUsers = users.filter((e: any) => e.staff);
      let mptAdminUsers = users.filter(
        (e: any) => e.role.name === "Administrator"
      );

      // Get users for this client only
      filteredUsers = users.filter(
        (e: any) => e.client_id === props.currentUser.client_id
      );

      let merged: any[] = [];

      mptAdminUsers.map((thisuser) => {
        let match = mptUsers.findIndex(
          (otherUser) => otherUser._id === thisuser._id
        );

        if (match === -1) merged.push(thisuser);

        return true;
      });

      mptAdminUsers = [...merged, ...mptUsers];
      merged = [];

      mptAdminUsers.map((user) => {
        let match = filteredUsers.findIndex(
          (otherUser) => otherUser._id === user._id
        );

        if (match === -1) merged.push(user);

        return true;
      });

      filteredUsers = JSON.parse(JSON.stringify([...filteredUsers, ...merged]));
    } else {
      // Get users for this client only
      filteredUsers = [...users];
    }
    //@ts-ignore
    setUserList(filteredUsers.sort((a, b) => a.name.localeCompare(b.name)));
  }, [
    props.chatList,
    props.chatType,
    props.currentUser._id,
    props.currentUser.client_id,
    props.currentUser.role.name,
    props.users,
  ]);

  const _onGroupUserSelected = (user: any) => {
    if (user) {
      let index = groupMembers.findIndex((e: any) => e.id === user._id);

      if (index !== -1) {
        let newCollaborators = [...groupMembers];
        newCollaborators.splice(index, 1);
        setGroupMembers([...newCollaborators]);
      } else {
        let member: ISimpleUser = {
          id: user._id,
          user_name: `${user.name} ${user.surname}`,
          email_address: user.email_address,
        };
        setGroupMembers([...groupMembers, member]);
      }
    }
  };

  const _onSingleUserSelected = (user: any) => {
    if (user) {
      let member: ISimpleUser = {
        id: user._id,
        user_name: `${user.name} ${user.surname}`,
        email_address: user.email_address,
      };
      setGroupMembers([member]);
    }
  };

  const multipleUsersList = () => (
    <div className="multiple-users-list-container">
      <PageHeader
        title={<small>Select Participants</small>}
        style={{ padding: "0" }}
      >
        <Input
          value={searchText}
          placeholder="Search here..."
          onChange={(e: any) => {
            let text: string = e.target.value.toLowerCase();
            setSearchText(text);
            if (text !== "") {
              let users = userList.filter(
                (user: any) =>
                  user.name.toLowerCase().includes(text) ||
                  user.surname.toLowerCase().includes(text) ||
                  user.email_address.toLowerCase().includes(text)
              );
              // console.log(users);
              setUserList([...users]);
            } else {
              _setUserList();
            }
            // console.log("Search Text Changed :", text);
          }}
          style={{ width: "100%" }}
        />
        <Text type={"secondary"}>
          <small>You can search by Name, Surname and Email Address</small>
        </Text>
      </PageHeader>
      <Divider orientation="left" plain>
        <small> Users ({userList.length})</small>
      </Divider>
      <div className="multiple-users-list">
        <List
          itemLayout="horizontal"
          dataSource={userList}
          renderItem={(item: any) => (
            <List.Item
              key={item.email_address}
              actions={[
                <Checkbox
                  key={item.name}
                  onChange={() => {
                    _onGroupUserSelected(item);
                  }}
                />,
              ]}
            >
              <List.Item.Meta
                title={
                  <span>
                    {item.name} {item.surname}
                  </span>
                }
                description={<small>{item.email_address}</small>}
              />
            </List.Item>
          )}
        />
      </div>
      <Divider />
    </div>
  );

  const _createChat = (): void => {
    try {
      let chat: IChat;

      if (props.chatType === "personal") {
        chat = {
          _id: "",
          attached_to_task: false,
          client_id: props.currentUser.client_id,
          created_at: new Date(),
          creator: {
            id: props.currentUser._id,
            user_name: `${props.currentUser.name} ${props.currentUser.surname}`,
            email_address: props.currentUser.email_address,
          },
          members: [
            groupMembers[0],
            {
              id: props.currentUser._id,
              user_name: `${props.currentUser.name} ${props.currentUser.surname}`,
              email_address: props.currentUser.email_address,
            },
          ],
          name: "",
          type: props.chatType,
        };
        props.createChat(chat);
        setTimeout(() => {
          props.handleOk();
        }, 1000);
      } else {
        //GROUP CHAT SECTION

        chat = {
          _id: "",
          attached_to_task: false,
          client_id: props.currentUser.client_id,
          created_at: new Date(),
          creator: {
            id: props.currentUser._id,
            user_name: `${props.currentUser.name} ${props.currentUser.surname}`,
            email_address: props.currentUser.email_address,
          },
          members: [
            ...groupMembers,
            {
              id: props.currentUser._id,
              user_name: `${props.currentUser.name} ${props.currentUser.surname}`,
              email_address: props.currentUser.email_address,
            },
          ],
          name: groupName,
          type: props.chatType,
        };

        props.createChat(chat);
        setTimeout(() => {
          props.handleOk();
        }, 1000);
      }
    } catch (exception) {
      console.error("Error during chat creation: ", exception);
      props.handleCancel();
    }
  };

  useEffect(() => {
    if (props.users !== null) {
      _setUserList();
    } else {
      loadAllUsers();
    }

    return () => {
      setGroupMembers([]);
      setGroupName("");
      setUserList([]);
    };
  }, [props.users, props.chatType, props.chatList, _setUserList, loadAllUsers]);

  return (
    <Modal
      destroyOnClose={true}
      bodyStyle={{
        height: props.chatType === "group" ? "75vh" : "20vh",
        overflowY: "hidden",
      }}
      title={props.chatType === "personal" ? "New Chat" : "New Group Chat"}
      visible={props.isVisible}
      onOk={() => {
        props.handleOk();
      }}
      onCancel={() => {
        props.handleCancel();
      }}
      footer={[
        <Button
          key={"create-chat-modal-cancel"}
          type={"default"}
          disabled={props.isCreatingChat}
          onClick={() => {
            setGroupMembers([]);
            setGroupName("");
            setTimeout(() => {
              props.handleCancel();
            }, 1000);
          }}
        >
          Cancel
        </Button>,
        <Button
          key={"create-chat-modal-ok"}
          style={{
            backgroundColor: "limegreen",
            color: "#ffffff",
            borderColor: "limegreen",
          }}
          loading={props.isCreatingChat}
          disabled={
            props.chatType === "group"
              ? groupName === "" || groupMembers.length === 0
              : groupMembers.length === 0
          }
          onClick={() => {
            _createChat();
          }}
        >
          Create Chat
        </Button>,
      ]}
    >
      {props.chatType === "personal" ? (
        <Spin spinning={props.isCreatingChat}>
          <Form.Item
            name="personal-chat-member-selection"
            rules={[{ required: true }]}
          >
            <span>Select a person</span>
            <Select
              showSearch
              onSearch={(value: string) => {
                console.log("search:", value);
              }}
              value={
                selectedUser !== null
                  ? `${selectedUser?.name} ${selectedUser?.surname}`
                  : "Select a person"
              }
              filterOption={(input: string, option: any) => {
                let fullName = `${option.value[1]} ${option.value[2]}`;
                let email: string = option.value[3];

                return (
                  fullName.toLowerCase().includes(input.toLowerCase()) ||
                  email.toLowerCase().includes(input.toLowerCase())
                );
              }}
              placeholder="Select a person"
              onSelect={(e: any) => {
                let user = userList.find((a: any) => a._id === e[0]);
                setSelectedUser(user);

                if (props.chatType === "personal") _onSingleUserSelected(user);
                else _onGroupUserSelected(user);
              }}
              allowClear
            >
              {userList.map((e: any) => (
                <Select.Option
                  value={[e._id, e.name, e.surname, e.email_address]}
                  key={"single-user-selection-" + e._id}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{e.name + " " + e.surname}</span>
                    <Text type={"secondary"}>
                      <small>{e.email_address}</small>
                    </Text>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Spin>
      ) : (
        <Spin spinning={props.isCreatingChat}>
          <Form.Item
            wrapperCol={{ offset: 0, span: 24 }}
            label="Group Name"
            name="groupName"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Group Name..."
              value={groupName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setGroupName(e.target.value);
              }}
            />
          </Form.Item>

          {multipleUsersList()}
        </Spin>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.auth.user,
  chatList: state.chat.chatList,
  users: state.users.data,
  isCreatingChat: state.chat.isCreatingChat,
});

export default connect(mapStateToProps, {
  createChat,
  loadAllUsers,
})(CreatePersonal);
