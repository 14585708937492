import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external components
import ClientTable from "./clientTable";
import ClientCard from "./clientCards";
import ModalAddNewClient from "./modals/ModalAddNewClient";
import ModalAddService from "./modals/ModalAddService";

// External functions
import {
  clearClientState,
  loadAllClients,
  ModalNewClientOpen,
} from "../../../actions/clients";
import { loadAllServices, clearServicesState } from "../../../actions/services";

// actual component
const Index = ({
  permissions,
  isAdmin,
  clearClientState,
  loadAllClients,
  ModalNewClientOpen,
  pageWidth,
  loadAllServices,
  clearServicesState,
}) => {
  useEffect(() => {
    loadAllClients();

    loadAllServices();

    // clean up the effect by removing it completely
    return () => {
      clearClientState();
      clearServicesState();
    };
  }, [loadAllClients, clearClientState, loadAllServices, clearServicesState]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Client Management"
        subTitle={pageWidth >= 795 && "Manage all your clients here"}
        extra={[
          <Button
            key="NewClient"
            type="primary"
            disabled={
              isAdmin === true
                ? false
                : permissions?.write === false
                ? true
                : false
            }
            icon={<PlusCircleOutlined />}
            onClick={ModalNewClientOpen}
          >
            {pageWidth >= 795 && "New Client"}
          </Button>,
        ]}
      ></PageHeader>

      <ModalAddNewClient pageWidth={pageWidth} />

      <ModalAddService pageWidth={pageWidth} />

      <Row>
        <Col xs={24} md={11}>
          <ClientTable
            pageWidth={pageWidth}
            isAdmin={isAdmin}
            permissions={permissions}
          />
        </Col>
        <Col xs={0} md={1}></Col>
        <Col xs={24} md={12}>
          <ClientCard
            pageWidth={pageWidth}
            isAdmin={isAdmin}
            permissions={permissions}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  clearClientState: PropTypes.func.isRequired,
  loadAllClients: PropTypes.func.isRequired,
  ModalNewClientOpen: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
  loadAllServices: PropTypes.func.isRequired,
  clearServicesState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Client Management"
  )[0],
});

export default connect(mapStateToProps, {
  clearClientState,
  loadAllClients,
  ModalNewClientOpen,
  loadAllServices,
  clearServicesState,
})(Index);
