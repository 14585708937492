import {
  GROUPS_CLEARALL,
  GROUPS_EDIT,
  GROUPS_GET_ALL,
  GROUPS_LOADING,
  GROUPS_MODAL_GROUPADD_CLOSE,
  GROUPS_MODAL_GROUPADD_OPEN,
  GROUPS_MODAL_SITEADD,
  GROUPS_MODAL_SITEADD_CLOSE,
  GROUPS_MODAL_SITEADD_OPEN,
  GROUPS_MODAL_SITEADD_TABLE,
  GROUPS_MODAL_SITEADD_TABLE_ALL,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddSiteVis: false,
  modalAddGroupVis: false,
  newSite: [],
  addSiteTableLoaded: false,
  addSiteTableLoadedALL: false,
};

export default function groups(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GROUPS_MODAL_SITEADD_TABLE_ALL:
      return { ...state, addSiteTableLoadedALL: payload };
    case GROUPS_MODAL_SITEADD_TABLE:
      return { ...state, addSiteTableLoaded: payload };
    case GROUPS_MODAL_SITEADD:
      return { ...state, newSite: payload };
    case GROUPS_MODAL_SITEADD_OPEN:
      return { ...state, modalAddSiteVis: true };
    case GROUPS_MODAL_SITEADD_CLOSE:
      return { ...state, modalAddSiteVis: false };
    case GROUPS_MODAL_GROUPADD_OPEN:
      return { ...state, modalAddGroupVis: true };
    case GROUPS_MODAL_GROUPADD_CLOSE:
      return { ...state, modalAddGroupVis: false };
    case GROUPS_EDIT:
      return { ...state, editData: payload };
    case GROUPS_LOADING:
      return { ...state, loading: payload };
    case GROUPS_GET_ALL:
      return { ...state, data: payload, loading: false };
    case GROUPS_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
