import {
  CONFIGMAN_CLEARALL,
  CONFIGMAN_GET_ALL,
  CONFIGMAN_LOADING,
  CONFIGMAN_MODALNEW_CLOSE,
  CONFIGMAN_MODALNEW_OPEN,
} from "../../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalVis: false,
};

export default function manufacture(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONFIGMAN_MODALNEW_CLOSE:
      return {
        ...state,
        modalVis: false,
        editData: null,
      };
    case CONFIGMAN_MODALNEW_OPEN:
      return {
        ...state,
        modalVis: true,
        editData: payload,
      };
    case CONFIGMAN_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    case CONFIGMAN_GET_ALL:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case CONFIGMAN_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
