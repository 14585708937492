import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Card, Form, Input, Checkbox, InputNumber, Button, Select } from "antd";

// external functions
import { NewSiteUpdate } from "../../../../../actions/sites";

const { Option } = Select;

// actual componet
const SiteDetails = ({
  current,
  setCurrent,
  isAdmin,
  userDetails,
  avaibleClients,
  avaibleGroups,
  NewSiteUpdate,
  newSiteData,
  pageWidth,
}) => {
  const [formDetails] = Form.useForm();
  const [fromData, setFromData] = useState(null);

  if (
    (isAdmin === false && formDetails.getFieldValue("client_name") === "") ||
    formDetails.getFieldValue("client_name") === undefined
  ) {
    formDetails.setFieldsValue({ client_name: userDetails.client_name });
  }

  if (fromData === null && newSiteData) {
    setFromData(newSiteData);

    formDetails.setFieldsValue({ name: newSiteData.name });
    formDetails.setFieldsValue({ client_group: newSiteData.client_group });
    formDetails.setFieldsValue({ is_legacy: newSiteData.is_legacy });
    formDetails.setFieldsValue({ legacy_id: newSiteData.legacy_id });
  }

  const handleNext = () => {
    const data = formDetails.getFieldsValue();

    const clientData = avaibleClients.filter(
      (item) => item.name === data.client_name
    );

    data.client_id = clientData[0]._id;
    data.name = data.name.trim();

    setCurrent(current + 1);
    NewSiteUpdate({ ...data, ...newSiteData });
    setFromData(null);
  };

  return (
    <Card
      title={
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Site Details</p>
      }
      extra={[
        <Button
          type="primary"
          onClick={() => formDetails.validateFields().then(() => handleNext())}
        >
          Next
        </Button>,
      ]}
      style={{ marginTop: pageWidth > 795 ? "15px" : "5px", width: "100%" }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 5 }}
        size="small"
        form={formDetails}
        onValuesChange={(_, all) => {
          setFromData(all);
        }}
      >
        <Form.Item
          label={<span style={{ color: "black" }}>Site Name</span>}
          name={"name"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Assign a Client</span>}
          name={"client_name"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please select a valid client!",
            },
          ]}
        >
          {isAdmin ? (
            <Select showSearch allowClear>
              {avaibleClients?.map((client) => (
                <Option key={client._id} value={client.name}>
                  {client.name}
                </Option>
              ))}
            </Select>
          ) : (
            <Input disabled={true} />
          )}
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Group</span>}
          name={"client_group"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
        >
          {isAdmin ? (
            <Select allowClear showSearch>
              {avaibleGroups?.map((group) => (
                <Option key={group._id} value={group.name}>
                  {group.name}
                </Option>
              ))}
            </Select>
          ) : (
            <Select allowClear showSearch>
              {avaibleGroups
                ?.filter((group) => group.client_id === userDetails.client_id)
                .map((group) => (
                  <Option key={group._id} value={group.name}>
                    {group.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Is this a Legacy Site?</span>}
          name={"is_legacy"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        {fromData?.is_legacy && (
          <Form.Item
            label={
              <span
                style={{
                  color: "black",
                }}
              >
                Legacy ID
              </span>
            }
            name={"legacy_id"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          >
            <InputNumber />
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

SiteDetails.propTypes = {
  userDetails: PropTypes.object,
  avaibleClients: PropTypes.array,
  avaibleGroups: PropTypes.array,
  NewSiteUpdate: PropTypes.func.isRequired,
  newSiteData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.user,
  avaibleClients: state.clients.data,
  avaibleGroups: state.groups.data,
  newSiteData: state.sites.newSiteData,
});

export default connect(mapStateToProps, { NewSiteUpdate })(SiteDetails);
