import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd comopnet
import { Button, Col, PageHeader, Row, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  loadAllTypes,
  clearStateTypes,
  ModalNewTypeOpen,
} from "../../../../actions/staffTools/types";

import TypeDetails from "./TypeDetails";

// external components
const TypeTable = lazy(() => import("./table"));
// const ModalAddType = lazy(() => import("./Modal/ModalAddType"));

// actual Component
const UCTypes = ({
  pageWidth,
  isAdmin,
  permissions,
  loadAllTypes,
  clearStateTypes,
  ModalNewTypeOpen,
  modalVis,
}) => {
  useEffect(() => {
    loadAllTypes();

    return () => {
      clearStateTypes();
    };
  }, [loadAllTypes, clearStateTypes]);

  return (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="UC Types"
          style={{
            paddingBottom: pageWidth < 795 && "0px",
            paddingTop: pageWidth < 795 && "0px",
          }}
          subTitle={
            pageWidth > 795 &&
            "Types define the variables and alarms of a device"
          }
          extra={[
            <Button
              key="newItem"
              type="primary"
              disabled={isAdmin ? false : !permissions?.write}
              icon={<PlusCircleOutlined />}
              onClick={() => ModalNewTypeOpen(null)}
            >
              {pageWidth > 795 && "New"}
            </Button>,
          ]}
        ></PageHeader>

        {/* <ModalAddType
          pageWidth={pageWidth}
          isAdmin={isAdmin}
          permissions={permissions}
        /> */}

        <Row>
          <Col xs={24} lg={modalVis === true ? 12 : 24}>
            <TypeTable
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
          {modalVis === true && (
            <Col xs={24} lg={12}>
              <TypeDetails
                pageWidth={pageWidth}
                isAdmin={isAdmin}
                permissions={permissions}
              />
            </Col>
          )}
        </Row>
      </Fragment>
    </Suspense>
  );
};

UCTypes.propTypes = {
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  permissions: PropTypes.object,
  loadAllTypes: PropTypes.func.isRequired,
  clearStateTypes: PropTypes.func.isRequired,
  ModalNewTypeOpen: PropTypes.func.isRequired,
  modalVis: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  modalVis: state.types.modalVis,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "UC Templates"
  )[0],
});

export default connect(mapStateToProps, {
  loadAllTypes,
  clearStateTypes,
  ModalNewTypeOpen,
})(UCTypes);
