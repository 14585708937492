import React from "react";
import { Descriptions } from "antd";

const UserDescriptions = (props) => {
	return (
		<Descriptions size="small" style={{ fontWeight: 800 }}>
			<Descriptions.Item label="Contact Number">{props.user.contact_number}</Descriptions.Item>
			<Descriptions.Item label="Designation">{props.user.staff.designation}</Descriptions.Item>
			<Descriptions.Item label="Department">{props.user.staff.department}</Descriptions.Item>
			<Descriptions.Item label="Client">{props.user.client_name}</Descriptions.Item>
			<Descriptions.Item label="Branch">{props.user.default_site}</Descriptions.Item>
			<Descriptions.Item label="Country">{props.user.staff.country}</Descriptions.Item>
		</Descriptions>
	);
};

export default UserDescriptions;
