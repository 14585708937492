import {
  SALESCALL_CLEARALL,
  SALESCALL_EDIT,
  SALESCALL_GET_ALL,
  SALESCALL_LOADING,
  SALESCALL_MODAL_ADDNEWCLIENT_CLOSE,
  SALESCALL_MODAL_ADDNEWCLIENT_OPEN,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddNewVis: false,
};

export default function salescalls(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case SALESCALL_MODAL_ADDNEWCLIENT_OPEN:
      return {
        ...state,
        modalAddNewVis: true,
        editData: payload !== undefined ? payload : null,
      };
    case SALESCALL_MODAL_ADDNEWCLIENT_CLOSE:
      return { ...state, modalAddNewVis: false, editData: null };
    case SALESCALL_EDIT:
      return { ...state, editData: payload };
    case SALESCALL_LOADING:
      return { ...state, loading: payload };
    case SALESCALL_GET_ALL:
      return { ...state, data: payload, loading: false };
    case SALESCALL_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
