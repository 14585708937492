import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// antd
import { Row, Col, Form, Input, Button, Typography } from "antd";

// Image
import { ReactComponent as LoginImg } from "../images/login.svg";

// style sheet
import localStyle from "./index.module.css";

// external functions
import { login } from "../../actions/auth";

// extenal components
import TwoFAPage from "./twoFA";
import PwdExpiredPage from "./PwdExpired";

const { Title } = Typography;

// actual component
const Index = ({
  login,
  isAuthenticated,
  loading,
  use2FA,
  pageWidth,
  passwordExpired,
}) => {
  const inputRef = React.useRef(null);
  const [buttonDisable, setbuttonDisable] = useState();
  const [formSignIn] = Form.useForm();

  // windows resize and enter button
  useEffect(() => {
    const listener = (event) => {
      // Listen fro both enter keys
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // submit the form
        formSignIn.submit();
      }
    };

    document.addEventListener("keydown", listener);
    formSignIn.resetFields();

    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);

    // clean up the effect by removing it completely
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formSignIn]);

  // Login function
  const handleFinish = () => {
    login(
      formSignIn.getFieldValue("email"),
      formSignIn.getFieldValue("password")
    );
  };

  // If we are already authenticated
  if (isAuthenticated) {
    return <Redirect to="/admin" />;
  }

  return (
    <Fragment>
      {passwordExpired ? (
        <PwdExpiredPage width={pageWidth} />
      ) : use2FA ? (
        <TwoFAPage width={pageWidth} />
      ) : (
        <div
          style={
            pageWidth >= 768
              ? {
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  width: "50%",
                  transform: "translate(-50%, -50%)",
                }
              : {}
          }
        >
          <Row>
            {pageWidth >= 768 && (
              <Fragment>
                <Col xs={24} md={11}>
                  <LoginImg
                    height="100%"
                    preserveAspectRatio="xMinYMin slice"
                    width="100%"
                    viewBox="0 0 500 500"
                  />
                </Col>
                <Col md={1}></Col>
              </Fragment>
            )}
            <Col xs={24} md={12}>
              <Typography
                style={{
                  marginTop: pageWidth >= 768 ? "0px" : "60px",
                }}
              >
                <Title
                  level={pageWidth >= 768 ? 1 : 2}
                  className={localStyle.myTypography}
                >
                  AIVA Client Zone
                </Title>
              </Typography>
              <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                layout="vertical"
                size="small"
                form={formSignIn}
                onFinish={handleFinish}
                onValuesChange={(_, All) => {
                  setbuttonDisable(All);
                }}
              >
                <Form.Item
                  label={
                    <p
                      style={{ margin: "0px" }}
                      className={localStyle.myTypography}
                    >
                      Email
                    </p>
                  }
                  name="email"
                  style={{ marginBottom: "5px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your email address!",
                    },
                  ]}
                >
                  <Input ref={inputRef} autoComplete="username" />
                </Form.Item>
                <Form.Item
                  label={
                    <p
                      style={{ margin: "0px" }}
                      className={localStyle.myTypography}
                    >
                      Password
                    </p>
                  }
                  name="password"
                  rules={[{ required: true, message: "Password is required" }]}
                >
                  <Input.Password autoComplete="current-password" />
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={
                      buttonDisable?.email === "" ||
                      buttonDisable === undefined ||
                      loading
                        ? true
                        : false
                    }
                    loading={loading}
                    htmlType="submit"
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};

Index.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  use2FA: PropTypes.bool,
  passwordExpired: PropTypes.bool,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  use2FA: state.auth?.use2FA,
  passwordExpired: state.auth?.passwordExpired,
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, { login })(Index);
