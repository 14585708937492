import React, { Fragment, useEffect, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external components
// import NotificationTable from "./notificationTable";
// import NotificationCard from "./notificationCard";
// import ModalAddSite from "./modal/ModalAddSite";
// import ModalAddNewUser from "./modal/ModalAddNewUser";

// external functions
import {
  clearViewNotificationUser,
  ModalAddUserOpen,
} from "../../../actions/notificationsUsers";
import { clearSitesState, loadAllSites } from "../../../actions/sites";
import { loadAllUsers, clearUsersState } from "../../../actions/users";
import { clearClientState, loadAllClients } from "../../../actions/clients";

const NotificationTable = lazy(() => import("./notificationTable"));
const NotificationCard = lazy(() => import("./notificationCard"));
const ModalAddSite = lazy(() => import("./modal/ModalAddSite"));
const ModalAddNewUser = lazy(() => import("./modal/ModalAddNewUser"));

// actual component
const Index = ({
  permissions,
  clearViewNotificationUser,
  isAdmin,
  clearSitesState,
  loadAllSites,
  ModalAddUserOpen,
  loadAllUsers,
  clearUsersState,
  pageWidth,
  avaiableSites,
  avaiableUsers,
  avaiableClients,
  clearClientState,
  loadAllClients,
}) => {
  useEffect(() => {
    loadAllSites();
    loadAllUsers();
    loadAllClients();
    // clean up the effect by removing it completely
    return () => {
      clearViewNotificationUser();
      clearSitesState();
      clearClientState();
      clearUsersState();
    };
  }, [
    clearViewNotificationUser,
    loadAllSites,
    clearSitesState,
    loadAllUsers,
    clearUsersState,
    clearClientState,
    loadAllClients,
  ]);

  return avaiableSites === null ||
    avaiableUsers === null ||
    avaiableClients === null ? (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <Fragment>
      <Suspense
        fallback={
          <Spin
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="large"
          />
        }
      >
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          style={{
            paddingBottom: pageWidth < 795 && "0px",
            paddingTop: pageWidth < 795 && "0px",
          }}
          title="User Notifications"
          subTitle={pageWidth > 795 && "Manage User Notifications"}
          extra={[
            <Button
              key="NewUserNotificaiton"
              type="primary"
              disabled={permissions?.write === false ? true : false}
              icon={<PlusCircleOutlined />}
              onClick={ModalAddUserOpen}
            >
              {pageWidth > 795 && "New User Notificaiton"}
            </Button>,
          ]}
        ></PageHeader>

        <ModalAddSite pageWidth={pageWidth} isAdmin={isAdmin} />

        <ModalAddNewUser pageWidth={pageWidth} isAdmin={isAdmin} />

        <Row>
          <Col xs={24} md={11}>
            <NotificationTable
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
          <Col xs={0} md={1}></Col>
          <Col xs={24} md={12}>
            <NotificationCard
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
        </Row>
      </Suspense>
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  clearViewNotificationUser: PropTypes.func.isRequired,
  clearSitesState: PropTypes.func.isRequired,
  loadAllSites: PropTypes.func.isRequired,
  ModalAddUserOpen: PropTypes.func.isRequired,
  loadAllUsers: PropTypes.func.isRequired,
  clearUsersState: PropTypes.func.isRequired,
  avaiableSites: PropTypes.array,
  data: PropTypes.array,
  avaiableUsers: PropTypes.array,
  avaiableClients: PropTypes.array,
  clearClientState: PropTypes.func.isRequired,
  loadAllClients: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "User Notification Management"
  )[0],
  avaiableSites: state.sites.data,
  avaiableUsers: state.users.data,
  avaiableClients: state.clients.data,
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  clearViewNotificationUser,
  clearSitesState,
  loadAllSites,
  ModalAddUserOpen,
  loadAllUsers,
  clearUsersState,
  clearClientState,
  loadAllClients,
})(Index);
