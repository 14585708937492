import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";

// antd compone
import { Divider, Form, Input, Modal, Select } from "antd";

// external functions
import {
  ModalNewTypeItemClose,
  editATypeLocal,
} from "../../../../../actions/staffTools/types";
import axios from "axios";

// actual componet
const ModalAddTypeItem = ({
  pageWidth,
  modalVis,
  modalData,
  ModalNewTypeItemClose,
  editATypeLocal,
  editData,
  allTypes,
}) => {
  const [formDataType] = Form.useForm();
  const inputRef = useRef();
  const [formData, setFormData] = useState(null);
  const [variableSelect, setVariableSelect] = useState([]);

  const handleOK = useCallback(() => {
    formDataType.validateFields().then(() => {
      const currentData = { ...editData };
      const newData = formDataType.getFieldsValue();

      if (modalData.isEdit === false) {
        const id = uuid();

        if (modalData.type === "Alarm") {
          currentData.alarms.push({ ...newData, key: id });
        } else {
          const detailOfVariable = allTypes?.find(
            (varName) => varName._id === newData.childrenType
          );

          currentData.vairables.push({
            variable: newData.variable,
            symbol: newData.symbol,
            ...(newData.childrenType !== undefined
              ? {
                  ExtraChildren: {
                    type: detailOfVariable.device_type_name,
                    name:
                      newData.childrenName !== undefined
                        ? newData.childrenName
                        : "",
                  },
                }
              : {}),
            key: id,
          });
        }
      } else {
        if (modalData.type === "Alarm") {
          const index = currentData.alarms.findIndex(
            (item) => item.key === modalData.payload.key
          );

          if (index !== -1) {
            currentData.alarms[index] = {
              ...newData,
              key: modalData.payload.key,
            };
          }
        } else {
          const index = currentData.vairables.findIndex(
            (item) => item.key === modalData.payload.key
          );

          let detailOfVariable = allTypes?.find(
            (varName) => varName._id === newData.childrenType
          );

          if (detailOfVariable === undefined) {
            detailOfVariable = {};
            detailOfVariable.device_type_name = newData.childrenType;
          }

          if (index !== -1) {
            currentData.vairables[index] = {
              variable: newData.variable,
              key: modalData.payload.key,
              symbol: newData.symbol,
              ...(newData.childrenType !== undefined
                ? {
                    ExtraChildren: {
                      type: detailOfVariable.device_type_name,
                      name:
                        newData.childrenName !== undefined
                          ? newData.childrenName
                          : "",
                    },
                  }
                : {}),
            };
          }
        }
      }

      editATypeLocal({});
      editATypeLocal({ ...currentData });
      ModalNewTypeItemClose();
    });
  }, [
    modalData,
    editData,
    ModalNewTypeItemClose,
    editATypeLocal,
    formDataType,
    allTypes,
  ]);

  useEffect(() => {
    const listener = (event) => {
      // Listen fro both enter keys
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // submit the form
        handleOK();
      }
    };

    if (modalVis) {
      document.addEventListener("keydown", listener);

      setTimeout(() => {
        inputRef.current?.focus({
          cursor: "end",
        });
      }, 500);
    }

    // clean up the effect by removing it completely
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [modalVis, handleOK]);

  const handleCancel = () => {
    ModalNewTypeItemClose();
  };

  if (
    modalVis &&
    modalData?.payload !== null &&
    formDataType.getFieldValue(
      modalData.type === "Alarm" ? "name" : "variable"
    ) === undefined
  ) {
    formDataType.setFieldsValue({ ...modalData?.payload });

    if (modalData.type !== "Alarm") {
      formDataType.setFieldsValue({
        childrenType: modalData?.payload?.ExtraChildren?.type,
      });
      formDataType.setFieldsValue({
        childrenName: modalData?.payload?.ExtraChildren?.name,
      });
      setFormData({
        childrenType: modalData?.payload?.ExtraChildren?.type,
        childrenName: modalData?.payload?.ExtraChildren?.name,
      });
    }
  }

  const handleChildTypeSelect = async (value) => {
    try {
      if (value !== undefined) {
        const typeDetails = await axios.get("/api/config/types/" + value);

        if (typeDetails.data) {
          setVariableSelect([...typeDetails.data.vairables]);
        }
      } else {
        formDataType.setFieldsValue({
          childrenName: "",
        });
      }
      formDataType.setFieldsValue({
        symbol: "",
        variable: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleVariableSelect = (value) => {
    try {
      if (value !== undefined) {
        const detailOfVariable = variableSelect.find(
          (varName) => varName.variable === value
        );

        if (detailOfVariable) {
          formDataType.setFieldsValue({
            symbol: detailOfVariable.symbol,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={
        modalData !== null && modalData?.isEdit
          ? modalData?.type === "Alarm"
            ? "Editing Alarm"
            : "Editing Variables"
          : modalData?.type === "Alarm"
          ? "Add new alarm to type"
          : "Add new variable to type"
      }
      onCancel={handleCancel}
      onOk={handleOK}
      okButtonProps={{ disabled: formData === null ? true : false }}
      visible={modalVis}
      width={pageWidth >= 768 ? "40%" : "100%"}
      afterClose={() => {
        formDataType.resetFields();
        setFormData(null);
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        size={pageWidth >= 795 ? "middle" : "small"}
        form={formDataType}
        onValuesChange={(_, all) => setFormData(all)}
      >
        {modalData?.type !== "Alarm" && (
          <Fragment>
            <Form.Item
              label={<span style={{ color: "black" }}>Child type</span>}
              tooltip="Leave blank if not used, this is the devices within this main type"
              name={"childrenType"}
              style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            >
              <Select
                allowClear
                showSearch
                onChange={handleChildTypeSelect}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {allTypes?.map((type, index) => {
                  return (
                    <Select.Option
                      value={type._id}
                      key={index}
                      title={type.device_type_name}
                    >
                      {type.device_type_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            {formData?.childrenType !== undefined && (
              <Form.Item
                label={<span style={{ color: "black" }}>Child names</span>}
                tooltip="Leave blank if only 1 device else comma seperate the names for multiples"
                name={"childrenName"}
                style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
              >
                <Input />
              </Form.Item>
            )}
            <Divider />
          </Fragment>
        )}
        <Form.Item
          label={
            <span style={{ color: "black" }}>
              {modalData?.type === "Alarm" ? "Alarm name" : "Variable name"}
            </span>
          }
          name={modalData?.type === "Alarm" ? "name" : "variable"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: `Enter a valid name`,
            },
          ]}
        >
          {formData?.childrenType !== undefined ? (
            <Select showSearch onChange={handleVariableSelect}>
              {variableSelect.map((item, index) => (
                <Select.Option value={item.variable} key={index}>
                  {item.variable}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input ref={inputRef} />
          )}
        </Form.Item>
        {modalData?.type !== "Alarm" && (
          <Form.Item
            label={<span style={{ color: "black" }}>Symbol</span>}
            name={"symbol"}
            tooltip="Add commas if there are potentially more symbols to this variable"
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          >
            <Input
              disabled={formData?.childrenType !== undefined ? true : false}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

ModalAddTypeItem.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  ModalNewTypeItemClose: PropTypes.func.isRequired,
  editATypeLocal: PropTypes.func.isRequired,
  editData: PropTypes.object,
  allTypes: PropTypes.array,
};

const mapStateToProps = (state) => ({
  modalVis: state.types.modaltemAddVis,
  modalData: state.types.modaltemAddData,
  editData: state.types.editData,
  allTypes: state.types.data,
});

export default connect(mapStateToProps, {
  ModalNewTypeItemClose,
  editATypeLocal,
})(ModalAddTypeItem);
