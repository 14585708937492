import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { 
	Table, 
	Input,
	Button,
	Tooltip, 
	Space} from "antd";

import {
	RightOutlined,
	} from "@ant-design/icons";

import { connect } from "react-redux";

import UserDescriptions from "./UserDesicriptions";

function FilteredList({ usersData, isAdmin}) {
	const [dynamFilt, setdynamFilt] = useState();
	const [userListwID, setuserListwID] = useState();

	useEffect(() => {
		let usersWithKey = usersData
			?.filter((person) => person.staff)
			.map((person, i) => ({
				key: person._id,
				extension_number: person.staff.extension_number,
				...person,
			})).sort((a,b)=>a.name.localeCompare(b.name));
		setuserListwID(usersWithKey);
		setdynamFilt(usersWithKey);
	}, [usersData]);

	const Namefilters = usersData
		?.map((a) => a.name[0])
		.filter((x, i, a) => a.indexOf(x) === i)
		.map((a) => ({ text: a, value: a }));

	const hasExtentionFilter = [{ text: "Has Extention", value: "N/A" }];

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			filters: Namefilters,
			onFilter: (value, record) => record.name[0] === value,	
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Surname",
			dataIndex: "surname",
			sorter: (a, b) => a.surname.localeCompare(b.surname),
		},
		{
			title: "Contact Number",
			dataIndex: "contact_number",
		},
		{
			title: "Extension Number",
			dataIndex: "extension_number",
			filters: hasExtentionFilter,
			// onFilter: (value, record) => record.extension_number !== value,
		},
		{
			title: "Email",
			dataIndex: "email_address",
			render: (text) => <a href={"mailto:"+text}>{text}</a>,
		},
		...(isAdmin
			? [{
			title: "Action",
			key: "Actions",
			width: "100px",
			render: (text, record) => (
				<Space>
				  <Tooltip title="Edit/view notification user">
					<Link to={{ pathname: `/admin/user/${record?._id}` }}>
					  <Button shape="circle" icon={<RightOutlined />} size="small" />
					</Link>
				  </Tooltip>
				</Space>
			),
		  }]:[]),
	];

	function onChange(pagination, filters, sorter, extra) {
		console.log("params", pagination, filters, sorter, extra);
	}

	function onSearchChange(event) {
		// console.log(event.target.value);
		if (userListwID) {
			setdynamFilt(
				userListwID.filter(
					(person) =>
						person?.name.includes(event.target.value) ||
						// person?.site_name.includes(event.target.value) ||
						person?.client_name.includes(event.target.value) ||
						person?.surname.includes(event.target.value) ||
						person?.email_address.includes(event.target.value)
				)
			);
		}
	}

	return (
		<div className='App'>
			<Input placeholder='input search text' onChange={onSearchChange} />
			<Table
				columns={columns}
				dataSource={dynamFilt}
				onChange={onChange}
				expandable={{
					expandedRowRender: (record) => <UserDescriptions user={record} />,
					rowExpandable: (record) => record.name !== "Not Expandable",
				}}
			/>
		</div>
	);
}

const mapStateToProps = (state) => ({
	usersData: state.users.data,
	isAdmin: state.auth.isAdmin,
});

export default connect(mapStateToProps)(FilteredList);
