import "./style.scss";
import { Avatar, Badge, Button, Card, Divider, List, notification } from "antd";
import {
  UpOutlined,
  DownOutlined,
  UserOutlined,
  TeamOutlined,
  WechatOutlined,
  MessageOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  getChatMessages,
  getUserChatList,
  newMessageReceived,
  sendMessage,
} from "../../../actions/chat";
import { IChat, IMessage } from "../interfaces/IChat";
import { ISimpleUser } from "../../../interfaces/shared/ISimpleUser";
import ChatMessages from "../chatMessages";
import { ChatMessagesSocketContext } from "../../../ChatMessagesSocketContext";
import CreatePersonal from "../CreateChat/create_personal";

const ChatBar = (props: {
  userAuthenticated: boolean;
  authLoading: boolean;
  currentUser: any;
  messages: IMessage[];
  sendingMessage: boolean;
  chatList: IChat[];
  getUserChatList: Function;
  getChatMessages: Function;
  sendMessage: Function;
  newMessageReceived: Function;
  pageWidth: Number;
}) => {
  const { getUserChatList, getChatMessages, newMessageReceived, pageWidth } =
    props;
  const chatSocket = useContext(ChatMessagesSocketContext);

  const defaultChat: IChat = {
    _id: "",
    attached_to_task: false,
    client_id: "",
    created_at: new Date(),
    creator: {
      id: "",
      user_name: "",
      email_address: "",
    },
    members: [],
    name: "",
    type: "",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [hasLoadedChatList, setHasLoadedChatList] = useState(false);
  const [inChat, setInChat] = useState(false);

  const [selectedChat, setSelectedChat] = useState<IChat>(defaultChat);
  const [chatsWithNewMessages, setChatsWithNewMessages] = useState<string[]>(
    []
  );
  // const [ searchText, setSearchText ] = useState<string>('');
  // const [chats, setChats] = useState<IChat[]>([]);

  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [chatType, setChatType] = useState<string>("personal");
  // const searchList = (): void => {

  //     if(searchText !== ''){
  //         let newList: IChat[] = props.chatList
  //             .filter((chat: IChat) => chat.name.toLowerCase()
  //                 .includes(searchText.toLowerCase()) );
  //         setChats([...newList]);
  //     }else{
  //         setChats([...props.chatList]);
  //     }

  // }

  const handleNewMessageEvent = useCallback(
    (chat_id: string) => {
      let containsChat = chatsWithNewMessages.findIndex(
        (item) => item === chat_id
      );

      console.log(containsChat);
      console.log(chatsWithNewMessages);
      if (containsChat === -1) {
        setChatsWithNewMessages([...chatsWithNewMessages, chat_id]);
      }
    },
    [chatsWithNewMessages]
  );

  const checkHasMessage = (id: string): boolean => {
    let containsChat = chatsWithNewMessages.findIndex((item) => item === id);
    return containsChat !== -1;
  };

  const initChats = () => {
    // setTimeout(() => {
    //   setChats([...props.chatList]);
    // }, 2000);
  };

  const initiateChat = useCallback(
    (chat: IChat) => {
      setSelectedChat(chat);
      getChatMessages(chat._id);
      setInChat(true);

      let chatHasIndicator = chatsWithNewMessages.findIndex(
        (item) => item === chat._id
      );
      if (chatHasIndicator !== -1) {
        let list: string[] = [...chatsWithNewMessages].filter(
          (item) => item !== chat._id
        );
        setChatsWithNewMessages([...list]);
      }
    },
    [chatsWithNewMessages, getChatMessages]
  );

  useEffect(() => {
    chatSocket?.on("new_chat_message", (data: IMessage) => {
      if (props.userAuthenticated && !props.authLoading) {
        if (data?.from?.id !== props?.currentUser?._id) {
          handleNewMessageEvent(data.chat_id);

          // console.log("[dd] ", previousMessageID, data._id);
          // if (data.from.id === props.currentUser._id) {
          //   getChatMessages(props.chat._id);
          // } else {
          // if (previousMessageID !== data._id) {
          newMessageReceived(data);
          // }
          // }
          // previousMessageID = data._id;
        }
      }

      if (!inChat) {
        let isInChatList = props.chatList.find(
          (chat: IChat) => chat?._id === data.chat_id
        );

        /*
         ******** ONLY IF NOT TASK CHAT ********
         * */
        if (isInChatList !== undefined) {
          notification.open({
            icon: <MessageOutlined />,
            message: "New message received",
            // description: data.message,
            description: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <span>{data.message}</span>
                <small>{data.from.user_name}</small>
              </div>
            ),
            duration: 10,
            btn: (
              <Button
                type={"link"}
                style={{ float: "left" }}
                onClick={() => {
                  if (isInChatList !== undefined) {
                    setIsOpen(true);
                    //@ts-ignore
                    let chat: IChat = props.chatList.find(
                      (chat: IChat) => chat?._id === data.chat_id
                    );
                    initiateChat(chat);
                    notification.destroy();
                  }
                }}
              >
                View Message
              </Button>
            ),
          });
        }
      }
    });

    return () => {
      chatSocket?.off("new_chat_message");
    };
  }, [
    chatSocket,
    inChat,
    handleNewMessageEvent,
    props.authLoading,
    props.chatList,
    props.currentUser?._id,
    props.userAuthenticated,
    initiateChat,
    newMessageReceived,
  ]);

  useEffect(() => {
    if (props.userAuthenticated && !props.authLoading) {
      if (!hasLoadedChatList) {
        getUserChatList(props.currentUser?._id);
        setHasLoadedChatList(true);
        initChats();
      }
    }
  }, [
    getUserChatList,
    hasLoadedChatList,
    props.authLoading,
    props.currentUser?._id,
    props.userAuthenticated,
  ]);

  const openMessagesBar = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const chatLoader = () => <></>;

  const unCollapsedBar = () =>
    isOpen ? (
      <div className="chat-bar">
        {/************MODALS************/}
        <CreatePersonal
          chatType={chatType}
          isVisible={createModalVisible}
          handleOk={() => {
            getUserChatList(props.currentUser._id);
            setCreateModalVisible(false);
          }}
          handleCancel={() => {
            getUserChatList(props.currentUser._id);
            setCreateModalVisible(false);
          }}
        />

        <Card
          title={
            <div className={"chat-bar-card"} onClick={openMessagesBar}>
              <Avatar size="small" icon={<WechatOutlined />} />{" "}
              <small>Messaging</small>
            </div>
          }
          bordered={false}
          style={{ borderRadius: "12px", borderBottom: 0 }}
          extra={
            <Button
              key={"chat-bar-action-opened"}
              icon={<DownOutlined />}
              size={"small"}
              type={"default"}
              shape={"circle"}
              onClick={openMessagesBar}
            />
          }
          bodyStyle={{ padding: "0", width: "100%", minHeight: "100%" }}
        >
          <div className="chat-creation-actions">
            <Button
              type={"link"}
              icon={<UserAddOutlined />}
              onClick={() => {
                setChatType("personal");
                setCreateModalVisible(true);
              }}
            >
              New Chat
            </Button>

            <Button
              type={"link"}
              icon={<UsergroupAddOutlined />}
              onClick={() => {
                setChatType("group");
                setCreateModalVisible(true);
              }}
            >
              New Group
            </Button>
          </div>
          <Divider style={{ marginTop: 10 }} />

          {/*<div className="chat-bar-search-and-filter">*/}
          {/*    <Input prefix={<SearchOutlined />}*/}
          {/*           placeholder="Search Messages"*/}
          {/*           style={{ width: '100%' }}*/}
          {/*           value={searchText}*/}
          {/*           onChange={(e:ChangeEvent<HTMLInputElement>) => {*/}
          {/*               console.log('Search input changed', e.target.value );*/}
          {/*               setSearchText(e.target.value);*/}
          {/*               searchList();*/}
          {/*           }}*/}
          {/*    />*/}
          {/*</div>*/}

          <Divider orientation={"left"} plain>
            Chats ({props.chatList.length})
          </Divider>
          <List
            style={{
              width: "95%",
              margin: "0 auto",
              overflowY: "scroll",
              height: "68vh",
            }}
            size={"small"}
            itemLayout="horizontal"
            dataSource={props.chatList}
            renderItem={(item) => (
              <List.Item
                className="chat-list-item"
                actions={
                  [
                    // <Button
                    //     key={'CHAT-BAR-' + item._id}
                    //     type={'link'}
                    //     icon={ <RightOutlined />}
                    //     onClick={() => {
                    //         initiateChat(item);
                    //     }}
                    // />,
                    // <Badge dot={checkHasMessage(item._id)}/>
                  ]
                }
                onClick={() => {
                  initiateChat(item);
                }}
              >
                <List.Item.Meta
                  avatar={
                    <Badge dot={checkHasMessage(item._id)}>
                      <Avatar
                        size="small"
                        style={{ backgroundColor: "#001528" }}
                        icon={
                          item.type === "personal" ? (
                            <UserOutlined />
                          ) : (
                            <TeamOutlined />
                          )
                        }
                      />
                    </Badge>
                  }
                  title={
                    item.type === "personal" ? (
                      <span>
                        {
                          //@ts-ignore
                          item.members.find(
                            (member: ISimpleUser) =>
                              member.id !== props.currentUser._id
                          ).user_name
                        }
                      </span>
                    ) : (
                      <span>{item.name}</span>
                    )
                  }
                  description={
                    item.type === "personal" ? (
                      <small>
                        {
                          //@ts-ignore
                          item.members.find(
                            (member: ISimpleUser) =>
                              member.id !== props.currentUser._id
                          ).email_address
                        }
                      </small>
                    ) : (
                      <small>Group Chat</small>
                    )
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </div>
    ) : pageWidth > 795 ? (
      <div
        className="chat-bar"
        style={{
          height: "6vh",
        }}
      >
        <Card
          bodyStyle={{ padding: 0 }}
          bordered={false}
          style={{ borderRadius: "12px", borderBottom: 0, padding: 0 }}
          title={
            <div className={"chat-bar-card"} onClick={openMessagesBar}>
              <Avatar size="small" icon={<WechatOutlined />} />{" "}
              <small>Messaging</small>
            </div>
          }
          extra={
            <Button
              key={"chat-bar-action-closed"}
              icon={<UpOutlined />}
              size={"small"}
              type={"default"}
              shape={"circle"}
              onClick={openMessagesBar}
            />
          }
        />
      </div>
    ) : (
      <></>
    );

  return !inChat ? (
    hasLoadedChatList ? (
      unCollapsedBar()
    ) : (
      chatLoader()
    )
  ) : (
    <div className="chat-bar">
      <ChatMessages
        chat={selectedChat}
        currentUser={props.currentUser}
        onBack={() => {
          setInChat(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userAuthenticated: state.auth.isAuthenticated,
  authLoading: state.auth.loading,
  currentUser: state.auth.user,
  messages: state.chat.selectedChatMessages,
  sendingMessage: state.chat.sendingMessage,
  chatList: state.chat.chatList,
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  getUserChatList,
  getChatMessages,
  sendMessage,
  newMessageReceived,
})(ChatBar);
