import axios from "axios";
import { setAlert } from "../../alert";
import setAuthToken from "../../../utils/setAuthToken";
import { logout } from "../../auth";
import {
  SALESMY_CLEARALL,
  SALESMY_DASHLOADING,
  SALESMY_EDIT,
  SALESMY_EDIT_VIEW,
  SALESMY_GET_ALL,
  SALESMY_GET_DASH,
  SALESMY_LOADING,
  SALESMY_MODAL_ADDNEWCLIENT_CLOSE,
  SALESMY_MODAL_ADDNEWCLIENT_OPEN,
  SALESMY_MODAL_ADDSALE_CLOSE,
  SALESMY_MODAL_ADDSALE_OPEN,
  SALESMY_MODAL_EDITSALE_CLOSE,
  SALESMY_MODAL_EDITSALE_OPEN,
} from "../../types";

// Gets all the sales related sales
export const loadAllSalesMySales = () => async (dispatch) => {
  try {
    dispatch({
      type: SALESMY_LOADING,
      payload: true,
    });

    let res = await axios.get("/api/mysales");

    dispatch({
      type: SALESMY_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: SALESMY_LOADING,
      payload: false,
    });
  }
};

// Gets all the CRM Dashboard items
export const loadSalesDashBoardItems = () => async (dispatch) => {
  try {
    dispatch({
      type: SALESMY_DASHLOADING,
      payload: true,
    });

    let res = await axios.get("/api/mysales/dashboard");

    dispatch({
      type: SALESMY_GET_DASH,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: SALESMY_DASHLOADING,
      payload: false,
    });
  }
};

// clears all the store items for the sales
export const clearMySalesState = () => (dispatch) => {
  dispatch({
    type: SALESMY_CLEARALL,
  });
};

// view details of a specific sale items
export const EditMySale = (details) => (dispatch) => {
  dispatch({
    type: SALESMY_EDIT,
    payload: details,
  });
};

// Viewing details of the editing sale
export const EditMySaleView = (details) => (dispatch) => {
  dispatch({
    type: SALESMY_EDIT_VIEW,
    payload: details,
  });
};

// updates a specific sales item, needs the sales object id
export const UpdateSpecificMySales =
  (salesID, salesData) => async (dispatch) => {
    try {
      if (salesID) {
        dispatch({
          type: SALESMY_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // update the specific clients details
        let res = await axios.put("/api/mysales/" + salesID, {
          data: salesData,
        });

        // get the clients
        res = await axios.get("/api/mysales");

        dispatch(setAlert("Sale is updated succesfully", "success"));

        dispatch({
          type: SALESMY_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));

        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
      }
      dispatch({
        type: SALESMY_LOADING,
        payload: false,
      });
    }
  };

// deletes a specific sales related clients, needs the client object id
export const DeleteSpecificMySales = (salesID) => async (dispatch) => {
  try {
    if (salesID) {
      dispatch({
        type: SALESMY_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the specific client
      let res = await axios.delete("/api/mysales/" + salesID);

      // get all the client
      res = await axios.get("/api/mysales");

      dispatch(setAlert("Sale was deleted successful", "success"));

      dispatch({
        type: SALESMY_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: SALESMY_LOADING,
      payload: false,
    });
  }
};

// open the modal to add a new Sale
export const ModalAddNewSalesOpen = (editData) => (dispatch) => {
  dispatch({
    type: SALESMY_MODAL_ADDNEWCLIENT_OPEN,
    payload: editData,
  });
};

// close the modal to add a new Sale
export const ModalAddNewSalesClose = () => (dispatch) => {
  dispatch({
    type: SALESMY_MODAL_ADDNEWCLIENT_CLOSE,
  });
};

// open the modal to add a new Sale item
export const ModalAddNewSalesItemOpen =
  (data, isEDIT, isEDITData) => (dispatch) => {
    dispatch({
      type: SALESMY_MODAL_ADDSALE_OPEN,
      payload: { data, isEDIT, isEDITData },
    });
  };

// open the modal to add a new Sale item
export const ModalEditSalesItemOpen = (data) => async (dispatch) => {
  const salesItem = await axios.get("/api/mysales/" + data._id);

  dispatch({
    type: SALESMY_MODAL_EDITSALE_OPEN,
    payload: salesItem.data,
  });
};

// open the modal to add a new Sale item
export const ModalEditSalesItemClose = () => (dispatch) => {
  dispatch({
    type: SALESMY_MODAL_EDITSALE_CLOSE,
  });
};

// close the modal to add a new Sale item
export const ModalAddNewSalesItemClose = () => (dispatch) => {
  dispatch({
    type: SALESMY_MODAL_ADDSALE_CLOSE,
  });
};

// Create a new Sale
export const NewSale =
  (data, fromImporter = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SALESMY_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // Send the data to create a new one
      await axios.post("/api/mysales", {
        data,
      });

      if (fromImporter === false) {
        // get all the services
        let res = await axios.get("/api/mysales");

        dispatch({
          type: SALESMY_GET_ALL,
          payload: res.data,
        });

        dispatch(setAlert("New sale added", "success"));

        dispatch({
          type: SALESMY_MODAL_ADDNEWCLIENT_CLOSE,
        });
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        const errors = error.response.data.errors;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }
      } else if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));
        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
      }
      dispatch({
        type: SALESMY_LOADING,
        payload: false,
      });
    }
  };
