import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Row, Col, Card, Descriptions, Switch, InputNumber } from "antd";
import { ProfileOutlined } from "@ant-design/icons";

// external functions
import { EditSpecificUser } from "../../../../actions/users";

// actual component
const Advanced = ({ userData, EditSpecificUser, pageWidth }) => {
  const columbWidth = pageWidth >= 795 ? "200px" : "70px";

  const handleChange = (e) => {
    if (e) {
      const user = { ...userData };

      if (user.management) {
        user.management.passwordExpiryDays = e;

        EditSpecificUser(user);
      }
    }
  };

  const handleTokenChange = (e) => {
    if (e) {
      const user = { ...userData };

      if (user.management) {
        user.management.tokenExpiry = e.toString() + "d";

        EditSpecificUser(user);
      }
    }
  };

  const handleSwitch = (section, val) => {
    const user = { ...userData };

    if (user.management) {
      user.management[section] = val;
      EditSpecificUser(user);
    }
  };

  const handleSwitch2FA = (val) => {
    const user = { ...userData };

    if (user.management) {
      user.management.twoFA.isEnabled = val;
      EditSpecificUser(user);
    }
  };

  return (
    <Card
      title={
        <span>
          <ProfileOutlined /> Advanced settings
        </span>
      }
      bordered={false}
    >
      <Row>
        <Col xs={24} md={19}>
          <Descriptions bordered>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bold", width: columbWidth }}
              label="User Enable"
              span={4}
            >
              <Switch
                onChange={(val) => handleSwitch("isEnabled", val)}
                checked={userData?.management?.isEnabled}
                size={pageWidth >= 795 ? "default" : "small"}
              ></Switch>
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bold", width: columbWidth }}
              label="Enable 2FA"
              span={4}
            >
              <Switch
                onChange={(val) => handleSwitch2FA(val)}
                checked={userData?.management?.twoFA.isEnabled}
                size={pageWidth >= 795 ? "default" : "small"}
              ></Switch>
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bold", width: columbWidth }}
              label="Enable password expiry"
              span={4}
            >
              <Switch
                onChange={(val) => handleSwitch("passwordExpiry", val)}
                checked={userData?.management?.passwordExpiry}
                size={pageWidth >= 795 ? "default" : "small"}
              ></Switch>
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bold", width: columbWidth }}
              label="Password expiry days"
              span={4}
            >
              <InputNumber
                step={1}
                min={1}
                onChange={(input) => handleChange(input)}
                value={userData?.management?.passwordExpiryDays}
                size="small"
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bold", width: columbWidth }}
              label="Token expiry days"
              span={4}
            >
              <InputNumber
                step={1}
                min={1}
                onChange={(input) => handleTokenChange(input)}
                value={userData?.management?.tokenExpiry.replace("d", "")}
                size="small"
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col></Col>
      </Row>
    </Card>
  );
};

Advanced.propTypes = {
  userData: PropTypes.object,
  EditSpecificUser: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  userData: state.users?.editData,
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  EditSpecificUser,
})(Advanced);
