import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external components
import ServicesTable from "./serviceTable";
import ServicesCard from "./serviceCard";
import ModalAddNew from "./modals/ModalAddNew";

// external functions
import {
  clearServicesState,
  loadAllServices,
  ModalAddServiceOpen,
} from "../../../actions/services";

// actual component
const Index = ({
  permissions,
  isAdmin,
  clearServicesState,
  loadAllServices,
  ModalAddServiceOpen,
  pageWidth,
}) => {
  useEffect(() => {
    loadAllServices();

    // clean up the effect by removing it completely
    return () => {
      clearServicesState();
    };
  }, [clearServicesState, loadAllServices]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Services"
        subTitle={pageWidth >= 795 && "Manage all service types"}
        extra={[
          <Button
            key="NewService"
            type="primary"
            disabled={isAdmin ? false : !permissions?.write}
            icon={<PlusCircleOutlined />}
            onClick={ModalAddServiceOpen}
          >
            {pageWidth >= 795 && "New Service"}
          </Button>,
        ]}
      ></PageHeader>

      <ModalAddNew pageWidth={pageWidth} isAdmin={isAdmin} />

      <Row>
        <Col xs={24} md={11}>
          <ServicesTable
            pageWidth={pageWidth}
            isAdmin={isAdmin}
            permissions={permissions}
          />
        </Col>
        <Col xs={0} md={1}></Col>
        <Col xs={24} md={12}>
          <ServicesCard
            pageWidth={pageWidth}
            isAdmin={isAdmin}
            permissions={permissions}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  clearServicesState: PropTypes.func.isRequired,
  loadAllServices: PropTypes.func.isRequired,
  ModalAddServiceOpen: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Service Management"
  )[0],
});

export default connect(mapStateToProps, {
  clearServicesState,
  loadAllServices,
  ModalAddServiceOpen,
})(Index);
