import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Modal, Form, Input, Select } from "antd";

// external functions
import { ModalRoleAddClose, NewRole } from "../../../../actions/roles";

const { Option } = Select;

// acutal component
const ModalAddNew = ({
  modalAddRoleVis,
  ModalRoleAddClose,
  NewRole,
  avaiablePermissions,
}) => {
  const [formInput] = Form.useForm();
  const inputRef = useRef(null);
  const [okBtnLoading, setOkBtnLoading] = useState(false);

  const listener = (event) => {
    // Listen fro both enter keys
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      // submit the form
      formInput.submit();
    }
  };

  if (modalAddRoleVis) {
    document.addEventListener("keydown", listener);

    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);
  }

  const handleOK = async () => {
    setOkBtnLoading(true);
    let data = formInput.getFieldsValue();

    if (data.permissions.length > 0) {
      let permissions = [];

      data.permissions.map((perm) => {
        avaiablePermissions.map((avaiPerm) => {
          if (avaiPerm.name === perm) permissions.push(avaiPerm);

          return true;
        });
        return true;
      });

      data.permissions = permissions;
    }
    await NewRole(data);
    ModalRoleAddClose();
  };

  const handleCancel = () => {
    ModalRoleAddClose();
  };

  return (
    <Modal
      onCancel={handleCancel}
      okText="Create"
      okButtonProps={{ loading: okBtnLoading }}
      onOk={() => formInput.validateFields().then(handleOK)}
      visible={modalAddRoleVis}
      afterClose={() => {
        document.removeEventListener("keydown", listener);
        formInput.resetFields();
        setOkBtnLoading(false);
      }}
      title="Create new role"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        size="small"
        form={formInput}
      >
        <Form.Item
          label={<label style={{ color: "black" }}>Name</label>}
          style={{ marginBottom: "5px" }}
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item
          label={<label style={{ color: "black" }}>Description</label>}
          name="description"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={<label style={{ color: "black" }}>Permissions</label>}
          name="permissions"
          style={{ marginBottom: "5px" }}
          extra="You can select mutiple options"
        >
          <Select allowClear showSearch mode={"multiple"}>
            {avaiablePermissions?.map((perm, index) => (
              <Option key={index} value={perm.name}>
                {perm.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAddNew.propTypes = {
  modalAddRoleVis: PropTypes.bool,
  ModalRoleAddClose: PropTypes.func.isRequired,
  NewRole: PropTypes.func.isRequired,
  avaiablePermissions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  avaiablePermissions: state.permissions.data,
  modalAddRoleVis: state.roles.modalAddRoleVis,
});

export default connect(mapStateToProps, { ModalRoleAddClose, NewRole })(
  ModalAddNew
);
