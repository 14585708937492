import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

// andt Components
import {
  PageHeader,
  Button,
  DatePicker,
  Empty,
  Select,
  Space,
  Switch,
  Spin,
  Progress,
  message,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// External functions
import { clearClientState, loadAllClients } from "../../../../actions/clients";
import { clearSitesState, loadAllSites } from "../../../../actions/sites";
import moment from "moment";

const { RangePicker } = DatePicker;

// actual component
const Index = ({
  isAdmin,
  pageWidth,
  clearClientState,
  loadAllClients,
  clientData,
  clearSitesState,
  loadAllSites,
  siteData,
  clientDataLoading,
  siteDataLoading,
  userClientID,
}) => {
  useEffect(() => {
    loadAllClients();
    loadAllSites();

    // clean up the effect by removing it completely
    return () => {
      clearClientState();
      clearSitesState();
    };
  }, [loadAllClients, clearClientState, clearSitesState, loadAllSites]);

  const [dates, setDates] = useState(null);
  const [selectedClient, setSelectedClient] = useState(
    isAdmin === true ? null : userClientID
  );
  const [selectedSite, setSelectedSite] = useState(null);
  const [allSitesCheck, setAllSitesCheck] = useState(false);
  const [progressPersentage, setProgressPersentage] = useState(0);
  const [loadingBtn, setLoadingBtn] = useState();

  const HandleDateChange = (value) => {
    if (value) {
      const d = new Date(value[1]._d);
      let dateTO = d.toISOString();
      dateTO = dateTO.split("T");
      dateTO = dateTO[0];

      const dt = new Date(value[0]._d);
      let dateFrom = dt.toISOString();
      dateFrom = dateFrom.split("T");
      dateFrom = dateFrom[0];

      setDates({ dateTO, dateFrom });
    } else {
      setDates(null);
    }
  };

  const handleClientSelect = (value) => {
    if (value === undefined) setSelectedClient(null);
    else setSelectedClient(value);
  };

  const handleSiteSelect = (value) => {
    if (value.length === 0) setSelectedSite(null);
    else setSelectedSite(value);
  };

  const onSwitchChange = (checked) => {
    let siteArray = [];
    setAllSitesCheck(checked);

    if (checked) {
      siteData
        ?.filter((site) => site.client_id === selectedClient)
        .map((sitedetail) => {
          if (sitedetail?.legacy_id) siteArray.push(sitedetail.legacy_id);
          else siteArray.push(sitedetail._id);

          return true;
        });
      setSelectedSite(siteArray);
    } else {
      setSelectedSite(null);
    }
  };

  const HandleDownload = async () => {
    try {
      let resCSVData = "";
      let siteCounter = 0;

      setProgressPersentage(0);

      if (dates) {
        setLoadingBtn(true);

        for (const site of selectedSite) {
          // get the temperatures
          const res = await axios.get(
            `https://nodered.cloudbms.co.za/api/analytics/temperatures_avarage_csv?site_id=${site}&date_from=${dates.dateFrom}&date_to=${dates.dateTO}`
          );

          if (siteCounter > 0) {
            // break the textblock into an array of lines
            var lines = res.data.split("\n");
            // remove one line, starting at the first position
            lines.splice(0, 1);
            // join the array back into a single string
            var newtext = lines.join("\n");
            resCSVData += newtext;
            resCSVData += "\n";
          } else {
            resCSVData += res.data;
            resCSVData += "\n";
          }
          siteCounter++;

          let completionProgress = (
            (siteCounter / selectedSite.length) *
            100
          ).toFixed(2);
          setProgressPersentage(completionProgress);
        }

        const csvData = new Blob([resCSVData], {
          type: "text/csv;charset=utf-8;",
        });

        const downloadLink = document.createElement("a");
        const fileName = `Temperature report ${dates.dateFrom} to ${dates.dateTO}.csv`;

        downloadLink.href = URL.createObjectURL(csvData);
        downloadLink.download = fileName;
        downloadLink.click();
        setLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
      message.error("😭 Error occurred !! 😭", 5);
      setLoadingBtn(false);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  return clientDataLoading || siteDataLoading ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Temperature Report"
        subTitle={
          pageWidth >= 795 && "Get the daily average temperature report"
        }
        extra={[
          <Button
            key="NewReport"
            type="primary"
            disabled={dates !== null && selectedSite !== null ? false : true}
            icon={<PlusCircleOutlined />}
            onClick={HandleDownload}
            loading={loadingBtn}
          >
            {pageWidth >= 795 && "Generate"}
          </Button>,
        ]}
      >
        <Space>
          <RangePicker
            onChange={HandleDateChange}
            disabled={loadingBtn}
            disabledDate={disabledDate}
          />
          {isAdmin && (
            <Select
              style={{ width: "250px" }}
              allowClear
              placeholder="Select client"
              onChange={handleClientSelect}
              disabled={loadingBtn}
            >
              {clientData?.map((client) => (
                <Select.Option key={client._id} value={client._id}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          )}
          All sites
          <Switch onChange={onSwitchChange} disabled={loadingBtn} />
          {allSitesCheck === false && (
            <Select
              style={{ width: "250px" }}
              allowClear
              mode="multiple"
              placeholder="Select site"
              onChange={handleSiteSelect}
              disabled={loadingBtn}
            >
              {siteData
                ?.filter((site) => site.client_id === selectedClient)
                .map((sitedetail) => (
                  <Select.Option
                    key={sitedetail._id}
                    value={sitedetail.legacy_id}
                  >
                    {sitedetail.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </Space>
      </PageHeader>

      {loadingBtn === true ? (
        <Progress
          type="circle"
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform:
              pageWidth >= 768
                ? "translate(-50%, -50%)"
                : "translate(-50%, 50%)",
          }}
          percent={progressPersentage}
        />
      ) : (
        <Empty
          description={
            <span>Select a start and end date then press generate</span>
          }
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform:
              pageWidth >= 768
                ? "translate(-50%, -50%)"
                : "translate(-50%, 50%)",
          }}
        />
      )}
    </Fragment>
  );
};

Index.propTypes = {
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  clearClientState: PropTypes.func.isRequired,
  loadAllClients: PropTypes.func.isRequired,
  clientData: PropTypes.array,
  clientDataLoading: PropTypes.bool,
  siteData: PropTypes.array,
  siteDataLoading: PropTypes.bool,
  clearSitesState: PropTypes.func.isRequired,
  loadAllSites: PropTypes.func.isRequired,
  userClientID: PropTypes.string,
};

const mapStateToProps = (state) => ({
  siteData: state.sites.data,
  siteDataLoading: state.sites.loading,
  clientData: state.clients.data,
  clientDataLoading: state.clients.loading,
  userClientID: state.auth?.user?.client_id,
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  clearClientState,
  loadAllClients,
  clearSitesState,
  loadAllSites,
})(Index);
