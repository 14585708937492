import React, { Fragment, useState, useCallback, useEffect } from "react";
import moment from "moment";
import axios from "axios";

// andt Components
import {
  Button,
  DatePicker,
  Empty,
  PageHeader,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { PlusCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// External componetns
import MeterTabs from "./MeterTabs";

const { RangePicker } = DatePicker;

// actual component
const Index = ({ pageWidth, selectedTab, selectedSite }) => {
  const [dates, setDates] = useState(null);
  const [metersListing, setMetersList] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState([]);

  const [recData, setRecData] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [metersListLoading, setMetersListLoading] = useState(true);

  const GetMetersList = useCallback(async () => {
    try {
      const rec = await axios.get(
        `https://noderedinternal.cloudbms.co.za/api/OADC/site/power_meter_names?site_id=${selectedSite}`
      );

      setMetersList(rec.data);
      setMetersListLoading(false);
    } catch (error) {
      console.log("GetMetersList, ", error);
      message.error("Meters list error");
      setMetersListLoading(false);
    }
  }, [selectedSite]);

  useEffect(() => {
    if (selectedTab === "2") GetMetersList();
    // clean up the effect by removing it completely
    return () => {
      setSelectedMeter([]);
      setRecData([]);
    };
  }, [GetMetersList, selectedTab]);

  const HandleGenerate = async () => {
    try {
      if (dates && selectedMeter.length > 0) {
        setLoadingBtn(true);
        setRecData([]);

        let allData = [];

        await Promise.all(
          selectedMeter.map(async (meterNames) => {
            let res = await axios.post(
              `https://noderedinternal.cloudbms.co.za/api/OADC/site/meter_energy`,
              {
                email: sessionStorage.getItem("email"),
                password: sessionStorage.getItem("ppps"),
                meter_id: meterNames,
                // date_from: dates.dateFrom,
                date_from: dates.dateFrom.split("T")[0],
                // date_to: dates.dateTO,
                date_to: dates.dateTO.split("T")[0],
              }
            );
            let meterStats = await axios.post(
              `https://noderedinternal.cloudbms.co.za/api/OADC/site/meter`,
              {
                email: sessionStorage.getItem("email"),
                password: sessionStorage.getItem("ppps"),
                meter_id: meterNames,
                // date_from: dates.dateFrom,
                date_from: dates.dateFrom.split("T")[0],
                // date_to: dates.dateTO,
                date_to: dates.dateTO.split("T")[0],
              }
            );

            let formatedData = {};

            if (res?.data?.values !== undefined) {
              let tableData = [...res.data.values];
              let otherData = { ...meterStats?.data[0] };

              // add symbols
              if (otherData?.used_energy)
                otherData.used_energy += ` ${otherData.energy_symbol}`;
              if (otherData?.avg_voltage)
                otherData.avg_voltage += ` ${otherData.voltage_symbol}`;
              if (otherData?.avg_power)
                otherData.avg_power += ` ${otherData.power_symbol}`;
              if (otherData?.avg_apparent_power)
                otherData.avg_apparent_power += ` ${otherData.apparent_power_symbol}`;
              if (otherData?.avg_current)
                otherData.avg_current += ` ${otherData.current_symbol}`;

              // remove kak
              if (otherData?.device_name) delete otherData.device_name;
              if (otherData?.site_name) delete otherData.site_name;
              if (otherData?.client_id) delete otherData.client_id;
              if (otherData?.site_id) delete otherData.site_id;
              if (otherData?.values) delete otherData.values;
              if (otherData?.energy_symbol) delete otherData.energy_symbol;
              if (otherData?.voltage_symbol) delete otherData.voltage_symbol;
              if (otherData?.power_symbol) delete otherData.power_symbol;
              if (otherData?.apparent_power_symbol)
                delete otherData.apparent_power_symbol;
              if (otherData?.current_symbol) delete otherData.current_symbol;

              formatedData = {
                tableData,
                stats: otherData,
                meterName: meterNames,
              };

              allData.push({ ...formatedData });
            } else {
              message.warning("No data for this query", 5);
            }

            return true;
          })
        );

        setRecData(allData);
        setLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
      message.error("😭 Error occurred !! 😭", 5);
      setLoadingBtn(false);
    }
  };

  const HandleDateChange = (value) => {
    if (value) {
      // const d = new Date(value[1]._d);
      const d = moment(value[1]._d);
      // d.setHours(0, 0, 0, 0);
      let dateTO = d.toISOString();

      // const dt = new Date(value[0]._d);
      const dt = moment(value[0]._d);
      // dt.setHours(0, 0, 0, 0);
      let dateFrom = dt.toISOString();

      setDates({ dateTO, dateFrom });
    } else {
      setDates(null);
    }
  };

  const handleMeterChange = (value) => {
    if (value?.length > 0) return setSelectedMeter(value);

    setSelectedMeter([]);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const HandleDownload = () => {
    recData.map((meterItem) => {
      // let formatedCSVData = "Date,Consumed\n";
      let formatedCSVData = `Date From,${dates.dateFrom.split("T")[0]}\n`;
      formatedCSVData += `Date To,${dates.dateTO.split("T")[0]}\n`;
      formatedCSVData += `Meter,${meterItem.meterName}\n`;
      formatedCSVData += `\n`;
      formatedCSVData += `Used Energy,${meterItem?.stats?.used_energy}\n`;
      formatedCSVData += `Average Power,${meterItem?.stats?.avg_power}\n`;
      formatedCSVData += `Average Current,${meterItem?.stats?.avg_current}\n`;
      formatedCSVData += `\n`;
      formatedCSVData += "Date,Consumed\n";

      meterItem.tableData.map((item) => {
        formatedCSVData += `${item.time},${item.value},\n`;
        return true;
      });

      const csvData = new Blob([formatedCSVData], {
        type: "text/csv;charset=utf-8;",
      });

      const downloadLink = document.createElement("a");

      let fileName = `${
        meterItem.meterName
      } Power readings ${new Date().toISOString()}.csv`;

      downloadLink.href = URL.createObjectURL(csvData);
      downloadLink.download = fileName;
      downloadLink.click();

      return true;
    });
  };

  return metersListLoading === true ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Fragment>
      <PageHeader
        ghost={false}
        title="Per meter energy"
        subTitle="Select a date range and a meter, then press generate"
        extra={[
          <Button
            key="btnDownload"
            type="default"
            disabled={recData.length === 0}
            icon={<CloudDownloadOutlined />}
            onClick={HandleDownload}
          >
            {pageWidth >= 795 && "Download"}
          </Button>,
          <Button
            key="btnGenerate"
            type="primary"
            disabled={dates !== null && selectedMeter.length > 0 ? false : true}
            icon={<PlusCircleOutlined />}
            onClick={HandleGenerate}
          >
            {pageWidth >= 795 && "Generate"}
          </Button>,
        ]}
      >
        <Space>
          <RangePicker
            onChange={HandleDateChange}
            disabled={loadingBtn}
            disabledDate={disabledDate}
          />
          {metersListing && metersListing?.length > 0 && (
            <Select
              style={{ width: "350px" }}
              allowClear
              placeholder="Select a meter"
              onChange={handleMeterChange}
              disabled={loadingBtn}
              value={selectedMeter}
              showSearch
              mode="multiple"
              maxTagCount="responsive"
            >
              {metersListing.map((meterDetails) => (
                <Select.Option key={meterDetails} value={meterDetails}>
                  {meterDetails}
                </Select.Option>
              ))}
            </Select>
          )}
        </Space>
      </PageHeader>
      {recData.length === 0 ? (
        loadingBtn === true ? (
          <Spin
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="large"
          />
        ) : (
          <Empty
            description={
              <span>Select a start and end date then press generate</span>
            }
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "50%",
              transform:
                pageWidth >= 768
                  ? "translate(-50%, -50%)"
                  : "translate(-50%, 50%)",
            }}
          />
        )
      ) : (
        <MeterTabs tabItems={recData} pageWidth={pageWidth} />
      )}
    </Fragment>
  );
};

export default Index;
