import { Layout } from "antd";
import { Fragment, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// extenal functions
import { ChangeHeight, ChangeWidth } from "../../actions/Misc";
import { useLocation } from "react-router-dom";
import ChatBar from "../../modules/chat/chatBar";

const { Footer } = Layout;

// actual component
const Footers = ({
  ChangeWidth,
  pageWidth,
  userAuthenticated,
  ChangeHeight,
}) => {
  const location = useLocation();

  const handleWindowSizeChange = useCallback(() => {
    ChangeWidth(window.innerWidth);
    ChangeHeight(window.innerHeight);
  }, [ChangeWidth, ChangeHeight]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    ChangeWidth(window.innerWidth);
    ChangeHeight(window.innerHeight);

    // clean up the effect by removing it completely
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange, ChangeWidth, ChangeHeight]);

  /*
    You made it to the version HIstory ;) write something amazing 

    Version History:

    V 0.12.53:
    V 0.12.52:
    V 0.12.51:
      - Reports added
      
    V 0.12.50:
    V 0.12.49:
      - Code clean up
      - Chat and tasks imporved
      
    V 0.12.48:
      - new user country code added
      - bug on filter in notifications section
      - users list filter by client sorted by alfa
      - template empty map needed empty array

    V 0.12.47:
    V 0.12.46:
    V 0.12.45:
      - Bug on all photo uploads fixed
      - Templates modbus and SNMP completed and working
      - notification users table added filters and sorters

    V 0.12.44:
      - Code clean up
      - Remove CRM route and menu
      - Template Types and template additions

    V 0.12.43:
      - Navbar changes
  */
  return (
    <Fragment>
      {location.pathname !== "/" && (
        <Footer
          style={{
            textAlign: "center",
            paddingBottom: pageWidth >= 795 ? "10px" : "0px",
            paddingTop: pageWidth >= 795 ? "10px" : "0px",
            fontSize: pageWidth >= 795 ? "14px" : "11px",
          }}
        >
          AIVA ClientZone | 2023 |{" "}
          <a
            href="https://www.kva.co.za"
            target="_blank"
            rel="noopener noreferrer"
          >
            Master Power Technologies
          </a>{" "}
          | <span>Version 0.12.56</span>
        </Footer>
      )}
      {userAuthenticated && <ChatBar isOpen={true} />}
    </Fragment>
  );
};

Footers.propTypes = {
  pageWidth: PropTypes.number,
  ChangeHeight: PropTypes.func.isRequired,
  ChangeWidth: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pageWidth: state.misc?.width,
  userAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { ChangeWidth, ChangeHeight })(Footers);
