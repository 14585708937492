import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Button, Card, Popconfirm, Space, Table } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { AiOutlineGlobal } from "react-icons/ai";

// external functions
import { ModalSitesAddOpen, EditSpecificUser } from "../../../../actions/users";

// external components
import ModalAddSites from "../modals/ModalAddSites";

// actual componet
const Sites = ({
  ModalSitesAddOpen,
  isAdmin,
  pageWidth,
  tableData,
  userData,
  EditSpecificUser,
}) => {
  const handleDelete = (record) => {
    if (record) {
      let thisUsersData = { ...userData };

      thisUsersData.sites = thisUsersData.sites.filter(
        (site) => site.name !== record.name
      );
      EditSpecificUser(thisUsersData);
    }
  };

  const HandleClearAll = () => {
    let thisUsersData = { ...userData };

    thisUsersData.sites = [];
    EditSpecificUser(thisUsersData);
  };

  const columns = [
    {
      title: "Site",
      key: "name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "",
      width: "50px",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Remove this site?"
            onConfirm={() => handleDelete(record)}
          >
            <a href="#!" style={{ color: "red" }}>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={
        <span>
          <AiOutlineGlobal /> User Sites
        </span>
      }
      extra={[
        <Popconfirm
          title="Remove All"
          onConfirm={() => HandleClearAll()}
          placement="bottom"
        >
          <a href="#!" style={{ color: "red" }}>
            <DeleteOutlined /> Clear all
          </a>
        </Popconfirm>,
        <Button
          icon={<PlusCircleOutlined />}
          type={"link"}
          onClick={ModalSitesAddOpen}
        >
          Add Sites
        </Button>,
      ]}
      bordered={false}
    >
      <ModalAddSites isAdmin={isAdmin} pageWidth={pageWidth} />

      <Table
        columns={columns}
        dataSource={tableData.map((item, index) => {
          item.key = index;
          return item;
        })}
        pagination={false}
        size={"small"}
        bordered
        scroll={{ y: 440 }}
      ></Table>
    </Card>
  );
};

Sites.propTypes = {
  ModalSitesAddOpen: PropTypes.func.isRequired,
  EditSpecificUser: PropTypes.func.isRequired,
  tableData: PropTypes.array,
  userData: PropTypes.object,
};

const MapStateToProps = (state) => ({
  userData: state.users.editData,
  tableData: state.users.editData.sites ?? [],
});
export default connect(MapStateToProps, {
  ModalSitesAddOpen,
  EditSpecificUser,
})(Sites);
