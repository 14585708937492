import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external components
import RolesTable from "./rolestable";
import RolesCard from "./rolesCard";
import ModalAddPermissions from "./modals/ModalAddPermissions";
import ModalAddNewRole from "./modals/ModalAddNewRole";

// External functions
import {
  clearRolesState,
  loadAllRoles,
  ModalRoleAddOpen,
} from "../../../actions/roles";
import {
  clearPermisionsState,
  loadAllPermissions,
} from "../../../actions/permissions";

// actual component
const Index = ({
  permissions,
  isAdmin,
  clearRolesState,
  loadAllRoles,
  clearPermisionsState,
  loadAllPermissions,
  ModalRoleAddOpen,
  pageWidth,
}) => {
  useEffect(() => {
    loadAllRoles();
    // load all the avaiable permision
    loadAllPermissions();

    // clean up the effect by removing it completely
    return () => {
      clearRolesState();
      clearPermisionsState();
    };
  }, [clearRolesState, loadAllRoles, clearPermisionsState, loadAllPermissions]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Roles"
        subTitle={pageWidth >= 795 && "Manage User Role types"}
        extra={[
          <Button
            key="NewUser"
            type="primary"
            disabled={isAdmin ? false : !permissions?.write}
            icon={<PlusCircleOutlined />}
            onClick={ModalRoleAddOpen}
          >
            {pageWidth >= 795 && "New Role"}
          </Button>,
        ]}
      ></PageHeader>

      <ModalAddPermissions pageWidth={pageWidth} />

      <ModalAddNewRole pageWidth={pageWidth} />

      <Row>
        <Col xs={24} md={11}>
          <RolesTable
            pageWidth={pageWidth}
            isAdmin={isAdmin}
            permissions={permissions}
          />
        </Col>
        <Col xs={0} md={1}></Col>
        <Col xs={24} md={12}>
          <RolesCard
            pageWidth={pageWidth}
            isAdmin={isAdmin}
            permissions={permissions}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  clearRolesState: PropTypes.func.isRequired,
  loadAllRoles: PropTypes.func.isRequired,
  clearPermisionsState: PropTypes.func.isRequired,
  loadAllPermissions: PropTypes.func.isRequired,
  ModalRoleAddOpen: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Role Management"
  )[0],
});

export default connect(mapStateToProps, {
  clearRolesState,
  loadAllRoles,
  clearPermisionsState,
  loadAllPermissions,
  ModalRoleAddOpen,
})(Index);
