import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { Spin } from "antd";

// External componetns
import MeterTabs from "../MeterTabs";
import CustomForm from "./CustomForm";

// actual component
const Index = ({
  pageWidth,
  metersListLoading,
  recData,
  metersListing,
  loadingBtn,
  selectedMeter,
  setSelectedMeter,
  tableData,
  setTableData,
  workbookName,
  setWorkbookName,
  tableAdditionalData,
  setTableAdditionalData,
}) => {
  return metersListLoading === true ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Fragment>
      {recData === null || recData?.length === 0 ? (
        loadingBtn === true ? (
          <Spin
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="large"
          />
        ) : (
          <CustomForm
            metersListing={metersListing}
            selectedMeter={selectedMeter}
            setSelectedMeter={setSelectedMeter}
            loadingBtn={loadingBtn}
            tableData={tableData}
            setTableData={setTableData}
            workbookName={workbookName}
            setWorkbookName={setWorkbookName}
            tableAdditionalData={tableAdditionalData}
            setTableAdditionalData={setTableAdditionalData}
          />
        )
      ) : (
        <MeterTabs tabItems={recData} pageWidth={pageWidth} />
      )}
    </Fragment>
  );
};

Index.propTypes = {
  pageWidth: PropTypes.number,
  loadingBtn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  pageWidth: state.misc?.width,
  loadingBtn: state.misc?.reportGenLoading,
});

export default connect(mapStateToProps)(Index);
