import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Card, Button, Divider, Tooltip, List } from "antd";
import {
  GroupOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import InfiniteScroll from "react-infinite-scroll-component";

// external components
import ModalAddFloor from "../create/modals/ModalAddFloor";

// external functions
import { EditViewSite, ModalAddFloorsOpen } from "../../../../actions/sites";

// actual component
const SiteContact = ({
  pageWidth,
  isAdmin,
  editData,
  permissions,
  EditViewSite,
  ModalAddFloorsOpen,
}) => {
  const handleDeleteSite = (record) => {
    if (record) {
      let thisSitesData = { ...editData };

      thisSitesData.floors = thisSitesData.floors.filter(
        (floor) => floor.name !== record.name
      );
      EditViewSite(thisSitesData);
    }
  };

  return (
    <Card
      title={
        <span>
          <GroupOutlined /> Floors
        </span>
      }
      bordered={false}
    >
      <ModalAddFloor isEdittingSite={true} pageWidth={pageWidth} />

      <div>
        <p style={{ fontWeight: "bold" }}>
          {permissions?.update | isAdmin ? (
            <span>
              <Button
                type="text"
                style={{ color: "blue", float: "right" }}
                icon={<PlusCircleOutlined />}
                onClick={ModalAddFloorsOpen}
              >
                Add Floor
              </Button>
            </span>
          ) : (
            <></>
          )}
        </p>
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: pageWidth >= 768 ? "calc(100vh - 31em)" : "100px",
          width: "100%",
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={
            editData?.floors?.length === undefined
              ? 0
              : editData?.floors?.length
          }
          hasMore={false}
          loader={<h4>Loading...</h4>}
          endMessage={
            editData?.floors?.length > 0 && (
              <Divider plain>Done!! add more floors</Divider>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          <List
            size="small"
            dataSource={editData?.floors}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta title={item.name} />
                <Tooltip title="Click to remove this site">
                  <Button
                    type="link"
                    onClick={() => handleDeleteSite(item)}
                    icon={<CloseCircleOutlined style={{ color: "red" }} />}
                  ></Button>
                </Tooltip>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </Card>
  );
};

SiteContact.propTypes = {
  editData: PropTypes.object,
  clientsData: PropTypes.array,
  EditViewSite: PropTypes.func.isRequired,
  ModalAddFloorsOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  editData: state.sites.editData,
  clientsData: state.clients?.data,
});

export default connect(mapStateToProps, {
  EditViewSite,
  ModalAddFloorsOpen,
})(SiteContact);
