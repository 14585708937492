import {
  DESIG_MODAL_ADDNEW_CLOSE,
  DESIG_MODAL_ADDNEW_OPEN,
  DESIG_LOADING,
  DESIG_EDIT,
  DESIG_CLEARALL,
  DESIG_GET_ALL,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddDesignationVis: false,
};

export default function designation(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case DESIG_MODAL_ADDNEW_CLOSE:
      return { ...state, modalAddDesignationVis: false };
    case DESIG_MODAL_ADDNEW_OPEN:
      return { ...state, modalAddDesignationVis: true };
    case DESIG_EDIT:
      return { ...state, editData: payload };
    case DESIG_LOADING:
      return { ...state, loading: payload };
    case DESIG_GET_ALL:
      return { ...state, data: payload, loading: false };
    case DESIG_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
