import './style.scss';
import {IActiveAlarm} from "../../../../interfaces/shared/IActiveAlarm";
import {Card, Tag, Typography} from "antd";
import moment from "moment";
import {ClockCircleOutlined, EnvironmentOutlined} from "@ant-design/icons";

const { Text } = Typography;

const AlarmItem = (props: {alarm: IActiveAlarm}) => {

    const getAlarmTypeColor =(type: string): string =>{

        console.log(type)

        let response: string = '#616161';

        switch (type){
            case 'Critical': {
                response = '#D32F2F';
                break;
            }
            case 'Urgent': {
                response = '#FFA000';
                break;
            }
            case 'Warning': {
                // response = '#FFA000';
                response = '#ffc107';
                break;
            }
            case 'Event': {
                response = '#1976D2';
                break;
            }
            default: {
                response = '#616161';
                break;
            }
        }

        return response;

    }

    return(
        <div className="active-alarms-container">
            <div className="priority-indicator"
            style={
                {
                    backgroundColor: getAlarmTypeColor(props.alarm.priority)
                }}>
            </div>
            <Card bordered={false}
                  className="alarm-description-container">
                <Card.Meta
                    title={props.alarm.description}
                />
                <div className="alarm-description-container-item">
                    <Text type={'secondary'} >{props.alarm.device_name}</Text>
                    <Text type={'secondary'} ><EnvironmentOutlined /> {props.alarm.site_name}</Text>
                </div>
                <div className="alarm-description-container-item">
                    <Text type="secondary"><ClockCircleOutlined /> {moment(props.alarm.triggered_at).format('MMMM Do YYYY HH:mm')}</Text>
                    <span>{
                        props.alarm.is_cleared ? <Tag color={'darkblue'}>Cleared</Tag> : <Tag color={'crimson'}>Active</Tag>
                    }</span>
                </div>

            </Card>
        </div>
    );

}

export default AlarmItem;
