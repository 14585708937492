import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Modal, Table, Tooltip, Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import { ModalPermAddClose, EditSpecificUser } from "../../../../actions/users";

// actual component
const ModalAddPermission = ({
  permissions,
  currentPermissions,
  modalAddPermVis,
  ModalPermAddClose,
  userAllData,
  EditSpecificUser,
  isAdmin,
  pageWidth,
}) => {
  const [tableData, setTableData] = useState([]);
  const [newPerm, setNewPerm] = useState([]);
  const isLoaded = useRef(false);

  if (modalAddPermVis) {
    if (isLoaded.current === false) {
      let tab = [];

      if (permissions !== null) {
        permissions.map((current) => {
          let number = 0;

          if (isAdmin === false && current.staffOnly) {
            return true;
          } else {
            currentPermissions?.map((item) => {
              if (item.name === current.name) {
                number++;
              }

              return true;
            });
          }

          if (number === 0) {
            tab.push(current);
          }

          return true;
        });

        setTableData(tab);
      }

      isLoaded.current = true;
    }
  }

  const handelClose = (lastData) => {
    let user = { ...userAllData };
    let userPermissions = newPerm;

    if (newPerm.length > 0) {
      user.role.permissions?.map((item) => userPermissions.push({ ...item }));
      user.role.permissions = userPermissions;
    }

    if (lastData !== null) user.role.permissions.push(lastData);

    EditSpecificUser(user);
    ModalPermAddClose();
  };

  const handleAdd = (record) => {
    let oldData = [...tableData];
    let newPerms = [...newPerm];

    newPerms.push(record);
    setNewPerm(newPerms);

    if (oldData.length === 1) {
      handelClose(record);
    } else {
      setTableData(oldData.filter((item) => item.name !== record.name));
    }
  };

  const columns = [
    {
      title: "Permission",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      responsive: ["lg"],
      dataIndex: "description",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Add this permission">
            <Button
              shape="circle"
              type="link"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={() => handleAdd(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      centered
      onCancel={ModalPermAddClose}
      visible={modalAddPermVis}
      afterClose={() => {
        setNewPerm([]);
        setTableData([]);
        isLoaded.current = false;
      }}
      title="Add Permissions"
      width={pageWidth > 795 ? "50%" : "100%"}
      footer={
        <>
          <Button type="primary" onClick={() => handelClose(null)}>
            Close
          </Button>
        </>
      }
    >
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        size={"small"}
        bordered
      ></Table>
    </Modal>
  );
};

ModalAddPermission.propTypes = {
  permissions: PropTypes.array,
  currentPermissions: PropTypes.array,
  modalAddPermVis: PropTypes.bool,
  ModalPermAddClose: PropTypes.func.isRequired,
  EditSpecificUser: PropTypes.func.isRequired,
  userAllData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  permissions: state.permissions.data,
  currentPermissions: state.users.editData.role.permissions,
  modalAddPermVis: state.users.modalAddPermVis,
  userAllData: state.users?.editData,
});

export default connect(mapStateToProps, {
  ModalPermAddClose,
  EditSpecificUser,
})(ModalAddPermission);
