import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import {
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_LOAD,
  CLEAR_PROFILE,
  LOGIN_2FA_LOAD,
  LOGIN_2FA_SUCCESS,
  USER_PERMISIONS,
  NOTIF_CLEARALL,
  USER_VERIFY_LOAD,
  USER_VERIFY_DONE,
  USER_PWDCHG_LOAD,
  USER_CLIENT_PERMISIONS,
} from "./types";

// load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    try {
      // make sure the token is in the auth header
      setAuthToken(localStorage.token);
      // get the basic user information
      let res = await axios.get("/api/auth/");

      const userClient = res.data?.client_id;

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);
      // get the users permissions
      res = await axios.get("/api/auth/permissions");

      dispatch({
        type: USER_PERMISIONS,
        payload: res.data,
      });

      // get the clients permissions

      if (userClient) {
        res = await axios.get("/api/services/client/" + userClient);

        dispatch({
          type: USER_CLIENT_PERMISIONS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  }
};

export const GetQRCode = (authToken) => {
  return new Promise(async (resolve, reject) => {
    setAuthToken(authToken);

    try {
      let results = await axios.get("/api/auth/qrcode");
      results = results.data;

      resolve(results);
    } catch (error) {
      reject(error);
    }
  });
};

export const validate2FAToken = (userToken, authToken) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ userToken });

  try {
    setAuthToken(authToken);

    dispatch({
      type: LOGIN_2FA_LOAD,
      payload: true,
    });

    const res = await axios.post("/api/auth/validateFA", body, config);

    if (res.data.valid) {
      dispatch({
        type: LOGIN_2FA_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
      // reset the page to load all style sheets
      setTimeout(() => window.location.reload(), 100);
    } else {
      dispatch({
        type: LOGIN_2FA_LOAD,
        payload: false,
      });
    }
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LOGIN_2FA_LOAD,
      payload: false,
    });
  }
};

// Login a user
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email: email.trim(), password });

  try {
    dispatch({
      type: LOGIN_LOAD,
    });

    const res = await axios.post("/api/auth/", body, config);

    if (
      res.data.role === undefined ||
      (res.data?.role !== "Client Administrator" &&
        res.data?.role !== "MPT Staff" &&
        res.data?.role !== "Administrator")
    ) {
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch(setAlert("Your role does not permit you to enter", "error"));

      return;
    }

    sessionStorage.setItem("email", email);
    sessionStorage.setItem("ppps", password);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    if (!res.data.use2FA && !res.data?.passwordExpired) {
      dispatch(loadUser());
      // reset the page to load all style sheets
      // setTimeout(() => window.location.reload(), 100);
    }
  } catch (error) {
    console.error(error);
    const errors = error.response?.data?.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// login out
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: NOTIF_CLEARALL });
  dispatch({ type: LOGOUT });
  sessionStorage.clear();
  // reset the page to load all style sheets
  // setTimeout(() => window.location.reload(), 100);
};

// Verfiy user
export const VerfiyMe = (data, userID) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ userData: data });

  try {
    dispatch({
      type: USER_VERIFY_LOAD,
      payload: true,
    });

    await axios.post("/api/users/verify/" + userID, body, config);

    dispatch({
      type: USER_VERIFY_DONE,
      payload: true,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: USER_VERIFY_LOAD,
      payload: false,
    });
  }
};

// Change the users password
export const ChangeMyPassword = (data, authToken) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ userData: data });

  try {
    dispatch({
      type: USER_PWDCHG_LOAD,
      payload: true,
    });

    setAuthToken(authToken);

    await axios.patch("/api/users/pwdchange", body, config);

    dispatch({
      type: USER_PWDCHG_LOAD,
      payload: false,
    });

    dispatch(setAlert("Password changed, please log in", "success"));
    setTimeout(() => dispatch(logout()), 500);
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: USER_PWDCHG_LOAD,
      payload: false,
    });
  }
};
