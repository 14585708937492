import axios from "axios";
import { setAlert } from "../alert";
import setAuthToken from "../../utils/setAuthToken";
import { logout } from "../auth";

import {
  CONFIGMAN_CLEARALL,
  CONFIGMAN_GET_ALL,
  CONFIGMAN_LOADING,
  CONFIGMAN_MODALNEW_CLOSE,
  CONFIGMAN_MODALNEW_OPEN,
} from "../types";

// Get all the items
export const loadAllManufactures = () => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGMAN_LOADING,
      payload: true,
    });

    // get the basic sites information
    let res = await axios.get("/api/config/manufactures");

    dispatch({
      type: CONFIGMAN_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: CONFIGMAN_LOADING,
      payload: false,
    });
  }
};

// clears all the items
export const clearStateManufactures = () => (dispatch) => {
  dispatch({
    type: CONFIGMAN_CLEARALL,
  });
};

// delets a specific Manufacture item, needs the object id
export const DeleteSpecificManufacture = (manuID) => async (dispatch) => {
  try {
    if (manuID) {
      dispatch({
        type: CONFIGMAN_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the item
      await axios.delete("/api/config/manufactures/" + manuID);

      // get all the items again
      let res = await axios.get("/api/config/manufactures");

      dispatch(setAlert("Manufacture is deleted successful", "success"));

      dispatch({
        type: CONFIGMAN_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("DeleteSpecificSite error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: CONFIGMAN_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: CONFIGMAN_LOADING,
        payload: false,
      });
    }
  }
};

// updates a specific Manufacture data, needs the  object id
export const UpdateSpecificManufacture = (manuID, data) => async (dispatch) => {
  try {
    if (manuID && data) {
      dispatch({
        type: CONFIGMAN_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.put("/api/config/manufactures/" + manuID, { data });

      // get the sites information
      res = await axios.get("/api/config/manufactures");

      dispatch(setAlert("Manufacture details saved", "success"));

      dispatch({
        type: CONFIGMAN_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("UpdateSpecificManufacture error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: CONFIGMAN_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: CONFIGMAN_LOADING,
        payload: false,
      });
    }
  }
};

// open modal
export const ModalNewManufactureOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGMAN_MODALNEW_OPEN,
    payload: data,
  });
};

// close modal
export const ModalNewManufactureClose = () => (dispatch) => {
  dispatch({
    type: CONFIGMAN_MODALNEW_CLOSE,
  });
};

// Creat new
export const createNewManufacture = (data) => async (dispatch) => {
  try {
    if (data) {
      dispatch({
        type: CONFIGMAN_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the item
      await axios.post("/api/config/manufactures", { data });

      // get all the items again
      let res = await axios.get("/api/config/manufactures");

      dispatch(setAlert("Manufacture is added successful", "success"));

      dispatch({
        type: CONFIGMAN_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CONFIGMAN_LOADING,
      payload: false,
    });

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }

    console.log("createNewManufacture error: ", error.response);
  }
};
