import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Card, Button, Table, Switch, Space, Popconfirm } from "antd";
import {
  LockOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

// external functions
import { EditSpecificUser, ModalPermAddOpen } from "../../../../actions/users";

// external components
import ModalPremissions from "../modals/ModalAddPermission";

// actual component
const Permissions = ({
  userData,
  userAllData,
  isAdmin,
  permissions,
  EditSpecificUser,
  ModalPermAddOpen,
  permPermissions,
  pageWidth,
}) => {
  const handleDelete = (record) => {
    if (record) {
      let user = { ...userAllData };

      user.role.permissions = userData.filter(
        (perm) => perm.name !== record.name
      );
      EditSpecificUser(user);
    }
  };

  const columns = [
    {
      title: "Permission",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Read",
      key: "read",
      dataIndex: "read",
      render: (text, record) => (
        <>
          <Switch
            size={pageWidth >= 768 ? "default" : "small"}
            disabled={
              permPermissions?.update === false
                ? isAdmin
                  ? false
                  : true
                : false
            }
            onClick={(e) => {
              let user = { ...userAllData };

              user.role.permissions.map((perm) => {
                if (perm.name === record.name) {
                  perm.read = e;
                }

                return true;
              });

              EditSpecificUser(user);
            }}
            checked={text}
          ></Switch>
        </>
      ),
    },
    {
      title: "Write",
      key: "write",
      dataIndex: "write",
      render: (text, record) => (
        <>
          <Switch
            size={pageWidth >= 768 ? "default" : "small"}
            disabled={
              permPermissions?.update === false
                ? isAdmin
                  ? false
                  : true
                : false
            }
            onClick={(e) => {
              let user = { ...userAllData };

              user.role.permissions.map((perm) => {
                if (perm.name === record.name) {
                  perm.write = e;
                }

                return true;
              });

              EditSpecificUser(user);
            }}
            checked={text}
          ></Switch>
        </>
      ),
    },
    {
      title: "Update",
      key: "update",
      dataIndex: "update",
      render: (text, record) => (
        <>
          <Switch
            size={pageWidth >= 768 ? "default" : "small"}
            disabled={
              permPermissions?.update === false
                ? isAdmin
                  ? false
                  : true
                : false
            }
            onClick={(e) => {
              let user = { ...userAllData };

              user.role.permissions.map((perm) => {
                if (perm.name === record.name) {
                  perm.update = e;
                }

                return true;
              });

              EditSpecificUser(user);
            }}
            checked={text}
          ></Switch>
        </>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      dataIndex: "delete",
      render: (text, record) => (
        <>
          <Switch
            size={pageWidth >= 768 ? "default" : "small"}
            disabled={
              permPermissions?.update === false
                ? isAdmin
                  ? false
                  : true
                : false
            }
            onClick={(e) => {
              let user = { ...userAllData };

              user.role.permissions.map((perm) => {
                if (perm.name === record.name) {
                  perm.delete = e;
                }

                return true;
              });

              EditSpecificUser(user);
            }}
            checked={text}
          ></Switch>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          {permPermissions?.delete | isAdmin ? (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <a href="#!" style={{ color: "red" }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={
        <span>
          <LockOutlined /> Permissions
        </span>
      }
      extra={
        <Button
          disabled={
            permPermissions?.update === false ? (isAdmin ? false : true) : false
          }
          icon={<PlusCircleOutlined />}
          type={"link"}
          onClick={ModalPermAddOpen}
        >
          Add Permission
        </Button>
      }
      bordered={false}
    >
      <ModalPremissions isAdmin={isAdmin} pageWidth={pageWidth} />

      <Table
        bordered
        pagination={false}
        columns={columns}
        scroll={{
          x: pageWidth >= 768 ? "100%" : "600px",
          y: pageWidth >= 768 ? "calc(100vh - 20em)" : "calc(100vh - 28em)",
        }}
        dataSource={userData?.map((perm, index) => {
          perm.key = index;

          return perm;
        })}
      />
    </Card>
  );
};

Permissions.propTypes = {
  userData: PropTypes.array,
  userAllData: PropTypes.object,
  EditSpecificUser: PropTypes.func.isRequired,
  ModalPermAddOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userData: state.users.editData?.role?.permissions,
  userAllData: state.users?.editData,
});

export default connect(mapStateToProps, { EditSpecificUser, ModalPermAddOpen })(
  Permissions
);
