import React from "react";

// antd  componets
import { Spin, Table } from "antd";

// actual componet
const KWTable = ({ tableData, dataloading, pageWidth }) => {
  const columns = [
    {
      title: "Timestamp",
      key: "dateTime",
      dataIndex: "dateTime",
      render: (text) => <p>{new Date(text).toLocaleString()}</p>,
    },
    {
      title: "A Supply",
      key: "ASupply",
      dataIndex: "ASupply",
      sorter: (a, b) => a.ASupply - b.ASupply,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} kW</p>,
    },
    {
      title: "B Supply",
      key: "BSupply",
      dataIndex: "BSupply",
      sorter: (a, b) => a.BSupply - b.BSupply,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} kW</p>,
    },
    {
      title: "Combined",
      key: "Combined",
      dataIndex: "Combined",
      sorter: (a, b) => a.Combined - b.Combined,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} kW</p>,
    },
  ];

  return dataloading === true ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Table
      columns={columns}
      dataSource={
        tableData &&
        tableData.map((item, index) => {
          item.key = index;

          return item;
        })
      }
      size="small"
      style={{ marginTop: 10 }}
      pagination={{ pageSize: 100, position: ["bottomLeft"] }}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 30em)" : "calc(100vh - 15em)",
      }}
    ></Table>
  );
};

export default KWTable;
