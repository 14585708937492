import "./style.scss";
import {
  Avatar,
  Button,
  Comment,
  Divider,
  Form,
  List,
  PageHeader,
  Tabs,
  Timeline,
} from "antd";

import {
  SendOutlined,
  FileTextOutlined,
  HistoryOutlined,
  FileOutlined,
  LeftOutlined,
  BellOutlined,
} from "@ant-design/icons";

import { IChat, IMessage } from "../../../chat/interfaces/IChat";
import TextArea from "antd/es/input/TextArea";
import { ITask } from "../../interfaces/ITask";

import React, { useContext, useEffect, useState, useCallback } from "react";
import TaskDescriptionComponent from "../../components/taskDescription";
import TaskMessagesComponent from "../../components/taskMessages";
import { connect } from "react-redux";
import moment from "moment";
import { ISimpleUser } from "../../../../interfaces/shared/ISimpleUser";
import {
  addCollaborator,
  addMultipleCollaborators,
  getTaskAttachments,
  removeCollaborator,
  setTaskChatLoaded,
  updateSelectedTask,
  updateTask,
} from "../../../../actions/tasks";

import CollaboratorsDrawerComponent from "../../components/collaborators/collaboratorsDrawer";
import { ITaskAttachment } from "../../interfaces/ITaskAttachment";
import { ChatMessagesSocketContext } from "../../../../ChatMessagesSocketContext";
import {
  clearTaskChatMessages,
  getTaskChatMessages,
  newMessageReceived,
  sendMessage,
} from "../../../../actions/chat";
import AddCollaboratorsModal from "../../components/collaborators/addCollaboratorsModal";
import AttachmentListItem from "../../components/attachments/attachment-list-item";
import UploadAttachment from "../../components/attachments/uploadAttachment";
import { IActiveAlarm } from "../../../../interfaces/shared/IActiveAlarm";
import AlarmItem from "../../components/alarms/alarm-item";

interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  submitting: boolean;
  value: string;
}

const { TabPane } = Tabs;

const Editor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
  <div className="text-area-container-items">
    <Form.Item style={{ flex: 2 }}>
      <TextArea
        style={{ borderRadius: "15px" }}
        rows={3}
        onChange={onChange}
        value={value}
        placeholder={"Type a message..."}
      />
    </Form.Item>
    <Form.Item style={{ padding: "20px" }}>
      <Button
        shape="circle"
        disabled={value === ""}
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
        icon={<SendOutlined />}
      ></Button>
    </Form.Item>
  </div>
);

const TaskView = (props: {
  currentUser: any;
  task: ITask;
  taskAttachments: ITaskAttachment[];
  users: any;
  updateSelectedTask: any;
  addCollaborator: any;
  addMultipleCollaborators: any;
  removeCollaborator: any;
  updateTask: any;
  messages: IMessage[];
  sendMessage: any;
  sendingMessage: boolean;
  taskChat: IChat;
  taskChatLoaded: boolean;
  newMessageReceived: any;
  setTaskChatLoaded: any;
  getTaskAttachments: any;
  updateAttachmentDetails: any;
  clearTaskChatMessages: Function;
  getTaskChatMessages: Function;
}) => {
  const {
    clearTaskChatMessages,
    setTaskChatLoaded,
    taskChat,
    taskChatLoaded,
    newMessageReceived,
    getTaskChatMessages,
  } = props;
  const [hasLoadedMessages, setHasLoadedMessages] = useState(false);
  const chatSocket = useContext(ChatMessagesSocketContext);

  let initialCollaborators: ISimpleUser[] = [...props.task.collaborators];

  const [taskDetails, setTaskDetails] = useState(props.task);
  const [collaborators, setCollaborators] = useState(initialCollaborators);
  const [openCollaboratorsDrawer, setOpenCollaboratorsDrawer] = useState(false);
  const [addCollaboratorsModalOpen, setAddCollaboratorsModalOpen] =
    useState(false);
  const [users, setUsers] = useState([]);
  const [typedMessage, setTypedMessage] = useState("");

  const performOpenCollaboratorsDrawer = () => {
    if (openCollaboratorsDrawer) {
      setOpenCollaboratorsDrawer(false);
    } else {
      setOpenCollaboratorsDrawer(true);
    }
  };

  const onTabChange = (key: string) => {
    if (key === "taskHistoryTab") {
      // props
    }
  };

  const _setUserList = useCallback((): void => {
    let _existingCollaboratorIDs: string[] = props.task.collaborators.map(
      (e) => e.id
    );
    // Adds the current signed in user and the user
    // that has been assigned to the task to the existing collaborators list
    _existingCollaboratorIDs.push(
      props.currentUser._id,
      props.task.assigned_to.id
    );

    //@ts-ignore
    let _existingCollabs = [...new Set(_existingCollaboratorIDs)];
    let _clientUsers =
      props.currentUser.role.name !== "Administrator"
        ? props.users.filter((e: any) => e.client_id === props.task.client._id)
        : [...props.users];

    let filteredUserList: any[] = [];
    for (let x = 0; x < _clientUsers.length; x++) {
      if (!_existingCollabs.includes(_clientUsers[x]._id)) {
        filteredUserList.push(_clientUsers[x]);
      }
    }

    // console.log('Filtered User List : ', filteredUserList);
    //@ts-ignore
    setUsers(filteredUserList);
  }, [
    props.currentUser._id,
    props.currentUser.role.name,
    props.task.assigned_to.id,
    props.task.client._id,
    props.task.collaborators,
    props.users,
  ]);

  const toggleAddCollaboratorsModal = () => {
    _setUserList();
    if (!addCollaboratorsModalOpen) {
      setAddCollaboratorsModalOpen(true);
    } else {
      setAddCollaboratorsModalOpen(false);
    }
  };

  const removeCollaborator = (collaborator: ISimpleUser): void => {
    let _collaborators: ISimpleUser[] = [...collaborators];

    let newCollaborators = _collaborators.filter(
      (c: ISimpleUser) => c.id !== collaborator.id
    );

    //Remove the collaborator
    props.removeCollaborator(collaborator, props.task._id);

    //Update the task details
    let newTaskDetails: ITask = props.task;
    newTaskDetails.collaborators = newCollaborators;

    props.updateSelectedTask(newTaskDetails);

    //Update state
    setCollaborators(newCollaborators);
  };

  const addCollaborator = (collaborator: ISimpleUser): void => {
    let _newCollaborators = [...collaborators];
    _newCollaborators.push(collaborator);
    //@ts-ignore
    let _cleanedList = [...new Set(_newCollaborators)];
    // console.log(_cleanedList);

    //*** Dispatch Events
    props.addCollaborator(collaborator, props.task._id);
    // task details
    let newTaskDetails = props.task;
    newTaskDetails.collaborators = [..._cleanedList];
    props.updateSelectedTask(newTaskDetails);

    // Update collaborators list
    setCollaborators(_cleanedList);
    //Reset User List
    _setUserList();
  };

  const _messagesScrollLogic = () => {
    setTimeout(() => {
      //@ts-ignore
      let elementHeight =
        document.getElementById("messagesContainer")?.scrollHeight || 0;
      //@ts-ignore
      document.getElementById("messagesContainer").scrollTo({
        top: elementHeight + 200,
        behavior: "smooth",
      });
    }, 500);
  };

  const getMessages = useCallback(() => {
    if (taskChat?._id) {
      getTaskChatMessages(taskChat._id);
      setHasLoadedMessages(true);
      _messagesScrollLogic();
    }
  }, [taskChat?._id, getTaskChatMessages]);

  const sendMessage = (): void => {
    const newMessage: IMessage = {
      _id: "",
      from: {
        id: props.currentUser._id,
        user_name: `${props.currentUser.name} ${props.currentUser.surname}`,
        email_address: props.currentUser.email_address,
      },
      chat_id: props.taskChat._id,
      message: typedMessage,
      created_at: new Date(),
    };

    // props.newMessageReceived(newMessage);
    props.sendMessage(newMessage);
    setTypedMessage("");
    setTimeout(() => getMessages(), 200);
  };

  useEffect(() => {
    if (taskChat !== null && taskChatLoaded) {
      if (!hasLoadedMessages) {
        getMessages();
      }

      chatSocket.emit("join_chat", taskChat._id, props.currentUser._id);

      chatSocket.on("new_chat_message", (data: IMessage) => {
        if (data.from.id === props.currentUser._id) {
          getMessages();
        } else {
          newMessageReceived(data);
          _messagesScrollLogic();
        }
      });

      chatSocket.on("user_joined_chat", (data: any) => {
        // console.log('User Joined Chat ', data);
      });

      if (props.users !== null) {
        _setUserList();
      }
    }

    return () => {
      chatSocket.emit("leave_chat", taskChat?._id, props.currentUser._id);
      chatSocket.off("new_chat_message");
      chatSocket.off("user_joined_chat");
      clearTaskChatMessages();
      setTaskChatLoaded(false);
      setHasLoadedMessages(false);
    };
  }, [
    taskChat,
    chatSocket,
    clearTaskChatMessages,
    newMessageReceived,
    taskChatLoaded,
    setTaskChatLoaded,
    hasLoadedMessages,
    props.users,
    props.currentUser._id,
    _setUserList,
    getMessages,
  ]);

  return (
    <div className="task-view-container">
      <CollaboratorsDrawerComponent
        collaborators={collaborators}
        assignedUser={props.task.assigned_to}
        openCollaboratorsDrawer={openCollaboratorsDrawer}
        onDrawerClose={performOpenCollaboratorsDrawer}
        onAddCollaborators={toggleAddCollaboratorsModal}
        onRemoveCollaborator={removeCollaborator}
      />

      <AddCollaboratorsModal
        users={users}
        open={addCollaboratorsModalOpen}
        onCancel={() => {
          setCollaborators(initialCollaborators);
          setAddCollaboratorsModalOpen(false);
        }}
        onOkay={() => {
          setAddCollaboratorsModalOpen(false);
        }}
        onAddCollaborator={(collaborator: ISimpleUser) => {
          addCollaborator({
            ...collaborator,
          });

          // props.updateSelectedTask()
        }}
      />

      <div className="task-view-details-container">
        <PageHeader
          backIcon={<LeftOutlined />}
          title="Task"
          onBack={() => window.history.back()}
        />
        <Tabs
          defaultActiveKey="1"
          onChange={onTabChange}
          style={{ padding: "0 10px" }}
          type="card"
        >
          <TabPane
            tab={
              <span>
                <FileTextOutlined /> Details
              </span>
            }
            key="taskDetailsTab"
            className="task-view-details-tab-pane"
          >
            <TaskDescriptionComponent
              onDueDateChange={(date: Date) => {
                let newObject: ITask = { ...taskDetails };
                newObject.due_date = date;
                setTaskDetails(newObject);
                props.updateTask(newObject);
                props.updateSelectedTask(newObject);
              }}
              onStatusChange={(status: string) => {
                let newObject: ITask = { ...taskDetails };
                newObject.status = status;
                setTaskDetails(newObject);
                props.updateTask(newObject);
                props.updateSelectedTask(newObject);
              }}
              onManageCollaboratorTriggered={performOpenCollaboratorsDrawer}
            />
          </TabPane>

          {props.task.alarm_details?.length !== undefined &&
            props.task.alarm_details.length > 0 && (
              <TabPane
                tab={
                  <span>
                    <BellOutlined /> Alarms
                  </span>
                }
                key="alarmAttachmentsTab"
              >
                <div>
                  {props.task.alarm_details.map(
                    (alarm: IActiveAlarm, index: number) => (
                      <AlarmItem key={"alarm-item-" + index} alarm={alarm} />
                    )
                  )}
                </div>
              </TabPane>
            )}

          <TabPane
            tab={
              <span>
                <FileOutlined /> Attachments
              </span>
            }
            key="taskAttachmentsTab"
          >
            <div className="attachments-container">
              <PageHeader
                style={{
                  padding: 0,
                }}
                title={<small>Attachments</small>}
              />

              <Divider orientation="left" plain>
                <small>Upload more attachments below</small>
              </Divider>
              <UploadAttachment
                key={"add-attachments-toggle"}
                task={props.task}
                onAttachmentsSubmitted={() => {
                  props.getTaskAttachments(props.task._id);
                }}
              />
              <Divider orientation="left" plain>
                <small>
                  Uploaded Attachments ({props.taskAttachments.length})
                </small>
              </Divider>

              <div className="attachments-list-container">
                <List
                  itemLayout="horizontal"
                  dataSource={props.taskAttachments}
                  renderItem={(attachment) => (
                    <AttachmentListItem
                      taskID={props.task._id}
                      taskTitle={props.task.title}
                      attachment={attachment}
                      key={attachment._id}
                      onChange={() => {
                        props.getTaskAttachments(props.task._id);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </TabPane>
          {/*<TabPane*/}
          {/*    style={{height: '100%'}}*/}
          {/*    tab={*/}
          {/*        <span>*/}
          {/*            <AlertOutlined /> Attached Alarms*/}
          {/*        </span>*/}
          {/*    }*/}
          {/*    key="attachedAlarms">*/}
          {/*    <div className="attached-alarms-container">*/}

          {/*    </div>*/}
          {/*</TabPane>*/}

          <TabPane
            tab={
              <span>
                <HistoryOutlined /> Activity Log
              </span>
            }
            key="taskHistoryTab"
          >
            <div className="timeline-container">
              <Timeline mode="left">
                {props.task.logs.map((log) => (
                  <Timeline.Item
                    key={log.created_at.toString()}
                    label={moment(log.created_at).format("dddd DD MMM YYYY")}
                  >
                    <h4>
                      {log.action_type} by {log.actioned_by.user_name}
                    </h4>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          </TabPane>
        </Tabs>
      </div>
      <div className="task-messages-container">
        <PageHeader ghost={false} title={<small>Messages</small>} />
        <div className="msgs" id={"messagesContainer"}>
          <TaskMessagesComponent
            task={props.task}
            messages={props.messages}
            currentUser={props.currentUser}
          />
        </div>

        <div className="text-area-container">
          <Comment
            avatar={
              <Avatar>
                {props.currentUser.name[0] + " " + props.currentUser.surname[0]}
              </Avatar>
            }
            style={{ height: "100%", padding: "0 10px" }}
            content={
              <Editor
                onChange={(e) => {
                  setTypedMessage(e.target.value);
                }}
                onSubmit={() => {
                  sendMessage();
                }}
                submitting={props.sendingMessage}
                value={typedMessage}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.auth.user,
  task: state.tasks.selectedTask,
  users: state.users.data,
  taskAttachments: state.tasks.selectedTaskAttachments,
  messages: state.chat.selectedTaskChatMessages,
  sendingMessage: state.chat.sendingMessage,
  taskChat: state.tasks.taskChat,
  taskChatLoaded: state.tasks.taskChatLoaded,
});

export default connect(mapStateToProps, {
  updateSelectedTask,
  addCollaborator,
  addMultipleCollaborators,
  removeCollaborator,
  updateTask,
  getTaskChatMessages,
  sendMessage,
  newMessageReceived,
  setTaskChatLoaded,
  clearTaskChatMessages,
  getTaskAttachments,
})(TaskView);
