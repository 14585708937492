import {
  SITES_CLEARALL,
  SITES_EDIT,
  SITES_GET_ALL,
  SITES_LOADING,
  SITES_MODAL_ADDFLR_CLOSE,
  SITES_MODAL_ADDFLR_OPEN,
  SITES_NEWSITE_SAVE,
  SITES_NEWSITE_UPDATE,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  newSiteData: null,
  newSiteSaving: false,
  modalAddFloorVis: false,
};

export default function sites(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case SITES_MODAL_ADDFLR_OPEN:
      return { ...state, modalAddFloorVis: true };
    case SITES_MODAL_ADDFLR_CLOSE:
      return { ...state, modalAddFloorVis: false };
    case SITES_NEWSITE_SAVE:
      return { ...state, newSiteSaving: payload };
    case SITES_NEWSITE_UPDATE:
      return { ...state, newSiteData: payload };
    case SITES_EDIT:
      return { ...state, editData: payload };
    case SITES_LOADING:
      return { ...state, loading: payload };
    case SITES_GET_ALL:
      return { ...state, data: payload, loading: false };
    case SITES_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
