import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Form, InputNumber, Modal, Select } from "antd";
import {
  editATemplateLocal,
  ModalNewTemplateBatchClose,
} from "../../../../../actions/staffTools/templates";

// actual component
const ModalAddBatch = ({
  modalVis,
  currentTemplate,
  pageWidth,
  ModalNewTemplateBatchClose,
  editATemplateLocal,
  modalData,
}) => {
  const [formBatch] = Form.useForm();
  const inputRef = useRef();

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 200);
  }, [modalVis]);

  const closingSequence = () => {
    formBatch.resetFields();
    ModalNewTemplateBatchClose();
  };

  const handleCancel = () => {
    closingSequence();
  };

  const handleOk = () => {
    let newTemplate = { ...currentTemplate };
    let formData = formBatch.getFieldsValue();

    editATemplateLocal({});

    if (modalData !== null) {
      let index = newTemplate?.Batchs.findIndex(
        (batchData) => batchData.key === modalData.key
      );

      if (index !== -1) {
        newTemplate.Batchs[index] = {
          ...newTemplate.Batchs[index],
          ...formData,
        };
      }
    } else {
      if (newTemplate?.Batchs !== undefined) {
        formData.function = Number(formData.function);

        newTemplate?.Batchs.push({ ...formData, Map: [] });
      } else {
        newTemplate.Batchs = [];
        newTemplate?.Batchs.push({ ...formData, Map: [] });
      }
    }
    editATemplateLocal(newTemplate);
    closingSequence();
  };

  if (
    modalData !== null &&
    modalVis === true &&
    formBatch.getFieldValue("startAddress") === undefined
  ) {
    formBatch.setFieldsValue({ ...modalData });
  }

  return (
    <Modal
      title={modalData !== null ? "Editing Batch" : "Add Batch"}
      visible={modalVis}
      onOk={() => formBatch.validateFields().then(() => handleOk())}
      onCancel={handleCancel}
      okText={modalData !== null ? "Update" : "Add"}
    >
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 10 }}
        size={"small"}
        form={formBatch}
        // onValuesChange={(_, all) => setFormData(all)}
      >
        <Form.Item
          label={<span style={{ color: "black" }}>Start Address</span>}
          name="startAddress"
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              validator(_, value) {
                if (value >= 0 && value <= 65535) {
                  return Promise.resolve();
                } else if (value === null || value === undefined) {
                  return Promise.reject(new Error("Cant be blank"));
                }
                return Promise.reject(new Error("Number between 0 and 65535"));
              },
            },
          ]}
        >
          <InputNumber ref={inputRef} min={0} max={65535} />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Quantity</span>}
          name="quantity"
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              validator(_, value) {
                if (value >= 1 && value <= 255) {
                  return Promise.resolve();
                } else if (value === null || value === undefined) {
                  return Promise.reject(new Error("Cant be blank"));
                }
                return Promise.reject(new Error("Number between 1 and 255"));
              },
            },
          ]}
        >
          <InputNumber min={1} max={255} />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Function Code</span>}
          name="function"
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: `Cant be blank`,
            },
          ]}
        >
          <Select style={{ width: "90px" }}>
            <Select.Option key={1} value={1}>
              1
            </Select.Option>
            <Select.Option key={2} value={2}>
              2
            </Select.Option>
            <Select.Option key={3} value={3}>
              3
            </Select.Option>
            <Select.Option key={4} value={4}>
              4
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAddBatch.propTypes = {
  modalVis: PropTypes.bool,
  ModalNewTemplateBatchClose: PropTypes.func.isRequired,
  editATemplateLocal: PropTypes.func.isRequired,
  currentTemplate: PropTypes.object,
  modalData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVis: state.templates.modalBatchaVis,
  modalData: state.templates.modalBatchData,
  currentTemplate: state.templates.editData,
});

export default connect(mapStateToProps, {
  ModalNewTemplateBatchClose,
  editATemplateLocal,
})(ModalAddBatch);
