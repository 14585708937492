import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd componets
import { Card, Button, Empty, Typography } from "antd";
import { SaveOutlined, HighlightOutlined } from "@ant-design/icons";

// external functions
import {
  EditViewPermission,
  UpdateSpecificPermission,
} from "../../../actions/permissions";

const { Paragraph } = Typography;

// actual component
const PermissionsCard = ({
  permissions,
  data,
  isAdmin,
  pageWidth,
  EditViewPermission,
  UpdateSpecificPermission,
}) => {
  const [descriptionStr, setDescriptionStr] = useState("");

  useEffect(() => {
    setDescriptionStr(data?.description);
  }, [data]);

  const handleChange = (e) => {
    if (e) {
      const permData = { ...data };

      permData[e.section] = e.val;

      EditViewPermission(permData);
    }
  };

  return !data ? (
    <Empty
      description={
        <span>Select an item in the table to view / edit details</span>
      }
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform:
          pageWidth >= 768 ? "translate(-50%, -50%)" : "translate(-50%, 50%)",
      }}
    />
  ) : (
    <Card
      title={
        <p style={{ fontWeight: "bold" }}>
          {pageWidth >= 768 && "Permission Name: "}
          <span style={{ fontWeight: "normal" }}>{data.name}</span>
        </p>
      }
      extra={
        <Button
          disabled={!permissions?.update ? (isAdmin ? false : true) : false}
          icon={<SaveOutlined />}
          onClick={() => UpdateSpecificPermission(data._id, data)}
        >
          {pageWidth >= 768 && "Save"}
        </Button>
      }
      style={{ marginTop: "15px", width: "100%" }}
    >
      <p style={{ fontWeight: "bold" }}>Description:</p>
      <Paragraph
        editable={{
          icon: <HighlightOutlined style={{ color: "black" }} />,
          maxLength: 150,
          tooltip: "click to edit description",
          onChange: (val) => handleChange({ section: "description", val }),
        }}
      >
        {descriptionStr}
      </Paragraph>
    </Card>
  );
};

PermissionsCard.propTypes = {
  data: PropTypes.object,
  EditViewPermission: PropTypes.func.isRequired,
  UpdateSpecificPermission: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.permissions.editData,
});

export default connect(mapStateToProps, {
  EditViewPermission,
  UpdateSpecificPermission,
})(PermissionsCard);
