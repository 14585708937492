import {
  SALESMY_CLEARALL,
  SALESMY_EDIT,
  SALESMY_GET_ALL,
  SALESMY_LOADING,
  SALESMY_MODAL_ADDNEWCLIENT_CLOSE,
  SALESMY_MODAL_ADDNEWCLIENT_OPEN,
  SALESMY_MODAL_ADDSALE_OPEN,
  SALESMY_MODAL_ADDSALE_CLOSE,
  SALESMY_MODAL_EDITSALE_CLOSE,
  SALESMY_MODAL_EDITSALE_OPEN,
  SALESMY_EDIT_VIEW,
  SALESMY_DASHLOADING,
  SALESMY_GET_DASH,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddNewVis: false,
  modalAddNewItemVis: false,
  modalEditItemVis: false,
  salesItemData: null,
  salesItemEditData: null,
  loadingDash: false,
  dashData: null,
};

export default function salesMySales(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case SALESMY_MODAL_ADDNEWCLIENT_OPEN:
      return {
        ...state,
        modalAddNewVis: true,
        editData: payload !== undefined ? payload : null,
      };
    case SALESMY_MODAL_ADDNEWCLIENT_CLOSE:
      return {
        ...state,
        modalAddNewVis: false,
        editData: null,
        salesItemEditData: null,
      };
    case SALESMY_MODAL_ADDSALE_OPEN:
      return {
        ...state,
        modalAddNewItemVis: true,
        salesItemData: payload ?? null,
      };
    case SALESMY_MODAL_ADDSALE_CLOSE:
      return { ...state, modalAddNewItemVis: false, salesItemData: null };
    case SALESMY_MODAL_EDITSALE_CLOSE:
      return { ...state, modalEditItemVis: false, salesItemEditData: null };
    case SALESMY_MODAL_EDITSALE_OPEN:
      // return { ...state, modalEditItemVis: true, salesItemEditData: payload };
      return {
        ...state,
        modalAddNewVis: true,
        editData: null,
        salesItemEditData: payload,
      };
    case SALESMY_EDIT:
      return { ...state, editData: payload };
    case SALESMY_EDIT_VIEW:
      return { ...state, salesItemEditData: payload };
    case SALESMY_GET_DASH:
      return { ...state, loadingDash: false, dashData: payload };
    case SALESMY_DASHLOADING:
      return { ...state, loadingDash: payload };
    case SALESMY_LOADING:
      return { ...state, loading: payload };
    case SALESMY_GET_ALL:
      return { ...state, data: payload, loading: false };
    case SALESMY_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
