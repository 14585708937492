import React from "react";

// antd  componets
import { Spin, Table } from "antd";

// actual componet
const SummaryTable = ({ tableData, dataloading, pageWidth, serchTerm }) => {
  const columns = [
    {
      title: "Device Name",
      key: "device_name",
      dataIndex: "device_name",
    },
    {
      title: "Supply",
      key: "supply",
      dataIndex: "supply",
    },
    {
      title: "Used Energy",
      key: "used_energy",
      dataIndex: "used_energy",
      sorter: (a, b) => a.used_energy - b.used_energy,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} kWh</p>,
    },
    {
      title: "Avg Apparent Power",
      key: "avg_apparent_power",
      dataIndex: "avg_apparent_power",
      sorter: (a, b) => a.avg_apparent_power - b.avg_apparent_power,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} kVA</p>,
    },
    {
      title: "Avg Current",
      key: "avg_current",
      dataIndex: "avg_current",
      sorter: (a, b) => a.avg_current - b.avg_current,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} A</p>,
    },
    {
      title: "Avg Power",
      key: "avg_power",
      dataIndex: "avg_power",
      sorter: (a, b) => a.avg_power - b.avg_power,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} kW</p>,
    },
    {
      title: "Avg Voltage",
      key: "avg_voltage",
      dataIndex: "avg_voltage",
      sorter: (a, b) => a.avg_voltage - b.avg_voltage,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} V</p>,
    },
    {
      title: "Max Power",
      key: "max_power",
      dataIndex: "max_power",
      sorter: (a, b) => a.max_power - b.max_power,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} kW</p>,
    },
    {
      title: "Max Apparent Power",
      key: "max_va",
      dataIndex: "max_va",
      sorter: (a, b) => a.max_va - b.max_va,
      render: (text) => <p style={{ margin: 0 }}>{text?.toFixed(2)} kVA</p>,
    },
  ];

  return dataloading === true ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Table
      columns={columns}
      dataSource={
        tableData && serchTerm === null
          ? tableData.map((item, index) => {
              item.key = index;

              return item;
            })
          : tableData
              .filter(
                (item) =>
                  item.device_name
                    .toLowerCase()
                    .includes(serchTerm.toLowerCase()) ||
                  item.supply.toLowerCase().includes(serchTerm.toLowerCase())
              )
              .map((item, index) => {
                item.key = index;

                return item;
              })
      }
      size="small"
      pagination={{ pageSize: 100, position: ["bottomLeft"] }}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 25em)" : "calc(100vh - 15em)",
      }}
    ></Table>
  );
};

export default SummaryTable;
