import React from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// antd
import { Col, Row, Statistic, Tabs } from "antd";
import MeterTable from "./MeterTable";

// actual componet
const MeterTabs = ({ tabItems, pageWidth }) => {
  return (
    tabItems &&
    tabItems.length > 0 && (
      <Tabs>
        {tabItems.map((item, index) => {
          return (
            <Tabs.TabPane tab={item.meterName} key={index}>
              <div
                style={{
                  overflowY: "auto",
                  height:
                    pageWidth >= 768
                      ? "calc(100vh - 30em)"
                      : "calc(100vh - 15em)",
                }}
              >
                {item?.stats && (
                  <Row>
                    {Object.keys(item?.stats).map((statItem, index) => {
                      let statText = statItem.replace(/_+/g, " ");

                      statText =
                        statText.charAt(0).toUpperCase() + statText.slice(1);

                      return (
                        <Col xs={4} sm={4} key={index}>
                          <Statistic
                            title={<p style={{ fontSize: 14 }}>{statText}</p>}
                            value={item?.stats[statItem]}
                            valueStyle={{ fontSize: 14, fontWeight: "bold" }}
                            precision={2}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                )}

                <Row>
                  <Col xs={12}>
                    <ResponsiveContainer width="100%" height="90%">
                      <BarChart
                        data={
                          item?.tableData &&
                          item.tableData.map((itemData) => {
                            return {
                              name: new Date(itemData.time).toLocaleString(),
                              consumed: parseFloat(itemData.value),
                            };
                          })
                        }
                        margin={{
                          right: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        {/* <YAxis type="number" domain={[0, item.maxValue + 100]} /> */}
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="consumed" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col xs={12}>
                    <MeterTable
                      tableData={item.tableData}
                      pageWidth={pageWidth}
                    />
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    )
  );
};

export default MeterTabs;
