import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Button, Space, Table, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  AddNewSiteToUserAllTableLoaded,
  AddNewSiteToUser,
  ModalSitesAddClose,
  EditSpecificUser,
} from "../../../../actions/users";

// actual component
const TabAllSite = ({
  avaiableSites,
  userData,
  modalVis,
  addSiteTableLoaded,
  AddNewSiteToUserAllTableLoaded,
  AddNewSiteToUser,
  currentSites,
  newSite,
  ModalSitesAddClose,
  EditSpecificUser,
  availableClients,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (modalVis) {
      //   if (tabletLoaded.current === false) {
      if (addSiteTableLoaded === false) {
        let tab = [];

        if (avaiableSites !== null) {
          avaiableSites.map((current) => {
            let number = 0;

            currentSites?.map((item) => {
              if (item.name === current.name) {
                number++;
              }

              return true;
            });

            if (number === 0) {
              tab.push({ ...current, key: current._id });
            }

            return true;
          });

          setTableData(tab);
        }

        // tabletLoaded.current = true;
        AddNewSiteToUserAllTableLoaded(true);
      }
    }
  }, [
    modalVis,
    avaiableSites,
    userData.client_id,
    currentSites,
    AddNewSiteToUserAllTableLoaded,
    addSiteTableLoaded,
  ]);

  const handelClose = (lastData) => {
    let user = { ...userData };
    let userSites = newSite;

    if (newSite.length > 0) {
      user.sites.map((item) => userSites.push({ ...item }));
      user.sites = userSites;
    }

    if (lastData !== null) user.sites.push(lastData);

    EditSpecificUser(user);
    ModalSitesAddClose();
  };

  const handleAdd = (record) => {
    let oldData = [...tableData];
    let newSites = [...newSite];

    delete record.key;
    newSites.push(record);

    AddNewSiteToUser(newSites);

    if (oldData.length === 1) {
      handelClose(record);
    } else {
      setTableData(oldData.filter((item) => item.name !== record.name));
    }
  };

  const columns = [
    {
      title: "Site",
      key: "name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: "Client",
      key: "client_name",
      dataIndex: "client_name",
      filters: availableClients
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((client) => {
          return { text: client.name, value: client.name };
        }),
      onFilter: (value, record) => record?.client_name.includes(value),
    },
    {
      title: "",
      width: "60px",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Add this site">
            <Button
              shape="circle"
              type="link"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={() => handleAdd(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      size={"small"}
      bordered
      scroll={{ y: 240 }}
    ></Table>
  );
};

TabAllSite.propTypes = {
  avaiableSites: PropTypes.array,
  userData: PropTypes.object,
  modalVis: PropTypes.bool,
  addSiteTableLoaded: PropTypes.bool,
  AddNewSiteToUserAllTableLoaded: PropTypes.func.isRequired,
  currentSites: PropTypes.array,
  AddNewSiteToUser: PropTypes.func.isRequired,
  ModalSitesAddClose: PropTypes.func.isRequired,
  EditSpecificUser: PropTypes.func.isRequired,
  newSite: PropTypes.array,
  availableClients: PropTypes.array,
};

const MapStateToProps = (state) => ({
  userData: state.users?.editData,
  currentSites: state.users?.editData?.sites,
  avaiableSites: state.sites.data,
  modalVis: state.users.modalAddSitesVis,
  addSiteTableLoaded: state.users.addSiteTableLoadedALL,
  newSite: state.users.newSite ?? [],
  availableClients: state.clients.data,
});

export default connect(MapStateToProps, {
  AddNewSiteToUserAllTableLoaded,
  AddNewSiteToUser,
  ModalSitesAddClose,
  EditSpecificUser,
})(TabAllSite);
