import {ISimpleUser} from "../../../../interfaces/shared/ISimpleUser";
import {Button, Drawer, List} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import React from "react";

const CollaboratorsDrawerComponent = (props: {
    collaborators: ISimpleUser[],
    openCollaboratorsDrawer: boolean,
    assignedUser: ISimpleUser,
    onDrawerClose: any,
    onAddCollaborators: any,
    onRemoveCollaborator: any,
}) => {

    return(
        <Drawer
            title="Manage Collaborators"

            width={'30%'}
            placement={'right'}
            closable={false}
            onClose={ () => {
                props.onDrawerClose();
            }}
            visible={props.openCollaboratorsDrawer}
            key={'collaborators'}
        >
            <div style={
                {
                    padding: '1rem'
                }
            }>
                <Button type={'primary'} onClick={ () => {
                   props.onAddCollaborators();
                } }>
                    <PlusCircleOutlined /> Add Collaborator
                </Button>
            </div>

            <List
                bordered
                dataSource={props.collaborators}
                renderItem={ (item: ISimpleUser) => (
                    <List.Item actions={[
                        item.id !== props.assignedUser.id
                            ? <Button
                                key={item.id}
                                danger={true}
                                type={'link'}
                                onClick={() => {
                                    props.onRemoveCollaborator(item);
                                }}
                                >
                                Remove
                            </Button>
                            : <span>Assigned to Task</span>
                    ]}>
                        {item.user_name}
                    </List.Item>
                )}
            />
        </Drawer>
    );

}

export default CollaboratorsDrawerComponent;
