import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./auth";
import {
  GROUPS_CLEARALL,
  GROUPS_EDIT,
  GROUPS_GET_ALL,
  GROUPS_LOADING,
  GROUPS_MODAL_GROUPADD_CLOSE,
  GROUPS_MODAL_GROUPADD_OPEN,
  GROUPS_MODAL_SITEADD,
  GROUPS_MODAL_SITEADD_CLOSE,
  GROUPS_MODAL_SITEADD_OPEN,
  GROUPS_MODAL_SITEADD_TABLE,
  GROUPS_MODAL_SITEADD_TABLE_ALL,
} from "./types";

// Loads all the roles
export const loadAllGroups = () => async (dispatch) => {
  try {
    dispatch({
      type: GROUPS_LOADING,
      payload: true,
    });

    // get the basic sites information
    let res = await axios.get("/api/groups");

    dispatch({
      type: GROUPS_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: GROUPS_LOADING,
      payload: false,
    });
  }
};

// clears all the role items in the store
export const clearGroupsState = () => (dispatch) => {
  dispatch({
    type: GROUPS_CLEARALL,
  });
};

// view details of a specific role
export const EditViewGroup = (details) => (dispatch) => {
  dispatch({
    type: GROUPS_EDIT,
    payload: details,
  });
};

// updates a specific group, needs the group object id
export const UpdateSpecificGroup = (groupID, groupData) => async (dispatch) => {
  try {
    if (groupID) {
      dispatch({
        type: GROUPS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      await axios.put("/api/groups/" + groupID, {
        groupData: groupData,
      });

      // get the groups
      let res = await axios.get("/api/groups");

      dispatch(setAlert("Group details saved", "success"));

      dispatch({
        type: GROUPS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: GROUPS_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: GROUPS_LOADING,
        payload: false,
      });
    }
  }
};

// deletes a specific Group, needs the group object id
export const DeleteSpecificGroups = (groupID) => async (dispatch) => {
  try {
    if (groupID) {
      dispatch({
        type: GROUPS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the groups
      await axios.delete("/api/groups/" + groupID);

      // get the groups
      let res = await axios.get("/api/groups");

      dispatch(setAlert("Group deleted successfully", "success"));

      dispatch({
        type: GROUPS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: GROUPS_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: GROUPS_LOADING,
        payload: false,
      });
    }
  }
};

// open the modal to add a Groups
export const ModalAddGroupOpen = () => (dispatch) => {
  dispatch({
    type: GROUPS_MODAL_GROUPADD_OPEN,
  });
};

// close the modal to add a Groups
export const ModalAddGroupClose = () => (dispatch) => {
  dispatch({
    type: GROUPS_MODAL_GROUPADD_CLOSE,
  });
};

// open the modal to add a site to Groups
export const ModalAddSiteToGroupOpen = () => (dispatch) => {
  dispatch({
    type: GROUPS_MODAL_SITEADD_OPEN,
  });
};

// close the modal to add a site to Groups
export const ModalAddSiteToGroupClose = () => (dispatch) => {
  dispatch({
    type: GROUPS_MODAL_SITEADD_CLOSE,
  });
};

// Create a new group
export const NewGroup = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GROUPS_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // Send the data to create a new one
    await axios.post("/api/groups", {
      data,
    });

    // get the groups
    let res = await axios.get("/api/groups");

    dispatch(setAlert("Group added", "success"));

    dispatch({
      type: GROUPS_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
      dispatch({
        type: GROUPS_LOADING,
        payload: false,
      });
    } else if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: GROUPS_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: GROUPS_LOADING,
        payload: false,
      });
    }
  }
};

// on the add site to group user modal, needs to be a way to know when the table is populated
export const AddNewSiteTableLoaded = (data) => async (dispatch) => {
  dispatch({
    type: GROUPS_MODAL_SITEADD_TABLE,
    payload: data,
  });
};

// on the add site to group user modal, needs to be a way to know when the table is populated
export const AddNewSiteAllTableLoaded = (data) => async (dispatch) => {
  dispatch({
    type: GROUPS_MODAL_SITEADD_TABLE_ALL,
    payload: data,
  });
};

// add sites to list
export const AddNewSiteToGroup = (data) => async (dispatch) => {
  dispatch({
    type: GROUPS_MODAL_SITEADD,
    payload: data,
  });
};
