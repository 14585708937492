import { ITask } from "../../interfaces/ITask";
import "./styles.scss";
//@ts-ignore
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Avatar, Badge, Button, DatePicker, Input, Table, Tag } from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import TaskTagComponent from "../taskTag";
import { connect } from "react-redux";
import {
  getTaskAttachments,
  getTaskChat,
  setSelectedTask,
} from "../../../../actions/tasks";
import { ISimpleUser } from "../../../../interfaces/shared/ISimpleUser";
import { ColorHelpers } from "../../helpers/color.helpers";

const TaskListView = (props: {
  tasks: ITask[];
  isLoading: boolean;
  userDetails: any;
  isAdmin: boolean;
  setSelectedTask: any;
  getTaskAttachments: any;
  getTaskChat: any;
}) => {
  let history = useHistory();

  const [tasks, setTasks] = useState(props.tasks);
  const [searchText, setSearchText] = useState("");

  const setSelectedTask = (task: ITask) => {
    props.setSelectedTask(task);
    props.getTaskAttachments(task._id);
    props.getTaskChat(task._id);
    history.push("/admin/tasks/view");
    // useNavigate('/admin/tasks/view');
  };

  const onSearch = (e: string) => {
    setSearchText(e);
    if (e !== "") {
      let filteredTasks = [...props.tasks];
      filteredTasks = filteredTasks.filter((task: ITask) =>
        task.title.includes(e)
      );
      setTasks(filteredTasks);
      console.log("Filtered Tasks : ", filteredTasks);
    } else {
      setTasks(props.tasks);
    }
  };

  const onDueDateFilter = (e: any) => {
    console.log("DUE DATE FILTER : ", e);

    if (e !== null) {
      let filteredTasks = [...props.tasks];
      filteredTasks = filteredTasks.filter((task: ITask) =>
        moment(task.due_date).isSame(e, "day")
      );
      console.log("Filtered Tasks DATE : ", filteredTasks);
      setTasks(filteredTasks);
    } else {
      setTasks(props.tasks);
    }
  };

  const columns = [
    {
      title: <small>Title</small>,
      dataIndex: "title",
      key: "title",
      render: (text: string) => <small>{text}</small>,
    },
    {
      title: <small>Status</small>,
      dataIndex: "status",
      render: (status: string) => (
        <small>
          <Badge color={new ColorHelpers().getTaskStatusColor(status)} />
          {status}
        </small>
      ),
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "In Progress",
          value: "In Progress",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value: any, record: ITask) =>
        record.status.indexOf(value) === 0,
    },
    {
      title: <small>Due Date</small>,
      dataIndex: "due_date",
      render: (date: Date) => (
        <small
          style={{
            color:
              moment(date).isSame(moment(), "day") ||
              moment(date).isBefore(moment(), "day")
                ? "#be1e2d"
                : "#a2a2a2",
          }}
        >
          <ClockCircleOutlined /> {moment(date).format("dddd DD MMM YYYY")}
        </small>
      ),
      key: "due_date",
    },
    {
      title: <small>Priority</small>,
      dataIndex: "priority",
      render: (priority: string) => (
        // <AlertOutlined/>
        <TaskTagComponent type={"priority"} value={priority} />
      ),
      key: "priority",
      filters: [
        {
          text: "High",
          value: "High",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "Low",
          value: "Low",
        },
      ],
      onFilter: (value: any, record: ITask) =>
        record.priority.indexOf(value) === 0,
    },

    {
      title: <small>Assignee</small>,
      dataIndex: "assigned_to",
      render: (assigned_to: ISimpleUser) => (
        <small>
          <Avatar size="small" icon={<UserOutlined />} />{" "}
          {assigned_to.email_address === props.userDetails.email_address
            ? "You"
            : assigned_to.user_name}
        </small>
      ),
      key: "assigned_to",
    },
    {
      title: <small>Type</small>,
      dataIndex: "type",
      render: (type: string) => (
        <small>
          <Tag>{type}</Tag>
        </small>
      ),
      key: "type",
      filters: props.tasks.map((task: ITask) => ({
        text: task.type,
        value: task.type,
      })),
      onFilter: (value: any, record: ITask) => record.type.indexOf(value) === 0,
    },
    {
      title: "",
      render: (_: any, item: ITask) => (
        <Button
          type={"link"}
          key={"navigateToTask"}
          onClick={() => {
            setSelectedTask(item);
          }}
        >
          <RightOutlined />
        </Button>
      ),
      key: "action",
    },
  ];

  return (
    <>
      <div className="search-filters">
        <Input
          style={{ width: "40%", flex: 2 }}
          placeholder="Search by task name..."
          allowClear
          value={searchText}
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />

        <DatePicker
          placeholder={"Filter by Due Date"}
          style={{ flex: 1 }}
          onChange={onDueDateFilter}
        />
      </div>
      <Table
        size={"small"}
        columns={columns}
        dataSource={tasks?.map((item, index) => {
          item.key = index;

          return item;
        })}
        style={{
          padding: "0 2em",
        }}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  isAdmin: state.auth.isAdmin,
  userDetails: state.auth.user,
});

export default connect(mapStateToProps, {
  setSelectedTask,
  getTaskAttachments,
  getTaskChat,
})(TaskListView);
