import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Form, Input, Button, Typography, Row, Col, Space } from "antd";
import { ExceptionOutlined } from "@ant-design/icons";

// external functions
import { ChangeMyPassword, logout } from "../../actions/auth";

// style sheet
import localStyle from "./index.module.css";

// Images'
import { ReactComponent as PassExpImage } from "../images/passwordExpire.svg";
import { Fragment } from "react";

const { Title, Paragraph } = Typography;

// actual component
const ChangePassword = ({
  loading,
  authToken,
  width,
  logout,
  ChangeMyPassword,
}) => {
  const inputRef = React.useRef(null);
  const [buttonDisable, setbuttonDisable] = useState();
  const [formPasswordChange] = Form.useForm();

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);
  }, []);

  // Login function
  const handleFinish = () => {
    ChangeMyPassword(
      { password: formPasswordChange.getFieldValue("password") },
      authToken
    );
  };

  return (
    <div
      style={
        width >= 768
          ? {
              position: "absolute",
              left: "50%",
              top: "50%",
              //   width: "50%",
              //   minWidth: "220px",
              transform: "translate(-50%, -50%)",
            }
          : {
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }
      }
    >
      <Row>
        {width >= 768 && (
          <Fragment>
            <Col xs={24} md={11}>
              <PassExpImage
                height="50%"
                // preserveAspectRatio="xMinYMin slice"
                width="100%"
                // viewBox="0 0 500 500"
              />
            </Col>
            <Col md={1}></Col>
          </Fragment>
        )}
        <Col xs={24} md={12}>
          <Typography>
            <Title
              level={2}
              style={{ marginBottom: width > 795 ? "50px" : "0px" }}
              className={localStyle.myTypography}
            >
              <ExceptionOutlined /> Password Expired
            </Title>

            <Paragraph className={localStyle.myTypography}>
              Your profile has password expiry enabled and your password has
              expired, please set a new one now to continue
            </Paragraph>
          </Typography>
          <Form
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 16 }}
            layout="vertical"
            size="small"
            form={formPasswordChange}
            onFinish={handleFinish}
            onValuesChange={(_, All) => {
              setbuttonDisable(All);
            }}
          >
            <Form.Item
              label={
                <p
                  style={{ margin: "0px" }}
                  className={localStyle.myTypography}
                >
                  New Password
                </p>
              }
              tooltip="Create a strong level password that has at least one lowercase
              letter, one uppercase letter, one digit/number, one special
              character, and is at least eight characters long."
              name="password"
              style={{ marginBottom: width > 795 ? "25px" : "5px" }}
              rules={[
                { required: true, message: "Password is required" },
                {
                  message: "Password doesn't meet requirements",
                  pattern: new RegExp(
                    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
                  ),
                },
              ]}
            >
              <Input.Password ref={inputRef} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  disabled={
                    buttonDisable?.userToken === "" ||
                    buttonDisable === undefined ||
                    loading
                      ? true
                      : false
                  }
                  loading={loading}
                  htmlType="submit"
                >
                  Change Password
                </Button>
                <Button
                  style={{ marginLeft: "4em" }}
                  loading={loading}
                  onClick={logout}
                >
                  Back
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

ChangePassword.propTypes = {
  ChangeMyPassword: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  authToken: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loading: state.auth.isChgPWDLoading,
  authToken: state.auth.token,
});

export default connect(mapStateToProps, { ChangeMyPassword, logout })(
  ChangePassword
);
