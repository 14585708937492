import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./auth";
import {
  ROLES_CLEARALL,
  ROLES_EDIT,
  ROLES_GET_ALL,
  ROLES_LOADING,
  ROLES_MODAL_ADDROLE_CLOSE,
  ROLES_MODAL_ADDROLE_OPEN,
  ROLES_MODAL_PERMADD_CLOSE,
  ROLES_MODAL_PERMADD_OPEN,
} from "./types";

// Loads all the roles
export const loadAllRoles = () => async (dispatch) => {
  try {
    dispatch({
      type: ROLES_LOADING,
      payload: true,
    });

    // get the basic sites information
    let res = await axios.get("/api/roles");

    dispatch({
      type: ROLES_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: ROLES_LOADING,
      payload: false,
    });
  }
};

// clears all the role items in the store
export const clearRolesState = () => (dispatch) => {
  dispatch({
    type: ROLES_CLEARALL,
  });
};

// view details of a specific role
export const EditViewRole = (details) => (dispatch) => {
  dispatch({
    type: ROLES_EDIT,
    payload: details,
  });
};

// delets a specific role, needs the role object id
export const DeleteSpecificRole = (roleID) => async (dispatch) => {
  try {
    if (roleID) {
      dispatch({
        type: ROLES_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.delete("/api/roles/" + roleID);

      // get the roles information
      res = await axios.get("/api/roles");

      dispatch(setAlert("Role is deleted successful", "success"));

      dispatch({
        type: ROLES_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: ROLES_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: ROLES_LOADING,
        payload: false,
      });
    }
  }
};

// updates a specific role, needs the user object id
export const UpdateSpecificRole = (roleID, roleData) => async (dispatch) => {
  try {
    if (roleID) {
      dispatch({
        type: ROLES_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.put("/api/roles/" + roleID, { roleData });

      // get the roles information
      res = await axios.get("/api/roles");

      dispatch(setAlert("Roles details saved", "success"));

      dispatch({
        type: ROLES_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: ROLES_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: ROLES_LOADING,
        payload: false,
      });
    }
  }
};

// Opens the modal to add permissions
export const ModalPermAddOpen = () => (dispatch) => {
  dispatch({
    type: ROLES_MODAL_PERMADD_OPEN,
  });
};

// Closes the modal to add permissions
export const ModalPermAddClose = () => (dispatch) => {
  dispatch({
    type: ROLES_MODAL_PERMADD_CLOSE,
  });
};

// Opens the modal to add permissions
export const ModalRoleAddOpen = () => (dispatch) => {
  dispatch({
    type: ROLES_MODAL_ADDROLE_OPEN,
  });
};

// Closes the modal to add permissions
export const ModalRoleAddClose = () => (dispatch) => {
  dispatch({
    type: ROLES_MODAL_ADDROLE_CLOSE,
  });
};

// Create a new role
export const NewRole = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ROLES_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // Send the data to create a new one
    await axios.post("/api/roles", {
      data,
    });

    // get the roles information
    let res = await axios.get("/api/roles");

    dispatch(setAlert("Role added", "success"));

    dispatch({
      type: ROLES_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
      dispatch({
        type: ROLES_LOADING,
        payload: false,
      });
    } else if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: ROLES_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: ROLES_LOADING,
        payload: false,
      });
    }
  }
};
