import React, { Fragment, useEffect, lazy, Suspense, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

// andt Components
import { PageHeader, Button, Spin, Input, Tooltip } from "antd";
import { PlusCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// external functions
import { loadAllUsers, clearUsersState } from "../../../actions/users";
import { loadAllClients, clearClientState } from "../../../actions/clients";
import { loadAllRoles, clearRolesState } from "../../../actions/roles";

// external components
const UsesTable = lazy(() => import("./usersTable"));

const CSVHeaders = [
  { label: "Name", key: "name" },
  { label: "Role", key: "role.name" },
  { label: "Client", key: "client_name" },
  { label: "Email", key: "email_address" },
  { label: "Phone Number", key: "contact_number" },
  { label: "Verified", key: "activated" },
  { label: "Cloud Logins", key: "analytics.successfullLogins" },
  { label: "Service desk Logins", key: "analytics.Service Desk" },
  { label: "Created On", key: "created_at" },
];

// actual component
const Index = ({
  permissions,
  isAdmin,
  loadAllUsers,
  clearUsersState,
  pageWidth,
  loadAllClients,
  clearClientState,
  loadAllRoles,
  clearRolesState,
  usersData,
  userClientID,
}) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const fileName = `Users List.csv`;

  useEffect(() => {
    loadAllUsers();
    loadAllClients();
    loadAllRoles();

    // clean up the effect by removing it completely
    return () => {
      clearUsersState();
      clearClientState();
      clearRolesState();
    };
  }, [
    loadAllUsers,
    clearUsersState,
    loadAllClients,
    clearClientState,
    loadAllRoles,
    clearRolesState,
  ]);

  return (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="User Management"
          style={{
            paddingBottom: pageWidth < 795 && "0px",
            paddingTop: pageWidth < 795 && "0px",
          }}
          subTitle={pageWidth > 795 && "Manage all your users"}
          extra={[
            <Input
              size="small"
              placeholder="Search something"
              onChange={(value) =>
                setSearchTerm(value.target.value.toLowerCase())
              }
              allowClear
            />,
            <Link to={{ pathname: `/admin/users/create` }}>
              <Button
                key="NewUser"
                type="primary"
                disabled={isAdmin ? false : !permissions?.write}
                icon={<PlusCircleOutlined />}
              >
                {pageWidth > 795 && "New User"}
              </Button>
            </Link>,
            ...(isAdmin
              ? [
                  <CSVLink
                    data={
                      isAdmin === false
                        ? usersData !== null
                          ? usersData?.filter(
                              (item) => item?.client_id === userClientID
                            )
                          : []
                        : usersData ?? []
                    }
                    headers={CSVHeaders}
                    filename={fileName}
                    className="btn btn-primary"
                    target="_blank"
                  >
                    <Tooltip title={pageWidth >= 795 && "Download users list"}>
                      <Button
                        style={{ backgroundColor: "green", color: "white" }}
                        icon={<CloudDownloadOutlined />}
                      ></Button>
                    </Tooltip>
                  </CSVLink>,
                ]
              : []),
          ]}
        ></PageHeader>

        <UsesTable
          pageWidth={pageWidth}
          isAdmin={isAdmin}
          permissions={permissions}
          searchTerm={searchTerm}
        />
      </Fragment>
    </Suspense>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  loadAllUsers: PropTypes.func,
  clearUsersState: PropTypes.func,
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  loadAllClients: PropTypes.func.isRequired,
  loadAllRoles: PropTypes.func.isRequired,
  clearRolesState: PropTypes.func.isRequired,
  clearClientState: PropTypes.func.isRequired,
  usersData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  usersData: state.users.data,
  userClientID: state.auth?.user?.client_id,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "User Management"
  )[0],
});

export default connect(mapStateToProps, {
  loadAllUsers,
  clearUsersState,
  loadAllClients,
  clearClientState,
  loadAllRoles,
  clearRolesState,
})(Index);
