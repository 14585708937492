import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd Componet
import { Menu } from "antd";
import {
  ToolOutlined,
  FileTextOutlined,
  TeamOutlined,
  SettingOutlined,
  ApartmentOutlined,
  CoffeeOutlined,
  MessageOutlined,
  FireOutlined,
  ThunderboltOutlined,
  SkinOutlined,
  StockOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";

// Permisions constants
const NotificationUsers = "User Notification Management";
// const FinanceModule = "Finance Module Access";
const ClientsModule = "Client Management";
const ClientsSitesModule = "Client Site Management";
const GroupsModule = "Group Management";
const PermissionsModule = "Permission Management";
const RoleModule = "Role Management";
const UsersModule = "User Management";
const ServiceModule = "Service Management";
const ExtensionListModule = "Extension List";
const DesignationManagementModule = "Designation Management";
const DepartmentManagementModule = "Department Management";
const UCTemplates = "UC Templates";
const StaffRegionManagement = "Staff Region Management";
const MPTTASKING = "Ticketing System";
const REPORT_POWER = "Power Report";
const REPORT_TEMPERATURE = "Temperature Reports";
const REPORT_AVERAGE = "Average Reports";
const REPORT_SMS = "SMS Reports";

// Actual Component
const LeftMenu = ({
  isAdmin,
  visible,
  permissions,
  userRole,
  clientPermissions,
}) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Menu
      mode={visible ? "vertical" : "horizontal"}
      style={{ fontSize: "11px" }}
      selectedKeys={[pathname]}
    >
      {(permissions.filter(
        (item) =>
          item.name === ClientsSitesModule ||
          item.name === ClientsModule ||
          item.name === NotificationUsers
      ).length > 0 ||
        clientPermissions?.filter(
          (item) =>
            item.name === REPORT_POWER ||
            item.name === REPORT_TEMPERATURE ||
            item.name === REPORT_SMS ||
            item.name === REPORT_AVERAGE
        ).length > 0 ||
        isAdmin) && (
        <Menu.SubMenu
          style={{ marginTop: "0px" }}
          key="ClientAdmin"
          title={
            <span>
              <TeamOutlined /> Client Services
            </span>
          }
        >
          {(clientPermissions?.filter(
            (item) =>
              item.name === REPORT_POWER ||
              item.name === REPORT_TEMPERATURE ||
              item.name === REPORT_SMS ||
              item.name === REPORT_AVERAGE
          ).length > 0 ||
            isAdmin) && (
            <Menu.SubMenu
              style={{ marginTop: "0px" }}
              key="ReportsMainMenu"
              title={
                <span>
                  <FileTextOutlined /> Reports
                </span>
              }
            >
              {(clientPermissions.filter((item) => item.name === REPORT_SMS)
                .length > 0 ||
                isAdmin) && (
                <Menu.Item
                  key="/admin/reports/sms-counts"
                  icon={<MessageOutlined />}
                >
                  <Link to="/admin/reports/sms-counts">SMS</Link>
                </Menu.Item>
              )}
              {(clientPermissions.filter(
                (item) => item.name === REPORT_TEMPERATURE
              ).length > 0 ||
                isAdmin) && (
                <Menu.Item
                  key="/admin/reports/temperature"
                  icon={<FireOutlined />}
                >
                  <Link to="/admin/reports/temperature">Temperature</Link>
                </Menu.Item>
              )}
              {(clientPermissions.filter((item) => item.name === REPORT_AVERAGE)
                .length > 0 ||
                isAdmin) && (
                <Menu.Item
                  key="/admin/reports/averages"
                  icon={<StockOutlined />}
                >
                  <Link to="/admin/reports/averages">Power Averages</Link>
                </Menu.Item>
              )}
              {(clientPermissions.filter((item) => item.name === REPORT_POWER)
                .length > 0 ||
                isAdmin) && (
                <Menu.Item
                  key="/admin/reports/power"
                  icon={<ThunderboltOutlined />}
                >
                  <Link to="/admin/reports/power">Power</Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          {(permissions.filter((item) => item.name === ClientsModule).length >
            0 ||
            isAdmin) && (
            <Menu.Item key="/admin/clients">
              <Link to="/admin/clients">Clients</Link>
            </Menu.Item>
          )}
          {(permissions.filter((item) => item.name === ClientsSitesModule)
            .length > 0 ||
            isAdmin) && (
            <Menu.Item key="/admin/sites">
              <Link to="/admin/sites">Sites</Link>
            </Menu.Item>
          )}
          {(permissions.filter((item) => item.name === NotificationUsers)
            .length > 0 ||
            isAdmin) && (
            <Menu.Item key="/admin/notifications/users">
              <Link to="/admin/notifications/users">Notification Users</Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      )}

      {(permissions.filter(
        (item) =>
          item.name === UsersModule ||
          item.name === GroupsModule ||
          item.name === RoleModule ||
          item.name === ServiceModule ||
          item.name === NotificationUsers ||
          item.name === PermissionsModule
      ).length > 0 ||
        isAdmin) && (
        <Menu.SubMenu
          style={{ marginTop: "0px" }}
          key="admininstationTools"
          title={
            <span>
              <SettingOutlined /> Admin Tools
            </span>
          }
        >
          <Menu.SubMenu
            style={{ marginTop: "0px" }}
            key="userTools"
            title={
              <span>
                <SkinOutlined />
                User Tools
              </span>
            }
          >
            {(permissions.filter((item) => item.name === UsersModule).length >
              0 ||
              isAdmin) && (
              <Menu.Item key="/admin/users">
                <Link to="/admin/users">User Management</Link>
              </Menu.Item>
            )}
            {(permissions.filter((item) => item.name === RoleModule).length >
              0 ||
              isAdmin) && (
              <Menu.Item key="/admin/roles">
                <Link to="/admin/roles">Roles</Link>
              </Menu.Item>
            )}
            {(permissions.filter((item) => item.name === PermissionsModule)
              .length > 0 ||
              isAdmin) && (
              <Menu.Item key="/admin/permissions">
                <Link to="/admin/permissions">Permission</Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>

          {(permissions.filter((item) => item.name === GroupsModule).length >
            0 ||
            isAdmin) && (
            <Menu.Item key="/admin/groups">
              <Link to="/admin/groups">Groups</Link>
            </Menu.Item>
          )}

          {(permissions.filter((item) => item.name === ServiceModule).length >
            0 ||
            isAdmin) && (
            <Menu.Item key="/admin/services">
              <Link to="/admin/services">Services</Link>
            </Menu.Item>
          )}

          {((userRole === "MPT Staff" &&
            permissions.filter(
              (item) =>
                item.name === DesignationManagementModule ||
                item.name === StaffRegionManagement ||
                item.name === DepartmentManagementModule
            ).length > 0) ||
            isAdmin) && (
            <Menu.SubMenu
              style={{ marginTop: "0px" }}
              key="StaffManagement"
              title={
                <span>
                  <ApartmentOutlined /> Staff Management
                </span>
              }
            >
              {(permissions.filter(
                (item) => item.name === DesignationManagementModule
              ).length > 0 ||
                isAdmin) && (
                <Menu.Item key="/staff/designation">
                  <Link to="/staff/designation">Designations</Link>
                </Menu.Item>
              )}
              {(permissions.filter(
                (item) => item.name === DepartmentManagementModule
              ).length > 0 ||
                isAdmin) && (
                <Menu.Item key="/staff/department">
                  <Link to="/staff/department">Departments</Link>
                </Menu.Item>
              )}
              {(permissions.filter(
                (item) => item.name === StaffRegionManagement
              ).length > 0 ||
                isAdmin) && (
                <Menu.Item key="/staff/regions">
                  <Link to="/staff/regions">Regions</Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
        </Menu.SubMenu>
      )}
      {((userRole === "MPT Staff" &&
        permissions.filter(
          (item) =>
            item.name === ExtensionListModule || item.name === UCTemplates
        ).length > 0) ||
        isAdmin) && (
        <Fragment>
          <Menu.SubMenu
            style={{ marginTop: "0px" }}
            key="StaffServices"
            title={
              <span>
                <CoffeeOutlined /> Staff Services
              </span>
            }
          >
            {(permissions.filter((item) => item.name === ExtensionListModule)
              .length > 0 ||
              isAdmin) && (
              <Menu.Item key="/staff/services/extensionList">
                <Link to="/staff/services/extensionList">Extension List</Link>
              </Menu.Item>
            )}
            <Menu.SubMenu
              style={{ marginTop: "0px" }}
              key="StaffTools"
              title={
                <span>
                  <ToolOutlined /> UC Tools
                </span>
              }
            >
              {(permissions.filter((item) => item.name === UCTemplates).length >
                0 ||
                isAdmin) && (
                <Fragment>
                  <Menu.Item key="/staff/tools/templates">
                    <Link to="/staff/tools/templates">UC Templates</Link>
                  </Menu.Item>
                  <Menu.Item key="/staff/tools/types">
                    <Link to="/staff/tools/types">UC Types</Link>
                  </Menu.Item>
                  <Menu.Item key="/staff/tools/manufactures">
                    <Link to="/staff/tools/manufactures">UC Maunfactures</Link>
                  </Menu.Item>
                  <Menu.Item key="/staff/tools/uccalcs">
                    <Link to="/staff/tools/uccalcs">UC Calcs</Link>
                  </Menu.Item>
                </Fragment>
              )}
            </Menu.SubMenu>
          </Menu.SubMenu>
        </Fragment>
      )}
      {(clientPermissions?.filter((item) => item.name === MPTTASKING).length >
        0 ||
        isAdmin) && (
        <Menu.Item key="/admin/tasks">
          <Link to="/admin/tasks">Tasks</Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

LeftMenu.propTypes = {
  isAdmin: PropTypes.bool,
  permissions: PropTypes.array,
  userRole: PropTypes.string,
  clientPermissions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  permissions: state.auth.permissions,
  userRole: state.auth.user?.role.name,
  clientPermissions: state.auth?.clientPermissions ?? [],
});

export default connect(mapStateToProps)(LeftMenu);
