import { connect } from "react-redux";
import {
  deleteAttachment,
  updateAttachmentDetails,
} from "../../../../actions/tasks";
import { ITaskAttachment } from "../../interfaces/ITaskAttachment";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { Button, List, Popconfirm, Skeleton, Typography } from "antd";
import React, { useEffect, useState, useCallback } from "react";

const { Paragraph } = Typography;

const AttachmentListItem = (props: {
  taskID: string;
  taskTitle: string;
  attachment: ITaskAttachment;
  onChange: Function;
  updateAttachmentDetails: Function;
  deleteAttachment: Function;
}) => {
  const { updateAttachmentDetails, onChange, attachment, taskTitle } = props;
  const [hasChanges, setHasChanges] = useState(false);
  const [editableStr, setEditableStr] = useState(props.attachment.description);

  const [isDeleting, setIsDeleting] = useState(false);

  const _updateAttachmentDetails = useCallback(
    (description: string) => {
      updateAttachmentDetails(attachment._id, description, taskTitle);
      setHasChanges(false);
      onChange();
    },
    [updateAttachmentDetails, onChange, attachment, taskTitle]
  );

  useEffect(() => {
    if (hasChanges) {
      _updateAttachmentDetails(editableStr);
    }
  }, [editableStr, hasChanges, _updateAttachmentDetails]);

  return (
    <Skeleton loading={isDeleting} active>
      <List.Item
        actions={[
          <a
            download
            key={props.attachment.name}
            href={`data:${props.attachment.file.contentType};base64,${props.attachment.file.data}`}
          >
            <DownloadOutlined />
          </a>,
          <Popconfirm
            key={"delete-attachment" + props.attachment._id}
            title={"Are you sure you want to delete this attachment ?"}
            onConfirm={() => {
              setIsDeleting(true);
              props.deleteAttachment(props.attachment._id);
              setTimeout(() => {
                onChange();
              }, 2000);
            }}
            okText={"Yes"}
            cancelText={"No"}
          >
            <Button type={"link"} danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>,
        ]}
      >
        <List.Item.Meta
          avatar={
            props.attachment.file.contentType.includes("image") ? (
              <FileImageOutlined style={{ fontSize: "25px" }} />
            ) : (
              <FileOutlined style={{ fontSize: "25px" }} />
            )
          }
          title={props.attachment.name}
          description={
            <Paragraph
              editable={{
                onStart: () => {
                  console.log("Started");
                },
                onChange: setEditableStr,
                onEnd: () => setHasChanges(true),
              }}
            >
              {/*{attachment.description}*/}
              {editableStr}
            </Paragraph>
          }
        />
      </List.Item>
    </Skeleton>
  );
};

export default connect(null, {
  updateAttachmentDetails,
  deleteAttachment,
})(AttachmentListItem);
