
export const TASK_TYPES = [
   // Drawings / CAD
    {
        name: 'Drawing Request',
        department: 'Drawings'
    },

    // R&D
    {
        name: 'Feature Request',
        department: 'R&D'
    },

    // Developers
    {
        name: 'Feature Request',
        department: 'Developers'
    },

    // Sales
    {
        name: 'Sales Order',
        department: 'Sales'
    },

    // Marketing
    {
        name: 'Signage & Labeling',
        department: 'Marketing'
    },
    {
        name: 'Marketing & Graphics',
        department: 'Marketing'
    },
    {
        name: 'Stationary',
        department: 'Marketing'
    },

    // Admin
    {
        name: 'Job Creation',
        department: 'Admin'
    },
    {
        name: 'Cost Report',
        department: 'Admin'
    },
    {
        name: 'Invoice',
        department: 'Admin'
    },
    {
        name: 'Delivery Note',
        department: 'Admin'
    },
    {
        name: 'Purchase Order Request',
        department: 'Admin'
    },

    // UC Department
    {
        name: 'Bill Of Materials',
        department: 'UC Department'
    },
    {
        name: 'Designs',
        department: 'UC Department'
    },
    {
        name: 'Approvals',
        department: 'UC Department'
    },
    {
        name: 'Project Installation',
        department: 'UC Department'
    },
    {
        name: 'Maintenance Request',
        department: 'UC Department'
    },
    {
        name: 'Handover & Testing',
        department: 'UC Department'
    },
    {
        name: 'Services',
        department: 'UC Department'
    },
];
