import axios from "axios";
import { setAlert } from "../alert";
import setAuthToken from "../../utils/setAuthToken";
import { logout } from "../auth";
import {
  REGIONS_CLEARALL,
  REGIONS_EDIT,
  REGIONS_GET_ALL,
  REGIONS_LOADING,
  REGIONS_MODAL_ADDNEW_CLOSE,
  REGIONS_MODAL_ADDNEW_OPEN,
} from "../types";

// Loads all the Regions
export const loadAllRegions = () => async (dispatch) => {
  try {
    dispatch({
      type: REGIONS_LOADING,
      payload: true,
    });

    // get the all regions
    let res = await axios.get("/api/regions");

    dispatch({
      type: REGIONS_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: REGIONS_LOADING,
      payload: false,
    });
  }
};

// clear the state
export const clearStateRegions = () => (dispatch) => {
  dispatch({
    type: REGIONS_CLEARALL,
  });
};

// view details of a specific Region
export const EditViewRegions = (details) => (dispatch) => {
  dispatch({
    type: REGIONS_EDIT,
    payload: details,
  });
};

// delets a specific region, needs the region object id
export const DeleteSpecificRegion = (regionID) => async (dispatch) => {
  try {
    if (regionID) {
      dispatch({
        type: REGIONS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete region information
      let res = await axios.delete("/api/regions/" + regionID);

      // get the all region
      res = await axios.get("/api/regions");

      dispatch(setAlert("Region is deleted successful", "success"));

      dispatch({
        type: REGIONS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: REGIONS_LOADING,
      payload: false,
    });
  }
};

// updates a specific region, needs the region object id
export const UpdateSpecificRegion = (regionID, data) => async (dispatch) => {
  try {
    if (regionID) {
      dispatch({
        type: REGIONS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // edit the region information
      let res = await axios.put("/api/regions/" + regionID, {
        data,
      });

      // get the all regions
      res = await axios.get("/api/regions");

      dispatch(setAlert("Region details saved", "success"));

      dispatch({
        type: REGIONS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: REGIONS_LOADING,
      payload: false,
    });
  }
};

// Opens the modal to add new region
export const ModalAddNewRegionOpen = () => (dispatch) => {
  dispatch({
    type: REGIONS_MODAL_ADDNEW_OPEN,
  });
};

// Closes the modal to add new region
export const ModalAddNewRegionClose = () => (dispatch) => {
  dispatch({
    type: REGIONS_MODAL_ADDNEW_CLOSE,
  });
};

// Create a new region
export const NewRegion = (data) => async (dispatch) => {
  try {
    dispatch({
      type: REGIONS_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // Send the data to create a new one
    await axios.post("/api/regions", {
      data,
    });

    // get the all regions
    let res = await axios.get("/api/regions");

    dispatch(setAlert("Region added", "success"));

    dispatch({
      type: REGIONS_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));

      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: REGIONS_LOADING,
      payload: false,
    });
  }
};
