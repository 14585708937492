import {
  SALESCLIENTS_CLEARALL,
  SALESCLIENTS_EDIT,
  SALESCLIENTS_GET_ALL,
  SALESCLIENTS_LOADING,
  SALESCLIENTS_MODAL_ADDNEWCLIENT_CLOSE,
  SALESCLIENTS_MODAL_ADDNEWCLIENT_OPEN,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddNewVis: false,
};

export default function salesclient(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case SALESCLIENTS_MODAL_ADDNEWCLIENT_OPEN:
      return {
        ...state,
        modalAddNewVis: true,
        editData: payload !== undefined ? payload : null,
      };
    case SALESCLIENTS_MODAL_ADDNEWCLIENT_CLOSE:
      return { ...state, modalAddNewVis: false, editData: null };
    case SALESCLIENTS_EDIT:
      return { ...state, editData: payload };
    case SALESCLIENTS_LOADING:
      return { ...state, loading: payload };
    case SALESCLIENTS_GET_ALL:
      return { ...state, data: payload, loading: false };
    case SALESCLIENTS_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
