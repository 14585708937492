import {
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_LOAD,
  LOGIN_2FA_SUCCESS,
  LOGIN_2FA_LOAD,
  USER_PERMISIONS,
  USER_VERIFY_DONE,
  USER_VERIFY_LOAD,
  USER_PWDCHG_LOAD,
  USER_CLIENT_PERMISIONS,
} from "../actions/types";

const initalState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isVerified: false,
  isVerifiedLoading: false,
  isChgPWDLoading: false,
  use2FA: false,
  passwordExpired: false,
  viewQR: false,
  isAdmin: false,
  loading: false,
  loading2FA: false,
  permissions: [],
  user: null,
  clientPermissions: null,
};

export default function auth(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_PWDCHG_LOAD:
      return { ...state, isChgPWDLoading: payload };
    case USER_VERIFY_LOAD:
      return { ...state, ...initalState, isVerifiedLoading: payload };
    case USER_VERIFY_DONE:
      return { ...state, isVerified: true };
    case LOGIN_LOAD:
      return { ...state, loading: true };
    case USER_CLIENT_PERMISIONS:
      return { ...state, clientPermissions: payload };
    case USER_PERMISIONS:
      return { ...state, permissions: payload };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        isAdmin: payload?.role.name === "Administrator" ? true : false,
      };
    case LOGIN_2FA_LOAD:
      return { ...state, loading2FA: payload };
    case LOGIN_2FA_SUCCESS:
      localStorage.setItem("token", state.token);
      return {
        ...state,
        token: payload.token,
        use2FA: false,
        isAuthenticated: true,
      };
    case LOGIN_SUCCESS:
      if (!payload.use2FA || !payload.passwordExpired)
        localStorage.setItem("token", payload.token);

      return {
        ...state,
        token: payload.token,
        use2FA: payload.use2FA,
        passwordExpired: payload.passwordExpired,
        viewQR: payload.viewQR ?? false,
        isAuthenticated: payload.use2FA
          ? false
          : payload.passwordExpired
          ? false
          : true,
      };
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
