import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Modal, Button, Tabs } from "antd";

// external functions
import {
  ModalSitesAddClose,
  EditSpecificUser,
  AddNewSiteToUserTableLoaded,
  AddNewSiteToUserAllTableLoaded,
  AddNewSiteToUser,
} from "../../../../actions/users";

// external componets
import ClientSpecificTable from "../tabs/TabClientSpecific";
import ClientALLTable from "../tabs/TabClientAll";

const { TabPane } = Tabs;

// acutal component
const ModalAddNew = ({
  pageWidth,
  isAdmin,
  modalVis,
  userData,
  ModalSitesAddClose,
  AddNewSiteToUserTableLoaded,
  AddNewSiteToUserAllTableLoaded,
  AddNewSiteToUser,
  EditSpecificUser,
  newSite,
}) => {
  const handelClose = (lastData) => {
    let user = { ...userData };
    let userSites = newSite;

    if (newSite.length > 0) {
      user.sites.map((item) => userSites.push({ ...item }));
      user.sites = userSites;
    }

    if (lastData !== null) user.sites.push(lastData);

    EditSpecificUser(user);
    ModalSitesAddClose();
  };

  return (
    <Modal
      centered
      onCancel={() => ModalSitesAddClose()}
      visible={modalVis}
      afterClose={() => {
        AddNewSiteToUser([]);
        AddNewSiteToUserTableLoaded(false);
        AddNewSiteToUserAllTableLoaded(false);
      }}
      title={"Add sites to " + userData?.name + " " + userData?.surname}
      width={pageWidth >= 768 ? "50%" : "100%"}
      footer={
        <>
          <Button type="primary" onClick={() => handelClose(null)}>
            Done
          </Button>
        </>
      }
    >
      {isAdmin ? (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Client Specific" key="1">
            <ClientSpecificTable />
          </TabPane>
          <TabPane tab="All Sites" key="2">
            <ClientALLTable />
          </TabPane>
        </Tabs>
      ) : (
        <ClientSpecificTable />
      )}
    </Modal>
  );
};

ModalAddNew.propTypes = {
  modalVis: PropTypes.bool,
  userData: PropTypes.object,
  ModalSitesAddClose: PropTypes.func.isRequired,
  AddNewSiteToUserTableLoaded: PropTypes.func.isRequired,
  AddNewSiteToUserAllTableLoaded: PropTypes.func.isRequired,
  AddNewSiteToUser: PropTypes.func.isRequired,
  newSite: PropTypes.array,
  EditSpecificUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  modalVis: state.users.modalAddSitesVis,
  userData: state.users?.editData,
  newSite: state.users.newSite ?? [],
});

export default connect(mapStateToProps, {
  ModalSitesAddClose,
  AddNewSiteToUserTableLoaded,
  AddNewSiteToUserAllTableLoaded,
  EditSpecificUser,
  AddNewSiteToUser,
})(ModalAddNew);
