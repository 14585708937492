import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// external functions
import {
  EditViewClient,
  DeleteSpecificClient,
  UpdateSpecificClient,
} from "../../../actions/clients";

// antd componetn
import {
  Table,
  Space,
  Popconfirm,
  Tooltip,
  Button,
  Input,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  RightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";

// actual Component
const ClientsTable = ({
  permissions,
  isAdmin,
  data,
  loading,
  pageWidth,
  EditViewClient,
  UpdateSpecificClient,
  DeleteSpecificClient,
  editClientDetails,
}) => {
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const searchInput = useRef();

  const handleDelete = (record) => {
    DeleteSpecificClient(record._id);
    EditViewClient(null);
  };

  const handleEdit = (record) => {
    EditViewClient(record);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex
        ? // <Highlighter
          //   highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          //   searchWords={[this.state.searchText]}
          //   autoEscape
          //   textToHighlight={text ? text.toString() : ""}
          // />
          text
        : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const handleMenuClick = (e, selectedItemData) => {
    let newClientData = null;

    switch (e.key) {
      case "1":
        newClientData = { ...selectedItemData };

        newClientData.activated = !newClientData.activated;
        UpdateSpecificClient(selectedItemData._id, newClientData);
        break;
      case "2":
        newClientData = { ...selectedItemData };

        if (newClientData.is_legacy === "true") {
          newClientData.is_legacy = "false";
          newClientData.client_id = null;
        } else {
          const clientIDs = data.map((client) => {
            if (client.client_id < 32000) return client.client_id;
            else return 0;
          });

          newClientData.client_id = Math.max(...clientIDs);
          newClientData.client_id++;
          newClientData.is_legacy = "true";
        }
        UpdateSpecificClient(selectedItemData._id, newClientData);
        break;
      default:
        break;
    }

    // refresh the card if we are editing the one the is open
    if (editClientDetails?._id === selectedItemData._id)
      EditViewClient(newClientData);
  };

  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        <Menu.Item key="1">
          {record.activated ? "Deactivate" : "Activate"}
        </Menu.Item>
        <Menu.Item key="2">
          {record.is_legacy === "true" ? "Remove legacy" : "Make legacy"}
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "Status",
      key: "status",
      width: "80px",
      align: "center",
      dataIndex: "activated",
      filters: [
        { text: "Enabled", value: true },
        { text: "Not Enabled", value: false },
      ],
      onFilter: (value, record) => record.activated === value,
      render: (text, record) =>
        text ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Organisation",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "#Services",
      key: "services",
      width: pageWidth >= 1224 ? "10%" : "20%",
      responsive: ["xl"],
      render: (text, record) => {
        const number = record?.services.length;

        return <p>{number}</p>;
      },
    },
    {
      title: "Legacy",
      key: "legacy",
      width: "15%",
      align: "center",
      dataIndex: "is_legacy",
      filters: [
        { text: "Verfied", value: "true" },
        { text: "Not Verfied", value: "false" },
      ],
      onFilter: (value, record) => record.is_legacy.includes(value),
      render: (text, record) =>
        text === "true" ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Legacy ID",
      key: "legacyID",
      width: "15%",
      align: "center",
      dataIndex: "client_id",
      sorter: (a, b) => a.client_id - b.client_id,
    },
    {
      title: "Action",
      dataIndex: "operation",
      align: "right",
      width: pageWidth >= 1224 ? "12%" : "20%",
      render: (text, record) => (
        <Space size="middle">
          <Fragment>
            {permissions?.update | isAdmin ? (
              <Dropdown overlay={() => menu(record)} placement="bottomCenter">
                <SettingOutlined />
              </Dropdown>
            ) : (
              <></>
            )}
            {permissions?.delete | isAdmin ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record)}
              >
                <Tooltip title="Delete user">
                  <a href="#!" style={{ color: "red" }}>
                    <DeleteOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            ) : (
              <></>
            )}
            <Tooltip title="Edit/view notification user">
              <Button
                shape="circle"
                icon={<RightOutlined />}
                onClick={() => handleEdit(record)}
                size="small"
              />
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
      loading={loading}
      columns={columns}
      dataSource={data?.map((item) => {
        item.key = item._id;

        return item;
      })}
      size="small"
      pagination={false}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 20em)" : "calc(100vh - 35em)",
      }}
    ></Table>
  );
};

ClientsTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  editClientDetails: PropTypes.object,
  EditViewClient: PropTypes.func.isRequired,
  DeleteSpecificClient: PropTypes.func.isRequired,
  UpdateSpecificClient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.clients.data,
  loading: state.clients.loading,
  editClientDetails: state.clients.editData,
});

export default connect(mapStateToProps, {
  EditViewClient,
  DeleteSpecificClient,
  UpdateSpecificClient,
})(ClientsTable);
