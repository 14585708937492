import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// andt Components
import { PageHeader, Button, Tabs, Spin } from "antd";
import {
  SaveOutlined,
  ProfileOutlined,
  LockOutlined,
  GroupOutlined,
  SettingOutlined,
  FundViewOutlined,
} from "@ant-design/icons";

import { AiOutlineGlobal } from "react-icons/ai";

// external components
import TabBasic from "./tabs/Basic";
import TabPermissins from "./tabs/Permissions";
import TabGroups from "./tabs/Groups";
import TabAdvanced from "./tabs/Advanced";
import TabStats from "./tabs/Stats";
import TabSites from "./tabs/Sites";

// external functions
import {
  clearSpecificUsersState,
  GetSpecificUserDetails,
  UpdateSpecificUser,
} from "../../../actions/users";
import {
  clearPermisionsState,
  loadAllPermissions,
} from "../../../actions/permissions";
import { clearGroupsState, loadAllGroups } from "../../../actions/groups";
import { clearClientState, loadAllClients } from "../../../actions/clients";
import { clearSitesState, loadAllSites } from "../../../actions/sites";
import { clearRolesState, loadAllRoles } from "../../../actions/roles";
import { loadAllDesignations } from "../../../actions/staffManagement/designation";
import {
  loadAllDepartements,
  clearAllDepartements,
} from "../../../actions/staffManagement/department";

const { TabPane } = Tabs;

// actual component
const Index = ({
  permissions,
  isAdmin,
  match,
  clearSpecificUsersState,
  userData,
  GetSpecificUserDetails,
  UpdateSpecificUser,
  clearPermisionsState,
  loadAllPermissions,
  clearClientState,
  loadAllClients,
  permPermissions,
  groupPermissions,
  clearGroupsState,
  loadAllGroups,
  pageWidth,
  clientsData,
  clearSitesState,
  loadAllSites,
  clearRolesState,
  loadAllRoles,
  loadAllDesignations,
  designations,
  loadAllDepartements,
  clearAllDepartements,
  departments,
}) => {
  const history = useHistory();
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  useEffect(() => {
    if (match.params.userID) GetSpecificUserDetails(match.params.userID);

    // clean up the effect by removing it completely
    return () => {
      clearSpecificUsersState();
    };
  }, [clearSpecificUsersState, GetSpecificUserDetails, match.params]);

  useEffect(() => {
    // we dont want to fill up the store if we dont need it... might be a security issue if the store is populated but not used
    if (isAdmin || permPermissions?.write) {
      // load all the avaiable permision
      loadAllPermissions();
    }

    // we dont want to fill up the store if we dont need it... might be a security issue if the store is populated but not used
    if (isAdmin || permPermissions?.write) {
      // get the clients in case we admin and want to change it
      loadAllClients();
      loadAllDesignations();
      loadAllDepartements();
    }

    loadAllGroups();
    loadAllSites();
    loadAllRoles();

    // clean up the effect by removing it completely
    return () => {
      clearPermisionsState();
      clearClientState();
      clearGroupsState();
      clearSitesState();
      clearRolesState();
      clearAllDepartements();
    };
  }, [
    clearPermisionsState,
    loadAllPermissions,
    clearClientState,
    loadAllClients,
    isAdmin,
    permPermissions,
    clearGroupsState,
    loadAllGroups,
    clearSitesState,
    loadAllSites,
    clearRolesState,
    loadAllRoles,
    loadAllDesignations,
    loadAllDepartements,

    clearAllDepartements,
  ]);

  const handleSave = async () => {
    // show loading state on the save button
    setSaveButtonLoading(true);
    // find the client_id from the client name
    const client = clientsData.filter(
      (item) => item.name === userData.client_name
    );

    userData.client_id = client[0]._id;

    // update the user
    await UpdateSpecificUser(userData._id, userData);
    setTimeout(() => {
      // clear the loading state on the save button
      setSaveButtonLoading(false);
      // redirect the user back
      history.push(`/admin/users`);
    }, 2000);
  };

  return userData === null ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={
          pageWidth >= 795
            ? `Viewing ${userData.name} ${userData.surname}`
            : `${userData.name}`
        }
        style={{
          paddingBottom: pageWidth < 795 && "0px",
          paddingTop: pageWidth < 795 && "0px",
        }}
        subTitle={
          pageWidth >= 795 &&
          "Make sure to save changes before you leave this page"
        }
        extra={[
          <Button
            key="NewUser"
            type="primary"
            disabled={isAdmin ? false : !permissions?.write}
            icon={<SaveOutlined />}
            onClick={handleSave}
            loading={saveButtonLoading}
          >
            {pageWidth >= 795 && "Save Changes"}
          </Button>,
        ]}
      ></PageHeader>

      <Tabs
        defaultActiveKey="Basic"
        style={{ marginTop: "10px" }}
        tabPosition={pageWidth >= 795 ? "left" : "top"}
        size={pageWidth >= 795 ? "large" : "small"}
      >
        <TabPane
          tab={
            <span>
              <ProfileOutlined />
              Basic Information
            </span>
          }
          key="Basic"
        >
          <TabBasic
            isAdmin={isAdmin}
            permissions={permissions}
            pageWidth={pageWidth}
            designations={designations}
            departments={departments}
          />
        </TabPane>
        {permPermissions?.read && (
          <TabPane
            tab={
              <span>
                <LockOutlined />
                Permissions
              </span>
            }
            key="Permission"
          >
            <TabPermissins
              isAdmin={isAdmin}
              permissions={permissions}
              permPermissions={permPermissions}
              pageWidth={pageWidth}
            />
          </TabPane>
        )}
        {groupPermissions?.read && (
          <TabPane
            tab={
              <span>
                <GroupOutlined />
                Groups
              </span>
            }
            key="Groups"
          >
            <TabGroups
              isAdmin={isAdmin}
              permissions={permissions}
              groupPermissions={groupPermissions}
            />
          </TabPane>
        )}
        <TabPane
          tab={
            <span>
              <AiOutlineGlobal style={{ marginRight: "7px" }} /> Sites
            </span>
          }
          key="userSites"
        >
          <TabSites
            isAdmin={isAdmin}
            pageWidth={pageWidth}
            permissions={permissions}
            groupPermissions={groupPermissions}
          />
        </TabPane>
        {isAdmin && (
          <Fragment>
            <TabPane
              tab={
                <span>
                  <SettingOutlined />
                  Advanced
                </span>
              }
              key="Advanced"
            >
              <TabAdvanced
                isAdmin={isAdmin}
                permissions={permissions}
                groupPermissions={groupPermissions}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <FundViewOutlined />
                  Stats
                </span>
              }
              key="statstabs"
            >
              <TabStats
                isAdmin={isAdmin}
                permissions={permissions}
                groupPermissions={groupPermissions}
              />
            </TabPane>
          </Fragment>
        )}
      </Tabs>
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  permPermissions: PropTypes.object,
  groupPermissions: PropTypes.object,
  userData: PropTypes.object,
  clearSpecificUsersState: PropTypes.func,
  GetSpecificUserDetails: PropTypes.func,
  UpdateSpecificUser: PropTypes.func,
  isAdmin: PropTypes.bool,
  clearPermisionsState: PropTypes.func.isRequired,
  loadAllPermissions: PropTypes.func.isRequired,
  loadAllClients: PropTypes.func.isRequired,
  clearClientState: PropTypes.func.isRequired,
  clearGroupsState: PropTypes.func.isRequired,
  loadAllGroups: PropTypes.func.isRequired,
  clearSitesState: PropTypes.func.isRequired,
  loadAllSites: PropTypes.func.isRequired,
  clearRolesState: PropTypes.func.isRequired,
  loadAllRoles: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
  clientsData: PropTypes.array,
  loadAllDesignations: PropTypes.func.isRequired,
  designations: PropTypes.array,
  departments: PropTypes.array,
  loadAllDepartements: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userData: state.users.editData,
  pageWidth: state.misc?.width,
  clientsData: state.clients?.data,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "User Management"
  )[0],
  permPermissions: state.auth?.permissions?.filter(
    (item) => item.name === "Permission Management"
  )[0],
  groupPermissions: state.auth?.permissions?.filter(
    (item) => item.name === "Group Management"
  )[0],
  designations: state.designation,
  departments: state.departments,
});

export default connect(mapStateToProps, {
  clearSpecificUsersState,
  GetSpecificUserDetails,
  UpdateSpecificUser,
  clearPermisionsState,
  loadAllPermissions,
  loadAllClients,
  clearClientState,
  clearGroupsState,
  loadAllGroups,
  clearSitesState,
  loadAllSites,
  clearRolesState,
  loadAllRoles,
  loadAllDesignations,
  loadAllDepartements,
  clearAllDepartements,
})(Index);
