import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Modal, Form, Input, Checkbox } from "antd";

// external functions
import {
  ModalAddPremClose,
  NewPermission,
} from "../../../../actions/permissions";

// acutal component
const ModalAddNew = ({
  modalVis,
  ModalAddPremClose,
  NewPermission,
  isAdmin,
}) => {
  const [formInput] = Form.useForm();
  const inputRef = useRef(null);

  const listener = (event) => {
    // Listen fro both enter keys
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      // submit the form
      formInput.submit();
    }
  };

  if (modalVis) {
    document.addEventListener("keydown", listener);

    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);
  }

  const handleOK = async () => {
    const data = formInput.getFieldsValue();
    await NewPermission(data);
    ModalAddPremClose();
  };

  const handleCancel = () => {
    ModalAddPremClose();
  };

  return (
    <Modal
      onCancel={handleCancel}
      onOk={() => formInput.validateFields().then(handleOK)}
      visible={modalVis}
      afterClose={() => {
        document.removeEventListener("keydown", listener);
        formInput.resetFields();
      }}
      title="Create new permission"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        size="small"
        form={formInput}
      >
        <Form.Item
          label={<label style={{ color: "black" }}>Name</label>}
          style={{ marginBottom: "5px" }}
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item
          label={<label style={{ color: "black" }}>Description</label>}
          name="description"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        {isAdmin && (
          <Form.Item
            label={<span style={{ color: "black" }}>Is a staff only?</span>}
            name={"staffOnly"}
            style={{ marginBottom: "5px" }}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

ModalAddNew.propTypes = {
  modalVis: PropTypes.bool,
  ModalAddPremClose: PropTypes.func.isRequired,
  NewPermission: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  modalVis: state.permissions.modalAddPermissionVis,
});

export default connect(mapStateToProps, { ModalAddPremClose, NewPermission })(
  ModalAddNew
);
