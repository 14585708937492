import {setAlert} from "./alert";
import axios from "axios";
import {
    CLEAR_TASK_CHAT_MESSAGES, CREATE_CHAT,
    GET_CHAT_MESSAGES, GET_TASK_CHAT_MESSAGES,
    GET_USER_CHAT_LIST, IS_CREATING_CHAT,
    NEW_CHAT_MESSAGE_RECEIVED,
    SENDING_MESSAGE,
    SET_HAS_LOADED_CHAT_MESSAGES
} from "./types";
import {chatsBaseUrl} from "../ChatMessagesSocketContext";

const _chatSlug = "[ CHAT ACTION ] ";

// const _messagesEndpoint = `http://localhost:8002/api/messages/`;
const _messagesEndpoint = `${chatsBaseUrl}messages/`;

// const _chatEndpoint = `http://localhost:8002/api/chats/`;
const _chatEndpoint = `${chatsBaseUrl}chats/`;

const exceptionClause = (exception, message) => async (dispatch) =>{
    dispatch(setAlert(message, "error"));
    console.warn(_chatSlug, exception);
}


const setIsCreatingChat = (value) => async (dispatch) => {
    dispatch({
        type: IS_CREATING_CHAT,
        payload: value
    });
}

export const createChat = (chat) => async (dispatch) => {
    try{

        setIsCreatingChat(true);

        let response = await axios.post(
            _chatEndpoint,
            chat
        );

        dispatch({
            type: CREATE_CHAT,
            payload: response.data
        });

        setTimeout(() => {
            setIsCreatingChat(false)
        }, 2000);

    }
    catch (exception){
        exceptionClause(exception, "Failed to create chat");
    }
}

export const getUserChatList = (userID) => async (dispatch) => {

    try{
        let response = await axios.get(`${_chatEndpoint}/user/${userID}`);
        dispatch({
            type: GET_USER_CHAT_LIST,
            payload: response.data
        });
    }
    catch (exception){
        exceptionClause(exception, "Failed to get the user chat list");
    }
}

export const getChatMessages = (chatID) => async (dispatch) => {
    try{
        let response = await axios.get(`${_messagesEndpoint}chat_messages/${chatID}`);

        if(response.data !== null){
            dispatch({
                type: GET_CHAT_MESSAGES,
                payload: response.data
            })

            dispatch({
                type: SET_HAS_LOADED_CHAT_MESSAGES,
                payload: true,
            })

        }else{
            dispatch({
                type: GET_CHAT_MESSAGES,
                payload: []
            })

            dispatch({
                type: SET_HAS_LOADED_CHAT_MESSAGES,
                payload: true,
            })

        }
    }
    catch (exception){
        exceptionClause(exception, "Failed to get chat messages");
    }
}

export const getTaskChatMessages = (chatID) => async (dispatch) => {
    try{
        let response = await axios.get(`${_messagesEndpoint}chat_messages/${chatID}`);

        if(response.data !== null){
            dispatch({
                type: GET_TASK_CHAT_MESSAGES,
                payload: response.data
            })

            dispatch({
                type: SET_HAS_LOADED_CHAT_MESSAGES,
                payload: true,
            })

        }else{
            dispatch({
                type: GET_TASK_CHAT_MESSAGES,
                payload: []
            })

            dispatch({
                type: SET_HAS_LOADED_CHAT_MESSAGES,
                payload: true,
            })

        }
    }
    catch (exception){
        exceptionClause(exception, "Failed to get chat messages");
    }
}

export const clearChatMessages = () => async (dispatch) => {
    
    dispatch({
        type: GET_CHAT_MESSAGES,
        payload: []
    })

    dispatch({
        type: SET_HAS_LOADED_CHAT_MESSAGES,
        payload: false,
    })
}

export const clearTaskChatMessages = () => async (dispatch) => {
    dispatch({
        type: CLEAR_TASK_CHAT_MESSAGES,
        payload: []
    })

    dispatch({
        type: SET_HAS_LOADED_CHAT_MESSAGES,
        payload: false,
    })

}

export const newMessageReceived = (message) => async (dispatch) => {

    try{
        dispatch({
            type: NEW_CHAT_MESSAGE_RECEIVED,
            payload: message
        });
    }
    catch (exception){
        exceptionClause(exception, "");
    }
}

export const sendMessage = (message) => async (dispatch) =>{
    try{
        dispatch({
            type: SENDING_MESSAGE,
            payload: true
        });
        //TODO :: REFACTOR
        await axios.post(`${_messagesEndpoint}`, message);
        dispatch({
            type: SENDING_MESSAGE,
            payload: false
        });

    }
    catch (exception){
        exceptionClause(exception, "Failed to get chat messages");
        dispatch({
            type: SENDING_MESSAGE,
            payload: false
        })
    }
}

// export const destroyChat
