import React, { Fragment } from "react";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

const Contents = ({ children }) => {
  const location = useLocation();

  return (
    <Fragment>
      {location.pathname === "/" ? (
        <Content
          style={{
            padding: "0 25px",
            backgroundColor: "#363636",
          }}
        >
          <div className="site-layout-content">{children}</div>
        </Content>
      ) : (
        <Content style={{ padding: "0 25px", backgroundColor: "#f0f2f5" }}>
          <div className="site-layout-content">{children}</div>
        </Content>
      )}
    </Fragment>
  );
};

export default Contents;
