import axios from "axios";
import { setAlert } from "../alert";
import setAuthToken from "../../utils/setAuthToken";
import { logout } from "../auth";
import {
  DEPART_EDIT,
  DEPART_GET_ALL,
  DEPART_LOADING,
  DEPART_MODAL_ADDNEW_CLOSE,
  DEPART_MODAL_ADDNEW_OPEN,
  DEPART_CLEARALL,
} from "../types";

// Loads all the depatments
export const loadAllDepartements = () => async (dispatch) => {
  try {
    dispatch({
      type: DEPART_LOADING,
      payload: true,
    });

    // get the all depatments
    let res = await axios.get("/api/departments");

    dispatch({
      type: DEPART_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: DEPART_LOADING,
      payload: false,
    });
  }
};

// clear the state
export const clearAllDepartements = () => (dispatch) => {
  dispatch({
    type: DEPART_CLEARALL,
  });
};

// view details of a specific departments
export const EditViewDepartement = (details) => (dispatch) => {
  dispatch({
    type: DEPART_EDIT,
    payload: details,
  });
};

// delets a specific role, needs the departments object id
export const DeleteSpecifiDepartement = (departmentID) => async (dispatch) => {
  try {
    if (departmentID) {
      dispatch({
        type: DEPART_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete departments information
      let res = await axios.delete("/api/departments/" + departmentID);

      // get the all depatments
      res = await axios.get("/api/departments");

      dispatch(setAlert("departments is deleted successful", "success"));

      dispatch({
        type: DEPART_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: DEPART_LOADING,
      payload: false,
    });
  }
};

// updates a specific departments, needs the departments object id
export const UpdateSpecificDepartement =
  (departmentID, data) => async (dispatch) => {
    try {
      if (departmentID) {
        dispatch({
          type: DEPART_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // delete the basic user information
        let res = await axios.put("/api/departments/" + departmentID, {
          data,
        });

        // get the all depatments
        res = await axios.get("/api/departments");

        dispatch(setAlert("departments details saved", "success"));

        dispatch({
          type: DEPART_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log("Error: ", error.response);
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));
        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
      }
      dispatch({
        type: DEPART_LOADING,
        payload: false,
      });
    }
  };

// Opens the modal to add new Departement
export const ModalAddNewDepartementOpen = () => (dispatch) => {
  dispatch({
    type: DEPART_MODAL_ADDNEW_OPEN,
  });
};

// Closes the modal to add new Departement
export const ModalAddNewDepartementClose = () => (dispatch) => {
  dispatch({
    type: DEPART_MODAL_ADDNEW_CLOSE,
  });
};

// Create a new desingation
export const NewDepartement = (data) => async (dispatch) => {
  try {
    dispatch({
      type: DEPART_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // Send the data to create a new one
    await axios.post("/api/departments", {
      ...data,
    });

    // get the all depatments
    let res = await axios.get("/api/departments");

    dispatch(setAlert("Desingation added", "success"));

    dispatch({
      type: DEPART_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));

      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: DEPART_LOADING,
      payload: false,
    });
  }
};
