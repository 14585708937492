import {
  REGIONS_MODAL_ADDNEW_CLOSE,
  REGIONS_MODAL_ADDNEW_OPEN,
  REGIONS_LOADING,
  REGIONS_EDIT,
  REGIONS_CLEARALL,
  REGIONS_GET_ALL,
} from "../../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddRegionVis: false,
};

export default function regions(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGIONS_MODAL_ADDNEW_CLOSE:
      return { ...state, modalAddRegionVis: false };
    case REGIONS_MODAL_ADDNEW_OPEN:
      return { ...state, modalAddRegionVis: true };
    case REGIONS_EDIT:
      return { ...state, editData: payload };
    case REGIONS_LOADING:
      return { ...state, loading: payload };
    case REGIONS_GET_ALL:
      return { ...state, data: payload, loading: false };
    case REGIONS_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
