import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./auth";
import {
  PERM_CLEARALL,
  PERM_EDIT,
  PERM_GET_ALL,
  PERM_LOADING,
  PERM_MODAL_PERMADD_CLOSE,
  PERM_MODAL_PERMADD_OPEN,
} from "./types";

// get all the permissions
export const loadAllPermissions = () => async (dispatch) => {
  try {
    dispatch({
      type: PERM_LOADING,
      payload: true,
    });

    // get the permssions
    let res = await axios.get("/api/permissions");

    dispatch({
      type: PERM_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: PERM_LOADING,
      payload: false,
    });
  }
};

// clears all the store items for the permissions
export const clearPermisionsState = () => (dispatch) => {
  dispatch({
    type: PERM_CLEARALL,
  });
};

// view details of a specific permision
export const EditViewPermission = (details) => (dispatch) => {
  dispatch({
    type: PERM_EDIT,
    payload: details,
  });
};

// updates a specific permission, needs the user object id
export const UpdateSpecificPermission =
  (permissionID, permData) => async (dispatch) => {
    try {
      if (permissionID) {
        dispatch({
          type: PERM_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // delete the basic user information
        let res = await axios.put("/api/permissions/" + permissionID, {
          permissionData: permData,
        });

        // get the permssions
        res = await axios.get("/api/permissions");

        dispatch(setAlert("Permission details saved", "success"));

        dispatch({
          type: PERM_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));
        dispatch({
          type: PERM_LOADING,
          payload: false,
        });
        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
        dispatch({
          type: PERM_LOADING,
          payload: false,
        });
      }
    }
  };

// deletes a specific permission, needs the permission object id
export const DeleteSpecificPermission = (permissionID) => async (dispatch) => {
  try {
    if (permissionID) {
      dispatch({
        type: PERM_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.delete("/api/permissions/" + permissionID);

      // get the permssions
      res = await axios.get("/api/permissions");

      dispatch(setAlert("Permission deleted successful", "success"));

      dispatch({
        type: PERM_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: PERM_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: PERM_LOADING,
        payload: false,
      });
    }
  }
};

// open the modal to add a permission
export const ModalAddPremOpen = () => (dispatch) => {
  dispatch({
    type: PERM_MODAL_PERMADD_OPEN,
  });
};

// close the modal to add a permission
export const ModalAddPremClose = () => (dispatch) => {
  dispatch({
    type: PERM_MODAL_PERMADD_CLOSE,
  });
};

// Create a new permission
export const NewPermission = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PERM_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // Send the data to create a new one
    await axios.post("/api/permissions", {
      data,
    });

    // get the permssions
    let res = await axios.get("/api/permissions");

    dispatch(setAlert("Permission added", "success"));

    dispatch({
      type: PERM_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
      dispatch({
        type: PERM_LOADING,
        payload: false,
      });
    } else if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: PERM_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: PERM_LOADING,
        payload: false,
      });
    }
  }
};
