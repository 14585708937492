import React from "react";

// antd componets
import { Table } from "antd";

// Actual componet
const MeterTable = ({ tableData, pageWidth }) => {
  const columns = [
    {
      title: "Date/Time",
      key: "date",
      dataIndex: "time",
      render: (text) => <p>{new Date(text).toLocaleString()}</p>,
    },
    {
      title: "Used Energy",
      key: "last_value",
      dataIndex: "value",
      render: (text) => <p>{text?.toFixed(2)} kWh</p>,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={
        tableData &&
        tableData.map((item, index) => {
          item.key = index;

          return item;
        })
      }
      size="small"
      pagination={{ pageSize: 100, position: ["bottomLeft"] }}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 40em)" : "calc(100vh - 15em)",
      }}
    ></Table>
  );
};

export default MeterTable;
