import { combineReducers } from "redux";

// individiual reducers
import alert from "./alert";
import auth from "./auth";
import notiUsers from "./notificationsUsers";
import users from "./users";
import sites from "./sites";
import permissions from "./permissions";
import roles from "./roles";
import groups from "./groups";
import clients from "./clients";
import misc from "./misc";
import services from "./services";
import designation from "./designations";
import department from "./department";
import salesclient from "./salesClient";
import salescalls from "./salesCallLog";
import salesMySales from "./salesSales";
import manufacture from "./config/manufacture";
import types from "./config/types";
import templates from "./config/templates";
import regions from "./staffManagement/regions";
import tasks from "./tasks";
import chat from "./chat";

export default combineReducers({
  auth,
  alert,
  notiUsers,
  users,
  sites,
  permissions,
  roles,
  groups,
  clients,
  misc,
  services,
  designation,
  department,
  salesclient,
  salescalls,
  salesMySales,
  manufacture,
  types,
  templates,
  regions,
  tasks,
  chat,
});
