import {
  SERVICES_CLEARALL,
  SERVICES_EDIT,
  SERVICES_GET_ALL,
  SERVICES_LOADING,
  SERVICES_MODAL_PERMADD_CLOSE,
  SERVICES_MODAL_PERMADD_OPEN,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddServicesVis: null,
};

export default function services(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case SERVICES_MODAL_PERMADD_OPEN:
      return { ...state, modalAddServicesVis: true };
    case SERVICES_MODAL_PERMADD_CLOSE:
      return { ...state, modalAddServicesVis: false };
    case SERVICES_EDIT:
      return { ...state, editData: payload };
    case SERVICES_LOADING:
      return { ...state, loading: payload };
    case SERVICES_GET_ALL:
      return { ...state, data: payload, loading: false };
    case SERVICES_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
