import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// antd components
import { Drawer, Button } from "antd";

// external components
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";

// images
import clientZoneImg from "../../images/round_bms_icon.png";

// actual componet
const Navbar = ({ isAuthenticated }) => {
  const [state, setState] = useState({
    current: "DashBoard",
    visible: false,
  });

  const showDrawer = () => {
    setState({
      visible: true,
    });
  };

  const onClose = () => {
    setState({
      visible: false,
    });
  };

  return (
    isAuthenticated && (
      <nav className="menuBar">
        <div className="logo">
          <Link to="/admin">
            <img
              width={50}
              height={50}
              src={clientZoneImg}
              alt="clientZoneImage"
            />
          </Link>
        </div>
        <div className="menuCon">
          {state.visible === false ? (
            <Fragment>
              <div className="leftMenu">
                <LeftMenu />
              </div>
              <div className="rightMenu">
                <RightMenu />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Drawer
                title="Client Zone"
                placement="right"
                width={"50%"}
                closable={false}
                onClose={onClose}
                visible={state.visible}
              >
                <Fragment>
                  <LeftMenu visible={state.visible} />
                  <RightMenu visible={state.visible} />
                </Fragment>
              </Drawer>
            </Fragment>
          )}
          <Button className="barsMenu" type="primary" onClick={showDrawer}>
            <span className="barsBtn"></span>
          </Button>
        </div>
      </nav>
    )
  );
};

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin,
});

export default connect(mapStateToProp)(Navbar);
