import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// antd componet
import { Result, Button } from "antd";

// external functions
import { NewSiteUpdate } from "../../../../../actions/sites";

// Actual component
const Finalisation = ({ setCurrent, NewSiteUpdate }) => {
  const HandleNewSite = () => {
    setCurrent(0);
    NewSiteUpdate(null);
  };

  return (
    <Result
      status="success"
      title="Successfully Added a site!"
      extra={[
        <Link to={{ pathname: `/admin/sites` }}>
          <Button type="primary" key="console">
            Go to Sites
          </Button>
        </Link>,
        <Button key="buy" onClick={HandleNewSite}>
          Add New Site
        </Button>,
      ]}
    />
  );
};

Finalisation.propTypes = {
  NewSiteUpdate: PropTypes.func.isRequired,
};

export default connect(null, { NewSiteUpdate })(Finalisation);
