import {Tag} from "antd";
import React from "react";
import {ColorHelpers} from "../../helpers/color.helpers";


const TaskTagComponent = (props: { type: string, value: string }) => {

    return(
        <Tag color={
            props.type === 'priority'
                ? new ColorHelpers().getTaskPriorityColor(props.value)
                : new ColorHelpers().getTaskStatusColor(props.value)
        }>
            { props.type === 'priority' ? props.value : props.value }
        </Tag>
    );

}

export default TaskTagComponent;
