import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Modal, Form, Input, Select, InputNumber } from "antd";

// external functions
import { ModalNewClientClose, CreateClient } from "../../../../actions/clients";

const { Option } = Select;

// acutal component
const ModalAddNew = ({
  modalAddRoleVis,
  ModalNewClientClose,
  pageWidth,
  CreateClient,
  services,
}) => {
  const [formInput] = Form.useForm();
  const inputRef = useRef(null);
  const [okBtnLoading, setOkBtnLoading] = useState(false);
  const [formData, setFormData] = useState(null);

  const listener = (event) => {
    // Listen fro both enter keys
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      // submit the form
      handleOK();
    }
  };

  if (modalAddRoleVis) {
    if (formData === null) {
      document.addEventListener("keydown", listener);

      setTimeout(() => {
        inputRef.current?.focus({
          cursor: "start",
        });
      }, 100);
    }
  }

  const handleOK = async () => {
    formInput.validateFields().then(async () => {
      setOkBtnLoading(true);
      let data = formInput.getFieldsValue();

      if (Array.isArray(data?.services) === false) {
        let service = data?.services;

        data.services = [];
        data.services.push(service);
      }

      let serviceData = data.services.map((item) => {
        return services.find((ser) => ser.name === item);
      });

      // remove the fluff
      serviceData.map((ser) => {
        delete ser.__v;
        delete ser.updatedAt;
        delete ser.createdAt;
        delete ser.created_by;
        delete ser.key;
        return ser;
      });

      data.services = serviceData;
      await CreateClient(data);
      ModalNewClientClose();
    });
  };

  const handleCancel = () => {
    ModalNewClientClose();
  };

  return (
    <Modal
      onCancel={handleCancel}
      okText="Create"
      okButtonProps={{
        loading: okBtnLoading,
        disabled:
          formData?.name === "" ||
          formData?.name === undefined ||
          formData?.is_legacy === undefined ||
          formData?.notification_subject === "" ||
          formData?.notification_subject === undefined,
      }}
      onOk={() => formInput.validateFields().then(handleOK)}
      visible={modalAddRoleVis}
      width={pageWidth >= 768 ? "30%" : "100%"}
      afterClose={() => {
        document.removeEventListener("keydown", listener);
        formInput.resetFields();
        setOkBtnLoading(false);
        setFormData();
      }}
      title="Register New Client"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        initialValues={{
          services: "Remote Monitoring",
          notification_subject: "MPT BMS",
        }}
        size="small"
        form={formInput}
        onValuesChange={(_, all) => {
          setFormData(all);
        }}
      >
        <Form.Item
          label={<label style={{ color: "black" }}>Organisation Name</label>}
          style={{ marginBottom: "5px" }}
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item
          label={<label style={{ color: "black" }}>Enlisted Services</label>}
          name="services"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              required: true,
              message: "Please choose and option!",
            },
          ]}
        >
          <Select allowClear showSearch mode={"multiple"}>
            {services?.map((item) => {
              return (
                <Option value={item.name} key={item._id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={<label style={{ color: "black" }}>Notification Subject</label>}
          name="notification_subject"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              required: true,
              message: "Please input a valid subject text!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<label style={{ color: "black" }}>Legacy System Client</label>}
          name="is_legacy"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              required: true,
              message: "Please choose an option",
            },
          ]}
        >
          <Select>
            <Option key={"yes"} value={true}>
              Yes
            </Option>
            <Option key={"no"} value={false}>
              No
            </Option>
          </Select>
        </Form.Item>
        {formData?.is_legacy && (
          <Form.Item
            label={<label style={{ color: "black" }}>Legacy ID</label>}
            name="client_id"
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                message: "Please enter a vaild number",
              },
            ]}
          >
            <InputNumber max={255} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

ModalAddNew.propTypes = {
  modalAddRoleVis: PropTypes.bool,
  ModalNewClientClose: PropTypes.func.isRequired,
  CreateClient: PropTypes.func.isRequired,
  services: PropTypes.array,
};

const mapStateToProps = (state) => ({
  modalAddRoleVis: state.clients.modalNewClientVis,
  services: state.services.data,
});

export default connect(mapStateToProps, { ModalNewClientClose, CreateClient })(
  ModalAddNew
);
