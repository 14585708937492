import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant component
import { Spin } from "antd";
import { ChatMessagesSocketContext } from "../../../ChatMessagesSocketContext";

// external components
// import StandardDash from "./standard";
const StandardDash = lazy(() => import("./standard"));

// actual component
const Index = ({ permissions, userDetails }) => {
  const socket = useContext(ChatMessagesSocketContext);
  const [hasChatSession, setHasChatSession] = useState(false);

  useEffect(() => {
    if (!hasChatSession && userDetails !== null) {
      // console.info(userDetails);
      socket?.emit("start_session", userDetails._id);
      setHasChatSession(true);
    }
  }, [hasChatSession, userDetails, socket]);

  return userDetails?.role.name === undefined ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <StandardDash />
    </Suspense>
  );
};

Index.propTypes = {
  isAdmin: PropTypes.bool,
  permissions: PropTypes.array,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  permissions: state.auth?.permissions,
  pageWidth: state.misc?.width,
  userDetails: state.auth.user,
});

export default connect(mapStateToProps)(Index);
