import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd comopnet
import { Popconfirm, Space, Spin, Table, Tag } from "antd";
import {
  DeleteOutlined,
  BarChartOutlined,
  EditOutlined,
} from "@ant-design/icons";

// extenal componets
import MeterTabs from "../MeterTabs";

// external comopnets
import {
  DeleteReportsRules,
  HandleGenerateReport,
} from "../../../../../actions/Misc";

// Actual componet
const TabSaved = ({
  pageWidth,
  metersListLoading,
  recData,
  loadingBtn,
  reportRuels,
  reportRuelsLoading,
  HandleGenerateReport,
  setSelectedMeter,
  setTableData,
  setTableAdditionalData,
  setWorkbookName,
  DeleteReportsRules,
  setTabSelection,
  date,
  setIsUpdate,
}) => {
  const handleDelete = (record) => {
    DeleteReportsRules(record._id);
  };

  const HandelEdit = (record) => {
    setSelectedMeter(record.selectedMeter);
    setTableData(record.tableData);
    setTableAdditionalData(record.tableAdditionalData);
    setWorkbookName(record.workbookName);

    setIsUpdate(record._id);
    setTabSelection("New");
  };

  const HandelExecute = (record) => {
    HandleGenerateReport(
      date,
      record.selectedMeter,
      record.tableData,
      record.tableAdditionalData
    );

    setWorkbookName(record.workbookName);
  };

  const columns = [
    {
      title: "Title",
      key: "title",
      dataIndex: "workbookName",
    },
    {
      title: "Rules",
      key: "rules",
      dataIndex: "tableData",
      render: (text) => (
        <>
          {text.map((tag, index) => {
            return (
              <Tag color={"blue"} key={"rules" + index}>
                {tag.Title.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Additional Rules",
      key: "addrules",
      dataIndex: "tableAdditionalData",
      render: (text) => (
        <>
          {text.map((tag, index) => {
            return (
              <Tag color={"blue"} key={"addrules" + index}>
                {tag.Title.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Created By",
      key: "createdBY",
      width: "200px",
      dataIndex: ["usersData", "name"],
    },
    {
      title: "",
      key: "actions",
      width: "100px",
      render: (text, record) => (
        <Fragment>
          <Space size={pageWidth > 795 ? "middle" : "small"}>
            <Space size={"small"}>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record)}
              >
                <a href="#!" style={{ color: "red" }}>
                  <DeleteOutlined />
                </a>
              </Popconfirm>

              <a href="#!" onClick={() => HandelEdit(record)}>
                <EditOutlined />
              </a>
            </Space>
            <a
              href="#!"
              style={{ color: "green" }}
              onClick={() => HandelExecute(record)}
            >
              <BarChartOutlined />
            </a>
          </Space>
        </Fragment>
      ),
    },
  ];

  return metersListLoading === true ||
    reportRuelsLoading === true ||
    loadingBtn === true ? (
    <Spin
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
    />
  ) : (
    <Fragment>
      {recData?.length === 0 ? (
        <Table
          columns={columns}
          dataSource={
            reportRuels &&
            reportRuels.map((item, index) => {
              item.key = index;

              return item;
            })
          }
          size="small"
          pagination={{ pageSize: 100, position: ["bottomLeft"] }}
          bordered
          scroll={{
            x: "100%",
            y: pageWidth >= 768 ? "calc(100vh - 25em)" : "calc(100vh - 15em)",
          }}
        ></Table>
      ) : (
        <MeterTabs tabItems={recData} pageWidth={pageWidth} />
      )}
    </Fragment>
  );
};

TabSaved.propTypes = {
  pageWidth: PropTypes.number,
  reportRuels: PropTypes.array,
  reportRuelsLoading: PropTypes.bool,
  loadingBtn: PropTypes.bool,
  DeleteReportsRules: PropTypes.func.isRequired,
  HandleGenerateReport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pageWidth: state.misc?.width,
  reportRuels: state.misc?.reportRuels,
  reportRuelsLoading: state.misc?.reportRuelsLoading,
  loadingBtn: state.misc?.reportGenLoading,
});

export default connect(mapStateToProps, {
  DeleteReportsRules,
  HandleGenerateReport,
})(TabSaved);
