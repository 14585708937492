import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Modal, Table, Tooltip, Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  ModalGroupsAddClose,
  EditSpecificUser,
} from "../../../../actions/users";

// actual component
const ModalAddGroups = ({
  avaiableGroups,
  currentGroups,
  modalAddGroupsVis,
  ModalGroupsAddClose,
  userAllData,
  EditSpecificUser,
  isAdmin,
  pageWidth,
}) => {
  const [tableData, setTableData] = useState([]);
  const [newGroups, setnewGroups] = useState([]);
  const isLoaded = useRef(false);

  if (modalAddGroupsVis) {
    if (isLoaded.current === false) {
      let tab = [];

      if (avaiableGroups !== null) {
        avaiableGroups.map((current) => {
          let number = 0;

          if (isAdmin === false) {
            if (current.client_id === userAllData.client_id) {
              currentGroups.map((item) => {
                if (item.name === current.name) {
                  number++;
                }

                return true;
              });
            } else number = 2;
          } else {
            currentGroups.map((item) => {
              if (item.name === current.name) {
                number++;
              }

              return true;
            });
          }

          if (number === 0) {
            tab.push(current);
          }

          return true;
        });

        setTableData(tab);
      }

      isLoaded.current = true;
    }
  }

  const handelClose = (lastData) => {
    let user = { ...userAllData };
    let userGroups = newGroups;

    if (newGroups.length > 0) {
      user.client_groups.map((item) => userGroups.push({ ...item }));
      user.client_groups = userGroups;
    }

    if (lastData !== null) user.client_groups.push(lastData);

    // remove unwanted items in the object
    user.client_groups.map((item) => delete item.sites);

    EditSpecificUser(user);
    ModalGroupsAddClose();
  };

  const handleAdd = (record) => {
    let oldData = [...tableData];
    let newGroupss = [...newGroups];

    newGroupss.push(record);
    setnewGroups(newGroupss);

    if (oldData.length === 1) {
      handelClose(record);
    } else {
      setTableData(oldData.filter((item) => item.name !== record.name));
    }
  };

  const columns = [
    {
      title: "Group",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      responsive: ["lg"],
      dataIndex: "description",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Add this group">
            <Button
              shape="circle"
              type="link"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={() => handleAdd(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      centered
      onCancel={ModalGroupsAddClose}
      visible={modalAddGroupsVis}
      afterClose={() => {
        setnewGroups([]);
        setTableData([]);
        isLoaded.current = false;
      }}
      title={`Add Groups to ${userAllData?.name}`}
      width={pageWidth > 795 ? "50%" : "100%"}
      footer={
        <>
          <Button type="primary" onClick={() => handelClose(null)}>
            Close
          </Button>
        </>
      }
    >
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        size={"small"}
        bordered
      ></Table>
    </Modal>
  );
};

ModalAddGroups.propTypes = {
  avaiableGroups: PropTypes.array,
  currentGroups: PropTypes.array,
  modalAddGroupsVis: PropTypes.bool,
  ModalGroupsAddClose: PropTypes.func.isRequired,
  EditSpecificUser: PropTypes.func.isRequired,
  userAllData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  avaiableGroups: state.groups.data,
  currentGroups: state.users.editData.client_groups,
  modalAddGroupsVis: state.users.modalAddGroupsVis,
  userAllData: state.users?.editData,
});

export default connect(mapStateToProps, {
  ModalGroupsAddClose,
  EditSpecificUser,
})(ModalAddGroups);
