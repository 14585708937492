import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Modal, Table, Tooltip, Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  ModalAddServiceClose,
  EditViewClient,
} from "../../../../actions/clients";

// actual component
const ModalAddService = ({
  services,
  currentServices,
  modalAddServiceVis,
  ModalAddServiceClose,
  clientAllData,
  EditViewClient,
  pageWidth,
}) => {
  const [tableData, setTableData] = useState(null);
  const [newService, setNewService] = useState([]);
  const isLoaded = useRef(false);

  if (modalAddServiceVis) {
    if (isLoaded.current === false) {
      let tab = [];

      if (services !== null) {
        services.map((current) => {
          let number = 0;

          currentServices.map((item) => {
            if (item.name === current.name) {
              number++;
            }

            return true;
          });

          if (number === 0) {
            tab.push(current);
          }

          return true;
        });

        setTableData(tab);
      }

      isLoaded.current = true;
    }
  }

  const handelClose = (lastData) => {
    let client = { ...clientAllData };
    let clientServices = newService;

    if (newService.length > 0) {
      client.services?.map((item) => clientServices.push({ ...item }));
      client.services = clientServices;
    }

    if (lastData !== null) client.services.push(lastData);

    // remove the fluff
    client.services.map((ser) => {
      delete ser.__v;
      delete ser.updatedAt;
      delete ser.createdAt;
      delete ser.created_by;
      delete ser.key;

      return ser;
    });

    EditViewClient(client);
    ModalAddServiceClose();
  };

  const handleAdd = (record) => {
    let oldData = [...tableData];
    let newServices = [...newService];

    delete record.key;
    newServices.push(record);

    setNewService(newServices);

    if (oldData.length === 1) {
      handelClose(record);
    } else {
      setTableData(oldData.filter((item) => item.name !== record.name));
    }
  };

  const columns = [
    {
      title: "Service",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      responsive: ["lg"],
      dataIndex: "description",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Add this service">
            <Button
              shape="circle"
              type="link"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={() => handleAdd(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      centered
      onCancel={ModalAddServiceClose}
      visible={modalAddServiceVis}
      afterClose={() => {
        setNewService([]);
        setTableData(null);
        isLoaded.current = false;
      }}
      title="Add Service"
      width={pageWidth >= 768 ? "50%" : "100%"}
      footer={
        <>
          <Button type="primary" onClick={() => handelClose(null)}>
            Done
          </Button>
        </>
      }
    >
      <Table
        columns={columns}
        dataSource={
          tableData === null
            ? []
            : tableData.map((item) => {
                item.key = item._id;

                return item;
              })
        }
        pagination={false}
        size={"small"}
        bordered
      ></Table>
    </Modal>
  );
};

ModalAddService.propTypes = {
  permissions: PropTypes.array,
  currentServices: PropTypes.array,
  modalAddServiceVis: PropTypes.bool,
  ModalAddServiceClose: PropTypes.func.isRequired,
  EditViewClient: PropTypes.func.isRequired,
  clientAllData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  services: state.services.data,
  currentServices: state.clients.editData?.services,
  modalAddServiceVis: state.clients.modalAddServiceVis,
  clientAllData: state.clients?.editData,
});

export default connect(mapStateToProps, {
  ModalAddServiceClose,
  EditViewClient,
})(ModalAddService);
