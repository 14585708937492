import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Card, Descriptions, Typography } from "antd";
import { HighlightOutlined, SolutionOutlined } from "@ant-design/icons";

// external functions
import { EditViewSite } from "../../../../actions/sites";

const { Paragraph } = Typography;

// actual component
const SiteContact = ({ editData, isAdmin, EditViewSite, permissions }) => {
  const handleChange = (e) => {
    if (e) {
      const siteChange = { ...editData };

      if (e.section === "email")
        siteChange.site_contact[e.section] = e.val.toLowerCase().trim();
      else siteChange.site_contact[e.section] = e.val.trim();

      EditViewSite(siteChange);
    }
  };

  return (
    <Card
      title={
        <span>
          <SolutionOutlined /> Site Contact
        </span>
      }
      bordered={false}
    >
      <Descriptions bordered>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "200px" }}
          label="Name"
          span={4}
        >
          <Paragraph
            editable={
              (isAdmin || permissions.update) && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "name", val }),
              }
            }
          >
            {editData?.site_contact.name}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Email Address"
          span={4}
        >
          <Paragraph
            editable={
              (isAdmin || permissions.update) && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "email", val }),
              }
            }
          >
            {editData?.site_contact.email}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Phone"
          span={4}
        >
          <Paragraph
            editable={
              (isAdmin || permissions.update) && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "phone", val }),
              }
            }
          >
            {editData?.site_contact.phone}
          </Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

SiteContact.propTypes = {
  editData: PropTypes.object,
  EditViewSite: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  editData: state.sites.editData,
});

export default connect(mapStateToProps, {
  EditViewSite,
})(SiteContact);
