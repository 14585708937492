import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";

// antd comopnet
import { Button, DatePicker, Input, message, PageHeader, Space } from "antd";
import { PlusCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// external functions
import { logout } from "../../../../../actions/auth";
import SummaryTable from "./SummaryTable";

const { RangePicker } = DatePicker;
const { Search } = Input;

// actual componet
const MeterSummary = ({ pageWidth, selectedSite, logout }) => {
  const [recData, setRecData] = useState([]);
  const [dates, setDates] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [serchTerm, setSerchTerm] = useState(null);

  const HandleDownload = () => {
    const dateFrom = dates.dateFrom.split("T")[0];
    const dateTO = dates.dateTO.split("T")[0];

    let formatedCSVData = `Meter Summary Report\n`;
    formatedCSVData += `\n`;
    formatedCSVData += `From,${dateFrom}\n`;
    formatedCSVData += `To,${dateTO}\n`;
    formatedCSVData += `\n`;
    formatedCSVData +=
      "Device Name,Supply,Used Energy,Average Apparent Power,Average Current,Average Power,Average Voltage,Max Power,Max Apparent Power\n";

    recData?.map((deviceDetails) => {
      formatedCSVData += `${deviceDetails.device_name},${deviceDetails.supply},${deviceDetails.used_energy},${deviceDetails.avg_apparent_power},${deviceDetails.avg_current},${deviceDetails.avg_power},${deviceDetails.avg_voltage},${deviceDetails.max_power},${deviceDetails.max_va},\n`;
      return true;
    });

    const csvData = new Blob([formatedCSVData], {
      type: "text/csv;charset=utf-8;",
    });

    const downloadLink = document.createElement("a");

    let fileName = `Summary Power from ${dateFrom} to ${dateTO}.csv`;

    downloadLink.href = URL.createObjectURL(csvData);
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const HandleGenerate = async () => {
    try {
      setLoadingBtn(true);
      if (
        sessionStorage.getItem("email") === null ||
        sessionStorage.getItem("ppps") === null
      ) {
        message.error("Session expired, please log in");
        return logout();
      }

      const dateFrom = dates.dateFrom.split("T")[0];
      const dateTO = dates.dateTO.split("T")[0];

      const rec = await axios.post(
        `https://noderedinternal.cloudbms.co.za/api/OADC/site/power`,
        {
          email: sessionStorage.getItem("email"),
          password: sessionStorage.getItem("ppps"),
          site_id: selectedSite,
          date_from: dateFrom,
          date_to: dateTO,
        }
      );

      setRecData(rec.data);
      setLoadingBtn(false);
    } catch (error) {
      console.error("HandleGenerate, ", error);
      message.error("An Error occured!!");
      setLoadingBtn(false);
    }
  };

  const HandleDateChange = (value) => {
    if (value) {
      // const d = new Date(value[1]._d);
      const d = moment(value[1]._d);
      // d.setHours(0, 0, 0, 0);
      let dateTO = d.toISOString();

      // const dt = new Date(value[0]._d);
      const dt = moment(value[0]._d);
      // dt.setHours(0, 0, 0, 0);
      let dateFrom = dt.toISOString();

      setDates({ dateTO, dateFrom });
    } else {
      setDates(null);
    }
  };

  const onSearch = (text) => {
    if (text === "") return setSerchTerm(null);
    setSerchTerm(text);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title="Meter Summary"
        subTitle="Select a date range, then press generate"
        extra={[
          <Button
            key="btnDownload"
            type="default"
            disabled={recData.length === 0}
            icon={<CloudDownloadOutlined />}
            onClick={HandleDownload}
          >
            {pageWidth >= 795 && "Download"}
          </Button>,
          <Button
            key="btnGenerate"
            type="primary"
            disabled={
              dates !== null && selectedSite !== null && loadingBtn === false
                ? false
                : true
            }
            icon={<PlusCircleOutlined />}
            onClick={HandleGenerate}
          >
            {pageWidth >= 795 && "Generate"}
          </Button>,
        ]}
      >
        <Space>
          <RangePicker
            onChange={HandleDateChange}
            disabled={loadingBtn}
            disabledDate={disabledDate}
          />
          <Search
            placeholder="Search something here"
            allowClear
            enterButton="Search"
            onSearch={onSearch}
          />
        </Space>
      </PageHeader>

      <SummaryTable
        dataloading={loadingBtn}
        tableData={recData}
        pageWidth={pageWidth}
        serchTerm={serchTerm}
      />
    </Fragment>
  );
};

MeterSummary.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(MeterSummary);
