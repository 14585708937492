import React, { Fragment, useState } from "react";

// antd components
import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

// actual componnet
const CustomForm = ({
  metersListing,
  selectedMeter,
  setSelectedMeter,
  loadingBtn,
  pageWidth,
  setTableData,
  tableData,
  workbookName,
  setWorkbookName,
  tableAdditionalData,
  setTableAdditionalData,
}) => {
  const [ruleMeters, setRuleMeters] = useState([]);
  const [modalVis, setModalVis] = useState(false);
  const [modalAdditionalVis, setModalAdditionalVis] = useState(false);
  const [modalRuleTitle, setModalRuleTitle] = useState("");

  const handleMeterChange = (value) => {
    if (value?.length > 0) return setSelectedMeter(value);

    setSelectedMeter([]);
  };

  const handleRulesMeterChange = (value) => {
    if (value?.length > 0) return setRuleMeters(value);

    setRuleMeters([]);
  };

  const handleDelete = (record) => {
    let allOldRules = JSON.parse(JSON.stringify(tableData));

    allOldRules = allOldRules.filter((item) => item.key !== record.key);

    setTableData(allOldRules);
  };

  const handleDeleteAdditional = (record) => {
    let allOldRules = JSON.parse(JSON.stringify(tableAdditionalData));

    allOldRules = allOldRules.filter((item) => item.key !== record.key);

    setTableAdditionalData(allOldRules);
  };

  const columns = [
    {
      title: "Title",
      key: "Title",
      width: "250px",
      dataIndex: "Title",
    },
    {
      title: "Meters",
      key: "Meters",
      dataIndex: "Meters",
      render: (text) => (
        <>
          {text.map((tag) => {
            return (
              <Tag color={"blue"} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Operation",
      key: "Operation",
      width: "100px",
      dataIndex: "Operation",
      render: (text) => (
        <Tag color={"blue"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      key: "Action",
      dataIndex: "action",
      width: "100px",
      render: (text, record) => (
        <Fragment>
          <Space size={pageWidth > 795 ? "middle" : "small"}>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <a href="#!" style={{ color: "red" }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Space>
        </Fragment>
      ),
    },
  ];

  const columnsAdditionRules = [
    {
      title: "Title",
      key: "Title",
      width: "250px",
      dataIndex: "Title",
    },
    {
      title: "Rules",
      key: "Rules",
      dataIndex: "Rules",
      render: (text) => (
        <>
          {text.map((tag) => {
            return (
              <Tag color={"blue"} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Operation",
      key: "Operation",
      width: "100px",
      dataIndex: "Operation",
      render: (text) => (
        <Tag color={"blue"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      key: "Action",
      dataIndex: "action",
      width: "100px",
      render: (text, record) => (
        <Fragment>
          <Space size={pageWidth > 795 ? "middle" : "small"}>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDeleteAdditional(record)}
            >
              <a href="#!" style={{ color: "red" }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Space>
        </Fragment>
      ),
    },
  ];

  return metersListing && metersListing?.length > 0 ? (
    <Fragment>
      <p style={{ margin: "auto", display: "block", width: "50%" }}>
        Select the date range above, then all the meters you would like to get
        data for then apply any rules on the data
      </p>

      <div style={{ margin: "auto", width: "500px", marginTop: "0px" }}>
        <Row>
          <Col span={6}>
            <p
              style={{
                marginTop: "2px",
                marginRight: "5px",
                textAlign: "right",
              }}
            >
              Meters:
            </p>
          </Col>
          <Col span={18}>
            <Select
              style={{ width: "350px" }}
              allowClear
              placeholder="Select a meter"
              onChange={handleMeterChange}
              disabled={loadingBtn}
              value={selectedMeter}
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              size="small"
            >
              {metersListing.map((meterDetails) => (
                <Select.Option key={meterDetails} value={meterDetails}>
                  {meterDetails}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>

      <div style={{ margin: "auto", width: "500px", marginTop: "0px" }}>
        <Row>
          <Col span={6}>
            <p
              style={{
                marginTop: "2px",
                marginRight: "5px",
                textAlign: "right",
              }}
            >
              Download Title:
            </p>
          </Col>
          <Col span={18}>
            <Input
              size="small"
              onChange={(e) => setWorkbookName(e.target.value)}
              value={workbookName}
            />
          </Col>
        </Row>
      </div>

      <hr />

      <p style={{ margin: "auto", display: "block", width: "50%" }}>
        Below is a set of rules can can be applied to the selected meters above,
        use the add button to add rules to the list
      </p>

      <Button onClick={() => modalVis === false && setModalVis(true)}>
        Add
      </Button>

      <Modal
        visible={modalVis}
        onCancel={() => {
          setRuleMeters([]);
          setModalRuleTitle("");
          setModalVis(false);
        }}
        onOk={() => {
          let newTableData = [...tableData];

          newTableData.push({
            Meters: ruleMeters,
            Operation: "Add",
            Title: modalRuleTitle,
          });
          setTableData([...newTableData]);
          setRuleMeters([]);
          setModalRuleTitle("");
          setModalVis(false);
        }}
        title="Add rule"
        okButtonProps={{ disabled: ruleMeters.length < 2 }}
      >
        <Space>
          <p style={{ margin: "auto" }}>Rule Title:</p>
          <Input
            onChange={(e) => setModalRuleTitle(e.target.value)}
            value={modalRuleTitle}
          />
        </Space>
        <Select
          style={{ width: "350px", marginTop: 10 }}
          allowClear
          placeholder="Select a meter"
          onChange={handleRulesMeterChange}
          disabled={loadingBtn}
          value={ruleMeters}
          showSearch
          mode="multiple"
          maxTagCount="responsive"
        >
          {selectedMeter.map((meterDetails) => (
            <Select.Option key={meterDetails} value={meterDetails}>
              {meterDetails}
            </Select.Option>
          ))}
        </Select>
      </Modal>

      <Table
        columns={columns}
        dataSource={
          tableData &&
          tableData.map((item, index) => {
            item.key = index;

            return item;
          })
        }
        size="small"
        style={{ marginTop: "10px" }}
        pagination={{ pageSize: 100, position: ["bottomLeft"] }}
        bordered
        scroll={{
          x: "100%",
          y: "100px",
        }}
      ></Table>

      <hr />

      <p style={{ margin: "auto", display: "block", width: "50%" }}>
        Below is a set of additional rules can can be applied to the rules
        created above, use the add button to add addition rules to the list
      </p>

      <Button
        onClick={() =>
          modalAdditionalVis === false && setModalAdditionalVis(true)
        }
      >
        Add Additional
      </Button>

      <Modal
        visible={modalAdditionalVis}
        onCancel={() => {
          setRuleMeters([]);
          setModalRuleTitle("");
          setModalAdditionalVis(false);
        }}
        onOk={() => {
          let newTableData = [...tableAdditionalData];

          newTableData.push({
            Rules: ruleMeters,
            Operation: "Add",
            Title: modalRuleTitle,
          });
          setTableAdditionalData([...newTableData]);
          setRuleMeters([]);
          setModalRuleTitle("");
          setModalAdditionalVis(false);
        }}
        title="Add additional rule"
        okButtonProps={{ disabled: ruleMeters.length < 2 }}
      >
        <Space>
          <p style={{ margin: "auto" }}>Rule Title:</p>
          <Input
            onChange={(e) => setModalRuleTitle(e.target.value)}
            value={modalRuleTitle}
          />
        </Space>
        <Select
          style={{ width: "350px", marginTop: 10 }}
          allowClear
          placeholder="Select a rule, if none create one first"
          onChange={handleRulesMeterChange}
          disabled={loadingBtn}
          value={ruleMeters}
          showSearch
          mode="multiple"
          maxTagCount="responsive"
        >
          {tableData.map((meterDetails) => (
            <Select.Option key={meterDetails.Title} value={meterDetails.Title}>
              {meterDetails.Title}
            </Select.Option>
          ))}
        </Select>
      </Modal>

      <Table
        columns={columnsAdditionRules}
        dataSource={
          tableAdditionalData &&
          tableAdditionalData.map((item, index) => {
            item.key = index;

            return item;
          })
        }
        style={{ marginTop: "10px" }}
        size="small"
        pagination={{ pageSize: 100, position: ["bottomLeft"] }}
        bordered
        scroll={{
          x: "100%",
          y: "100px",
        }}
      ></Table>
    </Fragment>
  ) : (
    <Empty
      description={<span>Select a start and end date then press generate</span>}
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform:
          pageWidth >= 768 ? "translate(-50%, -50%)" : "translate(-50%, 50%)",
      }}
    />
  );
};

export default CustomForm;
