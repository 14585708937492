import React, { Fragment, lazy, Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// External functions
import {
  loadAllDesignations,
  clearState,
  ModalAddNewDesignationOpen,
} from "../../../actions/staffManagement/designation";

// external components
const DesignationTable = lazy(() => import("./Table"));
const DesignationCard = lazy(() => import("./Card"));
const ModalAddNewDesignation = lazy(() =>
  import("./modals/ModalAddNewDesingation")
);

// actual component
const Index = ({
  permissions,
  isAdmin,
  pageWidth,
  loadAllDesignations,
  clearState,
  ModalAddNewDesignationOpen,
}) => {
  useEffect(() => {
    loadAllDesignations();

    // clean up the effect by removing it completely
    return () => {
      clearState();
    };
  }, [loadAllDesignations, clearState]);

  return (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Designation"
          subTitle={pageWidth >= 795 && "Add/Edit or remove staff designations"}
          extra={[
            <Button
              key="NewDesignation"
              type="primary"
              disabled={isAdmin ? false : !permissions?.write}
              icon={<PlusCircleOutlined />}
              onClick={ModalAddNewDesignationOpen}
            >
              {pageWidth >= 795 && "New Designation"}
            </Button>,
          ]}
        ></PageHeader>

        <ModalAddNewDesignation pageWidth={pageWidth} isAdmin={isAdmin} />

        <Row>
          <Col xs={24} md={11}>
            <DesignationTable
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
          <Col xs={0} md={1}></Col>
          <Col xs={24} md={12}>
            <DesignationCard
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
        </Row>
      </Fragment>
    </Suspense>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  loadAllDesignations: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  ModalAddNewDesignationOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Designation Management"
  )[0],
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  loadAllDesignations,
  clearState,
  ModalAddNewDesignationOpen,
})(Index);
