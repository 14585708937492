import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import {
  Document,
  Page,
  Image,
  Text,
  StyleSheet,
  usePDF,
  View,
} from "@react-pdf/renderer";

// antd comopnet
import {
  Button,
  Col,
  DatePicker,
  message,
  PageHeader,
  Row,
  Select,
  Space,
  Statistic,
  Tabs,
} from "antd";
import { PlusCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// external functions
import { logout } from "../../../../../actions/auth";
import KWTable from "./KwTable";

import OADCLogo from "../Images/Picture1.png";
// import PDFTables from "./PDFTable";
// import PDFSummary from "./PDFSummary";
import PDFMeters from "./PDFMeters";

const { RangePicker } = DatePicker;

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    textAlign: "center",
    // margin: 12,
  },
  text: {
    // margin: 12,
    fontSize: 12,
    textAlign: "justify",
  },
  textSubtitle: {
    // margin: 12,
    fontSize: 11,
    color: "grey",
    textAlign: "center",
  },
  image: {
    width: 100,
    paddingBottom: 5,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// actual componet
const KwHourlyIndex = ({ pageWidth, selectedTab, selectedSite }) => {
  const [recData, setRecData] = useState([]);
  const [recStats, setRecStats] = useState([]);
  const [maxOfMeter, setMaxOfMeter] = useState(null);
  const [dates, setDates] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [selectedPDU, setSelectedPDU] = useState([]);
  const [PDUList, setPDUList] = useState(null);
  const filteredOptions = PDUList?.filter((o) => !selectedPDU.includes(o));

  // {/* <Text style={styles.text}>{`Summary:`}</Text>
  //         <Text style={styles.text}>{`Meter with the max : ${
  //           maxOfMeter?.name
  //         } @ ${maxOfMeter?.timeStamp} with ${
  //           maxOfMeter?.value && maxOfMeter?.value.toFixed(2)
  //         }kW`}</Text>
  //         <Text
  //           style={styles.text}
  //         >{`Total Combined Max : ${maxOfMeter?.max} kW`}</Text> */}
  const MyDoc = (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image fixed src={OADCLogo} style={styles.image} />
        <View>
          <Text style={styles.title}>{`Meter Max kW Report\n`}</Text>
          <Text style={styles.subtitle}>{`From : ${
            dates && dates.dateFrom?.split("T")[0]
          }`}</Text>
          <Text style={styles.subtitle}>{`To : ${
            dates && dates.dateTO?.split("T")[0]
          }`}</Text>
          <Text> </Text>

          <PDFMeters recStats={recStats} selectedPDU={selectedPDU} />

          <Text> </Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Open Access Data Centres Isando: 16 Electron Ave, Isando, Kempton Park, 1600\nPage ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  const [instance, updateInstance] = usePDF({ document: MyDoc });

  useEffect(updateInstance, [maxOfMeter, recData]);

  const HandleDownloadPDF = () => {
    const dateFrom = dates.dateFrom.split("T")[0];
    const dateTO = dates.dateTO.split("T")[0];

    let fileNamePDF = `Summary Power from ${dateFrom} to ${dateTO}.pdf`;
    const downloadLinkPDF = document.createElement("a");
    downloadLinkPDF.href = instance.url;
    downloadLinkPDF.download = fileNamePDF;
    downloadLinkPDF.click();
  };

  const HandleDownload = () => {
    const dateFrom = dates.dateFrom.split("T")[0];
    const dateTO = dates.dateTO.split("T")[0];

    let len = selectedPDU.length;

    for (let i = 0; i < len; i++) {
      let formatedCSVData = `Meter Hourly Kw Report\n`;
      formatedCSVData += `\n`;
      formatedCSVData += `Meter :${selectedPDU[i]}\n`;
      formatedCSVData += `\n`;
      formatedCSVData += `From,${dateFrom}\n`;
      formatedCSVData += `To,${dateTO}\n`;
      formatedCSVData += `\n`;
      formatedCSVData += `A Supply Max,${recStats[i][0].value},${recStats[i][1].value}\n`;
      formatedCSVData += `B Supply Max,${recStats[i][2].value},${recStats[i][3].value}\n`;
      formatedCSVData += `Combined Max,${recStats[i][4].value},${recStats[i][5].value}\n`;
      formatedCSVData += `\n`;
      formatedCSVData += `A Supply Mean,${recStats[i][6].value}\n`;
      formatedCSVData += `B Supply Mean,${recStats[i][7].value}\n`;
      formatedCSVData += `Combined Mean,${recStats[i][8].value}\n`;
      formatedCSVData += `\n`;
      formatedCSVData += "Time,A Supply,B Supply,Combined\n";

      recData[i]?.map((deviceDetails) => {
        formatedCSVData += `${deviceDetails.dateTime},${deviceDetails.ASupply},${deviceDetails.BSupply},${deviceDetails.Combined},\n`;
        return true;
      });

      const csvData = new Blob([formatedCSVData], {
        type: "text/csv;charset=utf-8;",
      });

      const downloadLink = document.createElement("a");

      let fileName = `${selectedPDU[i]} Summary Power from ${dateFrom} to ${dateTO}.csv`;

      downloadLink.href = URL.createObjectURL(csvData);
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  const HandleGenerate = async () => {
    try {
      setLoadingBtn(true);
      setRecStats([]);
      setRecData([]);
      setMaxOfMeter(null);

      const dateFrom = dates.dateFrom.split("T")[0];
      const dateTO = dates.dateTO.split("T")[0];
      let totalTableData = [];
      let totalRecStats = [];
      let tableData = [];

      let maxMeter = {
        name: null,
        timeStamp: null,
        value: null,
        max: 0,
      };

      const len = selectedPDU.length;

      for (let i = 0; i < len; i++) {
        const rec = await axios.get(
          `https://noderedinternal.cloudbms.co.za/api/OADC/meter_per_hour?site_id=${selectedSite}&device_name=${selectedPDU[i]}&date_from=${dateFrom}&date_to=${dateTO}`
        );

        if (rec?.data?.perHour) {
          const maxCombined = rec.data?.max?.["Combined"].value;
          const maxCombinedTime = new Date(
            rec.data?.max?.["Combined"].time
          ).toLocaleString();

          if (maxCombined?.["Combined"] > maxMeter.value) {
            maxMeter.value = maxCombined?.["Combined"];
            maxMeter.timeStamp = maxCombinedTime;
            maxMeter.name = selectedPDU[i];
          }

          if (
            maxCombined?.["Combined"] !== undefined &&
            maxCombined?.["Combined"] !== null
          )
            maxMeter.max += Number(maxCombined?.["Combined"]);

          totalRecStats.push([
            { text: "A Supply Max", value: maxCombined?.["A Supply"] },
            {
              text: "A Supply Max Time",
              value: maxCombinedTime,
            },
            { text: "B Supply Max", value: maxCombined?.["B Supply"] },
            {
              text: "B Supply Max Time",
              value: maxCombinedTime,
            },
            { text: "Combined Max", value: maxCombined?.["Combined"] },
            {
              text: "Combined Max Time",
              value: maxCombinedTime,
            },
            { text: "A Supply Mean", value: rec.data?.mean?.["A Supply"] },
            { text: "B Supply Mean", value: rec.data?.mean?.["B Supply"] },
            { text: "Combined Mean", value: rec.data?.mean?.["Combined"] },
          ]);

          Object.keys(rec.data.perHour).map((hourEntry) => {
            return tableData.push({
              dateTime: hourEntry,
              ASupply: rec.data.perHour[hourEntry]?.["A Supply"],
              BSupply: rec.data.perHour[hourEntry]?.["B Supply"],
              Combined: rec.data.perHour[hourEntry]?.["Combined"],
            });
          });
        }
        totalTableData.push(tableData);
      }

      setLoadingBtn(false);
      setRecStats(totalRecStats);
      setRecData(totalTableData);
      setMaxOfMeter({ ...maxMeter });
    } catch (error) {
      console.error("HandleGenerate, ", error);
      message.error("An Error occured!!");
      setLoadingBtn(false);
    }
  };

  const HandleDateChange = (value) => {
    if (value) {
      // const d = new Date(value[1]._d);
      const d = moment(value[1]._d);
      // d.setHours(0, 0, 0, 0);
      let dateTO = d.toISOString();

      // const dt = new Date(value[0]._d);
      const dt = moment(value[0]._d);
      // dt.setHours(0, 0, 0, 0);
      let dateFrom = dt.toISOString();

      setDates({ dateTO, dateFrom });
    } else {
      setDates(null);
    }
  };

  const handlePDUSelection = (value) => {
    setRecStats([]);
    setRecData([]);
    setMaxOfMeter(null);

    if (value?.length > 0) return setSelectedPDU(value);

    setSelectedPDU([]);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const GetPDU = useCallback(async () => {
    try {
      const rec = await axios.get(
        `https://noderedinternal.cloudbms.co.za/api/OADC/PDU_names?site_id=${selectedSite}`
      );

      setPDUList(rec.data);
    } catch (error) {
      console.error("GetPDU, ", error);
      message.error("PDU list error");
    }
  }, [selectedSite]);

  useEffect(() => {
    if (selectedTab === "4") GetPDU();
    // clean up the effect by removing it completely
    return () => {
      setRecData([]);
      setRecStats([]);
    };
  }, [GetPDU, selectedTab]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title="Meter kw hourly"
        subTitle="Select a date range and a client, then press generate"
        extra={[
          // <PDFDownloadLink document={<MyDoc />} fileName="report.pdf">
          //   {({ blob, url, loading, error }) =>
          //     loading ? "Loading document..." : "Download now!"
          //   }
          // </PDFDownloadLink>,

          <Button
            key="btnDownloadPDF"
            type="default"
            disabled={recData.length === 0}
            icon={<CloudDownloadOutlined />}
            onClick={HandleDownloadPDF}
          >
            {pageWidth >= 795 && "Download PDF"}
          </Button>,
          <Button
            key="btnDownload"
            type="default"
            disabled={recData.length === 0}
            icon={<CloudDownloadOutlined />}
            onClick={HandleDownload}
          >
            {pageWidth >= 795 && "Download CSV"}
          </Button>,
          <Button
            key="btnGenerate"
            type="primary"
            disabled={
              dates !== null &&
              selectedSite !== null &&
              loadingBtn === false &&
              selectedPDU?.length > 0
                ? false
                : true
            }
            icon={<PlusCircleOutlined />}
            onClick={HandleGenerate}
          >
            {pageWidth >= 795 && "Generate"}
          </Button>,
        ]}
      >
        <Space>
          <RangePicker
            onChange={HandleDateChange}
            disabled={loadingBtn}
            disabledDate={disabledDate}
          />
          {PDUList && PDUList?.length > 0 && (
            <Select
              style={{ width: "450px" }}
              allowClear
              placeholder="Select PDU meter"
              onChange={handlePDUSelection}
              disabled={loadingBtn}
              showSearch
              value={selectedPDU}
              mode="multiple"
              maxTagCount="responsive"
              options={filteredOptions.map((item) => ({
                value: item,
                label: item,
              }))}
            ></Select>
          )}
        </Space>
      </PageHeader>

      <Tabs>
        {selectedPDU.map((item, pduindex) => {
          return (
            <Tabs.TabPane tab={item} key={"PDUName" + item}>
              <div
                style={{
                  overflowY: "auto",
                  height:
                    pageWidth >= 768
                      ? "calc(100vh - 30em)"
                      : "calc(100vh - 15em)",
                }}
              >
                <Row>
                  {recStats[pduindex] &&
                    recStats[pduindex]?.map((statItem, index) => {
                      return (
                        <Col xs={4} sm={4} key={index}>
                          <Statistic
                            title={
                              <p style={{ fontSize: 14 }}>{statItem.text}</p>
                            }
                            value={statItem.value}
                            valueStyle={{ fontSize: 14, fontWeight: "bold" }}
                            precision={2}
                          />
                        </Col>
                      );
                    })}
                </Row>

                <KWTable
                  tableData={recData[pduindex]}
                  dataloading={loadingBtn}
                  pageWidth={pageWidth}
                />
              </div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </Fragment>
  );
};

KwHourlyIndex.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(KwHourlyIndex);
