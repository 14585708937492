import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./auth";
import {
  NOTIF_EDIT,
  NOTIF_GET_ALL,
  NOTIF_LOADING,
  NOTIF_MODAL_SITEADD,
  NOTIF_MODAL_SITEADD_CLOSE,
  NOTIF_MODAL_SITEADD_OPEN,
  NOTIF_MODAL_SITEADD_TABLE,
  NOTIF_MODAL_SITEADD_TABLE_ALL,
  NOTIF_MODAL_USERADD_CLOSE,
  NOTIF_MODAL_USERADD_OPEN,
} from "./types";

export const loadNotificaitonUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: NOTIF_LOADING,
      payload: true,
    });

    // get the basic user information
    let res = await axios.get("/api/notificationusers/");

    dispatch({
      type: NOTIF_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: NOTIF_LOADING,
      payload: false,
    });
  }
};

export const EditViewNotificationUser = (details) => (dispatch) => {
  dispatch({
    type: NOTIF_EDIT,
    payload: details,
  });
};

export const clearViewNotificationUser = () => (dispatch) => {
  dispatch({
    type: NOTIF_EDIT,
    payload: null,
  });
};

// deletes a specific notification user, needs the object id
export const DeleteSpecificNotificationUser =
  (notificationUserID) => async (dispatch) => {
    try {
      if (notificationUserID) {
        dispatch({
          type: NOTIF_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // delete the notification users
        await axios.delete("/api/notificationusers/" + notificationUserID);

        // get the notification users
        let res = await axios.get("/api/notificationusers/");

        dispatch(setAlert("Notification user deleted successful", "success"));

        dispatch({
          type: NOTIF_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));
        dispatch({
          type: NOTIF_LOADING,
          payload: false,
        });
        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
        dispatch({
          type: NOTIF_LOADING,
          payload: false,
        });
      }
    }
  };

// updates a specific notification user, needs the object id
export const UpdateSpecificNotificationUser =
  (noteUserID, userData) => async (dispatch) => {
    try {
      if (noteUserID) {
        dispatch({
          type: NOTIF_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // delete the basic user information
        await axios.put("/api/notificationusers/" + noteUserID, { userData });

        // get the notification users
        let res = await axios.get("/api/notificationusers/");

        dispatch(setAlert("Notification user updated successful", "success"));

        dispatch({
          type: NOTIF_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log("Error: ", error.response);
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));
        dispatch({
          type: NOTIF_LOADING,
          payload: false,
        });
        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
        dispatch({
          type: NOTIF_LOADING,
          payload: false,
        });
      }
    }
  };

// Open the add new sites modal
export const ModalAddNewSiteOpen = () => (dispatch) => {
  dispatch({
    type: NOTIF_MODAL_SITEADD_OPEN,
  });
};

// Close the add new sites modal
export const ModalAddNewSiteClose = () => (dispatch) => {
  dispatch({
    type: NOTIF_MODAL_SITEADD_CLOSE,
  });
};

// Open the add new user modal
export const ModalAddUserOpen = () => (dispatch) => {
  dispatch({
    type: NOTIF_MODAL_USERADD_OPEN,
  });
};

// Close the add new user modal
export const ModalAddUserClose = () => (dispatch) => {
  dispatch({
    type: NOTIF_MODAL_USERADD_CLOSE,
  });
};

// create a user for notifications
export const CreateNewNotificationUser = (data) => async (dispatch) => {
  try {
    if (data) {
      dispatch({
        type: NOTIF_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the notification users
      await axios.post("/api/notificationusers", { data });

      // get the notification users
      let res = await axios.get("/api/notificationusers/");

      dispatch(setAlert("Notification user deleted successful", "success"));

      dispatch({
        type: NOTIF_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: NOTIF_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: NOTIF_LOADING,
        payload: false,
      });
    }
  }
};

// add sites to list
export const AddNewSiteToUser = (data) => async (dispatch) => {
  dispatch({
    type: NOTIF_MODAL_SITEADD,
    payload: data,
  });
};

// on the add site to notification user modal, needs to be a way to know when the table is populated
export const AddNewSiteToUserTableLoaded = (data) => async (dispatch) => {
  dispatch({
    type: NOTIF_MODAL_SITEADD_TABLE,
    payload: data,
  });
};

// on the add site to notification user modal, needs to be a way to know when the table is populated
export const AddNewSiteAllTableLoaded = (data) => async (dispatch) => {
  dispatch({
    type: NOTIF_MODAL_SITEADD_TABLE_ALL,
    payload: data,
  });
};
