import axios from "axios";
import { setAlert } from "../alert";
import setAuthToken from "../../utils/setAuthToken";
import { logout } from "../auth";

import {
  CONFIGTEMP_LOADING,
  CONFIGTEMP_GET_ALL,
  CONFIGTEMP_GET_SINGLE,
  CONFIGTEMP_CLEARALL,
  CONFIGTEMP_MODALNEW_OPEN,
  CONFIGTEMP_MODALNEW_CLOSE,
  CONFIGTEMP_MODALBATCH_OPEN,
  CONFIGTEMP_MODALBATCH_CLOSE,
  CONFIGTEMP_MODALADDRESS_CLOSE,
  CONFIGTEMP_MODALADDRESS_OPEN,
  CONFIGTEMP_MODALALARM_OPEN,
  CONFIGTEMP_MODALALARM_CLOSE,
  CONFIGTEMP_MODALALARMCOPY_CLOSE,
  CONFIGTEMP_MODALALARMCOPY_OPEN,
  CONFIGTEMP_MODALNEWSNMP_OPEN,
  CONFIGTEMP_MODALNEWSNMP_CLOSE,
  CONFIGTEMP_MODALEXTRADETAILS_CLOSE,
  CONFIGTEMP_MODALEXTRADETAILS_OPEN,
  CONFIGTEMP_MODAL_IMPORT,
} from "../types";

// Get all the items
export const loadAllTemplates = () => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGTEMP_LOADING,
      payload: true,
    });

    // get the all the items
    let res = await axios.get("/api/config/templates");

    dispatch({
      type: CONFIGTEMP_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: CONFIGTEMP_LOADING,
      payload: false,
    });
  }
};

// loads a specific item
export const loadATemplate = (ID) => async (dispatch) => {
  try {
    if (ID) {
      // get the all the items
      let res = await axios.get("/api/config/templates/" + ID);

      dispatch({
        type: CONFIGTEMP_GET_SINGLE,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
  }
};

// loads a specific item
export const CopyDuplicateTemplate = (ID) => async (dispatch) => {
  try {
    if (ID) {
      // get the all the items
      let res = await axios.get("/api/config/templates/" + ID);

      let newTemplate = JSON.parse(JSON.stringify(res.data));

      newTemplate.model = "*** " + newTemplate.model + " -COPY ***";
      delete newTemplate?._id;
      delete newTemplate?.updated_by;
      delete newTemplate?.__v;
      delete newTemplate?.updatedAt;
      delete newTemplate?.createdAt;

      dispatch(createNewTemplate(newTemplate));
    }
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
  }
};

// Edits a specific item
export const editATemplateLocal = (data) => async (dispatch) => {
  try {
    if (data) {
      dispatch({
        type: CONFIGTEMP_GET_SINGLE,
        payload: data,
      });
    }
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
  }
};

// clears all the items
export const clearStateTemplates = () => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_CLEARALL,
  });
};

// delets a specific Template item, needs the object id
export const DeleteSpecificTemplate = (ID) => async (dispatch) => {
  try {
    if (ID) {
      dispatch({
        type: CONFIGTEMP_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the item
      await axios.delete("/api/config/templates/" + ID);

      // get all the items again
      let res = await axios.get("/api/config/templates");

      dispatch(setAlert("Template is deleted successful", "success"));

      dispatch({
        type: CONFIGTEMP_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("DeleteSpecificTemplate error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: CONFIGTEMP_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: CONFIGTEMP_LOADING,
        payload: false,
      });
    }
  }
};

// updates a specific template data, needs the  object id
export const UpdateSpecificTemplate = (ID, data) => async (dispatch) => {
  try {
    if (ID && data) {
      dispatch({
        type: CONFIGTEMP_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.put("/api/config/templates/" + ID, { data });

      // get the sites information
      res = await axios.get("/api/config/templates");

      dispatch(setAlert("Template details saved", "success"));

      dispatch({
        type: CONFIGTEMP_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("UpdateSpecificTemplate error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: CONFIGTEMP_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: CONFIGTEMP_LOADING,
        payload: false,
      });
    }
  }
};

// open modal
export const ModalNewTemplateOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALNEW_OPEN,
    payload: data,
  });
};

// close modal
export const ModalNewTemplateClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALNEW_CLOSE,
  });
};

// open SNMP modal
export const ModalNewSNMPOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALNEWSNMP_OPEN,
    payload: data,
  });
};

// close modal
export const ModalNewSNMPClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALNEWSNMP_CLOSE,
  });
};

// open modal - Add Batch
export const ModalNewTemplateBatchOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALBATCH_OPEN,
    payload: data,
  });
};

// close modal - add batch
export const ModalNewTemplateBatchClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALBATCH_CLOSE,
  });
};

// open address modal -
export const ModalEditAddressOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALADDRESS_OPEN,
    payload: data,
  });
};

// close address modal -
export const ModalEditAddressClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALADDRESS_CLOSE,
  });
};

// open alarms edit modal -
export const ModalEditAlarmOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALALARM_OPEN,
    payload: data,
  });
};

// close alarm copy modal -
export const ModalAlarmCopyClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALALARMCOPY_CLOSE,
  });
};

// open alarms copy modal
export const ModalAlarmCopyOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALALARMCOPY_OPEN,
    payload: data,
  });
};

// close alarm edit modal -
export const ModalEditAlarmClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALALARM_CLOSE,
  });
};

// Creat new
export const createNewTemplate = (data) => async (dispatch) => {
  try {
    if (data) {
      dispatch({
        type: CONFIGTEMP_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // create the item
      await axios.post("/api/config/templates", { data });

      // get the all the items
      let res = await axios.get("/api/config/templates");

      dispatch({
        type: CONFIGTEMP_GET_ALL,
        payload: res.data,
      });

      dispatch(setAlert("Template is added successful", "success"));
    }
  } catch (error) {
    dispatch({
      type: CONFIGTEMP_LOADING,
      payload: false,
    });

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }

    console.log("createNewTemplate error: ", error.response);
  }
};

// open Extra details add modal
export const ModalNewExtraDetailsOpen = (data) => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALEXTRADETAILS_OPEN,
    payload: data,
  });
};

// close Extra details add modal
export const ModalNewExtraDetailsClose = () => (dispatch) => {
  dispatch({
    type: CONFIGTEMP_MODALEXTRADETAILS_CLOSE,
  });
};

export const ModalOpenImportTemplate =
  (open = true) =>
  async (dispatch) => {
    dispatch({
      type: CONFIGTEMP_MODAL_IMPORT,
      payload: open,
    });
  };
