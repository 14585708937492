import React from "react";
import { Layout } from "antd";

const Layouts = ({ children }) => {
  return (
    <Layout
      className="layout"
      style={{
        position: "absolute",
        left: 0,
        overflow: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </Layout>
  );
};

export default Layouts;
