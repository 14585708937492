import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd comopnet
import { Button, PageHeader, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  loadAllManufactures,
  clearStateManufactures,
  ModalNewManufactureOpen,
} from "../../../../actions/staffTools/manufacture";
import {
  clearStateTypes,
  loadAllTypes,
} from "../../../../actions/staffTools/types";

// external components
const ManuTable = lazy(() => import("./table"));
const ModalAddManufacture = lazy(() => import("./Modal/ModalAddManufacture"));

// actual Component
const UCManufactures = ({
  pageWidth,
  isAdmin,
  permissions,
  loadAllManufactures,
  clearStateManufactures,
  ModalNewManufactureOpen,
  clearStateTypes,
  loadAllTypes,
}) => {
  useEffect(() => {
    loadAllManufactures();
    loadAllTypes();

    return () => {
      clearStateManufactures();
      clearStateTypes();
    };
  }, [
    loadAllManufactures,
    clearStateManufactures,
    clearStateTypes,
    loadAllTypes,
  ]);

  return (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="UC Manufactures"
          style={{
            paddingBottom: pageWidth < 795 && "0px",
            paddingTop: pageWidth < 795 && "0px",
          }}
          subTitle={pageWidth > 795 && "List all the manufactures here"}
          extra={[
            <Button
              key="NewUser"
              type="primary"
              disabled={isAdmin ? false : !permissions?.write}
              icon={<PlusCircleOutlined />}
              onClick={() => ModalNewManufactureOpen(null)}
            >
              {pageWidth > 795 && "New"}
            </Button>,
          ]}
        ></PageHeader>

        <ModalAddManufacture
          pageWidth={pageWidth}
          isAdmin={isAdmin}
          permissions={permissions}
        />

        <ManuTable
          pageWidth={pageWidth}
          isAdmin={isAdmin}
          permissions={permissions}
        />
      </Fragment>
    </Suspense>
  );
};

UCManufactures.propTypes = {
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  permissions: PropTypes.object,
  loadAllManufactures: PropTypes.func.isRequired,
  clearStateManufactures: PropTypes.func.isRequired,
  ModalNewManufactureOpen: PropTypes.func.isRequired,
  clearStateTypes: PropTypes.func.isRequired,
  loadAllTypes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "UC Templates"
  )[0],
});

export default connect(mapStateToProps, {
  loadAllManufactures,
  clearStateManufactures,
  ModalNewManufactureOpen,
  clearStateTypes,
  loadAllTypes,
})(UCManufactures);
