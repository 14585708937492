import './style.scss';
import {connect} from "react-redux";
import {addAttachments} from "../../../../actions/tasks";
import {ITask} from "../../interfaces/ITask";
import {Button, Upload} from "antd";
import {CloudUploadOutlined, PlusSquareOutlined} from '@ant-design/icons';
import {useState} from "react";
import {UploadFile} from "antd/es/upload/interface";

const UploadAttachment = (props: { task: ITask, onAttachmentsSubmitted: any,  addAttachments: any }) => {

    const defaultAttachments: UploadFile[] = [];
    const [attachments, setAttachments] = useState([...defaultAttachments]);
    const [isUploading, setIsUploading] = useState(false);

    const uploadAttachments = (): void => {
        setIsUploading(true);
        // Attachments Form Data
        let formData = new FormData();
        formData.append('name', props.task.title + ' attachments');
        formData.append('description', 'No description provided');

        for(let i = 0; i < attachments.length; i++){
            //@ts-ignore
            formData.append('attachment', attachments[i]);
        }

        props.addAttachments(props.task._id, formData);

        setTimeout( () => {
            setIsUploading(false);
            setAttachments([]);
            props.onAttachmentsSubmitted();
        } , 2000);
    }

    const uploadButton = () => (
        <>
            <div className="upload-container-content">
                <PlusSquareOutlined style={{fontSize: '20px'}}/>
                <div
                    style={{
                        marginTop: 0,
                    }}
                >
                    <small>Add Attachments</small>
                </div>
            </div>
        </>
    );

    const onFilesRemoved = (file: UploadFile) :void => {

        let _oldAttachments = [...attachments];
        let newAttachments = _oldAttachments.filter( (a: any) => a.uid !== file.uid );
        setAttachments([...newAttachments]);
    };

    const onBeforeFilesUploaded = (data: UploadFile) => {
        setAttachments([...attachments, data]);
        return false;
    }

    return(
        <div className="upload-attachments-container">
            <div className="upload-container">
                <Upload
                    className="upload-container-content"
                    name="avatar"
                    showUploadList={true}
                    listType="picture-card"
                    onRemove={onFilesRemoved}
                    beforeUpload={onBeforeFilesUploaded}
                    fileList={attachments}
                >
                    { uploadButton() }

                </Upload>

            </div>

            <div className="upload-progress-container">
                <Button
                    disabled={attachments.length < 1}
                    loading={isUploading}
                    icon={<CloudUploadOutlined />}
                    type={'dashed'}
                    onClick={uploadAttachments}>
                    Upload Selected Files
                </Button>
            </div>

        </div>
    );
}

export default connect(null, {addAttachments, })(
    UploadAttachment
);
