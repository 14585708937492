import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd components
import { Button, Card, Form, Input, Space, Tabs, Typography } from "antd";
import { SaveOutlined } from "@ant-design/icons";

// External compoonets
import ModalAddTypeItem from "./Modal/ModalAddTypeItem";
import TabVariables from "./Tabs/TabVariables";
import TabAlarms from "./Tabs/TabAlarms";
import {
  createNewType,
  editATypeLocal,
  ModalNewTypeClose,
  UpdateSpecificType,
} from "../../../../actions/staffTools/types";
import TabUsedBy from "./Tabs/TabUsedBy";

const { Title, Paragraph, Text } = Typography;

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

// actual component
const TypeDetails = ({
  pageWidth,
  isEdit,
  editData,
  createNewType,
  editATypeLocal,
  ModalNewTypeClose,
  UpdateSpecificType,
}) => {
  const inputRef = useRef();
  const [newTypeFrom] = Form.useForm();

  const handleOK = () => {
    if (isEdit === false && editData === null) {
      newTypeFrom.validateFields().then(() => {
        editATypeLocal({
          ...newTypeFrom.getFieldsValue(),
          vairables: [],
          alarms: [],
        });
      });
    } else {
      if (isEdit) {
        UpdateSpecificType(editData._id, { ...editData });
      } else {
        createNewType({ ...editData });
      }
      ModalNewTypeClose();
    }
  };

  const handleCancel = () => {
    ModalNewTypeClose();
  };

  return (
    <Card style={{ height: "95%", marginTop: "15px", marginLeft: "15px" }}>
      <ModalAddTypeItem pageWidth={pageWidth} />

      {isEdit === false && editData === null ? (
        <Fragment>
          <Typography>
            <Title level={2} style={{ textAlign: "center" }}>
              Create a new type
            </Title>
            <Paragraph style={{ textAlign: "center" }}>
              Type the name of your new type in the box below and click on the
              <Text keyboard>Create</Text> button
            </Paragraph>
          </Typography>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            size={pageWidth >= 795 ? "middle" : "small"}
            form={newTypeFrom}
            style={{ marginTop: "40px" }}
          >
            <Form.Item
              label={<span style={{ color: "black" }}>Type name</span>}
              name={"device_type_name"}
              style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
              rules={[
                {
                  required: true,
                  message: `Enter a valid name`,
                },
              ]}
            >
              <Input ref={inputRef} />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" onClick={handleOK}>
                  Create
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            icon={<SaveOutlined />}
            type="primary"
            style={{ position: "absolute", right: "5%" }}
            onClick={handleOK}
          >
            Save
          </Button>
          <Typography>
            <Title level={2}>Editing {editData?.device_type_name}</Title>
          </Typography>
          <Tabs
            defaultActiveKey="1"
            style={{ marginTop: "-30px" }}
            size={pageWidth >= 795 ? "large" : "small"}
          >
            <Tabs.TabPane tab={"Variables"} key={"1"}>
              <TabVariables pageWidth={pageWidth} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Alarms"} key={"2"}>
              <TabAlarms pageWidth={pageWidth} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Used By"} key={"3"}>
              <TabUsedBy pageWidth={pageWidth} />
            </Tabs.TabPane>
          </Tabs>
        </Fragment>
      )}
    </Card>
  );
};

TypeDetails.propTypes = {
  ModalNewTypeClose: PropTypes.func.isRequired,
  createNewType: PropTypes.func.isRequired,
  editATypeLocal: PropTypes.func.isRequired,
  UpdateSpecificType: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  editData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  editData: state.types.editData,
  isEdit: state.types.isEdit,
});

export default connect(mapStateToProps, {
  createNewType,
  editATypeLocal,
  ModalNewTypeClose,
  UpdateSpecificType,
})(TypeDetails);
