import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Card, List } from "antd";

import { AiOutlineTeam } from "react-icons/ai";

import InfiniteScroll from "react-infinite-scroll-component";

// external components
// external functions
// import { EditViewSite, ModalAddFloorsOpen } from "../../../../actions/sites";

// actual component
const SiteContact = ({ pageWidth, editData }) => {
  return (
    <Card
      title={
        <span>
          <AiOutlineTeam /> Users
        </span>
      }
      bordered={false}
    >
      <div
        id="scrollableDiv"
        style={{
          height: pageWidth >= 768 ? "calc(100vh - 31em)" : "100px",
          width: "100%",
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={
            editData?.users?.length === undefined ? 0 : editData?.users?.length
          }
          hasMore={false}
          loader={<h4>Loading...</h4>}
          //   endMessage={
          //     editData?.users?.length > 0 && (
          //       <Divider plain>Done!! add more floors</Divider>
          //     )
          //   }
          scrollableTarget="scrollableDiv"
        >
          <List
            size="small"
            dataSource={editData?.users}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={
                    item.name + " " + item.surname + " - " + item.email_address
                  }
                />
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </Card>
  );
};

SiteContact.propTypes = {
  editData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  editData: state.sites.editData,
});

export default connect(mapStateToProps, {})(SiteContact);
