import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";

// andt components
import { Modal, Form, Input, InputNumber } from "antd";

// external functions
import {
  ModalAddFloorsClose,
  NewSiteUpdate,
  EditViewSite,
} from "../../../../../actions/sites";

// actual component
const ModalAddFloor = ({
  modalVis,
  ModalAddFloorsClose,
  pageWidth,
  NewSiteUpdate,
  newSiteData,
  isEdittingSite,
  editData,
  EditViewSite,
}) => {
  const inputRef = useRef();
  const [formData, setFormData] = useState(null);
  const [formDetails] = Form.useForm();

  const handleOk = () => {
    const id = uuid();
    let data = formDetails.getFieldsValue();

    data.name = data.name.trim();

    if (isEdittingSite === true) {
      let siteData = { ...editData };

      if (siteData?.floors === undefined) siteData.floors = [];

      let floorArray = siteData.floors;

      floorArray.push({ ...data, key: id });

      siteData.floors = [...floorArray];
      EditViewSite({ ...siteData });
    } else {
      let siteData = { ...newSiteData };

      if (siteData?.floors === undefined) siteData.floors = [];

      let floorArray = siteData.floors;

      floorArray.push({ ...data, key: id });

      siteData.floors = [...floorArray];
      NewSiteUpdate({ ...siteData });
    }

    ModalAddFloorsClose();
  };

  const handleCancel = () => {
    ModalAddFloorsClose();
  };

  if (formData === null) {
    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);
  }

  return (
    <Modal
      title="Add Floor to site"
      onCancel={handleCancel}
      okText="Add"
      afterClose={() => {
        setFormData(null);
        formDetails.resetFields();
      }}
      onOk={() => formDetails.validateFields().then(() => handleOk())}
      visible={modalVis}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        size="small"
        form={formDetails}
        onValuesChange={(_, all) => setFormData(all)}
      >
        <Form.Item
          label={<span style={{ color: "black" }}>Floor Name</span>}
          name={"name"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Floor Level</span>}
          name={"level"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              type: "number",
              message: "Please input a valid number!",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAddFloor.propTypes = {
  modalVis: PropTypes.bool,
  ModalAddFloorsClose: PropTypes.func.isRequired,
  NewSiteUpdate: PropTypes.func.isRequired,
  EditViewSite: PropTypes.func.isRequired,
  newSiteData: PropTypes.object,
  editData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVis: state.sites.modalAddFloorVis,
  newSiteData: state.sites.newSiteData,
  editData: state.sites.editData,
});

export default connect(mapStateToProps, {
  ModalAddFloorsClose,
  NewSiteUpdate,
  EditViewSite,
})(ModalAddFloor);
