import {
  ROLES_CLEARALL,
  ROLES_EDIT,
  ROLES_GET_ALL,
  ROLES_LOADING,
  ROLES_MODAL_ADDROLE_CLOSE,
  ROLES_MODAL_ADDROLE_OPEN,
  ROLES_MODAL_PERMADD_CLOSE,
  ROLES_MODAL_PERMADD_OPEN,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddPermVis: false,
  modalAddRoleVis: false,
};

export default function roles(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ROLES_MODAL_ADDROLE_CLOSE:
      return { ...state, modalAddRoleVis: false };
    case ROLES_MODAL_ADDROLE_OPEN:
      return { ...state, modalAddRoleVis: true };
    case ROLES_MODAL_PERMADD_CLOSE:
      return { ...state, modalAddPermVis: false };
    case ROLES_MODAL_PERMADD_OPEN:
      return { ...state, modalAddPermVis: true };
    case ROLES_EDIT:
      return { ...state, editData: payload };
    case ROLES_LOADING:
      return { ...state, loading: payload };
    case ROLES_GET_ALL:
      return { ...state, data: payload, loading: false };
    case ROLES_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
