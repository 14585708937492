import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import setAuthToken from "./utils/setAuthToken";

// Essential Components
import Layout from "./components/layout/index";
import Contents from "./components/layout/content";
import Footers from "./components/layout/footer";
import Alert from "./components/layout/alert";
import Navbar from "./components/layout/navbar/index";
import PrivateRoute from "./components/routing/PrivateRoute";

// UI Components, Public
import Login from "./components/login/index";
import VerifyPage from "./components/verify";

// UI Components, Private routes
import DashBoard from "./components/admin/dashboard";
import NotificationUsers from "./components/admin/notifications";
import SitesManagment from "./components/admin/sites";
import UsersManagment from "./components/admin/users";
import RolesManagment from "./components/admin/roles";
import GroupsManagment from "./components/admin/Groups";
import PremissionsManagment from "./components/admin/permissions";
import ClientsManagment from "./components/admin/clients";
import UserManagment from "./components/admin/user";
import SiteManagment from "./components/admin/site";
import CreateASite from "./components/admin/site/create";
import CreateAUser from "./components/admin/user/create";
import ServiceManagment from "./components/admin/services";

// reports
import SMSReport from "./components/admin/reports/SMS";
import AveragesReport from "./components/admin/reports/Averages";
import PowerReport from "./components/admin/reports/Power/landing";
import TemperatureReport from "./components/admin/reports/Temperature";

// staff
import DepartmentManagment from "./components/staff/Department";
import DesignationManagment from "./components/staff/Designation";
import ExtensionList from "./components/staff/Services/ExtensionList";
import PipelineImport from "./components/PipelineImport";
import StaffRegions from "./components/staff/StaffRegions";

// Templates
import UCTypes from "./components/staff/tools/UCTypes";
import UCTemplates from "./components/staff/tools/UCTemplates";
import UCManufactures from "./components/staff/tools/UCManufactures";
import UCCalcs from "./components/staff/tools/UCCalcs";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";

// Contexts
import { ChatMessagesSocketContext, socket } from "./ChatMessagesSocketContext";
import TaskManagement from "./modules/taskManagement";
import TaskView from "./modules/taskManagement/pages/taskView";
import CreateTaskPage from "./modules/taskManagement/pages/create";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

// actual component
const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <ChatMessagesSocketContext.Provider value={socket}>
      <Provider store={store}>
        <BrowserRouter>
          <Fragment>
            <Layout>
              <Navbar />
              <Alert />
              <Contents>
                <Route exact path="/" component={Login} />
                <Route
                  exact
                  path="/verify/admin/:userID"
                  component={VerifyPage}
                />
                <Switch>
                  <PrivateRoute exact path="/admin" component={DashBoard} />
                  <PrivateRoute
                    exact
                    path="/admin/reports/sms-counts"
                    component={SMSReport}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/reports/power"
                    component={PowerReport}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/reports/averages"
                    component={AveragesReport}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/reports/temperature"
                    component={TemperatureReport}
                  />

                  <PrivateRoute
                    exact
                    path="/staff/tools/templates"
                    component={UCTemplates}
                  />
                  <PrivateRoute
                    exact
                    path="/staff/regions"
                    component={StaffRegions}
                  />
                  <PrivateRoute
                    exact
                    path="/staff/tools/types"
                    component={UCTypes}
                  />
                  <PrivateRoute
                    exact
                    path="/staff/tools/manufactures"
                    component={UCManufactures}
                  />
                  <PrivateRoute
                    exact
                    path="/staff/tools/uccalcs"
                    component={UCCalcs}
                  />
                  <PrivateRoute
                    exact
                    path="/staff/importer"
                    component={PipelineImport}
                  />
                  <PrivateRoute
                    exact
                    path="/staff/designation"
                    component={DesignationManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/staff/services/extensionList"
                    component={ExtensionList}
                  />
                  <PrivateRoute
                    exact
                    path="/staff/department"
                    component={DepartmentManagment}
                  />

                  <PrivateRoute
                    exact
                    path="/admin/notifications/users"
                    component={NotificationUsers}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/sites"
                    component={SitesManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/site/:siteID"
                    component={SiteManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/sites/create"
                    component={CreateASite}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/users/create"
                    component={CreateAUser}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/services"
                    component={ServiceManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/user/:userID"
                    component={UserManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/users"
                    component={UsersManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/roles"
                    component={RolesManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/groups"
                    component={GroupsManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/permissions"
                    component={PremissionsManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/clients"
                    component={ClientsManagment}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/tasks"
                    component={TaskManagement}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/tasks/view"
                    component={TaskView}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/tasks/create"
                    component={CreateTaskPage}
                  />
                </Switch>
              </Contents>
              <Footers />
            </Layout>
          </Fragment>
        </BrowserRouter>
      </Provider>
    </ChatMessagesSocketContext.Provider>
  );
};

export default App;
