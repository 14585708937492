import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Card, Button, Popconfirm, Space, Table, Row, Col } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  NewSiteUpdate,
  ModalAddFloorsOpen,
  NewSiteSave,
} from "../../../../../actions/sites";

// external components
import ModalAddFloors from "../modals/ModalAddFloor";

// actual componet
const Floors = ({
  current,
  setCurrent,
  pageWidth,
  NewSiteUpdate,
  newSiteData,
  ModalAddFloorsOpen,
  NewSiteSave,
}) => {
  const handleNext = () => {
    NewSiteSave(newSiteData);
    setCurrent(current + 1);
  };

  const handleDelete = (record) => {
    let currentSiteData = { ...newSiteData };

    currentSiteData.floors = currentSiteData.floors.filter(
      (floor) => floor.key !== record.key
    );

    NewSiteUpdate(currentSiteData);
  };

  const columns = [
    {
      title: "Floor Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Level",
      key: "level",
      width: "50px",
      dataIndex: "level",
    },
    {
      title: "",
      dataIndex: "operation",
      width: "50px",
      align: "right",
      render: (text, record) => (
        <Space size="middle">
          <Fragment>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <a href="#!" style={{ color: "red" }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={<p style={{ fontWeight: "bold" }}>Floors</p>}
      extra={[
        <Button
          style={{ margin: "0 8px" }}
          onClick={() => setCurrent(current - 1)}
        >
          Previous
        </Button>,
        <Button type="primary" onClick={() => handleNext()}>
          Finalise
        </Button>,
      ]}
      style={{ marginTop: "15px", width: "100%" }}
    >
      <ModalAddFloors pageWidth={pageWidth} />

      <Row>
        <Col xs={0} lg={8}></Col>
        <Col xs={32} lg={8}>
          <p style={{ fontWeight: "bold" }}>
            Sites floors:
            <span>
              <Button
                type="text"
                style={{ color: "blue", float: "right" }}
                icon={<PlusCircleOutlined />}
                onClick={ModalAddFloorsOpen}
              >
                Add floor
              </Button>
            </span>
          </p>
          <Table
            style={{ marginTop: pageWidth >= 768 ? "15px" : "0px" }}
            columns={columns}
            dataSource={newSiteData?.floors}
            size="small"
            pagination={false}
            bordered
            scroll={{
              x: "100%",
              y: pageWidth >= 768 ? "calc(100vh - 20em)" : "calc(100vh - 30em)",
            }}
          ></Table>
        </Col>
      </Row>
    </Card>
  );
};

Floors.propTypes = {
  userDetails: PropTypes.object,
  avaibleClients: PropTypes.array,
  avaibleGroups: PropTypes.array,
  NewSiteUpdate: PropTypes.func.isRequired,
  ModalAddFloorsOpen: PropTypes.func.isRequired,
  NewSiteSave: PropTypes.func.isRequired,
  newSiteData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.user,
  avaibleClients: state.clients.data,
  avaibleGroups: state.groups.data,
  newSiteData: state.sites.newSiteData,
});

export default connect(mapStateToProps, {
  NewSiteUpdate,
  ModalAddFloorsOpen,
  NewSiteSave,
})(Floors);
