import React from "react";
// import PropTypes from "prop-types";

const UCCalcs = (props) => {
  return <div>UCCalcs</div>;
};

UCCalcs.propTypes = {};

export default UCCalcs;
