import {
  CLIENTS_CLEARALL,
  CLIENTS_EDIT,
  CLIENTS_GET_ALL,
  CLIENTS_GET_LOGO,
  CLIENTS_LOADING,
  CLIENTS_MODAL_NEWCLIENT_CLOSE,
  CLIENTS_MODAL_NEWCLIENT_OPEN,
  CLIENTS_MODAL_ADDSERVICE_CLOSE,
  CLIENTS_MODAL_ADDSERVICE_OPEN,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalNewClientVis: false,
  modalAddServiceVis: false,
  logoPic: null,
};

export default function clients(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENTS_MODAL_ADDSERVICE_CLOSE:
      return { ...state, modalAddServiceVis: false };
    case CLIENTS_MODAL_ADDSERVICE_OPEN:
      return { ...state, modalAddServiceVis: true };
    case CLIENTS_GET_LOGO:
      return { ...state, logoPic: payload };
    case CLIENTS_MODAL_NEWCLIENT_OPEN:
      return { ...state, modalNewClientVis: true };
    case CLIENTS_MODAL_NEWCLIENT_CLOSE:
      return { ...state, modalNewClientVis: false };
    case CLIENTS_EDIT:
      return { ...state, editData: payload };
    case CLIENTS_LOADING:
      return { ...state, loading: payload };
    case CLIENTS_GET_ALL:
      return { ...state, data: payload, loading: false };
    case CLIENTS_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
