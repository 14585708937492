import {
  CONFIGTYPE_CLEARALL,
  CONFIGTYPE_GET_ALL,
  CONFIGTYPE_GET_SINGLE,
  CONFIGTYPE_LOADING,
  CONFIGTYPE_MODALNEWITEM_CLOSE,
  CONFIGTYPE_MODALNEWITEM_OPEN,
  CONFIGTYPE_MODALNEW_CLOSE,
  CONFIGTYPE_MODALNEW_OPEN,
} from "../../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  isEdit: false,
  modalVis: false,
  modaltemAddVis: false,
  modaltemAddData: null,
};

export default function types(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONFIGTYPE_GET_SINGLE:
      return {
        ...state,
        editData: payload,
      };
    case CONFIGTYPE_MODALNEWITEM_OPEN:
      return {
        ...state,
        modaltemAddVis: true,
        modaltemAddData: payload,
      };
    case CONFIGTYPE_MODALNEWITEM_CLOSE:
      return {
        ...state,
        modaltemAddVis: false,
        modaltemAddData: null,
      };
    case CONFIGTYPE_MODALNEW_CLOSE:
      return {
        ...state,
        modalVis: false,
        editData: null,
        isEdit: false,
      };
    case CONFIGTYPE_MODALNEW_OPEN:
      return {
        ...state,
        modalVis: true,
        editData: null,
        isEdit: payload !== null ? true : false,
      };
    case CONFIGTYPE_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    case CONFIGTYPE_GET_ALL:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case CONFIGTYPE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
