import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Steps } from "antd";

// external components
import StepUserDetails from "./Steps/UserDetails";
import StepSiteSite from "./Steps/UserSite";
// import StepLocation from "./Steps/Location";
// import StepFloors from "./Steps/Floors";
import StepFinalisation from "./Steps/Finalisation";

// external functions
import { clearClientState, loadAllClients } from "../../../../actions/clients";
import { clearSitesState, loadAllSites } from "../../../../actions/sites";
import { clearRolesState, loadAllRoles } from "../../../../actions/roles";
import { clearGroupsState, loadAllGroups } from "../../../../actions/groups";
import { NewUserUpdate } from "../../../../actions/users";
import { loadAllDepartements } from "../../../../actions/staffManagement/department";
import {
  clearState,
  loadAllDesignations,
} from "../../../../actions/staffManagement/designation";

const { Step } = Steps;

// actual component
const Index = ({
  clearClientState,
  loadAllClients,
  clearGroupsState,
  loadAllGroups,
  isAdmin,
  clearRolesState,
  loadAllRoles,
  clearSitesState,
  loadAllSites,
  NewUserUpdate,
  loadAllDepartements,
  clearState,
  loadAllDesignations,
  newUserData,
}) => {
  // const staffRegex = RegExp(/staff/, "gi");

  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: "User Details",
      content: (
        <StepUserDetails
          current={current}
          setCurrent={setCurrent}
          isAdmin={isAdmin}
          pageWidth={pageWidth}
        />
      ),
      description: "General information",
    },
    {
      title: "Sites",
      content: (
        <StepSiteSite
          current={current}
          setCurrent={setCurrent}
          isAdmin={isAdmin}
          pageWidth={pageWidth}
        />
      ),
      description: "Sites and groups for the user",
    },
    {
      title: "Finalisation",
      content: (
        <StepFinalisation
          current={current}
          setCurrent={setCurrent}
          pageWidth={pageWidth}
        />
      ),
      description: "Process Complete",
    },
  ];

  const handleWindowSizeChange = () => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    // add event listener for the window resize
    window.addEventListener("resize", handleWindowSizeChange);

    loadAllGroups();
    loadAllClients();
    loadAllSites();
    loadAllRoles();
    loadAllDepartements();
    loadAllDesignations();

    // clean up the effect by removing it completely
    return () => {
      clearClientState();
      clearGroupsState();
      clearRolesState();
      clearSitesState();
      clearState();
      NewUserUpdate(null);
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [
    clearClientState,
    loadAllClients,
    isAdmin,
    clearGroupsState,
    loadAllGroups,
    clearRolesState,
    loadAllRoles,
    clearSitesState,
    loadAllSites,
    NewUserUpdate,
    loadAllDepartements,
    clearState,
    loadAllDesignations,
  ]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Create a new user`}
        subTitle={pageWidth > 765 && "Complete all steps to create a new user"}
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Step
              key={item.title}
              title={pageWidth > 765 && item.title}
              description={pageWidth > 765 && item.description}
            />
          ))}
        </Steps>
      </PageHeader>

      <div className="steps-content">{steps[current].content}</div>
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  clearClientState: PropTypes.func.isRequired,
  loadAllClients: PropTypes.func.isRequired,
  clearGroupsState: PropTypes.func.isRequired,
  loadAllGroups: PropTypes.func.isRequired,
  clearRolesState: PropTypes.func.isRequired,
  loadAllRoles: PropTypes.func.isRequired,
  clearSitesState: PropTypes.func.isRequired,
  loadAllSites: PropTypes.func.isRequired,
  NewUserUpdate: PropTypes.func.isRequired,
  loadAllDepartements: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  loadAllDesignations: PropTypes.func.isRequired,
  newUserData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  newUserData: state.users.newUserData,
  isAdmin: state.auth.isAdmin,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Client Site Management"
  )[0],
});

export default connect(mapStateToProps, {
  clearClientState,
  loadAllClients,
  clearGroupsState,
  loadAllGroups,
  clearRolesState,
  loadAllRoles,
  clearSitesState,
  loadAllSites,
  NewUserUpdate,
  loadAllDepartements,
  clearState,
  loadAllDesignations,
})(Index);
