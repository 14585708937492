import React, { Fragment, useState, useCallback, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";

// andt Components
import {
  Button,
  DatePicker,
  Empty,
  PageHeader,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { PlusCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// External componetns
import PowerTabs from "./PowerTabs";

const { RangePicker } = DatePicker;

// actual component
const Index = ({ pageWidth, selectedTab, selectedSite }) => {
  const [dates, setDates] = useState(null);
  const [tagsList, setTagList] = useState([]);
  const [tagsOtherList, setTagsOtherList] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedOtherTag, setSelectedOtherTag] = useState(null);

  const [recData, setRecData] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(true);

  const GetTagsOtherList = async (tagOther) => {
    try {
      setTagsLoading(true);

      const rec = await axios.get(
        `https://noderedinternal.cloudbms.co.za/api/OADC/tag_values?tag=${tagOther}&site_id=${selectedSite}`
      );

      if (rec.data) {
        setTagsOtherList(rec.data);
      }
      setTagsLoading(false);
    } catch (error) {
      console.log("GetTagsList, ", error);
      message.error("Tag lists loading error");
      setTagsLoading(false);
    }
  };

  const GetTagsList = useCallback(async () => {
    try {
      setTagsLoading(true);
      const rec = await axios.get(
        `https://noderedinternal.cloudbms.co.za/api/OADC/tags?site_id=${selectedSite}`
      );

      if (rec.data) {
        setTagList(rec.data);
      }
      setTagsLoading(false);
    } catch (error) {
      console.log("GetTagsList, ", error);
      message.error("Tag lists loading error");
      setTagsLoading(false);
    }
  }, [selectedSite]);

  useEffect(() => {
    if (selectedTab === "1") GetTagsList();
    // clean up the effect by removing it completely
    return () => {
      setSelectedTag(null);
      setRecData([]);
    };
  }, [GetTagsList, selectedTab]);

  const handelTagOther = (value) => {
    if (value?.length > 0) {
      return setSelectedOtherTag(value);
    }
    setSelectedOtherTag(null);
  };

  const HandleGenerate = async () => {
    try {
      if (dates) {
        setLoadingBtn(true);
        setRecData([]);

        const res = await axios.get(
          `https://noderedinternal.cloudbms.co.za/api/OADC/meters_by_tag?${selectedTag}=${selectedOtherTag}&date_to=${
            dates.dateTO.split("T")[0]
          }&date_from=${
            dates.dateFrom.split("T")[0]
          }&site_id=6343b7d1cd3624022cff2312`
        ); //site/meter

        // let requestData = {
        //   email: sessionStorage.getItem("email"),
        //   password: sessionStorage.getItem("ppps"),
        //   date_from: dates.dateFrom.split("T")[0],
        //   date_to: dates.dateTO.split("T")[0],
        // };

        // requestData[selectedTag] = selectedOtherTag;
        // const res = await axios.post(
        //   `https://noderedinternal.cloudbms.co.za/api/OADC/client/power?site_id=6343b7d1cd3624022cff2312`,
        //   {
        //     ...requestData,
        //   }
        // ); //site/meter
        // const res = await axios.post(
        //   `https://noderedinternal.cloudbms.co.za/api/OADC/site/meter`,
        //   {
        //     ...requestData,
        //   }
        // );

        // format data
        let formatedData = [];

        if (res?.data) {
          res.data.map((tabItem) => {
            let stats = JSON.parse(JSON.stringify(tabItem));

            let daily_energy = [...tabItem.daily_energy];
            delete stats.daily_energy;
            delete stats.month;
            delete stats.energy_symbol;

            let maxValue = 0;
            let totalValue = 0;
            let aveValue = 0;

            if (daily_energy) {
              daily_energy.map((singleItem) => {
                parseInt(singleItem.day) > maxValue &&
                  (maxValue = parseInt(singleItem.day));

                totalValue += parseFloat(singleItem.day);

                return true;
              });
            }

            aveValue = totalValue / daily_energy.length;

            let completeItem = {
              stats,
              daily_energy,
              month: tabItem.month,
              maxValue,
              totalValue: totalValue.toFixed(3),
              aveValue: aveValue.toFixed(3),
            };

            // console.log(completeItem);

            formatedData.push(completeItem);
            return true;
          });

          setRecData(formatedData);
        }
        setLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
      message.error("😭 Error occurred !! 😭", 5);
      setLoadingBtn(false);
    }
  };

  const HandleDateChange = (value) => {
    if (value) {
      const d = moment(value[1]._d);
      // d.setHours(0, 0, 0, 0);
      let dateTO = d.toISOString();

      // const dt = new Date(value[0]._d);
      const dt = moment(value[0]._d);
      // dt.setHours(0, 0, 0, 0);
      let dateFrom = dt.toISOString();

      setDates({ dateTO, dateFrom });
    } else {
      setDates(null);
    }
  };

  const handelTagChange = (value) => {
    if (value?.length > 0) {
      GetTagsOtherList(value);
      setSelectedOtherTag(null);
      return setSelectedTag(value);
    }
    setSelectedTag(null);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const HandleDownload = () => {
    recData?.map((tabItem) => {
      // let formatedCSVData = "Date,Consumed\n";
      let formatedCSVData = `Month,${tabItem.month}\n`;
      formatedCSVData += `Client,${selectedTag}\n`;
      formatedCSVData += `\n`;
      formatedCSVData += `Month Total Energy,${tabItem.totalValue}\n`;
      formatedCSVData += `Month Average Power,${tabItem.aveValue}\n`;
      formatedCSVData += `Month Max reached,${tabItem?.maxValue}\n`;
      formatedCSVData += `\n`;
      formatedCSVData += "Date,Actual kwh,Consumed\n";

      tabItem.daily_energy.map((item) => {
        formatedCSVData += `${item.date},${item.last_value},${item.day},\n`;
        return true;
      });

      const csvData = new Blob([formatedCSVData], {
        type: "text/csv;charset=utf-8;",
      });

      const downloadLink = document.createElement("a");

      let fileName = `Daily Power - ${selectedTag} - ${tabItem.month}.csv`;

      downloadLink.href = URL.createObjectURL(csvData);
      downloadLink.download = fileName;
      downloadLink.click();

      return true;
    });
  };

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title="Power by tag"
        subTitle="Select a date range, tag and the individual tag followed by the generate button"
        extra={[
          <Button
            key="btnDownLoadItem"
            type="default"
            disabled={recData.length === 0}
            icon={<CloudDownloadOutlined />}
            onClick={HandleDownload}
            loading={tagsLoading}
          >
            {pageWidth >= 795 && "Download"}
          </Button>,
          <Button
            key="btnGenerate"
            type="primary"
            disabled={
              dates !== null && selectedTag !== null && loadingBtn === false
                ? false
                : true
            }
            icon={<PlusCircleOutlined />}
            onClick={HandleGenerate}
            loading={tagsLoading}
          >
            {pageWidth >= 795 && "Generate"}
          </Button>,
        ]}
      >
        <Space>
          <RangePicker
            onChange={HandleDateChange}
            disabled={loadingBtn}
            disabledDate={disabledDate}
          />
          {tagsList && tagsList?.length > 0 && (
            <Select
              style={{ width: "250px" }}
              allowClear
              placeholder="Select tag"
              onChange={handelTagChange}
              disabled={loadingBtn}
              showSearch
              value={selectedTag}
              loading={tagsLoading}
            >
              {tagsList.map((tagDetails, index) => (
                <Select.Option key={index} value={tagDetails}>
                  {tagDetails.replace("_", " ")}
                </Select.Option>
              ))}
            </Select>
          )}
          {selectedTag && (
            <Select
              style={{ width: "250px" }}
              allowClear
              placeholder={`Select ${selectedTag}`}
              onChange={handelTagOther}
              disabled={loadingBtn}
              showSearch
              value={selectedOtherTag}
              loading={tagsLoading}
            >
              {tagsOtherList.map((tagOtherDetails, index) => (
                <Select.Option key={index} value={tagOtherDetails}>
                  {tagOtherDetails.replace("_", " ")}
                </Select.Option>
              ))}
            </Select>
          )}
        </Space>
      </PageHeader>
      {recData.length === 0 ? (
        loadingBtn === true ? (
          <Spin
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="large"
          />
        ) : (
          <Empty
            description={
              <span>Select a start and end date then press generate</span>
            }
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "50%",
              transform:
                pageWidth >= 768
                  ? "translate(-50%, -50%)"
                  : "translate(-50%, 50%)",
            }}
          />
        )
      ) : (
        <PowerTabs tabItems={recData} pageWidth={pageWidth} />
      )}
    </Fragment>
  );
};

export default connect(null, {})(Index);
