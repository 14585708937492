import "./styles.scss";
import {
  Avatar,
  Button,
  DatePicker,
  Descriptions,
  Select,
  Tag,
  Typography,
} from "antd";
import TaskTagComponent from "../taskTag";
import { ClockCircleOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ITask } from "../../interfaces/ITask";
import { connect } from "react-redux";
import { STATUSES } from "../../helpers/statuses";
import { RangePickerProps } from "antd/es/date-picker";
import { ISimpleUser } from "../../../../interfaces/shared/ISimpleUser";

const { Text } = Typography;

const TaskDescriptionComponent = (props: {
  currentUser: any;
  task: ITask;
  onStatusChange: any;
  onManageCollaboratorTriggered: any;
  onDueDateChange: any;
}) => {
  const [selectedDueDate, setSelectedDueDate] = useState(props.task.due_date);
  const [actionsDisabled, setActionsDisabled] = useState(true);

  const init = useCallback((): void => {
    try {
      if (
        props.currentUser._id !== props.task.creator.id &&
        props.currentUser.role.name !== "Administrator"
      ) {
        setActionsDisabled(true);
      } else if (
        props.currentUser._id !== props.task.creator.id &&
        props.currentUser.role.name === "Administrator"
      ) {
        setActionsDisabled(false);
      } else {
        setActionsDisabled(false);
      }
    } catch (exception) {
      console.error("Exception: ", exception);
    }
  }, [
    props.currentUser._id,
    props.currentUser.role.name,
    props.task.creator.id,
  ]);

  useEffect(() => {
    init();
  }, [init]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  return (
    <Descriptions layout={"horizontal"} bordered>
      <Descriptions.Item
        label="Type"
        span={3}
        labelStyle={{ fontWeight: "bold" }}
      >
        <Tag>{props.task.type}</Tag>
      </Descriptions.Item>

      <Descriptions.Item
        label="Title"
        labelStyle={{ fontWeight: "bold" }}
        span={3}
      >
        {props.task.title}
      </Descriptions.Item>

      <Descriptions.Item
        label="Due Date"
        span={3}
        labelStyle={{ fontWeight: "bold", color: "black" }}
        style={{
          color:
            moment(selectedDueDate).isSame(moment(), "day") ||
            moment(selectedDueDate).isBefore(moment(), "day")
              ? "crimson"
              : "black",
        }}
      >
        {/*<span>*/}
        {/*    <CalendarOutlined /> {moment(props.task.due_date).format('dddd DD MMM YYYY')}*/}
        {/*</span>*/}
        <DatePicker
          //@ts-ignore
          defaultValue={moment(props.task.due_date)}
          bordered={false}
          disabled={actionsDisabled}
          placeholder="Change Date"
          disabledDate={disabledDate}
          onChange={(date: any) => {
            if (date !== null) {
              setSelectedDueDate(date.toDate());
              props.onDueDateChange(date.toDate());
            }
          }}
        />
      </Descriptions.Item>

      <Descriptions.Item
        style={{ margin: "0 0 10px" }}
        label="Description"
        labelStyle={{ fontWeight: "bold" }}
        span={3}
      >
        <p>{props.task.description}</p>
      </Descriptions.Item>

      <Descriptions.Item
        label="Priority"
        span={2}
        labelStyle={{ fontWeight: "bold" }}
      >
        <TaskTagComponent type={"priority"} value={props.task.priority} />
      </Descriptions.Item>

      <Descriptions.Item
        label="Status"
        span={1}
        labelStyle={{ fontWeight: "bold" }}
      >
        <Select
          defaultValue={props.task.status}
          disabled={actionsDisabled}
          style={{ width: "100%" }}
          bordered={false}
          onChange={(value: string) => {
            console.log("Status Value Change", value);
            props.onStatusChange(value);
          }}
        >
          {STATUSES.map((status: string, index: number) => (
            <Select.Option key={"status-key-" + index} value={status}>
              <TaskTagComponent type={"status"} value={status} />
            </Select.Option>
          ))}
        </Select>
      </Descriptions.Item>

      <Descriptions.Item
        label="Assignee"
        span={3}
        labelStyle={{ fontWeight: "bold" }}
      >
        <div className="task-description-container">
          <span>
            {props.task.assigned_to.user_name}{" "}
            <Text type={"secondary"}>
              {props.task.assigned_to.email_address}
            </Text>{" "}
          </span>
          {/*<Button*/}
          {/*    size={'small'}*/}
          {/*    key="change-assignee"*/}
          {/*    type={'link'}*/}
          {/*    onClick={() => {*/}

          {/*    }}*/}
          {/*>*/}
          {/*    <UserSwitchOutlined /> Change*/}
          {/*</Button>*/}
        </div>
      </Descriptions.Item>

      <Descriptions.Item
        label="Collaborators"
        span={3}
        labelStyle={{ fontWeight: "bold" }}
      >
        <div className="task-description-container">
          <Avatar.Group
            maxCount={10}
            size={"small"}
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            {props.task.collaborators.map(
              (collaborator: ISimpleUser, number: number) => {
                return (
                  <Avatar
                    key={"avatar-key-" + number}
                    style={{ backgroundColor: "#f56a00" }}
                  >
                    {collaborator.user_name.slice(0, 1)}
                  </Avatar>
                );
              }
            )}

            {/*<Avatar src="https://joeschmoe.io/api/v1/random" />*/}
            {/*<Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>*/}
            {/*<Avatar style={{ backgroundColor: '#1890ff' }} icon={<UserOutlined />} />*/}
          </Avatar.Group>
          <Button
            disabled={actionsDisabled}
            key="manage-collaborators"
            size={"small"}
            type={"link"}
            onClick={props.onManageCollaboratorTriggered}
          >
            <UsergroupAddOutlined /> Manage Collaborators
          </Button>
        </div>
      </Descriptions.Item>

      <Descriptions.Item
        label="Date Created"
        span={3}
        labelStyle={{ fontWeight: "bold", color: "black" }}
      >
        <p>
          <ClockCircleOutlined />{" "}
          {moment(props.task.created_at).format("dddd DD MMM YYYY")}
        </p>
      </Descriptions.Item>

      <Descriptions.Item
        label="Created By"
        span={3}
        labelStyle={{ fontWeight: "bold" }}
      >
        <p>
          {props.task.creator.user_name}{" "}
          <Text type={"secondary"}>{props.task.creator.email_address}</Text>
        </p>
      </Descriptions.Item>
    </Descriptions>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.auth.user,
  task: state.tasks.selectedTask,
});

export default connect(mapStateToProps)(TaskDescriptionComponent);
