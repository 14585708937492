import React, { Fragment, lazy, Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// External functions
import {
  loadAllRegions,
  clearStateRegions,
  ModalAddNewRegionOpen,
} from "../../../actions/staffManagement/regions";

// external components
const RegionTable = lazy(() => import("./Table"));
const RegionCard = lazy(() => import("./Card"));
const ModalAddNewRegion = lazy(() => import("./modals/ModalAddNewRegion"));

// actual component
const StaffRegions = ({
  permissions,
  isAdmin,
  pageWidth,
  loadAllRegions,
  clearStateRegions,
  ModalAddNewRegionOpen,
}) => {
  useEffect(() => {
    loadAllRegions();

    // clean up the effect by removing it completely
    return () => {
      clearStateRegions();
    };
  }, [loadAllRegions, clearStateRegions]);

  return (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Region Managment"
          subTitle={pageWidth >= 795 && "Add/Edit or remove regions"}
          extra={[
            <Button
              key="NewRegion"
              type="primary"
              disabled={isAdmin ? false : !permissions?.write}
              icon={<PlusCircleOutlined />}
              onClick={ModalAddNewRegionOpen}
            >
              {pageWidth >= 795 && "New Region"}
            </Button>,
          ]}
        ></PageHeader>

        <ModalAddNewRegion pageWidth={pageWidth} isAdmin={isAdmin} />

        <Row>
          <Col xs={24} md={11}>
            <RegionTable
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
          <Col xs={0} md={1}></Col>
          <Col xs={24} md={12}>
            <RegionCard
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
        </Row>
      </Fragment>
    </Suspense>
  );
};

StaffRegions.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  loadAllRegions: PropTypes.func.isRequired,
  clearStateRegions: PropTypes.func.isRequired,
  ModalAddNewRegionOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Staff Region Management"
  )[0],
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  loadAllRegions,
  clearStateRegions,
  ModalAddNewRegionOpen,
})(StaffRegions);
