import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Card, Descriptions, Typography } from "antd";
import { HighlightOutlined, LockOutlined } from "@ant-design/icons";

// external functions
import { EditViewSite } from "../../../../actions/sites";

const { Paragraph } = Typography;

// actual component
const Address = ({ editData, isAdmin, EditViewSite, permissions }) => {
  const handleChange = (e) => {
    if (e) {
      const changeSite = { ...editData };

      changeSite.address[e.section] = e.val.trim();

      EditViewSite(changeSite);
    }
  };

  return (
    <Card
      title={
        <span>
          <LockOutlined /> Address
        </span>
      }
      bordered={false}
    >
      <Descriptions bordered>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "200px" }}
          label="Street"
          span={4}
        >
          <Paragraph
            editable={
              (isAdmin || permissions.update) && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "street", val }),
              }
            }
          >
            {editData?.address.street}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Suburb"
          span={4}
        >
          <Paragraph
            editable={
              isAdmin && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "suburb", val }),
              }
            }
          >
            {editData?.address.suburb}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="City"
          span={4}
        >
          <Paragraph
            editable={
              isAdmin && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "city", val }),
              }
            }
          >
            {editData?.address.city}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Province"
          span={4}
        >
          <Paragraph
            editable={
              isAdmin && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "province", val }),
              }
            }
          >
            {editData?.address.province}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Country"
          span={4}
        >
          <Paragraph
            editable={
              isAdmin && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "country", val }),
              }
            }
          >
            {editData?.address.country}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Latitude"
          span={4}
        >
          <Paragraph
            editable={
              isAdmin && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "latitude", val }),
              }
            }
          >
            {editData?.address.latitude}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: "bold", width: "50px" }}
          label="Longitude"
          span={4}
        >
          <Paragraph
            editable={
              isAdmin && {
                icon: <HighlightOutlined style={{ color: "black" }} />,
                tooltip: "click to edit text",
                onChange: (val) => handleChange({ section: "longitude", val }),
              }
            }
          >
            {editData?.address.longitude}
          </Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

Address.propTypes = {
  editData: PropTypes.object,
  EditViewSite: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  editData: state.sites.editData,
  clientsData: state.clients?.data,
});

export default connect(mapStateToProps, {
  EditViewSite,
})(Address);
