import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Row, Col, Form, Input, Button, Typography, InputNumber } from "antd";
import { SecurityScanOutlined } from "@ant-design/icons";

// Image
import { ReactComponent as VerifyMeImage } from "../images/security.svg";

// external functions
import { VerfiyMe } from "../../actions/auth";

const { Title, Paragraph } = Typography;

// actual component
const Index = ({ VerfiyMe, isVerified, isVerifiedLoading, match }) => {
  const inputRef = React.useRef(null);
  const [buttonDisable, setbuttonDisable] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [formSignIn] = Form.useForm();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  // windows resize and enter button
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    const listener = (event) => {
      // Listen fro both enter keys
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // submit the form
        formSignIn.submit();
      }
    };

    document.addEventListener("keydown", listener);
    formSignIn.resetFields();

    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);

    // clean up the effect by removing it completely
    return () => {
      document.removeEventListener("keydown", listener);
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [formSignIn]);

  // VerfiyMe function
  const handleFinish = () => {
    if (match.params.userID.length > 4) {
      let data = formSignIn.getFieldsValue();

      delete data.passwordFirst;

      VerfiyMe(data, match.params.userID);
    }
  };

  // If we are already authenticated
  if (isVerified) {
    return window.location.replace("https://www.cloudbms.co.za");
  }

  return (
    <div
      style={
        width >= 768
          ? {
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "50%",
              transform: "translate(-50%, -50%)",
            }
          : {}
      }
    >
      <Row>
        {width >= 768 && (
          <Fragment>
            <Col xs={24} md={11}>
              <VerifyMeImage
                height="100%"
                preserveAspectRatio="xMinYMin slice"
                width="100%"
                viewBox="0 0 1500 1500"
              />
            </Col>
            <Col md={1}></Col>
          </Fragment>
        )}
        <Col xs={24} md={6}>
          <Typography>
            <Title
              level={2}
              style={{
                marginBottom: "0px",
                marginTop: width >= 768 ? "0px" : "15px",
              }}
            >
              <SecurityScanOutlined style={{ color: "black" }} />
              <span style={{ color: "black" }}>Verfiy Account</span>
            </Title>
            <Paragraph>
              <span style={{ color: "black" }}>
                Provide the passcode you received on the email and type a new
                password for your account.
              </span>
            </Paragraph>
            <Paragraph>
              <span style={{ color: "black " }}>
                Create a <strong>strong level</strong> password that has at
                least one lowercase letter, one uppercase letter, one
                digit/number, one special character, and is at least eight
                characters long.
              </span>
            </Paragraph>
          </Typography>
          <Form
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 16 }}
            layout="vertical"
            size="small"
            form={formSignIn}
            onFinish={() =>
              formSignIn.validateFields().then(() => handleFinish())
            }
            onValuesChange={(_, All) => {
              setbuttonDisable(All);
            }}
          >
            <Form.Item
              label={<span style={{ color: "black" }}>Passcode</span>}
              name="emailToken"
              style={{ marginBottom: width >= 768 ? "5px" : "0px" }}
              rules={[
                {
                  required: true,
                  message: "Please input a passcode!",
                },
              ]}
            >
              <InputNumber ref={inputRef} />
            </Form.Item>
            <Form.Item
              label={<span style={{ color: "black" }}>Password</span>}
              style={{ marginBottom: width >= 768 ? "5px" : "0px" }}
              name="passwordFirst"
              rules={[
                { required: true, message: "Password is required" },
                {
                  message: "Password doesn't meet requirements",
                  pattern: new RegExp(
                    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
                  ),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label={<span style={{ color: "black" }}>Confirm Password</span>}
              name="password"
              rules={[
                {
                  required: buttonDisable?.passwordFirst,
                  message: "Please confirm your password",
                },
                () => ({
                  validator(_, value) {
                    if (value !== buttonDisable?.passwordFirst) {
                      return Promise.reject("Passwords don't match");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={
                  buttonDisable?.passcode === "" ||
                  buttonDisable === undefined ||
                  isVerifiedLoading
                    ? true
                    : false
                }
                loading={isVerifiedLoading}
                htmlType="submit"
              >
                Accept
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

Index.propTypes = {
  VerfiyMe: PropTypes.func.isRequired,
  isVerifiedLoading: PropTypes.bool,
  isVerified: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isVerified: state.auth.isVerified,
  isVerifiedLoading: state.auth.isVerifiedLoading,
});

export default connect(mapStateToProps, { VerfiyMe })(Index);
