import React from "react";
import socketio from "socket.io-client";

// const ENDPOINT = "mpt.dedicated.co.za:8002";
const ENDPOINT = "https://chat.cloudbms.co.za";

export const chatsBaseUrl = "https://chat.cloudbms.co.za/api/";

let authSocket = null;

if (localStorage.token) {
  authSocket = socketio(ENDPOINT, {
    transports: ["websocket"],
    secure: true,
    jsonp: false,
  });
}

export const socket = authSocket;
export const ChatMessagesSocketContext = React.createContext();
