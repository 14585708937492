import React from "react";

// antd componets
import { Table } from "antd";

// Actual componet
const PowerTable = ({ tableData, pageWidth }) => {
  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      render: (text) => <p>{new Date(text).toLocaleString()}</p>,
    },
    {
      title: "Last reading",
      key: "last_value",
      dataIndex: "last_value",
    },
    {
      title: "Consumed",
      key: "Consumed",
      dataIndex: "day",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={
        tableData &&
        tableData.map((item, index) => {
          item.key = index;

          if (item?.last_value)
            item.last_value = Number(item.last_value).toFixed(2);
          if (item?.day) item.day = Number(item.day).toFixed(2);

          return item;
        })
      }
      size="small"
      pagination={{ pageSize: 100 }}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 32em)" : "calc(100vh - 15em)",
      }}
    ></Table>
  );
};

export default PowerTable;
