import React, { Fragment, lazy, Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// External functions
import {
  loadAllDepartements,
  clearAllDepartements,
  ModalAddNewDepartementOpen,
} from "../../../actions/staffManagement/department";

// external components
const DepartementTable = lazy(() => import("./Table"));
const DepartementCard = lazy(() => import("./Card"));
const ModalAddNewDepartement = lazy(() =>
  import("./modals/ModalAddNewDepartement")
);

// actual component
const Index = ({
  permissions,
  isAdmin,
  pageWidth,
  loadAllDepartements,
  clearAllDepartements,
  ModalAddNewDepartementOpen,
}) => {
  useEffect(() => {
    loadAllDepartements();

    // clean up the effect by removing it completely
    return () => {
      clearAllDepartements();
    };
  }, [loadAllDepartements, clearAllDepartements]);

  return (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Department"
          subTitle={pageWidth >= 795 && "Add/Edit or remove departement"}
          extra={[
            <Button
              key="NewDepartement"
              type="primary"
              disabled={isAdmin ? false : !permissions?.write}
              icon={<PlusCircleOutlined />}
              onClick={ModalAddNewDepartementOpen}
            >
              {pageWidth >= 795 && "New Departement"}
            </Button>,
          ]}
        ></PageHeader>

        <ModalAddNewDepartement pageWidth={pageWidth} isAdmin={isAdmin} />

        <Row>
          <Col xs={24} md={11}>
            <DepartementTable
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
          <Col xs={0} md={1}></Col>
          <Col xs={24} md={12}>
            <DepartementCard
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
        </Row>
      </Fragment>
    </Suspense>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  loadAllDepartements: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  ModalAddNewDepartementOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Department Management"
  )[0],
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  loadAllDepartements,
  clearAllDepartements,
  ModalAddNewDepartementOpen,
})(Index);
