import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common = { Authorization: `bearer ${token}` };
  } else {
    axios.defaults.headers.common = {};
  }
};

export default setAuthToken;
