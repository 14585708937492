import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Card, Button, Table, Space, Popconfirm } from "antd";
import {
  GroupOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

// external functions
import {
  EditSpecificUser,
  ModalGroupsAddOpen,
} from "../../../../actions/users";

// external components
import ModalAddGroups from "../modals/ModalAddGroups";

// actual component
const GroupsPage = ({
  userData,
  userAllData,
  isAdmin,
  pageWidth,
  EditSpecificUser,
  ModalGroupsAddOpen,
  groupPermissions,
}) => {
  const handleDelete = (record) => {
    if (record) {
      let user = { ...userAllData };

      user.client_groups = userData.filter(
        (group) => group.name !== record.name
      );
      EditSpecificUser(user);
    }
  };

  const columns = [
    {
      title: "Group",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          {groupPermissions?.delete | isAdmin ? (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <a href="#!" style={{ color: "red" }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={
        <span>
          <GroupOutlined /> Groups
        </span>
      }
      extra={
        <Button
          disabled={
            groupPermissions?.update === false
              ? isAdmin
                ? false
                : true
              : false
          }
          icon={<PlusCircleOutlined />}
          type={"link"}
          onClick={ModalGroupsAddOpen}
        >
          Add Group
        </Button>
      }
      bordered={false}
    >
      <ModalAddGroups isAdmin={isAdmin} pageWidth={pageWidth} />

      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={userData?.map((group, index) => {
          group.key = index;

          return group;
        })}
      />
    </Card>
  );
};

GroupsPage.propTypes = {
  userData: PropTypes.array,
  userAllData: PropTypes.object,
  EditSpecificUser: PropTypes.func.isRequired,
  ModalGroupsAddOpen: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  userData: state.users.editData?.client_groups,
  pageWidth: state.misc?.width,
  userAllData: state.users?.editData,
});

export default connect(mapStateToProps, {
  EditSpecificUser,
  ModalGroupsAddOpen,
})(GroupsPage);
