import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 18,
    textAlign: "center",
    // margin: 12,
  },
  text: {
    // margin: 12,
    fontSize: 12,
    textAlign: "justify",
  },
  textSubtitle: {
    // margin: 12,
    fontSize: 11,
    color: "grey",
    textAlign: "center",
  },
});

const PDFMeters = ({ selectedPDU, recStats }) => {
  console.log(selectedPDU, recStats);

  return (
    selectedPDU &&
    selectedPDU.map(
      (pduNem, index) =>
        recStats.length > 0 &&
        recStats?.[index] !== undefined && (
          <View>
            <Text style={styles.subtitle}>{`Meter : ${pduNem}`}</Text>
            <Text> </Text>
            <Text style={styles.textSubtitle}>{`Max kW`}</Text>
            <Text style={styles.text}>{`A Supply : ${
              recStats[index][0]?.value && recStats[index][0]?.value.toFixed(2)
            }kW @ ${recStats[index][1]?.value ?? "No Data"}`}</Text>
            <Text style={styles.text}>{`B Supply : ${
              recStats[index][2]?.value && recStats[index][2]?.value.toFixed(2)
            }kW @ ${recStats[index][3]?.value ?? "No Data"}`}</Text>
            <Text style={styles.text}>{`Combined Supplies : ${
              recStats[index][4]?.value && recStats[index][4]?.value.toFixed(2)
            }kW @ ${recStats[index][5]?.value ?? "No Data"}`}</Text>
            <Text> </Text>
            <Text style={styles.textSubtitle}>{`Mean/Average kW`}</Text>
            <Text style={styles.text}>{`A Supply : ${
              recStats[index][6]?.value && recStats[index][6]?.value.toFixed(2)
            } kW`}</Text>
            <Text style={styles.text}>{`B Supply : ${recStats[
              index
            ][7]?.value.toFixed(2)} kW`}</Text>
            <Text style={styles.text}>{`Combined Supplies : ${
              recStats[index][8]?.value && recStats[index][8]?.value.toFixed(2)
            } kW`}</Text>
          </View>
        )
    )
  );
};

export default PDFMeters;
