import React, { Fragment } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// antd
import { Col, Row, Statistic, Tabs } from "antd";
import PowerTable from "./PowerTable";

// actual componet
const PowerTabs = ({ tabItems, pageWidth }) => {
  return (
    tabItems &&
    tabItems.length > 0 && (
      <Tabs>
        {tabItems.map((item, index) => {
          return (
            <Tabs.TabPane tab={item.month} key={index}>
              {item?.stats && (
                <Row>
                  {Object.keys(item?.stats).map((statItem, index) => {
                    let statText = statItem.replace(/_+/g, " ");

                    statText =
                      statText.charAt(0).toUpperCase() + statText.slice(1);

                    return (
                      <Col xs={24} sm={12} key={index}>
                        <Statistic
                          title={statText}
                          value={item?.stats[statItem]}
                          precision={2}
                        />
                      </Col>
                    );
                  })}
                </Row>
              )}

              {item?.daily_energy && (
                <Fragment>
                  <Row>
                    <Col xs={12}>
                      <ResponsiveContainer width="100%" height="90%">
                        <BarChart
                          data={item.daily_energy.map((item) => {
                            return {
                              name: item.date.split("T")[0],
                              consumed: parseInt(item.day),
                            };
                          })}
                          margin={{
                            right: 30,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis
                            type="number"
                            domain={[0, item.maxValue + 100]}
                          />
                          <Tooltip />
                          <Bar dataKey="consumed" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </Col>
                    <Col xs={12}>
                      <PowerTable
                        tableData={item?.daily_energy}
                        pageWidth={pageWidth}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    )
  );
};

export default PowerTabs;
