import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Form, Input, Button, Typography, Row, Col, Space } from "antd";
import { QrcodeOutlined } from "@ant-design/icons";

// external functions
import { validate2FAToken, GetQRCode, logout } from "../../actions/auth";

// style sheet
import localStyle from "./index.module.css";

const { Title, Paragraph, Text } = Typography;

// actual component
const TwoFA = ({
  validate2FAToken,
  loading,
  authToken,
  width,
  viewQR,
  logout,
}) => {
  const inputRef = React.useRef(null);
  const [buttonDisable, setbuttonDisable] = useState();
  const [QRCodeImage, setQRCodeImage] = useState();
  const [form2FA] = Form.useForm();

  useEffect(() => {
    const GetCode = async () => {
      try {
        if (viewQR) {
          const QRimage = await GetQRCode(authToken);
          setQRCodeImage(QRimage);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetCode();

    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);
  }, [authToken, viewQR]);

  // Login function
  const handleFinish = () => {
    validate2FAToken(form2FA.getFieldValue("userToken"), authToken);
  };

  return (
    <div
      style={
        width >= 768
          ? {
              position: "absolute",
              left: "50%",
              top: "50%",
              width: viewQR ? "30%" : "20%",
              minWidth: viewQR ? "500px" : "220px",
              transform: "translate(-50%, -50%)",
            }
          : {
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }
      }
    >
      <Row>
        <Col xs={24} md={viewQR ? 11 : 0}>
          {viewQR && (
            <img width={200} height={200} src={QRCodeImage} alt="QRCOde" />
          )}
        </Col>
        {viewQR && <Col md={1}></Col>}
        <Col xs={24} md={viewQR ? 12 : 24}>
          <Typography>
            <Title
              level={2}
              style={{ marginBottom: "0px" }}
              className={localStyle.myTypography}
            >
              <QrcodeOutlined /> Two Step Verification
            </Title>
            {viewQR ? (
              <Paragraph className={localStyle.myTypography}>
                Download Google authenticator and scan the QR code, if you have
                already scan it again and choose replace, then enter the
                passcode it generates to gain access
              </Paragraph>
            ) : (
              <Paragraph className={localStyle.myTypography}>
                Your profile has two step verification enabled, open the
                authentication app and enter the{" "}
                <Text className={localStyle.myTypography} strong>
                  Passcode
                </Text>{" "}
                to gain access
              </Paragraph>
            )}
          </Typography>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="vertical"
            size="small"
            form={form2FA}
            onFinish={handleFinish}
            onValuesChange={(_, All) => {
              setbuttonDisable(All);
            }}
          >
            <Form.Item
              label={
                <p
                  style={{ margin: "0px" }}
                  className={localStyle.myTypography}
                >
                  Pass Code
                </p>
              }
              name="userToken"
              style={{ marginBottom: "5px" }}
              rules={[{ required: true, message: "Can not be blank" }]}
            >
              <Input ref={inputRef} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  disabled={
                    buttonDisable?.userToken === "" ||
                    buttonDisable === undefined ||
                    loading
                      ? true
                      : false
                  }
                  loading={loading}
                  htmlType="submit"
                >
                  Verfy code
                </Button>
                <Button
                  style={{ marginLeft: "4em" }}
                  loading={loading}
                  onClick={logout}
                >
                  Back
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

TwoFA.propTypes = {
  validate2FAToken: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  viewQR: PropTypes.bool,
  authToken: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading2FA,
  viewQR: state.auth.viewQR,
  authToken: state.auth.token,
});

export default connect(mapStateToProps, { validate2FAToken, logout })(TwoFA);
