import axios from "axios";
import { setAlert } from "../../alert";
import setAuthToken from "../../../utils/setAuthToken";
import { logout } from "../../auth";
import {
  SALESCLIENTS_CLEARALL,
  SALESCLIENTS_EDIT,
  SALESCLIENTS_GET_ALL,
  SALESCLIENTS_LOADING,
  SALESCLIENTS_MODAL_ADDNEWCLIENT_CLOSE,
  SALESCLIENTS_MODAL_ADDNEWCLIENT_OPEN,
} from "../../types";

// Gets all the sales related clients
export const loadAllSalesClients = () => async (dispatch) => {
  try {
    dispatch({
      type: SALESCLIENTS_LOADING,
      payload: true,
    });

    let res = await axios.get("/api/salesclients");

    dispatch({
      type: SALESCLIENTS_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: SALESCLIENTS_LOADING,
      payload: false,
    });
  }
};

// clears all the store items for the sales related clients
export const clearSalesClientsState = () => (dispatch) => {
  dispatch({
    type: SALESCLIENTS_CLEARALL,
  });
};

// view details of a specific sales related clients
export const EditViewSalesClient = (details) => (dispatch) => {
  dispatch({
    type: SALESCLIENTS_EDIT,
    payload: details,
  });
};

// updates a specific sales related clients, needs the client object id
export const UpdateSpecificSalesClient =
  (clientID, clientData) => async (dispatch) => {
    try {
      if (clientID) {
        dispatch({
          type: SALESCLIENTS_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // update the specific clients details
        let res = await axios.put("/api/salesclients/" + clientID, {
          data: clientData,
        });

        // get the clients
        res = await axios.get("/api/salesclients");

        dispatch(setAlert("Clients is updated succesfully", "success"));

        dispatch({
          type: SALESCLIENTS_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));

        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
      }
      dispatch({
        type: SALESCLIENTS_LOADING,
        payload: false,
      });
    }
  };

// deletes a specific sales related clients, needs the client object id
export const DeleteSpecificSalesClient = (clientID) => async (dispatch) => {
  try {
    if (clientID) {
      dispatch({
        type: SALESCLIENTS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the specific client
      let res = await axios.delete("/api/salesclients/" + clientID);

      // get all the client
      res = await axios.get("/api/salesclients");

      dispatch(setAlert("Client was deleted successful", "success"));

      dispatch({
        type: SALESCLIENTS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: SALESCLIENTS_LOADING,
      payload: false,
    });
  }
};

// open the modal to add a sales client
export const ModalAddSalesClientOpen = (editData) => (dispatch) => {
  dispatch({
    type: SALESCLIENTS_MODAL_ADDNEWCLIENT_OPEN,
    payload: editData,
  });
};

// close the modal to add a sales client
export const ModalAddSalesClientClose = () => (dispatch) => {
  dispatch({
    type: SALESCLIENTS_MODAL_ADDNEWCLIENT_CLOSE,
  });
};

// Create a new Client
export const NewClient =
  (data, fromImporter = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SALESCLIENTS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // Send the data to create a new one
      let clientAdd = await axios.post("/api/salesclients", {
        data,
      });

      if (fromImporter === false) {
        // get all the services
        let res = await axios.get("/api/salesclients");

        dispatch(setAlert(clientAdd.data.msg, "success"));

        dispatch({
          type: SALESCLIENTS_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        const errors = error.response.data.errors;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }
      } else if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));
        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
      }
      dispatch({
        type: SALESCLIENTS_LOADING,
        payload: false,
      });
    }
  };
