import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

// andt Components
import { PageHeader, Button, DatePicker, Empty } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

// actual component
const Index = ({ permissions, isAdmin, ModalAddPremOpen, pageWidth }) => {
  const [dates, setDates] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState();

  const HandleDateChange = (value) => {
    if (value) {
      const d = new Date(value[1]._d);
      let dateTO = d.toISOString();
      dateTO = dateTO.split("T");
      dateTO = dateTO[0];

      const dt = new Date(value[0]._d);
      let dateFrom = dt.toISOString();
      dateFrom = dateFrom.split("T");
      dateFrom = dateFrom[0];

      console.log(dateTO);
      console.log(dateFrom);

      setDates({ dateTO, dateFrom });
    } else {
      setDates(null);
    }
  };

  const HandleDownload = async () => {
    if (dates) {
      setLoadingBtn(true);
      // get the users permissions
      const res = await axios.get(
        `/api/reports/sms?fromDate=${dates.dateFrom}&toDate=${dates.dateTO}`
      );
      const linkSource = `data:application/pdf;base64,${res.data.base64Report}`;
      const downloadLink = document.createElement("a");
      const fileName = `SMSReport ${dates.dateFrom} to ${dates.dateTO}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setLoadingBtn(false);
    }
  };

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="SMS Report"
        subTitle={pageWidth >= 795 && "SMS Count Report"}
        extra={[
          <Button
            key="NewUser"
            type="primary"
            disabled={
              dates !== null ? (isAdmin ? false : !permissions?.write) : true
            }
            icon={<PlusCircleOutlined />}
            onClick={HandleDownload}
            loading={loadingBtn}
          >
            {pageWidth >= 795 && "Generate"}
          </Button>,
        ]}
      >
        <RangePicker onChange={HandleDateChange} disabled={loadingBtn} />
      </PageHeader>

      <Empty
        description={
          <span>Select a start and end date then press generate</span>
        }
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          width: "50%",
          transform:
            pageWidth >= 768 ? "translate(-50%, -50%)" : "translate(-50%, 50%)",
        }}
      />
    </Fragment>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  pageWidth: state.misc?.width,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Finance Module Access"
  )[0],
});

export default connect(mapStateToProps)(Index);
