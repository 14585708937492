import {
  PERM_CLEARALL,
  PERM_EDIT,
  PERM_GET_ALL,
  PERM_LOADING,
  PERM_MODAL_PERMADD_CLOSE,
  PERM_MODAL_PERMADD_OPEN,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddPermissionVis: null,
};

export default function permissions(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case PERM_MODAL_PERMADD_OPEN:
      return { ...state, modalAddPermissionVis: true };
    case PERM_MODAL_PERMADD_CLOSE:
      return { ...state, modalAddPermissionVis: false };
    case PERM_EDIT:
      return { ...state, editData: payload };
    case PERM_LOADING:
      return { ...state, loading: payload };
    case PERM_GET_ALL:
      return { ...state, data: payload, loading: false };
    case PERM_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
