import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd componetn
import { Table, Space, Popconfirm, Tooltip, Button } from "antd";
import { DeleteOutlined, RightOutlined } from "@ant-design/icons";

// external functions
import {
  EditViewService,
  DeleteSpecificService,
} from "../../../actions/services";

// actual Component
const ServicesTable = ({
  permissions,
  isAdmin,
  data,
  loading,
  pageWidth,
  EditViewService,
  DeleteSpecificService,
}) => {
  const handleDelete = (record) => {
    DeleteSpecificService(record._id);
    EditViewService(null);
  };

  const handleEdit = (record) => {
    EditViewService(record);
  };

  const columns = [
    {
      title: "Service",
      key: "service",
      width: "25%",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      responsive: ["lg"],
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "10%",
      align: "right",
      render: (text, record) => (
        <Space size="middle">
          <Fragment>
            {permissions?.delete | isAdmin ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record)}
              >
                <a href="#!" style={{ color: "red" }}>
                  <DeleteOutlined />
                </a>
              </Popconfirm>
            ) : (
              <></>
            )}
            <Tooltip title="Edit/view service">
              <Button
                shape="circle"
                icon={<RightOutlined />}
                onClick={() => handleEdit(record)}
                size="small"
              />
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
      loading={loading}
      columns={columns}
      dataSource={data?.map((item) => {
        item.key = item._id;

        return item;
      })}
      size="small"
      pagination={false}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 20em)" : "190px",
      }}
    ></Table>
  );
};

ServicesTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  EditViewService: PropTypes.func.isRequired,
  DeleteSpecificService: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.services.data,
  loading: state.services.loading,
});

export default connect(mapStateToProps, {
  EditViewService,
  DeleteSpecificService,
})(ServicesTable);
