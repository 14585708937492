import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant design Components
import { message } from "antd";

const Alerts = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map((alert) => {
    switch (alert.alertType) {
      case "warning":
        return message.warning(
          {
            key: alert.id,
            content: alert.msg,
          },
          6
        );
      case "success":
        return message.success(
          {
            key: alert.id,
            content: alert.msg,
          },
          6
        );
      case "error":
        return message.error(
          {
            key: alert.id,
            content: alert.msg,
          },
          6
        );
      default:
        return message.info(
          {
            key: alert.id,
            content: alert.msg,
          },
          6
        );
    }
  });

Alerts.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProp = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProp)(Alerts);
