import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";

// andt Components
import {
  PageHeader,
  Button,
  Upload,
  Modal,
  Table,
  Typography,
  Steps,
  Form,
  Select,
} from "antd";
import {
  PlusCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

// external functions
import {
  clearSalesClientsState,
  loadAllSalesClients,
  NewClient,
} from "../../actions/staffServices/Pipline/salesClient";
import {
  loadAllSalesMySales,
  clearMySalesState,
  NewSale,
} from "../../actions/staffServices/Pipline/salesSales";
import { loadAllUsers, clearUsersState } from "../../actions/users";
import axios from "axios";

const { Paragraph, Text } = Typography;
const { Step } = Steps;

const steps = [
  {
    title: "Clients",
  },
  {
    title: "Sales",
  },
];

// actual component
const PipeLineImport = ({
  pageWidth,
  clearSalesClientsState,
  loadAllSalesClients,
  dataClients,
  loadingClients,
  NewClient,
  loadAllSalesMySales,
  clearMySalesState,
  NewSale,
  dataSales,
  loadingSales,
  loadAllUsers,
  clearUsersState,
  dataUsers,
  loadingUsers,
}) => {
  useEffect(() => {
    loadAllSalesClients();
    loadAllSalesMySales();
    loadAllUsers();

    // clean up the effect by removing it completely
    return () => {
      clearSalesClientsState();
      clearUsersState();
      clearMySalesState();
    };
  }, [
    loadAllSalesClients,
    clearSalesClientsState,
    loadAllSalesMySales,
    clearMySalesState,
    loadAllUsers,
    clearUsersState,
  ]);

  const [salesPersonForm] = Form.useForm();
  const history = useHistory();
  const [modalVis, setModalVis] = useState(false);
  const [mySalesData, setMySalesData] = useState([]);
  // const [callRegisterData, setCallRegisterData] = useState([]);
  const [clientDBData, setClientDBData] = useState([]);
  const [saleStatusFilter, setSaleStatusFilter] = useState([]);

  // button effects
  const [uploadLoading, setUploadLoading] = useState(false);
  const [okBtnLoading, setOkBtnLoading] = useState(false);

  const [current, setCurrent] = useState(0);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onChange = (e) => {
    try {
      setUploadLoading(true);
      const reader = new FileReader();

      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        let NoClientsToImport = false;
        //   const wsname = wb.SheetNames[0];

        wb.SheetNames.map((sheet, index) => {
          let data = [];
          const ws = wb.Sheets[sheet];
          const wsData = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          data = wsData.split("\n");
          let sanitizedData = [];

          if (data.length > 0) {
            // remove the heading
            data.shift();
            // last item is blank
            data.pop();

            switch (sheet) {
              case "Client Data Base":
                // remove the heading
                data.shift();
                // remove the heading
                data.shift();

                // go through each row
                data.map((line, index) => {
                  let lineData = line.split(",");

                  if (
                    lineData[5].trim() !== "" &&
                    lineData[9].trim() !== "" &&
                    lineData[1].trim() !== "End"
                  ) {
                    let newText = lineData[2].trim().toLocaleLowerCase();
                    let nameText = lineData[5].trim().toLocaleLowerCase();
                    let emailText = lineData[9]
                      .replace('"', "")
                      .trim()
                      .toLocaleLowerCase();

                    newText = capitalizeFirstLetter(newText);
                    nameText = capitalizeFirstLetter(nameText);

                    // find duplicate email address
                    const emialIndex = sanitizedData.find(
                      (item) => item.keyRelationship.Email === emailText
                    );

                    if (emialIndex === undefined) {
                      const index = dataClients.find(
                        (dbclient) =>
                          dbclient.keyRelationship.Email === emailText
                      );

                      if (index === undefined) {
                        sanitizedData.push({
                          companyInfo: {
                            Industry: lineData[1].trim(),
                            AccountType: newText === "" ? "New" : newText,
                            Company: lineData[3].trim(),
                            Department: lineData[4].trim(),
                          },
                          keyRelationship: {
                            Name: nameText,
                            Surname: lineData[6].trim(),
                            Position: lineData[7].trim(),
                            Mobile: lineData[8].trim().replace(/ /g, ""),
                            Email: emailText,
                          },
                          key: index,
                        });
                      }
                    }
                  }

                  return true;
                });

                if (sanitizedData.length > 0) {
                  setClientDBData([...sanitizedData]);
                } else {
                  if (index === 0) NoClientsToImport = true;
                  else handleAdd(null);
                }
                break;
              case "Call Register":
                // console.log(data);
                // setCallRegisterData([]);
                break;
              case "Data":
                let saleFilter = [];

                // remove the heading
                data.shift();

                // look for errors '\r' in the data, and fix
                let wsDataLines = handleNewLines(data);
                // look for errors '"' in the data, and fix
                wsDataLines = handleQuotationMarks(wsDataLines);
                // look for errors '#N/A' in the data, and fix
                wsDataLines = handleNAData(wsDataLines);
                // look for errors '#NUM!' in the data, and fix
                wsDataLines = handleNUMData(wsDataLines);

                // go through each row
                wsDataLines.map((line, index) => {
                  let lineData = line.split(",");

                  if (
                    lineData[0].trim() !== "" &&
                    lineData[1].trim() !== "End" &&
                    lineData[1].trim() !== "" &&
                    lineData[2].trim() !== "" &&
                    lineData[8].trim() !== ""
                  ) {
                    const duplicateIndex = dataSales.find(
                      (dbSale) => dbSale.quoteRef === lineData[0].trim()
                    );

                    if (duplicateIndex !== undefined) {
                      console.log("duplicate Found: ", lineData[0]);
                      return true;
                    }

                    let dateEntered = new Date(
                      lineData[1].trim(),
                      lineData[2].trim() - 1,
                      lineData[3].trim(),
                      2,
                      0,
                      0
                    ).toISOString();

                    let dateExpected = new Date();

                    try {
                      dateExpected = new Date(
                        lineData[16].trim(),
                        lineData[17].trim() - 1,
                        lineData[18].trim(),
                        2,
                        0,
                        0
                      ).toISOString();
                    } catch (error) {}

                    let contactEmail = lineData[8].trim().toLocaleLowerCase();
                    // find the @
                    var emailRegex =
                      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
                    contactEmail = emailRegex.exec(contactEmail);

                    let statusText = lineData[21].trim();
                    statusText = capitalizeFirstLetter(statusText);

                    let probabilityText = lineData[22].trim();
                    probabilityText = capitalizeFirstLetter(probabilityText);

                    let TotalSales = Number(
                      lineData[12].replace(/R/g, "").replace(/ /g, "")
                    );
                    let TotalCost = Number(
                      lineData[13].replace(/R/g, "").replace(/ /g, "")
                    );
                    let TotalMargin = Number(
                      lineData[15]
                        .replace(/R/g, "")
                        .replace(/%/g, "")
                        .replace(/ /g, "")
                    );

                    if (isNaN(TotalSales)) TotalSales = 0;
                    if (isNaN(TotalCost)) TotalCost = 0;
                    if (isNaN(TotalMargin)) TotalMargin = 0;

                    sanitizedData.push({
                      quoteRef: lineData[0].trim(),
                      dateEntered: dateEntered,
                      leadFrom: lineData[5].trim(),
                      salesCycle: lineData[6].trim(),
                      scopeOfOpportunity: lineData[7].trim(),
                      marketSegment: lineData[9].trim(),
                      competitorsInBid: lineData[10].trim(),
                      dateExpected: dateExpected,
                      priority: Number(lineData[20].trim()),
                      contactPerson: Array.isArray(contactEmail)
                        ? contactEmail[0].trim()
                        : null,
                      Status: statusText,
                      Probability:
                        probabilityText === "Blue Bird"
                          ? 100
                          : probabilityText === "Forecast"
                          ? 25
                          : probabilityText === "Probable"
                          ? 50
                          : 0,
                      daysAgeOfOpertuatity: Number(lineData[24].trim()),
                      reasonWonLost: lineData[23].trim(),
                      SalesItems: [
                        {
                          type: "Unknown",
                          name: "Other",
                          sales: TotalSales,
                          cost: TotalCost,
                        },
                      ],
                      TotalSales: TotalSales,
                      TotalCost: TotalCost,
                      margin: TotalMargin,
                      key: index,
                    });
                  }
                  return true;
                });

                if (sanitizedData.length > 0) {
                  setMySalesData([...sanitizedData]);

                  sanitizedData.map((item) => {
                    const index = saleFilter.indexOf(item.Status);

                    if (index === -1) {
                      saleFilter.push(item.Status);
                    }

                    return true;
                  });
                  setSaleStatusFilter([...saleFilter]);

                  if (NoClientsToImport) handleAdd([...sanitizedData]);
                }
                break;
              default:
                break;
            }
          }

          return true;
        });
        setUploadLoading(false);
      };

      // reader.readAsBinaryString(file);
      reader.readAsBinaryString(e);
    } catch (error) {
      setUploadLoading(false);
      console.log(error);
    }
  };

  const handleNewLines = (data) => {
    // look for errors '\r' in the data, and fix
    let wsDataLines = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i].charAt(data[i].length - 1) === "\r") {
        let newLineData = data[i];

        for (let m = 0; m < 5; m++) {
          if (newLineData.charAt(newLineData.length - 1) === "\r") {
            // remove newline
            newLineData = newLineData.substring(0, newLineData.length - 1);
            // replace with blank
            newLineData += " ";
            // add next line
            i++;
            newLineData += data[i];
          }
        }
        wsDataLines.push(newLineData);
      } else {
        wsDataLines.push(data[i]);
      }
    }

    return wsDataLines;
  };

  const handleQuotationMarks = (data) => {
    // look for errors '"' in the data, and fix
    let wsDataLines = [];

    for (let i = 0; i < data.length; i++) {
      let newLineDataSegments = data[i].split('"');
      let needsFixing = false;

      for (let m = 0; m < newLineDataSegments.length; m++) {
        if (needsFixing) {
          newLineDataSegments[m] = newLineDataSegments[m].replace(/,/g, " ");
        }

        needsFixing = !needsFixing;
      }

      // wsDataLines.push(newLineDataSegments.join(","));
      wsDataLines.push(newLineDataSegments.join(""));
    }

    return wsDataLines;
  };

  const handleNAData = (data) => {
    // look for errors 'N/A' in the data, and fix
    let wsDataLines = [];

    for (let i = 0; i < data.length; i++) {
      wsDataLines.push(data[i].replace(/#N\/A/g, ""));
    }

    return wsDataLines;
  };

  const handleNUMData = (data) => {
    // look for errors 'N/A' in the data, and fix
    let wsDataLines = [];

    for (let i = 0; i < data.length; i++) {
      wsDataLines.push(data[i].replace(/#NUM!/g, ""));
    }

    return wsDataLines;
  };

  const handleClearAll = () => {
    setSaleStatusFilter([]);
    setClientDBData([]);
    // setCallRegisterData([]);
    setMySalesData([]);
    setCurrent(0);
  };

  const handleAdd = async (data) => {
    setOkBtnLoading(true);

    if (current === 0) {
      if (data === null) {
        if (clientDBData.length > 0) {
          for (const client of clientDBData) {
            await NewClient(client, true);
          }
        }

        let res = await axios.get("/api/salesclients");

        if (mySalesData.length > 0) {
          let ArrayToData = [...mySalesData];

          ArrayToData.map((sale) => {
            if (sale.contactPerson) {
              const dbContact = res.data.find(
                (dbClient) =>
                  dbClient.keyRelationship.Email === sale.contactPerson
              );

              if (dbContact) {
                sale.contactPerson = dbContact._id;
                sale.marketSegment = dbContact.companyInfo.Industry;
              } else {
                sale.contactPerson = null;
              }
            }
            return sale;
          });
          setMySalesData([...ArrayToData]);
        }
      } else {
        data.map((sale) => {
          if (sale.contactPerson) {
            const dbContact = dataClients.find(
              (dbClient) =>
                dbClient.keyRelationship.Email === sale.contactPerson
            );

            if (dbContact) {
              sale.contactPerson = dbContact._id;
              sale.marketSegment = dbContact.companyInfo.Industry;
            } else {
              sale.contactPerson = null;
            }
          }
          return sale;
        });

        setMySalesData([...data]);
      }
      setCurrent(current + 1);
    } else {
      setModalVis(true);
    }

    setOkBtnLoading(false);
  };

  const clientDBColumns = [
    {
      title: "Key Company / Account Responsible for",
      children: [
        {
          title: "Industry",
          key: "Industry",
          dataIndex: ["companyInfo", "Industry"],
        },
        {
          title: "Account Type",
          key: "AccountType",
          dataIndex: ["companyInfo", "AccountType"],
          responsive: ["lg"],
        },
        {
          title: "Key Account / Company",
          key: "Company",
          dataIndex: ["companyInfo", "Company"],
        },
        {
          title: "Department",
          key: "Department",
          responsive: ["md"],
          dataIndex: ["companyInfo", "Department"],
        },
      ],
    },
    {
      title: "Key Relationship(s) within Account",
      children: [
        {
          title: "Name",
          key: "Name",
          responsive: ["md"],
          dataIndex: ["keyRelationship", "Name"],
        },
        {
          title: "Surname",
          key: "Surname",
          responsive: ["md"],
          dataIndex: ["keyRelationship", "Surname"],
        },
        {
          title: "Position",
          key: "Position",
          responsive: ["md"],
          dataIndex: ["keyRelationship", "Position"],
        },
        {
          title: "Mobile",
          key: "Mobile",
          responsive: ["md"],
          dataIndex: ["keyRelationship", "Mobile"],
        },
        {
          title: "Email",
          key: "Email",
          responsive: ["md"],
          dataIndex: ["keyRelationship", "Email"],
        },
      ],
    },
  ];

  const mySalesColumns = [
    {
      title: "Quote Ref",
      key: "quoteRef",
      dataIndex: "quoteRef",
    },
    {
      title: "Date created",
      key: "dateEntered",
      dataIndex: "dateEntered",
      sorter: (a, b) => new Date(a.dateEntered) - new Date(b.dateEntered),
      defaultSortOrder: "descend",
      render: (text) => <p>{new Date(text).toLocaleString()}</p>,
    },
    {
      title: "Date expected",
      key: "dateExpected",
      dataIndex: "dateExpected",
      render: (text) => <p>{new Date(text).toLocaleString()}</p>,
    },
    {
      title: "Lead From",
      key: "leadFrom",
      dataIndex: "leadFrom",
    },
    {
      title: "Sales Cycle",
      key: "salesCycle",
      dataIndex: "salesCycle",
    },
    {
      title: "Scope of opportunity",
      key: "scopeOfOpportunity",
      dataIndex: "scopeOfOpportunity",
    },
    {
      title: "Age Of Opertuatity",
      key: "daysAgeOfOpertuatity",
      dataIndex: "daysAgeOfOpertuatity",
    },
    {
      title: "Reason Won/Lost",
      key: "reasonWonLost",
      dataIndex: "reasonWonLost",
    },
    {
      title: "Competitors In Bid",
      key: "competitorsInBid",
      dataIndex: "competitorsInBid",
    },
    {
      title: "Probability",
      key: "Probability",
      dataIndex: "Probability",
    },
    {
      title: "Total Sales",
      key: "TotalSales",
      dataIndex: "TotalSales",
      sorter: (a, b) => a.TotalSales - b.TotalSales,
    },
    {
      title: "Total Cost",
      key: "TotalCost",
      dataIndex: "TotalCost",
      sorter: (a, b) => a.TotalCost - b.TotalCost,
    },
    {
      title: "Margin",
      key: "margin",
      dataIndex: "margin",
      sorter: (a, b) => a.margin - b.margin,
    },
    {
      title: "Status",
      key: "quoteStatus",
      dataIndex: "Status",
      filters: saleStatusFilter?.map((item) => {
        return { text: item, value: item };
      }),
      onFilter: (value, record) => record?.Status.includes(value),
    },
  ];

  const handleModalCancel = () => {
    setModalVis(false);
  };

  const handleModalOk = async () => {
    if (mySalesData.length > 0) {
      setOkBtnLoading(true);
      const salesPeson = salesPersonForm.getFieldValue("salesPerson");

      for (const sale of mySalesData) {
        await NewSale(
          {
            ...sale,
            salesPeson,
          },
          true
        );
      }
    }

    setOkBtnLoading(false);
    setModalVis(false);
    history.push("/staff/services/pipeline");
  };

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Pipeline Import"
        subTitle={pageWidth > 795 && "MPT Sales and marketing importer"}
        extra={[
          <Upload
            key="upload"
            maxCount={1}
            showUploadList={false}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            beforeUpload={(file) => {
              onChange(file);
              return false;
            }}
          >
            <Button
              icon={<UploadOutlined />}
              loading={
                uploadLoading || loadingClients || loadingSales || loadingUsers
              }
              disabled={current === 1}
            >
              Upload (Max: 1)
            </Button>
          </Upload>,
          <Button
            key="clearALL"
            type="primary"
            disabled={clientDBData?.length === 0 && mySalesData?.length === 0}
            icon={<DeleteOutlined />}
            loading={okBtnLoading}
            onClick={handleClearAll}
          >
            Clear All
          </Button>,
          <Button
            key="NewUser"
            type="primary"
            loading={okBtnLoading}
            disabled={clientDBData?.length === 0 && mySalesData?.length === 0}
            icon={<PlusCircleOutlined />}
            onClick={() => handleAdd(null)}
          >
            {current === 0 ? "Import clients" : "Import Sales"}
          </Button>,
        ]}
      ></PageHeader>

      <Modal
        title="Who is this import for?"
        visible={modalVis}
        onCancel={handleModalCancel}
        onOk={() =>
          salesPersonForm.validateFields().then(() => handleModalOk())
        }
        okButtonProps={{ loading: okBtnLoading }}
        cancelButtonProps={{ loading: okBtnLoading }}
      >
        <Form form={salesPersonForm}>
          <Form.Item
            label="Who is this sales data for?"
            name="salesPerson"
            rules={[
              {
                required: true,
                message: "Can not be blank!",
              },
            ]}
          >
            <Select allowClear showSearch>
              {dataUsers?.map(
                (user, index) =>
                  (user.client_name === "Master Power Technologies" ||
                    user.client_name === "MPT BMS") && (
                    <Select.Option value={user._id} key={index}>
                      {user.name +
                        " " +
                        user.surname +
                        "-" +
                        user?.email_address}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Typography>
        <Paragraph>
          <Text strong>NB!!</Text> Will only show <Text strong>none</Text>{" "}
          duplicate items in reference to current Database
        </Paragraph>
      </Typography>

      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div className="steps-content">
        {current === 0 ? (
          <Table
            style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
            loading={uploadLoading}
            columns={clientDBColumns}
            dataSource={clientDBData}
            size="small"
            pagination={false}
            bordered
            scroll={{
              x: "100%",
              y: pageWidth >= 768 ? "calc(100vh - 25em)" : "calc(100vh - 25em)",
            }}
          ></Table>
        ) : (
          <Table
            style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
            loading={uploadLoading}
            columns={mySalesColumns}
            dataSource={mySalesData}
            size="small"
            pagination={false}
            bordered
            scroll={{
              x: "100%",
              y: pageWidth >= 768 ? "calc(100vh - 25em)" : "calc(100vh - 25em)",
            }}
          ></Table>
        )}
      </div>
    </Fragment>
  );
};

PipeLineImport.propTypes = {
  pageWidth: PropTypes.number,
  clearSalesClientsState: PropTypes.func.isRequired,
  NewSale: PropTypes.func.isRequired,
  loadAllSalesClients: PropTypes.func.isRequired,
  NewClient: PropTypes.func.isRequired,
  dataClients: PropTypes.array,
  dataSales: PropTypes.array,
  loadingClients: PropTypes.bool,
  loadingSales: PropTypes.bool,
  loadAllSalesMySales: PropTypes.func.isRequired,
  clearMySalesState: PropTypes.func.isRequired,
  loadAllUsers: PropTypes.func.isRequired,
  clearUsersState: PropTypes.func.isRequired,
  loadingUsers: PropTypes.bool,
  dataUsers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  pageWidth: state.misc.width,
  dataClients: state.salesclient.data,
  loadingClients: state.salesclient.loading,
  dataSales: state.salesMySales.data,
  loadingSales: state.salesMySales.loading,
  dataUsers: state.users.data,
  loadingUsers: state.users.loading,
});

export default connect(mapStateToProps, {
  clearSalesClientsState,
  loadAllSalesClients,
  loadAllSalesMySales,
  clearMySalesState,
  NewClient,
  NewSale,
  loadAllUsers,
  clearUsersState,
})(PipeLineImport);
