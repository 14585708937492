import {
    TASK_ADD_COLLABORATOR,
    TASK_GET_ALL,
    TASK_LIST_LOADING,
    TASK_SET_SELECTED,
    TASK_UPDATE_SELECTED_TASK,
    TASK_UPDATE,
    TASK_ADD_MULTIPLE_COLLABORATOR,
    TASK_REMOVE_COLLABORATOR,
    TASK_CREATE,
    TASK_ADD_ATTACHMENTS,
    TASK_GET_ATTACHMENTS,
    GET_TASK_CHAT,
    TASK_CHAT_LOADED,
    UPDATE_TASK_ATTACHMENT_DETAILS,
    DELETE_TASK_ATTACHMENT, GET_USER_TASKS
} from "./types";
import axios from "axios";
import {setAlert} from "./alert";
import {exceptionClause} from "./methods";
import {chatsBaseUrl} from "../ChatMessagesSocketContext";

// const _baseURL = `http://localhost:8003/api/tasks/`;
// const _baseURL = `http://mptdev.dedicated.co.za:8003/api/tasks/`;
const _baseURL = `https://tasks.cloudbms.co.za/api/tasks/`;

// const _taskChatUrl = `http://localhost:8002/api/chats/task/`;
const _taskChatUrl = `${chatsBaseUrl}chats/task/`;

// const _taskAttachmentsUrl = `http://localhost:8003/api/attachments/`;
const _taskAttachmentsUrl = `https://tasks.cloudbms.co.za/api/attachments/`;


export const createTask = (task, attachments) => async (dispatch) => {
    try {



        let response = await axios.post(`${_baseURL}`, task);
        let taskID = response.data.msg;
        dispatch({
            type: TASK_CREATE,
            payload: response.data.msg
        });

        await axios
            .post(
                `${_baseURL}uploadAttachment/${taskID}`,
                attachments,
                {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });

        dispatch({
            type: TASK_ADD_ATTACHMENTS,
            payload: response.data
        });

        // dispatch(setAlert("Task created successfully", "success"));
    }
    catch (exception){
        dispatch(setAlert("Failed to create new task", "error"));
        console.error('[TASKS ACTIONS] createTask failed with exception: ', exception);
    }
}

export const addAttachments = (taskID, attachments) => async (dispatch) => {
    try{
        await axios
            .post(
                `${_baseURL}uploadAttachment/${taskID}`,
                attachments,
                {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }});

        dispatch({
            type: TASK_ADD_ATTACHMENTS,
            payload: true
        });

        // dispatch(setAlert("Attachments added successfully", "success"));

    }
    catch (exception){
        exceptionClause(exception, "Failed to add task attachments");
    }
}

export const updateAttachmentDetails = (id, description, name) => async (dispatch) => {
    try{
        let response = await axios.put(`${_taskAttachmentsUrl}${id}`, {
            description: description,
            name: name
        });

        console.log('Update Single Task Response:', response.data);

        dispatch({
            type: UPDATE_TASK_ATTACHMENT_DETAILS,
            payload: ''
        })
    }
    catch (exception){
        exceptionClause(exception, "Failed to update task attachment");
    }
}

export const deleteAttachment = (id) => async (dispatch) => {
    try{
        let response = await axios.delete(`${_taskAttachmentsUrl}${id}`);
        dispatch({
            type: DELETE_TASK_ATTACHMENT,
            payload: response
        });

        // dispatch(setAlert("Attachment deleted", "success"));
    }
    catch (exception){
        exceptionClause(exception, "Failed to delete task attachment");
    }
}

export const getTaskAttachments = (taskID) => async (dispatch) => {
    try{

        let response = await axios.get(
            `${_baseURL}attachments/${taskID}`
        );

        dispatch({
            type: TASK_GET_ATTACHMENTS,
            payload: response.data
        });

    }
    catch (exception){
        dispatch(setAlert("An error occurred whilst loading the selected task's attachments, please refresh", "error"));
        console.error('[TASKS ACTIONS] getTaskAttachments failed with exception: ', exception);
    }
}

export const getAllClientTasks = (clientID) =>  async (dispatch) => {
    try{

        dispatch({
            type: TASK_LIST_LOADING,
            payload: true
        });

        // Get all tasks by clientID (Object ID)

        let httpResponse = await axios.get(`${_baseURL}byClient/${clientID}`);

        dispatch({
            type: TASK_GET_ALL,
            payload: httpResponse.data
        });



    }
    catch (exception){
        dispatch(setAlert("An error occurred whilst loading tasks, please refresh", "error"));
        console.error('[TASKS ACTIONS] getAllClientTasks failed with exception: ', exception);
        dispatch({
            type: TASK_LIST_LOADING,
            payload: false
        });

    }
}

export const getTasksAsAdmin = (userID, clientID) =>  async (dispatch) => {
    try{

        dispatch({
            type: TASK_LIST_LOADING,
            payload: true
        });

        let httpResponse = await axios.get(`${_baseURL}asAdmin/${userID}/${clientID}`);

        dispatch({
            type: GET_USER_TASKS,
            payload: httpResponse.data
        });


    }
    catch (exception){
        dispatch(setAlert("An error occurred whilst loading tasks, please refresh", "error"));
        console.error('[TASKS ACTIONS] get user tasks failed with exception: ', exception);
        dispatch({
            type: TASK_LIST_LOADING,
            payload: false
        });

    }
}

export const getUserTasks = (userID) =>  async (dispatch) => {
    try{

        dispatch({
            type: TASK_LIST_LOADING,
            payload: true
        });

        let httpResponse = await axios.get(`${_baseURL}user/${userID}`);

        dispatch({
            type: GET_USER_TASKS,
            payload: httpResponse.data
        });


    }
    catch (exception){
        dispatch(setAlert("An error occurred whilst loading tasks, please refresh", "error"));
        console.error('[TASKS ACTIONS] get user tasks failed with exception: ', exception);
        dispatch({
            type: TASK_LIST_LOADING,
            payload: false
        });

    }
}


export const getTaskChat = (taskID) =>  async (dispatch) => {

    try{
        dispatch({
            type: TASK_CHAT_LOADED,
            payload: false
        });

        let response = await axios.get(`${_taskChatUrl}${taskID}`);
        dispatch({
            type: GET_TASK_CHAT,
            payload: response.data
        });

    }
    catch (exception){
        exceptionClause(exception, 'Failed to get task chat object');
    }


}

export const setTaskChatLoaded = (value) => async (dispatch) => {
    try{
        dispatch({
            type: TASK_CHAT_LOADED,
            payload: value
        });
    }
    catch (exception){
        exceptionClause(exception, 'Failed to get task chat object');
    }
}

export const getOneTask = (taskID) =>  async (dispatch) => {



}

export const addCollaborator = (collaborator, taskID) => async (dispatch) => {
    try{
        let httpResponse = await axios.post(`${_baseURL}addCollaborator/${taskID}` , collaborator);
        dispatch({
            type: TASK_ADD_COLLABORATOR,
            payload: httpResponse.data
        });
    }
    catch (exception){

    }
}

export const removeCollaborator = (collaborator, taskID) => async (dispatch) => {
    try{
        let httpResponse = await axios.post(`${_baseURL}removeCollaborator/${taskID}` , collaborator);
        dispatch({
            type: TASK_REMOVE_COLLABORATOR,
            payload: httpResponse.data
        });
    }
    catch (exception){
        dispatch(setAlert("An error occurred whilst removing collaborator from task please refresh", "error"));
        console.error('[TASKS ACTIONS] removeCollaborator failed with exception: ', exception);

    }
}

export const addMultipleCollaborators = (collaborators, taskID) => async (dispatch) => {
    try{

        await axios.all(
            collaborators.map( collaborator =>
                axios
                    .post(
                        `${_baseURL}addCollaborator/${taskID}`,
                        collaborator
                    )
            )
        );

        dispatch({
            type: TASK_ADD_MULTIPLE_COLLABORATOR,
            payload: ''
        });
    }
    catch (exception){
        dispatch(setAlert("An error occurred whilst adding multiple collaborators please refresh", "error"));
        console.error('[TASKS ACTIONS] addMultipleCollaborators failed with exception: ', exception);
    }
}

export const updateSelectedTask = (task) => async (dispatch) => {

    try{
        dispatch({
            type: TASK_UPDATE_SELECTED_TASK,
            payload: task
        });
    }
    catch (exception){

    }

}

export const updateTask = (task) => async (dispatch) => {

    try{
        await axios
            .put(
                `${_baseURL}${task._id}`,
                task
            );

        dispatch({
            type: TASK_UPDATE,
            payload: ''
        });

        dispatch(setAlert("Task Updated Successfully", "success"));
    }
    catch (exception){
        dispatch(setAlert("An error occurred whilst updating a task, please refresh", "error"));
        console.error('[TASKS ACTIONS] updateTask failed with exception: ', exception);
    }

}

export const setSelectedTask = (task) => async (dispatch) => {
    dispatch({
        type: TASK_SET_SELECTED,
        payload: task
    });
}
