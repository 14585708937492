import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd componets
import { Card, Button, List, Empty, Divider, Tooltip, Typography } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  SaveOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  HighlightOutlined,
} from "@ant-design/icons";

// external functions
import {
  EditViewRole,
  UpdateSpecificRole,
  ModalPermAddOpen,
} from "../../../actions/roles";

const { Paragraph } = Typography;

// actual component
const RolesCard = ({
  permissions,
  data,
  isAdmin,
  EditViewRole,
  pageWidth,
  UpdateSpecificRole,
  ModalPermAddOpen,
}) => {
  const [descriptionStr, setDescriptionStr] = useState("");

  useEffect(() => {
    setDescriptionStr(data?.description);
  }, [data]);

  const handleDeleteRole = (record) => {
    if (record) {
      let thisUsersData = { ...data };

      thisUsersData.permissions = thisUsersData.permissions.filter(
        (perm) => perm.name !== record.name
      );
      EditViewRole(thisUsersData);
    }
  };

  const handleChange = (e) => {
    if (e) {
      const roleData = { ...data };

      roleData[e.section] = e.val;

      EditViewRole(roleData);
    }
  };

  return !data ? (
    <Empty
      description={
        <span>Select an item in the table to view / edit details</span>
      }
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "50%",
        transform:
          pageWidth >= 768 ? "translate(-50%, -50%)" : "translate(-50%, 50%)",
      }}
    />
  ) : (
    <Card
      title={
        <p style={{ fontWeight: "bold" }}>
          Role Name: <span style={{ fontWeight: "normal" }}>{data.name}</span>
        </p>
      }
      extra={
        <Button
          disabled={!permissions?.update ? (isAdmin ? false : true) : false}
          icon={<SaveOutlined />}
          onClick={() => UpdateSpecificRole(data._id, data)}
        >
          {pageWidth >= 768 && "Save"}
        </Button>
      }
      style={{ marginTop: pageWidth >= 768 ? "15px" : "1px", width: "100%" }}
    >
      <p style={{ fontWeight: "bold" }}>Description:</p>
      <Paragraph
        editable={{
          icon: <HighlightOutlined style={{ color: "black" }} />,
          maxLength: 150,
          tooltip: "click to edit description",
          onChange: (val) => handleChange({ section: "description", val }),
        }}
      >
        {descriptionStr}
      </Paragraph>

      <div>
        <p style={{ fontWeight: "bold" }}>
          Default Allowed Permission:
          {permissions?.update | isAdmin ? (
            <span>
              <Button
                type="text"
                style={{ color: "blue", float: "right" }}
                icon={<PlusCircleOutlined />}
                onClick={ModalPermAddOpen}
              >
                Add Permission
              </Button>
            </span>
          ) : (
            <></>
          )}
        </p>
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: pageWidth >= 768 ? "calc(100vh - 31em)" : "100px",
          width: "100%",
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={
            data?.permissions?.length === undefined
              ? 0
              : data?.permissions?.length
          }
          hasMore={false}
          loader={<h4>Loading...</h4>}
          endMessage={
            data?.permissions?.length > 0 &&
            pageWidth >= 795 && (
              <Divider plain>Done!! add more permissions</Divider>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          <List
            size="small"
            dataSource={data?.permissions}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta title={item.name} />
                <Tooltip title="Click to remove this permission">
                  <Button
                    type="link"
                    onClick={() => handleDeleteRole(item)}
                    icon={<CloseCircleOutlined style={{ color: "red" }} />}
                  ></Button>
                </Tooltip>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </Card>
  );
};

RolesCard.propTypes = {
  data: PropTypes.object,
  EditViewRole: PropTypes.func.isRequired,
  UpdateSpecificRole: PropTypes.func.isRequired,
  ModalPermAddOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.roles.editData,
});

export default connect(mapStateToProps, {
  EditViewRole,
  UpdateSpecificRole,
  ModalPermAddOpen,
})(RolesCard);
