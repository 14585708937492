import "./style.scss";
//@ts-ignore
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  PageHeader,
  Radio,
  Select,
  Steps,
  Typography,
  Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ISimpleUser } from "../../../../interfaces/shared/ISimpleUser";
import moment from "moment";
import { RangePickerProps } from "antd/es/date-picker";
import { ITask } from "../../interfaces/ITask";
import { createTask } from "../../../../actions/tasks";
import { TASK_TYPES } from "../../helpers/constants";
import { UploadFile } from "antd/es/upload/interface";
// import Search from "antd/es/input/Search";

const { Step } = Steps;
const { Dragger } = Upload;
const { Text } = Typography;

const initialTaskDetails: {
  title: string;
  description: string;
  type: string;
  priority: string;
  due_date: Date;
  assigned_to: ISimpleUser;
} = {
  title: "",
  description: "",
  type: "",
  priority: "",
  due_date: new Date(),
  assigned_to: {
    id: "",
    user_name: "",
    email_address: "",
  },
};

const CreateTaskPage = (props: {
  users: any[];
  userDetails: any;
  createTask: any;
  departments: any[];
}) => {
  let history = useHistory();

  const [form] = Form.useForm();
  const [attachments, setAttachments] = useState<any[]>([]);

  const [taskDetails, setTaskDetails] = useState(initialTaskDetails);
  const [userList, setUserList] = useState([]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    // console.log("CURRENT DATE :: ", current);
    return current && current < moment().subtract(1, "day").endOf("day");
  };
  /*
   ************* STEPS *************
   * */
  const [currentStep, setCurrentStep] = useState(0);

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [taskTypes, setTaskTypes] = useState(TASK_TYPES);
  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [showTypesError, setShowTypesError] = useState(false);
  const [collaboratorsSearchText, setCollaboratorsSearchText] = useState("");

  const nextStep = (): void => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = (): void => {
    if (currentStep === 0) {
      setSelectedDepartment("");
      setSelectedTaskType("");
      setTaskDetails(initialTaskDetails);
      history.push("/admin/tasks");
    } else if (currentStep === 1) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  /*
   ************* END STEPS *************
   * */

  /*
   ************* Task Type Section *************
   * */
  const handleSelectedDepartmentEven = (department: string) => {
    const filteredTypes = TASK_TYPES.filter(
      (item) => item.department === department
    );
    setTaskTypes([...filteredTypes]);
    setSelectedDepartment(department);
  };

  /*
   ************* END Task Type Section *************
   * */

  /*
   ************* Collaborators *************
   * */
  const [_collaborators, _setCollaborators] = useState([]);
  const _onCollaboratorSelected = (user: any) => {
    if (user) {
      let index = _collaborators.findIndex((e: any) => e.id === user._id);
      if (index !== -1) {
        let newCollaborators = [..._collaborators];
        newCollaborators.splice(index, 1);
        _setCollaborators([...newCollaborators]);
      } else {
        let collaborator: ISimpleUser = {
          id: user._id,
          user_name: `${user.name} ${user.surname}`,
          email_address: user.email_address,
        };

        //@ts-ignore
        _setCollaborators([..._collaborators, collaborator]);
      }
    }
  };

  /*
   ************* END Collaborators *************
   * */

  const submitForm = (): void => {
    if (currentStep === 0) {
      //Task Type
      if (selectedDepartment === "") {
        setShowTypesError(true);
      } else if (selectedTaskType === "") {
        setShowTypesError(true);
      } else {
        setShowTypesError(false);
        nextStep();
      }
    }

    if (currentStep === 1) {
      //Submit Form
      form.submit();
    }

    if (currentStep === 2) {
      // console.log(attachments);
      nextStep();
    }

    if (currentStep === 3) {
      //Create the Ticket
      createTask();
    }
  };

  const onTaskDetailsFinished = (values: any) => {
    //Perform Validation
    const objectKeys = Object.keys(values);
    let isValid: boolean = true;

    let invalidFields = [];

    objectKeys.forEach((key) => {
      if (values[`${key}`] === "" || values[`${key}`] === undefined) {
        isValid = false;
        invalidFields.push(key);
      }
    });

    if (isValid) {
      let user = props.users.find((e: any) => e._id === values.assignedTo);
      let assignedTo: ISimpleUser = {
        id: user._id,
        user_name: `${user.name} ${user.surname}`,
        email_address: user.email_address,
      };

      // set state
      setTaskDetails({
        title: values.title,
        description: values.description,
        type: values.type,
        priority: values.priority,
        due_date: values.dueDate,
        assigned_to: assignedTo,
      });
      nextStep();
    }
  };

  const onTaskDetailsFinishFailed = () => {
    // console.log("Finish failed");
  };

  const _filterUserList = useCallback((): void => {
    let users = [...props.users];

    let filteredUsers: any[] = [];

    if (props.userDetails.role.name !== "Administrator") {
      // Get users for this client only
      filteredUsers = users.filter(
        (e: any) => e.client_id === props.userDetails.client_id
      );
    } else {
      // Get users for this client only
      filteredUsers = [...users];
    }

    //@ts-ignore
    setUserList(filteredUsers);
  }, [props.users, props.userDetails.client_id, props.userDetails.role.name]);

  const createTask = () => {
    // Task Details
    const task: ITask = {
      assigned_to: taskDetails.assigned_to,
      created_at: new Date(),
      _id: "",
      title: taskDetails.title,
      description: taskDetails.description,
      priority: taskDetails.priority,
      type: selectedTaskType,
      due_date: taskDetails.due_date,
      attachments: [],
      collaborators: [..._collaborators],
      client: {
        _id: props.userDetails.client_id,
        name: props.userDetails.client_name,
      },
      site: {
        _id: "",
        name: "",
      },
      logs: [],
      alarm_details: [],
      status: "Pending",
      creator: {
        id: props.userDetails._id,
        user_name: `${props.userDetails.name} ${props.userDetails.surname}`,
        email_address: props.userDetails.email_address,
      },
    };

    // Attachments Form Data
    let formData = new FormData();
    formData.append("name", task.title);
    formData.append("description", "No description provided");
    for (let i = 0; i < attachments.length; i++) {
      formData.append("attachment", attachments[i]);
    }

    props.createTask(task, formData);

    setTimeout(() => {
      history.push("/admin/tasks");
    }, 1000);
  };

  useEffect(() => {
    _filterUserList();
  }, [_filterUserList]);

  const onFormDataChange = (e: any) => {
    // console.log("Change:", e.target.value);
    // console.log("Change:", e.target.name);

    let type: string = e.target.name;
    switch (type) {
      case "title":
        form.setFieldsValue({ title: e.target.value });
        setTaskDetails((old) => ({ ...old, title: e.target.value }));
        break;
      case "description":
        form.setFieldsValue({ description: e.target.value });
        setTaskDetails((old) => ({ ...old, description: e.target.value }));
        break;
      case "priority":
        form.setFieldsValue({ priority: e.target.value });
        setTaskDetails((old) => ({ ...old, priority: e.target.value }));
        break;
    }
  };

  const onTypeDataChange = (value: string) => {
    // console.log("Task Type", value);
    form.setFieldsValue({ type: value });
  };

  const onDueDateChange = (value: any) => {
    // console.log("Due Date", value);
    form.setFieldsValue({ dueDate: value?.toDate() });
    setTaskDetails((old) => ({ ...old, due_date: value?.toDate() }));
  };

  const onAssignedToChange = (data: any) => {
    // console.log("Assigned to", data);
    form.setFieldsValue({ assignedTo: data });
    let taskDetailLocal = { ...taskDetails };

    taskDetailLocal.assigned_to.id = data;

    setTaskDetails((old) => ({ ...taskDetailLocal }));
  };

  const taskMetaForm = () => (
    <div className="task-meta-form">
      <PageHeader
        className="no-padding"
        style={{
          margin: "0 0 2em",
        }}
        title={"Task Type"}
      >
        <Text type="secondary">
          {showTypesError && (
            <Alert
              message="You must select the department and type"
              type="error"
              showIcon
            />
          )}
          {!showTypesError && (
            <Alert
              message="Provide the requested information below in order to proceed"
              type="info"
              showIcon
            />
          )}
        </Text>
      </PageHeader>
      <Divider />
      <div className="mpt-form-item-container">
        <span>
          Which <strong>Department</strong> does this task belong to ?
        </span>
        <Select
          value={selectedDepartment}
          showSearch
          placeholder="Department..."
          onSelect={(change: any) => {
            handleSelectedDepartmentEven(change);
          }}
          allowClear
        >
          {props.departments.map((department: any) => {
            if (
              department.name !== "HR" &&
              department.name !== "Logistics" &&
              department.name !== "Finance"
            ) {
              return (
                <Select.Option key={department.name} value={department.name}>
                  <span>{department.name}</span>
                  <br />
                  <small>{department.description}</small>
                </Select.Option>
              );
            }

            return true;
          })}
        </Select>
      </div>

      <div className="mpt-form-item-container">
        <span>
          What type of <strong>Task or Job Type</strong> will be performed?
        </span>
        <Select
          value={selectedTaskType}
          disabled={
            selectedDepartment === "" ||
            selectedDepartment === null ||
            selectedDepartment === undefined
          }
          showSearch
          placeholder="Task Type..."
          onSelect={(type: any) => {
            setSelectedTaskType(type);
            onTypeDataChange(type);
          }}
          allowClear
        >
          {taskTypes.map((type: any, index: number) => (
            <Select.Option key={"task-type-" + index} value={type.name}>
              <span>{type.name}</span>
            </Select.Option>
          ))}
        </Select>
      </div>

      {/*<div className="mpt-form-item-container">*/}
      {/*    <span>What type of <strong>Task or Process</strong> will be performed?</span>*/}
      {/*    <div className="horizontal-card-item-container">*/}

      {/*        {*/}
      {/*            TASK_TYPES.map((type: any) =>*/}
      {/*                <Card*/}
      {/*                    className="horizontal-card-item"*/}
      {/*                    bordered={false}*/}
      {/*                    cover={*/}
      {/*                        <img*/}
      {/*                            style={{padding: '0', height: '100px'}}*/}
      {/*                            alt="example"*/}
      {/*                            src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"*/}
      {/*                        />*/}
      {/*                    }*/}
      {/*                    style={{padding: '0'}}*/}
      {/*                    bodyStyle={{padding: '1em'}}*/}
      {/*                >*/}
      {/*                    <Card.Meta*/}
      {/*                        title={type.name}*/}
      {/*                        // description="This is the description"*/}
      {/*                    />*/}
      {/*                   <Button type={'link'}>Select</Button>*/}
      {/*                </Card>*/}
      {/*            )*/}
      {/*        }*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );

  const taskDetailsForm = () => (
    <>
      <Alert
        style={{ marginBottom: "1em" }}
        message={
          <span>
            This task is related to the{" "}
            <strong>{selectedDepartment} Department</strong> and is of type{" "}
            <strong>{selectedTaskType}</strong>{" "}
          </span>
        }
        type="info"
        showIcon
      />
      <div className="task-details-form">
        <div style={{ height: "100%" }}>
          <Form
            form={form}
            name="taskDetailsForm"
            layout={"horizontal"}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            onChange={onFormDataChange}
            initialValues={{ ...initialTaskDetails }}
            onFinish={onTaskDetailsFinished}
            onFinishFailed={onTaskDetailsFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="title"
              rules={[{ required: true, message: "The title is required" }]}
            >
              <span>Title</span>
              <Input
                placeholder={"Title..."}
                name="title"
                value={taskDetails.title}
              />
            </Form.Item>

            <Form.Item
              name="description"
              rules={[
                { required: true, message: "The description is required" },
              ]}
            >
              <span>Description</span>
              <Input.TextArea
                rows={4}
                placeholder={"Task description..."}
                name="description"
                value={taskDetails.description}
              />
            </Form.Item>

            {/*<Form.Item*/}
            {/*    name="type"  rules={[{ required: true, message: 'Task type Required' }]}>*/}
            {/*    <span>Task Type</span>*/}
            {/*    <Select*/}

            {/*        placeholder="Select the type"*/}

            {/*        onChange={onTypeDataChange}*/}
            {/*        allowClear*/}
            {/*    >*/}
            {/*        <Select.Option value="Issue">Issue</Select.Option>*/}
            {/*        <Select.Option value="Incident">Incident</Select.Option>*/}
            {/*        <Select.Option value="Problem">Problem</Select.Option>*/}
            {/*    </Select>*/}
            {/*</Form.Item>*/}

            <Form.Item
              name="priority"
              rules={[{ required: true, message: "Priority Required" }]}
            >
              <span>Priority</span>
              <Radio.Group
                style={{ width: "100%" }}
                name={"priority"}
                value={taskDetails.priority}
              >
                <Radio value={"Low"}>Low Priority</Radio>
                <Radio value={"Medium"}>Medium Priority</Radio>
                <Radio value={"High"}>High Priority</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="dueDate"
              rules={[{ required: true, message: "Due date required" }]}
            >
              <span>Due Date</span>
              <DatePicker
                name="dueDate"
                placeholder={"Select the due date"}
                style={{ width: "100%" }}
                onChange={onDueDateChange}
                disabledDate={disabledDate}
                //@ts-ignore
                value={moment(taskDetails.due_date)}
              />
            </Form.Item>

            <Form.Item
              name="assignedTo"
              rules={[{ required: true, message: "Assignee required" }]}
            >
              <span>Assigned to</span>
              <Select
                value={taskDetails.assigned_to.id}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select a user from the list"
                onChange={onAssignedToChange}
                allowClear
              >
                {userList.map((e: any) => (
                  <Select.Option value={e._id} key={e._id}>
                    {e.name + " " + e.surname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );

  const collaboratorsSection = () => (
    <>
      <PageHeader
        title="Add Collaborators"
        subTitle="Select collaborators from the list below. Adding collaborators is not mandatory"
        style={{ padding: "0" }}
      >
        <Input
          value={collaboratorsSearchText}
          placeholder="Search here..."
          onChange={(e: any) => {
            let text: string = e.target.value.toLowerCase();
            setCollaboratorsSearchText(text);
            if (text !== "") {
              let users = userList.filter(
                (user: any) =>
                  user.name.toLowerCase().includes(text) ||
                  user.surname.toLowerCase().includes(text) ||
                  user.email_address.toLowerCase().includes(text)
              );
              // console.log(users);
              setUserList([...users]);
            } else {
              _filterUserList();
            }
            // console.log("Search Text Changed :", text);
          }}
          style={{ width: "100%" }}
        />
        <Text type={"secondary"}>
          <small>You can search by Name, Surname and Email Address</small>
        </Text>
      </PageHeader>
      <Divider orientation="left" plain>
        <small> Users ({userList.length})</small>
      </Divider>
      <div className="collaborators-selection">
        <List
          itemLayout="horizontal"
          dataSource={userList}
          // style={{overflowY: 'scroll'}}
          renderItem={(item: any) => (
            <List.Item
              key={item.email_address}
              actions={[
                <Checkbox
                  key={item.name}
                  onChange={() => _onCollaboratorSelected(item)}
                />,
                // <Button
                //     disabled={_collaborators.findIndex( (e: any) => e.id === item._id) !== -1}
                //     type={'link'}
                //     onClick={ () => _onCollaboratorSelected(item) }>
                //     Add
                // </Button>
              ]}
            >
              <List.Item.Meta
                title={
                  <span>
                    {item.name} {item.surname}
                  </span>
                }
                description={<small>{item.email_address}</small>}
              />
            </List.Item>
          )}
        />
      </div>
      <Divider />
    </>
  );

  const attachmentsForm = () => (
    <>
      <PageHeader
        title="Upload Attachments"
        subTitle="Attach files to this job. Attachments are not mandatory"
        style={{ padding: "0 0 30px 0" }}
      />
      <div className="attachment-form">
        {/*<DragAndDrop/>*/}
        <Dragger {...draggerProps} style={{ cursor: "default" }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Drag and Drop files into this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading sensitive company data.
          </p>
        </Dragger>
        {/*<div className="attachments-list-area">*/}
        {/*    {*/}
        {/*        attachments.map( (attachment: any) =>*/}
        {/*            <div className="attachments-list-item">*/}
        {/*                <h4>{attachment.name}</h4>*/}
        {/*                <div>*/}
        {/*                    /!*<span style={{marginRight: '20px'}}>*!/*/}
        {/*                    /!*    <Button type={'link'}>*!/*/}
        {/*                    /!*        Add Description*!/*/}
        {/*                    /!*         /!*<EditOutlined />*!/*!/*/}
        {/*                    /!*    </Button>*!/*/}

        {/*                    /!*</span>*!/*/}
        {/*                    <span>*/}
        {/*                       <Button type={'link'} danger>*/}
        {/*                           <DeleteOutlined />*/}
        {/*                       </Button>*/}
        {/*                   </span>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        )*/}
        {/*    }*/}

        {/*</div>*/}
      </div>
    </>
  );

  const draggerProps = {
    showUploadList: {
      showRemoveIcon: true,
    },

    name: "file",
    multiple: true,
    onChange(info: any) {
      const { status } = info.file;
      // console.log(status);

      if (status === "removed") {
        // console.log("File removed", info.file);
        let _oldAttachments = [...attachments];
        let newAttachments = _oldAttachments.filter(
          (a: any) => a.uid !== info.file.uid
        );
        setAttachments([...newAttachments]);
        // console.log("Attachments :", newAttachments);
      }

      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }

      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onBeforeFilesUploaded: (data: UploadFile) => {
      setAttachments([...attachments, data]);
      return false;
    },
    onDrop(e: any) {
      // console.log("Dropped files", e.dataTransfer.files);
      let _attachments: any = [...attachments, ...e.dataTransfer.files];
      setAttachments(_attachments);
      // console.log("Attachments :", _attachments);
    },
    fileList: attachments,
  };

  const steps = [
    {
      title: "Task Label",
      content: taskMetaForm(),
    },
    {
      title: "Task Details",
      //content: taskDetailsForm(),
    },
    {
      title: "Attachments",
      content: attachmentsForm,
    },
    {
      title: "Collaborators",
      content: collaboratorsSection,
    },
  ];

  return (
    <>
      <div className="create-task-container">
        <PageHeader
          title="Create Task"
          subTitle="Complete the steps below to create a new task"
        >
          <Steps current={currentStep}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </PageHeader>
        <div className="task-creation-steps-container">
          <div className="step-content">
            {
              // (currentStep === 0) ? taskDetailsForm() : collaboratorsSection()
              currentStep === 0 && taskMetaForm()
            }
            {
              // (currentStep === 0) ? taskDetailsForm() : collaboratorsSection()
              currentStep === 1 && taskDetailsForm()
            }
            {currentStep === 2 && attachmentsForm()}
            {currentStep === 3 && collaboratorsSection()}
          </div>
          <div className="step-actions">
            {currentStep === 0 && (
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => previousStep()}
                danger
              >
                Cancel
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={() => submitForm()}>
                Next
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button
                style={{
                  backgroundColor: "limegreen",
                  color: "#ffffff",
                  borderColor: "limegreen",
                }}
                onClick={() => submitForm()}
              >
                Create Task
              </Button>
            )}
            {currentStep > 0 && (
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => previousStep()}
              >
                Back
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  users: state.users.data,
  userDetails: state.auth.user,
  departments: state.department.data,
});

export default connect(mapStateToProps, {
  createTask,
})(CreateTaskPage);
