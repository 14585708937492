import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./auth";
import {
  CLIENTS_CLEARALL,
  CLIENTS_EDIT,
  CLIENTS_GET_ALL,
  CLIENTS_GET_LOGO,
  CLIENTS_LOADING,
  CLIENTS_MODAL_ADDSERVICE_CLOSE,
  CLIENTS_MODAL_ADDSERVICE_OPEN,
  CLIENTS_MODAL_NEWCLIENT_CLOSE,
  CLIENTS_MODAL_NEWCLIENT_OPEN,
} from "./types";

// Loads all the clients
export const loadAllClients = () => async (dispatch) => {
  try {
    dispatch({
      type: CLIENTS_LOADING,
      payload: true,
    });

    // get the basic clients information
    let res = await axios.get("/api/clients");

    dispatch({
      type: CLIENTS_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: CLIENTS_LOADING,
      payload: false,
    });
  }
};

// clears all the client items in the store
export const clearClientState = () => (dispatch) => {
  dispatch({
    type: CLIENTS_CLEARALL,
  });
};

// view details of a specific client
export const EditViewClient = (details) => (dispatch) => {
  dispatch({
    type: CLIENTS_EDIT,
    payload: details,
  });
};

// get the profile picture of the person
export const GetClientPicture = (clientID) => async (dispatch) => {
  try {
    if (clientID) {
      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // Get the data from the backend
      let res = await axios.get("/api/clients/picture/" + clientID);

      dispatch({
        type: CLIENTS_GET_LOGO,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("Error: ", error.response);

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    }

    dispatch({
      type: CLIENTS_GET_LOGO,
      payload: null,
    });
  }
};

// deletes a specific client, needs the client object id
export const DeleteSpecificClient = (clientID) => async (dispatch) => {
  try {
    if (clientID) {
      dispatch({
        type: CLIENTS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the client
      await axios.delete("/api/clients/" + clientID);

      // get the basic clients information
      let res = await axios.get("/api/clients");

      dispatch(setAlert("Client was deleted successful", "success"));

      dispatch({
        type: CLIENTS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: CLIENTS_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: CLIENTS_LOADING,
        payload: false,
      });
    }
  }
};

// Open the new client modal
export const ModalNewClientOpen = () => (dispatch) => {
  dispatch({
    type: CLIENTS_MODAL_NEWCLIENT_OPEN,
  });
};

// Close the new client modal
export const ModalNewClientClose = () => (dispatch) => {
  dispatch({
    type: CLIENTS_MODAL_NEWCLIENT_CLOSE,
  });
};

// create a new client
export const CreateClient = (data) => async (dispatch) => {
  try {
    if (data) {
      dispatch({
        type: CLIENTS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the client
      await axios.post("/api/clients", { data });

      // get the basic clients information
      let res = await axios.get("/api/clients");

      dispatch(setAlert("Client was created", "success"));

      dispatch({
        type: CLIENTS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: CLIENTS_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: CLIENTS_LOADING,
        payload: false,
      });
    }
  }
};

// Open the add service modal
export const ModalAddServiceOpen = () => (dispatch) => {
  dispatch({
    type: CLIENTS_MODAL_ADDSERVICE_OPEN,
  });
};

// Close the add service modal
export const ModalAddServiceClose = () => (dispatch) => {
  dispatch({
    type: CLIENTS_MODAL_ADDSERVICE_CLOSE,
  });
};

// updates a specific role, needs the user object id
export const UpdateSpecificClient = (clientID, data) => async (dispatch) => {
  try {
    if (clientID) {
      dispatch({
        type: CLIENTS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.put("/api/clients/" + clientID, { data });

      // get the basic clients information
      res = await axios.get("/api/clients");

      dispatch(setAlert("Client details saved", "success"));

      dispatch({
        type: CLIENTS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: CLIENTS_LOADING,
      payload: false,
    });
  }
};
