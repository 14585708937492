import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd componet
import { Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

// External functions
import { logout } from "../../../actions/auth";

// actual component
const RightMenu = ({ logout, visible }) => {
  return (
    <Menu mode={visible ? "vertical" : "horizontal"} theme="light">
      <Menu.Item key="Logout">
        <a onClick={logout} href="#!" style={{ color: "gold" }}>
          Sign Out {""}
          <LogoutOutlined />
        </a>
      </Menu.Item>
    </Menu>
  );
};

RightMenu.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(RightMenu);
