import {
  DEPART_MODAL_ADDNEW_CLOSE,
  DEPART_MODAL_ADDNEW_OPEN,
  DEPART_LOADING,
  DEPART_EDIT,
  DEPART_CLEARALL,
  DEPART_GET_ALL,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  modalAddDepartmentVis: false,
};

export default function department(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case DEPART_MODAL_ADDNEW_CLOSE:
      return { ...state, modalAddDepartmentVis: false };
    case DEPART_MODAL_ADDNEW_OPEN:
      return { ...state, modalAddDepartmentVis: true };
    case DEPART_EDIT:
      return { ...state, editData: payload };
    case DEPART_LOADING:
      return { ...state, loading: payload };
    case DEPART_GET_ALL:
      return { ...state, data: payload, loading: false };
    case DEPART_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
