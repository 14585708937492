import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Card, Form, Input, Button } from "antd";

// external functions
import { NewSiteUpdate } from "../../../../../actions/sites";

// actual componet
const Locations = ({
  current,
  setCurrent,
  NewSiteUpdate,
  newSiteData,
  pageWidth,
}) => {
  const [formDetails] = Form.useForm();
  const [fromData, setFromData] = useState(null);

  if (fromData === null && newSiteData?.address) {
    setFromData(newSiteData?.address);

    formDetails.setFieldsValue({ province: newSiteData?.address.province });
    formDetails.setFieldsValue({ country: newSiteData?.address.country });
    formDetails.setFieldsValue({ street: newSiteData?.address.street });
    formDetails.setFieldsValue({ suburb: newSiteData?.address.suburb });
    formDetails.setFieldsValue({ city: newSiteData?.address.city });
    formDetails.setFieldsValue({ latitude: newSiteData?.address.latitude });
    formDetails.setFieldsValue({ longitude: newSiteData?.address.longitude });
  }

  const handleNext = () => {
    let data = formDetails.getFieldsValue();

    if (data.province) data.province = data.province.trim();
    else data.province = "";
    if (data.country) data.country = data.country.trim();
    else data.country = "";
    if (data.street) data.street = data.street.trim();
    else data.street = "";
    if (data.suburb) data.suburb = data.suburb.trim();
    else data.suburb = "";
    if (data.city) data.city = data.city.trim();
    else data.city = "";

    data.latitude = data.latitude.trim();
    data.longitude = data.longitude.trim();

    let currentSiteData = { ...newSiteData };

    currentSiteData.address = {
      ...data,
    };

    setCurrent(current + 1);
    setFromData(null);
    NewSiteUpdate(currentSiteData);
  };

  return (
    <Card
      title={
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Location</p>
      }
      extra={[
        <Button
          style={{ margin: "0 8px" }}
          onClick={() => setCurrent(current - 1)}
        >
          Previous
        </Button>,
        <Button
          type="primary"
          onClick={() => formDetails.validateFields().then(() => handleNext())}
        >
          Next
        </Button>,
      ]}
      style={{ marginTop: pageWidth > 795 ? "15px" : "5px", width: "100%" }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 5 }}
        size="small"
        form={formDetails}
        onValuesChange={(_, all) => {
          setFromData(all);
        }}
      >
        <Form.Item
          label={<span style={{ color: "black" }}>Street</span>}
          name={"street"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
        >
          <Input />
        </Form.Item>
        {pageWidth > 795 && (
          <Fragment>
            <Form.Item
              label={<span style={{ color: "black" }}>Suburb</span>}
              name={"suburb"}
              style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span style={{ color: "black" }}>City</span>}
              name={"city"}
              style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span style={{ color: "black" }}>Province</span>}
              name={"province"}
              style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            >
              <Input />
            </Form.Item>
          </Fragment>
        )}
        <Form.Item
          label={<span style={{ color: "black" }}>Country</span>}
          name={"country"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Latitude</span>}
          name={"latitude"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please input a valid Latitude!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Longitude</span>}
          name={"longitude"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please input a valid Longitude!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Card>
  );
};

Locations.propTypes = {
  NewSiteUpdate: PropTypes.func.isRequired,
  newSiteData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  newSiteData: state.sites.newSiteData,
});

export default connect(mapStateToProps, { NewSiteUpdate })(Locations);
