import React, { Fragment, lazy, Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// andt Components
import { PageHeader, Button, Row, Col, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// External functions
import {
  clearGroupsState,
  loadAllGroups,
  ModalAddGroupOpen,
} from "../../../actions/groups";
import { clearSitesState, loadAllSites } from "../../../actions/sites";
import { clearClientState, loadAllClients } from "../../../actions/clients";

// external components
const GroupsTable = lazy(() => import("./groupsTable"));
const GroupsCard = lazy(() => import("./groupsCard"));
const ModalAddNewGroup = lazy(() => import("./modal/ModalAddNew"));

// actual component
const Index = ({
  permissions,
  isAdmin,
  clearGroupsState,
  loadAllGroups,
  pageWidth,
  clearSitesState,
  loadAllSites,
  clearClientState,
  loadAllClients,
  ModalAddGroupOpen,
}) => {
  useEffect(() => {
    loadAllGroups();
    loadAllSites();

    // only if admin, else waist of time and resoucres
    if (isAdmin) loadAllClients();

    // clean up the effect by removing it completely
    return () => {
      clearGroupsState();
      clearSitesState();
      clearClientState();
    };
  }, [
    loadAllGroups,
    clearGroupsState,
    clearSitesState,
    loadAllSites,
    clearClientState,
    isAdmin,
    loadAllClients,
  ]);

  return (
    <Suspense
      fallback={
        <Spin
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            width: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size="large"
        />
      }
    >
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Groups"
          subTitle={pageWidth >= 795 && "Manage clients groups"}
          extra={[
            <Button
              key="NewUser"
              type="primary"
              disabled={isAdmin ? false : !permissions?.write}
              icon={<PlusCircleOutlined />}
              onClick={ModalAddGroupOpen}
            >
              {pageWidth >= 795 && "New Group"}
            </Button>,
          ]}
        ></PageHeader>

        <ModalAddNewGroup pageWidth={pageWidth} isAdmin={isAdmin} />

        <Row>
          <Col xs={24} md={11}>
            <GroupsTable
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
          <Col xs={0} md={1}></Col>
          <Col xs={24} md={12}>
            <GroupsCard
              pageWidth={pageWidth}
              isAdmin={isAdmin}
              permissions={permissions}
            />
          </Col>
        </Row>
      </Fragment>
    </Suspense>
  );
};

Index.propTypes = {
  permissions: PropTypes.object,
  isAdmin: PropTypes.bool,
  clearGroupsState: PropTypes.func.isRequired,
  loadAllGroups: PropTypes.func.isRequired,
  pageWidth: PropTypes.number,
  clearSitesState: PropTypes.func.isRequired,
  loadAllSites: PropTypes.func.isRequired,
  clearClientState: PropTypes.func.isRequired,
  loadAllClients: PropTypes.func.isRequired,
  ModalAddGroupOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  permissions: state.auth?.permissions?.filter(
    (item) => item.name === "Group Management"
  )[0],
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps, {
  clearGroupsState,
  loadAllGroups,
  clearSitesState,
  loadAllSites,
  clearClientState,
  loadAllClients,
  ModalAddGroupOpen,
})(Index);
