import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd componet
import {
  Button,
  Input,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  RightOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  editATypeLocal,
  ModalNewTypeItemOpen,
} from "../../../../../actions/staffTools/types";

const { Paragraph, Text } = Typography;

// actual componet
const TabVariables = ({
  data,
  pageWidth,
  ModalNewTypeItemOpen,
  editATypeLocal,
  editData,
}) => {
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex
        ? // <Highlighter
          //   highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          //   searchWords={[this.state.searchText]}
          //   autoEscape
          //   textToHighlight={text ? text.toString() : ""}
          // />
          text
        : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const handleDelete = (key) => {
    const currentData = { ...editData };
    currentData.vairables = currentData.vairables.filter(
      (alarm) => alarm.key !== key
    );

    editATypeLocal({});
    editATypeLocal({ ...currentData });
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "variable",
      ...getColumnSearchProps("variable"),
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.variable.localeCompare(b.variable),
      render: (text, record) => (
        <a
          href="#!"
          onClick={() =>
            ModalNewTypeItemOpen({
              type: "Variable",
              payload: record,
              isEdit: true,
            })
          }
        >
          {text}
        </a>
      ),
    },
    {
      title: "Symbol",
      key: "symbol",
      dataIndex: "symbol",
      width: "80px",
    },
    {
      title: "Children",
      children: [
        {
          title: "Name",
          key: "childrenName",
          dataIndex: ["ExtraChildren", "name"],
        },
        {
          title: "Type",
          key: "childrenType",
          dataIndex: ["ExtraChildren", "type"],
        },
      ],
    },
    {
      title: "Action",
      key: "Actions",
      width: "80px",
      render: (text, record) => (
        <Space size={pageWidth > 795 ? "middle" : "small"}>
          <Fragment>
            <Popconfirm
              title={"Sure to delete " + record.variable + "?"}
              onConfirm={() => handleDelete(record.key)}
            >
              <a href="#!" style={{ color: "red" }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
            <Tooltip title="Edit/view item">
              <Button
                shape="circle"
                icon={<RightOutlined />}
                size="small"
                onClick={() =>
                  ModalNewTypeItemOpen({
                    type: "Variable",
                    payload: record,
                    isEdit: true,
                  })
                }
              />
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Typography>
        <Paragraph>
          Below is a list of vairables associated to this type, use the{" "}
          <Text keyboard>Add Item</Text> button to add a new alarm or state to
          the type list
        </Paragraph>
      </Typography>

      <Button
        icon={<PlusCircleOutlined />}
        onClick={() =>
          ModalNewTypeItemOpen({
            type: "Variable",
            payload: null,
            isEdit: false,
          })
        }
      >
        Add Item
      </Button>

      <Table
        style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
        columns={columns}
        dataSource={data?.map((item, index) => {
          if (item.key === undefined) item.key = index;

          return item;
        })}
        size="small"
        pagination={{ pageSize: 50 }}
        bordered
        scroll={{
          x: "100%",
          y: pageWidth >= 768 ? "calc(100vh - 45em)" : "calc(100vh - 15em)",
        }}
      ></Table>
    </Fragment>
  );
};

TabVariables.propTypes = {
  data: PropTypes.array,
  ModalNewTypeItemOpen: PropTypes.func.isRequired,
  editATypeLocal: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  data: state.types.editData?.vairables,
  editData: state.types.editData,
});

export default connect(mapStateToProps, {
  ModalNewTypeItemOpen,
  editATypeLocal,
})(TabVariables);
