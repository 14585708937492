import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// antd componet
import { Result, Button } from "antd";

// external functions
import { NewUserUpdate } from "../../../../../actions/users";

// Actual component
const Finalisation = ({ setCurrent, NewUserUpdate }) => {
  const HandleNewUser = () => {
    setCurrent(0);
    NewUserUpdate(null);
  };

  return (
    <Result
      status="success"
      title="Successfully created new user!"
      extra={[
        <Link to={{ pathname: `/admin/users` }}>
          <Button type="primary" key="console">
            Go to users
          </Button>
        </Link>,
        <Button key="buy" onClick={HandleNewUser}>
          Add new user
        </Button>,
      ]}
    />
  );
};

Finalisation.propTypes = {
  NewUserUpdate: PropTypes.func.isRequired,
};

export default connect(null, { NewUserUpdate })(Finalisation);
