import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./auth";

import {
  SITES_CLEARALL,
  SITES_EDIT,
  SITES_GET_ALL,
  SITES_LOADING,
  SITES_MODAL_ADDFLR_CLOSE,
  SITES_MODAL_ADDFLR_OPEN,
  SITES_NEWSITE_SAVE,
  SITES_NEWSITE_UPDATE,
} from "./types";

export const loadAllSites = () => async (dispatch) => {
  try {
    dispatch({
      type: SITES_LOADING,
      payload: true,
    });

    // get the basic sites information
    let res = await axios.get("/api/sites");

    dispatch({
      type: SITES_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: SITES_LOADING,
      payload: false,
    });
  }
};

export const clearSitesState = () => (dispatch) => {
  dispatch({
    type: SITES_CLEARALL,
  });
};

// gets a specific sites data, needs the site object id
export const GetSpecificSiteDetails = (siteID) => async (dispatch) => {
  try {
    dispatch({
      type: SITES_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // get the basic user information
    let res = await axios.get("/api/sites/" + siteID);

    dispatch({
      type: SITES_EDIT,
      payload: res.data,
    });
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: SITES_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: SITES_LOADING,
        payload: false,
      });
    }
  }
};

// view details of a specific site
export const EditViewSite = (details) => (dispatch) => {
  dispatch({
    type: SITES_EDIT,
    payload: details,
  });
};

// on creation of new site update the store with the details
export const NewSiteUpdate = (details) => (dispatch) => {
  dispatch({
    type: SITES_NEWSITE_UPDATE,
    payload: details,
  });
};

// Creates a new site
export const NewSiteSave = (details) => async (dispatch) => {
  try {
    dispatch({
      type: SITES_NEWSITE_SAVE,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // send the data to the endpoint to get saved
    await axios.post("/api/sites", { data: details });

    dispatch({
      type: SITES_CLEARALL,
    });
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: SITES_NEWSITE_SAVE,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: SITES_NEWSITE_SAVE,
        payload: false,
      });
    }
  }
};

// Open add floors modal
export const ModalAddFloorsOpen = () => (dispatch) => {
  dispatch({
    type: SITES_MODAL_ADDFLR_OPEN,
  });
};

// Close add floors modal
export const ModalAddFloorsClose = () => (dispatch) => {
  dispatch({
    type: SITES_MODAL_ADDFLR_CLOSE,
  });
};

// delets a specific site, needs the user object id
export const DeleteSpecificSite = (siteID) => async (dispatch) => {
  try {
    if (siteID) {
      dispatch({
        type: SITES_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the site
      await axios.delete("/api/sites/" + siteID);

      // get the basic sites information
      let res = await axios.get("/api/sites");

      dispatch(setAlert("Site is deleted successful", "success"));

      dispatch({
        type: SITES_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: SITES_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: SITES_LOADING,
        payload: false,
      });
    }
  }
};

// updates a specific site data, needs the user object id
export const UpdateSpecificSite = (siteID, siteData) => async (dispatch) => {
  try {
    if (siteID) {
      dispatch({
        type: SITES_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.put("/api/sites/" + siteID, { siteData });

      // get the sites information
      res = await axios.get("/api/sites");

      dispatch(setAlert("Site details saved", "success"));

      dispatch({
        type: SITES_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: SITES_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: SITES_LOADING,
        payload: false,
      });
    }
  }
};
