import {
  USERS_CLEARALL,
  USERS_EDIT,
  USERS_EDIT_CLEAR,
  USERS_EDIT_LOADING,
  USERS_GET_ALL,
  USERS_GET_PROFILEPIC,
  USERS_LOADING,
  USERS_MODAL_PERMADD_CLOSE,
  USERS_MODAL_PERMADD_OPEN,
  USERS_NEWSITE_UPDATE,
  USERS_NEWSITE_SAVE,
  USERS_MODAL_GROUPADD_CLOSE,
  USERS_MODAL_GROUPADD_OPEN,
  USERS_MODAL_SITESADD_OPEN,
  USERS_MODAL_SITESADD_CLOSE,
  USERS_MODAL_SITEADD_TABLE_ALL,
  USERS_MODAL_SITEADD_TABLE,
  USERS_MODAL_SITEADD,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  editLoading: false,
  modalAddPermVis: false,
  modalAddGroupsVis: false,
  modalAddSitesVis: false,
  profilePic: null,
  newUserData: null,
  newUserSaving: false,
  addSiteTableLoaded: false,
  addSiteTableLoadedALL: false,
  newSite: null,
};

export default function users(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case USERS_NEWSITE_SAVE:
      return { ...state, newUserSaving: payload };
    case USERS_NEWSITE_UPDATE:
      return { ...state, newUserData: payload };
    case USERS_GET_PROFILEPIC:
      return { ...state, profilePic: payload };
    case USERS_MODAL_PERMADD_OPEN:
      return { ...state, modalAddPermVis: true };
    case USERS_MODAL_GROUPADD_OPEN:
      return { ...state, modalAddGroupsVis: true };
    case USERS_MODAL_SITESADD_OPEN:
      return { ...state, modalAddSitesVis: true };
    case USERS_MODAL_SITESADD_CLOSE:
      return { ...state, modalAddSitesVis: false };
    case USERS_MODAL_SITEADD_TABLE:
      return { ...state, addSiteTableLoaded: payload };
    case USERS_MODAL_SITEADD_TABLE_ALL:
      return { ...state, addSiteTableLoadedALL: payload };
    case USERS_MODAL_SITEADD:
      return { ...state, newSite: payload };
    case USERS_MODAL_GROUPADD_CLOSE:
      return { ...state, modalAddGroupsVis: false };
    case USERS_MODAL_PERMADD_CLOSE:
      return { ...state, modalAddPermVis: false };
    case USERS_EDIT_CLEAR:
      return { ...state, editLoading: false, editData: null };
    case USERS_EDIT_LOADING:
      return { ...state, editLoading: payload };
    case USERS_EDIT:
      return { ...state, editData: payload };
    case USERS_LOADING:
      return { ...state, loading: payload };
    case USERS_GET_ALL:
      return { ...state, data: payload, loading: false };
    case USERS_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
