export class ColorHelpers{

    getTaskPriorityColor(priority: string): string{

        let response: string = '';

        switch (priority){
            case 'High':
                response = '#b71c1c';
                break;
            case 'Medium':
                response = '#e65100';
                break;
            case 'Low':
                response = '#0d47a1';
                break;
            default:
                response = '#03a9f4';
                break;
        }

        return response;

    }

    getTaskStatusColor(status: string): string{
        let response: string = '';

        switch (status){
            case 'Pending':
                response = '#FFC107';
                break;
            case 'In Progress':
                response = '#4CAF50';
                break;
            case 'Completed':
                response = '#536DFE';
                break;
            case 'Closed':
                response = '#CFD8DC';
                break;
            case 'Overdue':
                response = '#F44336';
                break;

            default:
                response = '#03a9f4';
                break;
        }

        return response;


    }

}
