import {Button, List, Modal, Tag} from "antd";
import React from "react";

const AddCollaboratorsModal = ( props: {
    open: boolean, onCancel: any, onOkay: any, onAddCollaborator: any, users: any[]
})  => {

    return(
        <Modal title="Select Collaborators"
               visible={props.open}
               width={700}
               bodyStyle={{
                   width: '100%',
                   height: '500px'
               }}
               okText={'Done'}
               onOk={ () => {
                   props.onOkay();
               }}
               onCancel={ () => {
                   props.onCancel();
               }}>
            <div className="collaborators-selection-container">
                <List
                    bordered={false}
                    dataSource={props.users}
                    renderItem={ (user: any) => (
                        <List.Item actions={[
                            <Button
                                key={user._id}
                                type={'link'}
                                onClick={ () => {
                                    props.onAddCollaborator({
                                        id: user._id,
                                        user_name: `${user.name} ${user.surname}`,
                                        email_address: user.email_address
                                    });
                                }}>
                                Add
                            </Button>
                        ]}>
                            <List.Item.Meta
                                title={<span>{user.name + ' ' + user.surname}</span>}
                                description={user.email_address}
                            />
                            <Tag>
                                <small>
                                    {user.client_name}
                                </small>
                            </Tag>
                        </List.Item>
                    )}
                />
            </div>
        </Modal>
    );

}

export default AddCollaboratorsModal;
