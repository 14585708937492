import "./taskManagement.scss";
import {
  PageHeader,
  Card,
  Button,
  Divider,
  Calendar,
  Badge,
  Tag,
  Tabs,
  Avatar,
  Empty,
} from "antd";

import {
  CalendarFilled,
  ClockCircleOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useCallback } from "react";
import TaskListView from "./components/taskListView";
import { ITask } from "./interfaces/ITask";
import { connect } from "react-redux";
import {
  getAllClientTasks,
  getTaskAttachments,
  getTaskChat,
  getTasksAsAdmin,
  getUserTasks,
  setSelectedTask,
} from "../../actions/tasks";
import { loadAllUsers } from "../../actions/users";
//@ts-ignore
import { useHistory } from "react-router-dom";
import { ColorHelpers } from "./helpers/color.helpers";
import moment, { Moment } from "moment";
import { loadAllDepartements } from "../../actions/staffManagement/department";
import { ISimpleUser } from "../../interfaces/shared/ISimpleUser";

import { ReactComponent as EmptyImg } from "../../components/images/empty.svg";

const TaskManagement = (props: {
  isAdmin: boolean;
  getTasksAsAdmin: Function;
  getAllClientTasks: Function;
  getUserTasks: Function;
  userDetails: any;
  clientTasks: ITask[];
  users: any[];
  loadAllUsers: Function;
  setSelectedTask: Function;
  getTaskAttachments: Function;
  getTaskChat: Function;
  loaded: boolean;
  loadAllDepartements: Function;
}) => {
  const {
    loadAllDepartements,
    loadAllUsers,
    getUserTasks,
    getTasksAsAdmin,
    users,
    userDetails,
    clientTasks,
  } = props;
  let history = useHistory();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tasksDueOnDay, setTasksDueOnDay] = useState<Array<ITask>>([]);

  const getListData = (value: Moment): ITask[] => {
    return props.clientTasks.filter((task: ITask) =>
      moment(task.due_date).isSame(value, "day")
    );
  };
  const dateCellRender = (value: Moment) => {
    const tasks = getListData(value);
    return (
      <ul className="calendar-events">
        {tasks.map((task: ITask) => (
          <li key={`calendar-item-in-calendar-${task._id}`}>
            <Badge
              color={
                moment(task.due_date).isSame(moment(), "day") ||
                moment(task.due_date).isBefore(moment(), "day")
                  ? "#be1e2d"
                  : "#a2a2a2"
              }
              text={task.title}
            ></Badge>
          </li>
        ))}
      </ul>

      // <Badge style={{width: '100%'}}
      //        showZero={false}
      //        count={  itemCount > 0
      //            ? <Tag color={'#b71c1c'}>{itemCount} task{itemCount > 1 ? 's' : ''}</Tag>
      //            : itemCount } />
    );
  };

  const _getTasksDueOnDay = useCallback(
    (date: Moment) => {
      if (clientTasks !== null) {
        let tasks = clientTasks.filter((task: ITask) =>
          moment(task.due_date).isSame(date, "day")
        );

        setTasksDueOnDay(tasks);
      }
    },
    [clientTasks]
  );

  useEffect(() => {
    _getTasksDueOnDay(moment(selectedDate));
  }, [_getTasksDueOnDay, selectedDate]);

  useEffect(() => {
    loadAllDepartements();

    if (users === null) {
      loadAllUsers();
    }

    if (userDetails.role.name !== "Administrator") {
      getUserTasks(userDetails._id);
    } else {
      getTasksAsAdmin(userDetails._id, userDetails.client_id);
    }
  }, [
    loadAllDepartements,
    loadAllUsers,
    getUserTasks,
    getTasksAsAdmin,
    users,
    userDetails,
  ]);

  const setSelectedTask = (task: ITask) => {
    props.setSelectedTask(task);
    props.getTaskAttachments(task._id);
    props.getTaskChat(task._id);
    history.push("/admin/tasks/view");
    // useNavigate('/admin/tasks/view');
  };

  return !props.loaded ? (
    <></>
  ) : (
    <div className="task-dashboard-container">
      <Card className="task-list-area">
        <PageHeader
          avatar={{ icon: <CalendarFilled /> }}
          className="no-padding"
          title="Tasks"
          extra={[
            <Button
              key={"create-task-action"}
              type={"primary"}
              onClick={() => {
                history.push("/admin/tasks/create");
              }}
            >
              <PlusCircleOutlined /> Create a Task
            </Button>,
          ]}
        />
        <Tabs defaultActiveKey="1" onChange={() => {}} style={{ padding: "0" }}>
          <Tabs.TabPane
            tab={
              <span>
                <CalendarOutlined /> Calendar
              </span>
            }
            key="calendar-tab"
          >
            <div className="task-calendar-section">
              <div className="calendar">
                <Calendar
                  fullscreen={true}
                  onChange={(date: Moment) => {
                    setSelectedDate(new Date(date.toISOString()));
                    _getTasksDueOnDay(date);
                  }}
                  dateCellRender={dateCellRender}
                />
              </div>

              <div className="calendar-list-section">
                <Divider orientation="left">
                  <strong>Tasks due on</strong>{" "}
                  <small>
                    {moment(selectedDate).format("dddd DD MMM YYYY")}
                  </small>
                </Divider>
                {tasksDueOnDay.length > 0 ? (
                  tasksDueOnDay.map((task: ITask) => (
                    <Badge.Ribbon
                      key={"calendar-list-section-task-" + task._id}
                      text={task.priority}
                      color={new ColorHelpers().getTaskPriorityColor(
                        task.priority
                      )}
                    >
                      <Card
                        onClick={() => {
                          setSelectedTask(task);
                        }}
                        style={{ marginBottom: ".3em" }}
                        bodyStyle={{
                          padding: "1em",
                        }}
                        hoverable
                        title={task.title}
                      >
                        <div
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <small>
                            <Badge
                              color={new ColorHelpers().getTaskStatusColor(
                                task.status
                              )}
                            />{" "}
                            {task.status}
                          </small>
                          <small>
                            <Tag>{task.type}</Tag>
                          </small>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "small",
                              color:
                                moment(task.due_date).isSame(moment(), "day") ||
                                moment(task.due_date).isBefore(moment(), "day")
                                  ? "#be1e2d"
                                  : "#a2a2a2",
                            }}
                          >
                            <ClockCircleOutlined />{" "}
                            {moment(task.due_date).format("dddd DD MMM YYYY")}
                          </span>
                          <small>
                            <Avatar.Group
                              maxCount={2}
                              size={"default"}
                              maxStyle={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                              }}
                            >
                              <Avatar
                                key={"assigned-to-avatar"}
                                style={{
                                  backgroundColor: "#f56a00",
                                  padding: "auto 1em",
                                }}
                              >
                                <span>
                                  {task.assigned_to?.user_name &&
                                    task.assigned_to.user_name.split(
                                      " "
                                    )[0][0] +
                                      " " +
                                      task?.assigned_to.user_name.split(
                                        " "
                                      )[1][0]}
                                </span>
                              </Avatar>
                              {task.collaborators.map(
                                (collaborator: ISimpleUser, index: number) => (
                                  <Avatar
                                    key={"collaborator-to-avatar-" + index}
                                    style={{
                                      backgroundColor: "#1890ff",
                                      padding: "auto 1em",
                                    }}
                                  >
                                    <span>
                                      {collaborator.user_name.split(" ")[0][0] +
                                        " " +
                                        collaborator.user_name.split(" ")[1][0]}
                                    </span>
                                  </Avatar>
                                )
                              )}
                            </Avatar.Group>
                          </small>
                        </div>
                      </Card>
                    </Badge.Ribbon>
                  ))
                ) : (
                  <Empty
                    image={<EmptyImg />}
                    imageStyle={{
                      height: 60,
                    }}
                    description={
                      <span>There are no tasks due on this day</span>
                    }
                  ></Empty>
                )}
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span>
                <UnorderedListOutlined /> List
              </span>
            }
            key="task-list-tab"
          >
            <div className="task-list">
              <TaskListView
                tasks={props.clientTasks}
                isLoading={props.loaded}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
        {/*<Divider/>*/}
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isAdmin: state.auth.isAdmin,
  userDetails: state.auth.user,
  clientTasks: state.tasks.tasks,
  loaded: state.tasks.loaded,
  users: state.users.data,
});

export default connect(mapStateToProps, {
  getTasksAsAdmin,
  getAllClientTasks,
  getUserTasks,
  loadAllUsers,
  setSelectedTask,
  getTaskAttachments,
  getTaskChat,
  loadAllDepartements,
})(TaskManagement);
