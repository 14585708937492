import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

// antd componet
import { message, PageHeader, Select, Space, Tabs } from "antd";

// External componets
// import ClientIndex from "./ClientIndex";
import TagIndex from "./Tagpower";
import MeterIndex from "./MeterIndex";
import CustomIndex from "./Custom/CustomIndex";
import KwHourly from "./KwHourly/index";
import MeterSummary from "./Summary/index";

// external componets
import { logout } from "../../../../actions/auth";

// actual compoent
const LandingIndex = ({ pageWidth, logout }) => {
  const [selectedTab, setselectedTab] = useState("1");
  const [selectedSite, setSelectedSite] = useState(null);
  const [clientsIDList, setClientsIDList] = useState(null);

  const GetClientsList = useCallback(async () => {
    try {
      if (
        sessionStorage.getItem("email") === null ||
        sessionStorage.getItem("ppps") === null
      ) {
        message.error("Session expired, please log in");
        return logout();
      }

      const rec = await axios.post(
        `https://noderedinternal.cloudbms.co.za/api/OADC/sites`,
        {
          email: sessionStorage.getItem("email"),
          password: sessionStorage.getItem("ppps"),
        }
      );

      setClientsIDList(rec.data);
    } catch (error) {
      console.log("GetClientsList, landing Index, ", error);
      message.error("Client list loading error");
    }
  }, [logout]);

  useEffect(() => {
    GetClientsList();
  }, [GetClientsList]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Power Report"
        subTitle={pageWidth >= 795 && "Get different power report"}
        extra={[
          <Space>
            <p style={{ marginTop: 10 }}>Site:</p>
            <Select
              style={{ width: "200px" }}
              key="NewClient"
              disabled={clientsIDList === null}
              onChange={setSelectedSite}
            >
              {clientsIDList &&
                clientsIDList.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item._id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Space>,
        ]}
      ></PageHeader>

      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        onChange={(e) => setselectedTab(e)}
      >
        <Tabs.TabPane tab="Tags" key="1">
          <TagIndex
            pageWidth={pageWidth}
            selectedTab={selectedTab}
            selectedSite={selectedSite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Meter" key="2">
          <MeterIndex
            pageWidth={pageWidth}
            selectedTab={selectedTab}
            selectedSite={selectedSite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Custom" key="3">
          <CustomIndex
            pageWidth={pageWidth}
            selectedTab={selectedTab}
            selectedSite={selectedSite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="kW Hourly" key="4">
          <KwHourly
            pageWidth={pageWidth}
            selectedTab={selectedTab}
            selectedSite={selectedSite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Summary" key="5">
          <MeterSummary
            pageWidth={pageWidth}
            selectedTab={selectedTab}
            selectedSite={selectedSite}
          />
        </Tabs.TabPane>
      </Tabs>
    </Fragment>
  );
};

LandingIndex.propTypes = {
  isAdmin: PropTypes.bool,
  pageWidth: PropTypes.number,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pageWidth: state.misc?.width,
  isAdmin: state.auth.isAdmin,
});

export default React.memo(connect(mapStateToProps, { logout })(LandingIndex));
