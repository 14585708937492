import "./styles.scss";
import { IChat, IMessage } from "./interfaces/IChat";
import { connect } from "react-redux";
import { Avatar, Button, Divider, PageHeader, Comment, InputRef } from "antd";
import { LeftOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { ISimpleUser } from "../../interfaces/shared/ISimpleUser";
import TextArea from "antd/es/input/TextArea";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import moment from "moment";
import {
  clearChatMessages,
  getChatMessages,
  newMessageReceived,
  sendMessage,
} from "../../actions/chat";
import { ChatMessagesSocketContext } from "../../ChatMessagesSocketContext";

// const { Text } = Typography;

const ChatMessages = (props: {
  currentUser: any;
  chat: IChat;
  selectedChatMessages: IMessage[];
  hasLoadedChatMessages: boolean;
  onBack: any;
  sendMessage: any;
  getChatMessages: Function;
  newMessageReceived: Function;
  clearChatMessages: Function;
}) => {
  const { clearChatMessages } = props;
  const chatSocket = useContext(ChatMessagesSocketContext);
  const [messageText, setMessageText] = useState("");
  const inputRef = useRef<InputRef>(null);

  const scrollToBottom = useCallback((timer = 500) => {
    setTimeout(() => {
      if (document.getElementById("chat-message-list") !== null) {
        //@ts-ignore
        let elementHeight =
          document.getElementById("chat-message-list")?.scrollHeight;
        //@ts-ignore
        document.getElementById("chat-message-list").scrollTo({
          top: elementHeight ?? 0 + 200,
          behavior: "smooth",
        });
      }
    }, timer);
  }, []);

  const sendMessage = () => {
    const newMessage: IMessage = {
      _id: "",
      from: {
        id: props.currentUser._id,
        user_name: `${props.currentUser.name} ${props.currentUser.surname}`,
        email_address: props.currentUser.email_address,
      },
      chat_id: props.chat._id,
      message: messageText,
      created_at: new Date(),
    };

    // props.newMessageReceived(newMessage);
    props.sendMessage(newMessage);
    setMessageText("");
    props.getChatMessages(props.chat._id);
    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom(20);
  }, [props.selectedChatMessages, scrollToBottom]);

  useEffect(() => {
    scrollToBottom();
    chatSocket.emit("join_chat", props.chat._id, props.currentUser._id);

    inputRef.current!.focus({
      cursor: "start",
    });

    return () => {
      chatSocket.emit("leave_chat", props.chat._id, props.currentUser._id);
    };
  }, [
    chatSocket,
    props.chat._id,
    props.currentUser._id,
    clearChatMessages,
    scrollToBottom,
  ]);

  return (
    <div className="chat-messages-container">
      <PageHeader
        style={{ paddingBottom: 0 }}
        backIcon={<LeftOutlined />}
        onBack={() => {
          props.onBack();
        }}
        title={
          <>
            <Avatar
              style={{ backgroundColor: "#001528" }}
              icon={
                props.chat.type === "personal" ? (
                  <UserOutlined />
                ) : (
                  <TeamOutlined />
                )
              }
            />{" "}
            {props.chat.type === "personal" ? (
              <small>
                {
                  //@ts-ignore
                  props.chat.members.find(
                    (member: ISimpleUser) => member.id !== props.currentUser._id
                  ).user_name
                }
              </small>
            ) : (
              <small>{props.chat.name}</small>
            )}
          </>
        }
      />
      <Divider style={{ margin: 0 }} />
      <div className="chat-message-list" id={"chat-message-list"}>
        {props.selectedChatMessages.map((message: IMessage) => (
          <Comment
            key={"ChatMessage-" + message._id}
            author={
              <>
                {props.currentUser._id === message.from.id ? (
                  "You"
                ) : (
                  <span>{message.from.user_name}</span>
                )}
              </>
            }
            avatar={
              <Avatar
                style={{ backgroundColor: "#001528" }}
                icon={<UserOutlined />}
              />
            }
            content={<p>{message.message}</p>}
            datetime={<span>{moment(message.created_at).fromNow()}</span>}
            style={
              props.currentUser._id === message.from.id
                ? { direction: "rtl" }
                : {}
            }
          />
        ))}
      </div>
      <div className="text-box-container">
        <TextArea
          ref={inputRef}
          rows={2}
          placeholder={"Write a message..."}
          className={"chat-text-area"}
          value={messageText}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setMessageText(e.target.value);
          }}
        />
        <div className="text-box-container-actions">
          <Button
            disabled={messageText === ""}
            type={"primary"}
            shape="round"
            onClick={sendMessage}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  selectedChatMessages: state.chat.selectedChatMessages,
  hasLoadedChatMessages: state.chat.hasLoadedChatMessages,
});

export default connect(mapStateToProps, {
  sendMessage,
  getChatMessages,
  newMessageReceived,
  clearChatMessages,
})(ChatMessages);
