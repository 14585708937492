import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

// antd component
import { Card, Form, Input, Button, Select } from "antd";

// external functions
import { NewUserUpdate } from "../../../../../actions/users";

const { Option } = Select;

// actual componet
const UserDetails = ({
  current,
  setCurrent,
  isAdmin,
  NewUserUpdate,
  newUserData,
  pageWidth,
  availableRoles,
  availableDepartments,
  availableDesignations,
}) => {
  const inputRef = useRef(null);
  const [formDetails] = Form.useForm();
  const [fromData, setFromData] = useState(null);
  const staffRegex = RegExp(/staff/, "gi");

  if (fromData === null && newUserData) {
    setFromData(newUserData);

    formDetails.setFieldsValue({ name: newUserData.name });
    formDetails.setFieldsValue({ surname: newUserData.surname });
    formDetails.setFieldsValue({ email_address: newUserData.email_address });
    formDetails.setFieldsValue({
      contact_number: newUserData.contact_number,
    });
    formDetails.setFieldsValue({ role: newUserData.role.name });
  }

  if (fromData === null) {
    setTimeout(() => {
      inputRef.current?.focus({
        cursor: "start",
      });
    }, 100);
  }

  const handleNext = () => {
    const data = formDetails.getFieldsValue();

    // format the data
    data.name = data.name.trim();
    data.surname = data.surname.trim();
    data.email_address = data.email_address.toLowerCase().trim();
    data.contact_number.phone = parseInt(data.contact_number.phone);
    data.staff = {
      department: data.department,
      designation: data.designation,
      extension_number: data.extension_number,
    };

    const roleData = availableRoles.filter((item) => item.name === data.role);

    let currentUserData = { ...newUserData };

    currentUserData.role = {
      ...roleData[0],
    };

    delete data.role;
    delete data.department;
    delete data.designation;
    delete data.extension_number;

    setCurrent(current + 1);

    NewUserUpdate({ ...currentUserData, ...data });
    setFromData(null);
  };

  return (
    <Card
      title={
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
          Basic Information
        </p>
      }
      extra={[
        <Button
          type="primary"
          onClick={() => formDetails.validateFields().then(() => handleNext())}
        >
          Next
        </Button>,
      ]}
      style={{ marginTop: pageWidth > 795 ? "15px" : "5px", width: "100%" }}
    >
      <ConfigProvider locale={en}>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 5 }}
          size={pageWidth >= 795 ? "middle" : "small"}
          form={formDetails}
          onValuesChange={(_, all) => {
            setFromData(all);
          }}
        >
          <Form.Item
            label={<span style={{ color: "black" }}>Name</span>}
            name={"name"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            rules={[
              {
                required: true,
                message: "Please input a valid name!",
              },
            ]}
          >
            <Input ref={inputRef} />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "black" }}>Surname</span>}
            name={"surname"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            rules={[
              {
                required: true,
                message: "Please input a valid surname!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "black" }}>Email Address</span>}
            name={"email_address"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your email address!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "black" }}>Contact Number</span>}
            name={"contact_number"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            initialValue={{
              short: "za",
            }}
            rules={[
              {
                message: "Mobile number cannot be blank",
                validator: (_, value) => {
                  if (value.phone) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Mobile number cannot be blank");
                  }
                },
              },
            ]}
          >
            <CountryPhoneInput />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "black" }}>User Role</span>}
            name={"role"}
            style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
            rules={[
              {
                required: true,
                message: "Please select a role!",
              },
            ]}
          >
            {isAdmin ? (
              <Select allowClear showSearch>
                {availableRoles?.map((role) => (
                  <Option key={role._id} value={role.name}>
                    {role.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <Select allowClear showSearch>
                {availableRoles?.map(
                  (role) =>
                    role.name !== "Administrator" &&
                    staffRegex.exec(role.name) === null &&
                    role.name !== "Finance Administrator" && (
                      <Option key={role._id} value={role.name}>
                        {role.name}
                      </Option>
                    )
                )}
              </Select>
            )}
          </Form.Item>
          {fromData?.role &&
            (staffRegex.exec(fromData?.role) !== null ||
              fromData?.role === "Administrator") && (
              <Fragment>
                <Form.Item
                  label={<span style={{ color: "black" }}>Designation</span>}
                  name={"designation"}
                  style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a correct designation!",
                    },
                  ]}
                >
                  <Select allowClear showSearch>
                    {availableDesignations?.map((designation) => (
                      <Option key={designation._id} value={designation.name}>
                        {designation.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<span style={{ color: "black" }}>Department</span>}
                  name={"department"}
                  style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a correct department!",
                    },
                  ]}
                >
                  <Select allowClear showSearch>
                    {availableDepartments?.map((department) => (
                      <Option key={department._id} value={department.name}>
                        {department.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {/* Extension number */}
                <Form.Item
                  label={
                    <span style={{ color: "black" }}>Extension number</span>
                  }
                  name={"extension_number"}
                  style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
                  rules={[
                    {
                      required: false,
                      message: "Please select a correct department!",
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.length > 0) {
                          if (value.length === 4) {
                            return Promise.resolve(value);
                          } else {
                            return Promise.reject(
                              new Error("Incorrect extension number format")
                            );
                          }
                        } else {
                          return Promise.resolve(value);
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="0000" />
                </Form.Item>
              </Fragment>
            )}
        </Form>
      </ConfigProvider>
    </Card>
  );
};

UserDetails.propTypes = {
  NewUserUpdate: PropTypes.func.isRequired,
  newUserData: PropTypes.object,
  availableRoles: PropTypes.array,
  availableDesignations: PropTypes.array,
  availableDepartments: PropTypes.array,
};

const mapStateToProps = (state) => ({
  newUserData: state.users.newUserData,
  availableRoles: state.roles.data,
  availableDepartments: state.department.data,
  availableDesignations: state.designation.data,
});

export default connect(mapStateToProps, { NewUserUpdate })(UserDetails);
