import {
  NOTIF_CLEARALL,
  NOTIF_EDIT,
  NOTIF_GET_ALL,
  NOTIF_LOADING,
  NOTIF_MODAL_SITEADD,
  NOTIF_MODAL_SITEADD_CLOSE,
  NOTIF_MODAL_SITEADD_OPEN,
  NOTIF_MODAL_SITEADD_TABLE,
  NOTIF_MODAL_SITEADD_TABLE_ALL,
  NOTIF_MODAL_USERADD_CLOSE,
  NOTIF_MODAL_USERADD_OPEN,
} from "../actions/types";

const initalState = {
  loading: false,
  data: null,
  editData: null,
  addNewSiteModalVis: false,
  addNewUserModalVis: false,
  newSite: [],
  addSiteTableLoaded: false,
  addSiteTableLoadedALL: false,
};

export default function notiUsers(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case NOTIF_MODAL_SITEADD_TABLE_ALL:
      return { ...state, addSiteTableLoadedALL: payload };
    case NOTIF_MODAL_SITEADD_TABLE:
      return { ...state, addSiteTableLoaded: payload };
    case NOTIF_MODAL_SITEADD:
      return { ...state, newSite: payload };
    case NOTIF_MODAL_USERADD_OPEN:
      return { ...state, addNewUserModalVis: true };
    case NOTIF_MODAL_USERADD_CLOSE:
      return { ...state, addNewUserModalVis: false };
    case NOTIF_MODAL_SITEADD_OPEN:
      return { ...state, addNewSiteModalVis: true };
    case NOTIF_MODAL_SITEADD_CLOSE:
      return { ...state, addNewSiteModalVis: false };
    case NOTIF_EDIT:
      return { ...state, editData: payload };
    case NOTIF_LOADING:
      return { ...state, loading: payload };
    case NOTIF_GET_ALL:
      return { ...state, data: payload, loading: false };
    case NOTIF_CLEARALL:
      return {
        ...state,
        ...initalState,
      };
    default:
      return state;
  }
}
