import "./styles.scss";
import { ITask } from "../../interfaces/ITask";
import { Comment, Empty, Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";
import { IMessage } from "../../../chat/interfaces/IChat";

import { ReactComponent as EmptyImg } from "../../../../components/images/empty.svg";

const { Text } = Typography;

const TaskMessagesComponent = (props: {
  task: ITask;
  messages: IMessage[];
  currentUser: any;
}) => {
  return (
    <>
      {props.messages.length > 0 ? (
        props.messages.map((message: IMessage) => (
          <div
            key={message._id}
            className={`task-message-item ${
              message.from.id !== props.currentUser._id
                ? "sb14"
                : "sb13 message-from-you"
            }`}
          >
            <Comment
              author={
                <>
                  {props.currentUser._id === message.from.id ? (
                    "You"
                  ) : (
                    <span>{message.from.user_name}</span>
                  )}
                </>
              }
              content={
                <>
                  <Text type={"secondary"}>{message.message}</Text>
                  {/*<br/>*/}
                  {/*<small>*/}
                  {/*    <Text type="secondary" style={{fontSize: 'smaller'}}>*/}
                  {/*        {message.from.email_address}*/}
                  {/*    </Text>*/}
                  {/*</small>*/}
                </>
              }
              datetime={
                <Tooltip
                  title={moment(message.created_at).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                >
                  <span>{moment(message.created_at).fromNow()}</span>
                </Tooltip>
              }
            />
          </div>
        ))
      ) : (
        <Empty
          image={<EmptyImg />}
          //   imageStyle={{
          //     height: "400px",
          //   }}
          description={<Text type={"secondary"}>There are no messages</Text>}
        ></Empty>
      )}
    </>
  );
};

export default TaskMessagesComponent;
