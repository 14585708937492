import axios from "axios";
import { setAlert } from "./alert";
import { logout } from "./auth";
import {
  MISC_CHANGE_HEIGHT,
  MISC_CHANGE_WIDTH,
  MISC_GET_REPORTRULES,
  MISC_GET_REPORTRULES_LOADING,
  MISC_REPORTGEN_DATA,
  MISC_REPORTGEN_LOADING,
} from "./types";

export const ChangeWidth = (newWidth) => (dispatch) => {
  dispatch({
    type: MISC_CHANGE_WIDTH,
    payload: newWidth,
  });
};

export const ChangeHeight = (newHeight) => (dispatch) => {
  dispatch({
    type: MISC_CHANGE_HEIGHT,
    payload: newHeight,
  });
};

export const DeleteReportsRules = (ID) => async (dispatch) => {
  try {
    // get all reports
    await axios.delete("/api/reports/rules/" + ID);

    dispatch(setAlert("Report deleted succesfully", "success"));
    dispatch(GetReportsRules());
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));

      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
  }
};

export const GetReportsRules = () => async (dispatch) => {
  try {
    dispatch({
      type: MISC_GET_REPORTRULES_LOADING,
      payload: true,
    });

    // get all reports
    let res = await axios.get("/api/reports/rules");

    dispatch({
      type: MISC_GET_REPORTRULES,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: MISC_GET_REPORTRULES_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: MISC_GET_REPORTRULES_LOADING,
        payload: false,
      });
    }
  }
};

export const CreateReportsRules = (newReportRuleData) => async (dispatch) => {
  try {
    // get all reports
    await axios.post("/api/reports/rules", {
      data: {
        ...newReportRuleData,
      },
    });

    dispatch(setAlert("Report succesfully added", "success"));
    dispatch(GetReportsRules());
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));

      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
  }
};

export const UpdateReportRule = (ruelID, data) => async (dispatch) => {
  try {
    // get all reports
    await axios.put("/api/reports/rules/" + ruelID, {
      data: {
        ...data,
      },
    });

    dispatch(setAlert("Report updated", "success"));
    dispatch(GetReportsRules());
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));

      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
  }
};

export const ClearReportGenData = () => (dispatch) => {
  dispatch({
    type: MISC_REPORTGEN_DATA,
    payload: [],
  });
};

export const HandleGenerateReport =
  (dates, selectedMeter, tableData, tableAdditionalData) =>
  async (dispatch) => {
    try {
      if (dates && selectedMeter.length > 0) {
        dispatch({
          type: MISC_REPORTGEN_DATA,
          payload: [],
        });
        dispatch({
          type: MISC_REPORTGEN_LOADING,
          payload: true,
        });

        let allData = [];

        await Promise.all(
          selectedMeter.map(async (meterNames) => {
            let res = await axios.post(
              `https://noderedinternal.cloudbms.co.za/api/OADC/site/meter_energy`,
              {
                email: sessionStorage.getItem("email"),
                password: sessionStorage.getItem("ppps"),
                meter_id: meterNames,
                // date_from: dates.dateFrom,
                date_from: dates.dateFrom.split("T")[0],
                // date_to: dates.dateTO,
                date_to: dates.dateTO.split("T")[0],
              }
            );
            let meterStats = await axios.post(
              `https://noderedinternal.cloudbms.co.za/api/OADC/site/meter`,
              {
                email: sessionStorage.getItem("email"),
                password: sessionStorage.getItem("ppps"),
                meter_id: meterNames,
                // date_from: dates.dateFrom,
                date_from: dates.dateFrom.split("T")[0],
                // date_to: dates.dateTO,
                date_to: dates.dateTO.split("T")[0],
              }
            );

            let formatedData = {};

            if (res.data) {
              let tableData = [...res.data.values];
              let otherData = { ...meterStats?.data[0] };

              // add symbols
              if (otherData?.used_energy)
                otherData.used_energy += ` ${otherData.energy_symbol}`;
              if (otherData?.avg_voltage)
                otherData.avg_voltage += ` ${otherData.voltage_symbol}`;
              if (otherData?.avg_power)
                otherData.avg_power += ` ${otherData.power_symbol}`;
              if (otherData?.avg_apparent_power)
                otherData.avg_apparent_power += ` ${otherData.apparent_power_symbol}`;
              if (otherData?.avg_current)
                otherData.avg_current += ` ${otherData.current_symbol}`;

              // remove kak
              if (otherData?.device_name) delete otherData.device_name;
              if (otherData?.site_name) delete otherData.site_name;
              if (otherData?.client_id) delete otherData.client_id;
              if (otherData?.site_id) delete otherData.site_id;
              if (otherData?.values) delete otherData.values;
              if (otherData?.energy_symbol) delete otherData.energy_symbol;
              if (otherData?.voltage_symbol) delete otherData.voltage_symbol;
              if (otherData?.power_symbol) delete otherData.power_symbol;
              if (otherData?.apparent_power_symbol)
                delete otherData.apparent_power_symbol;
              if (otherData?.current_symbol) delete otherData.current_symbol;

              formatedData = {
                tableData,
                stats: otherData,
                meterName: meterNames,
              };

              allData.push({ ...formatedData });
            }

            return true;
          })
        );

        if (tableData.length > 0) {
          tableData.map((rulesData) => {
            if (rulesData.Operation === "Add") {
              let formatedData = {};

              if (rulesData?.Meters && rulesData.Meters.length > 1) {
                rulesData.Meters.map((selectedMetersData, index) => {
                  var meterIndex = allData.findIndex(
                    (item) => item.meterName === selectedMetersData
                  );

                  let tableDatas = JSON.parse(
                    JSON.stringify(allData[meterIndex].tableData)
                  );
                  let statsData = JSON.parse(
                    JSON.stringify(allData[meterIndex].stats)
                  );

                  // remove symbols
                  if (statsData?.used_energy)
                    statsData.used_energy = parseFloat(statsData.used_energy);

                  if (index === 0) {
                    formatedData = {
                      tableData: tableDatas,
                      stats: statsData,
                      fromRule: true,
                      meterName: rulesData.Title,
                    };
                  } else {
                    formatedData.stats.used_energy += statsData.used_energy;

                    formatedData.tableData.map((meterTableData) => {
                      let oldIndex = tableDatas.findIndex(
                        (newData) => newData.time === meterTableData.time
                      );

                      if (oldIndex === -1) {
                        return true;
                      }

                      return (meterTableData.value +=
                        tableDatas[oldIndex].value);
                    });
                  }

                  return true;
                });

                formatedData.stats.used_energy =
                  formatedData.stats.used_energy.toFixed(2) + " kWh";
                delete formatedData.stats.supply;
                delete formatedData.stats.avg_voltage;
                delete formatedData.stats.avg_power;
                delete formatedData.stats.avg_apparent_power;
                delete formatedData.stats.avg_current;
                delete formatedData.stats.max_power;
                delete formatedData.stats.max_va;

                allData.push({ ...formatedData });
              }
            }

            return true;
          });
        }

        if (tableAdditionalData.length > 0) {
          tableAdditionalData.map((rulesData) => {
            if (rulesData.Operation === "Add") {
              let formatedData = {};

              if (rulesData?.Rules && rulesData.Rules.length > 1) {
                rulesData.Rules.map((selectedRules, index) => {
                  var meterIndex = allData.findIndex(
                    (item) => item.meterName === selectedRules
                  );

                  let tableDatas = JSON.parse(
                    JSON.stringify(allData[meterIndex].tableData)
                  );
                  let statsData = JSON.parse(
                    JSON.stringify(allData[meterIndex].stats)
                  );

                  // remove symbols
                  if (statsData?.used_energy)
                    statsData.used_energy = parseFloat(statsData.used_energy);

                  if (index === 0) {
                    formatedData = {
                      tableData: tableDatas,
                      stats: statsData,
                      fromRule: true,
                      meterName: rulesData.Title,
                    };
                  } else {
                    formatedData.stats.used_energy += statsData.used_energy;

                    formatedData.tableData.map((meterTableData) => {
                      let oldIndex = tableDatas.findIndex(
                        (newData) => newData.time === meterTableData.time
                      );

                      if (oldIndex === -1) {
                        return true;
                      }

                      return (meterTableData.value +=
                        tableDatas[oldIndex].value);
                    });
                  }

                  return true;
                });

                formatedData.stats.used_energy =
                  formatedData.stats.used_energy.toFixed(2) + " kWh";
                delete formatedData.stats.supply;
                delete formatedData.stats.avg_voltage;
                delete formatedData.stats.avg_power;
                delete formatedData.stats.avg_apparent_power;
                delete formatedData.stats.avg_current;
                delete formatedData.stats.max_power;
                delete formatedData.stats.max_va;

                allData.push({ ...formatedData });
              }
            }

            return true;
          });
        }

        dispatch({
          type: MISC_REPORTGEN_DATA,
          payload: allData,
        });
      } else {
        dispatch(setAlert("Please choose a date range", "error"));
      }
    } catch (error) {
      console.log(error);
      dispatch(setAlert("😭 Error occurred !! 😭", "error"));
      dispatch({
        type: MISC_REPORTGEN_LOADING,
        payload: false,
      });
    }
  };
