import axios from "axios";
import { setAlert } from "../alert";
import setAuthToken from "../../utils/setAuthToken";
import { logout } from "../auth";
import {
  DESIG_CLEARALL,
  DESIG_EDIT,
  DESIG_GET_ALL,
  DESIG_LOADING,
  DESIG_MODAL_ADDNEW_CLOSE,
  DESIG_MODAL_ADDNEW_OPEN,
} from "../types";

// Loads all the Designations
export const loadAllDesignations = () => async (dispatch) => {
  try {
    dispatch({
      type: DESIG_LOADING,
      payload: true,
    });

    // get the all designations
    let res = await axios.get("/api/designation");

    dispatch({
      type: DESIG_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: DESIG_LOADING,
      payload: false,
    });
  }
};

// clear the state
export const clearState = () => (dispatch) => {
  dispatch({
    type: DESIG_CLEARALL,
  });
};

// view details of a specific designation
export const EditViewDesignation = (details) => (dispatch) => {
  dispatch({
    type: DESIG_EDIT,
    payload: details,
  });
};

// delets a specific role, needs the designation object id
export const DeleteSpecificDesignation =
  (designationID) => async (dispatch) => {
    try {
      if (designationID) {
        dispatch({
          type: DESIG_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // delete designation information
        let res = await axios.delete("/api/designation/" + designationID);

        // get the all designations
        res = await axios.get("/api/designation");

        dispatch(setAlert("Designation is deleted successful", "success"));

        dispatch({
          type: DESIG_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));
        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
      }
      dispatch({
        type: DESIG_LOADING,
        payload: false,
      });
    }
  };

// updates a specific designation, needs the designation object id
export const UpdateSpecificDesignation =
  (designationID, data) => async (dispatch) => {
    try {
      if (designationID) {
        dispatch({
          type: DESIG_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // delete the basic user information
        let res = await axios.put("/api/designation/" + designationID, {
          data,
        });

        // get the all designations
        res = await axios.get("/api/designation");

        dispatch(setAlert("Designation details saved", "success"));

        dispatch({
          type: DESIG_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log("Error: ", error.response);
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));
        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
      }
      dispatch({
        type: DESIG_LOADING,
        payload: false,
      });
    }
  };

// Opens the modal to add new desingation
export const ModalAddNewDesignationOpen = () => (dispatch) => {
  dispatch({
    type: DESIG_MODAL_ADDNEW_OPEN,
  });
};

// Closes the modal to add new desingation
export const ModalAddNewDesignationClose = () => (dispatch) => {
  dispatch({
    type: DESIG_MODAL_ADDNEW_CLOSE,
  });
};

// Create a new desingation
export const NewDesignation = (data) => async (dispatch) => {
  try {
    dispatch({
      type: DESIG_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // Send the data to create a new one
    await axios.post("/api/designation", {
      data,
    });

    // get the all designations
    let res = await axios.get("/api/designation");

    dispatch(setAlert("Desingation added", "success"));

    dispatch({
      type: DESIG_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));

      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: DESIG_LOADING,
      payload: false,
    });
  }
};
