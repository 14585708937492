import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd componet
import { Table, Typography } from "antd";

const { Paragraph } = Typography;

// actual componet
const TabUsedBy = ({ data, pageWidth }) => {
  const columns = [
    {
      title: "Maunfacture",
      key: "maunfacture",
      dataIndex: "maunfacture",
    },
    {
      title: "Model",
      key: "model",
      dataIndex: "model",
    },
  ];

  return (
    <Fragment>
      <Typography>
        <Paragraph>
          Below is a list of templates that are using the type
        </Paragraph>
      </Typography>

      <Table
        style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
        columns={columns}
        dataSource={data?.map((item, index) => {
          item.key = index;

          return item;
        })}
        size="small"
        pagination={{ pageSize: 50 }}
        bordered
        scroll={{
          x: "100%",
          y: pageWidth >= 768 ? "calc(100vh - 42em)" : "calc(100vh - 15em)",
        }}
      ></Table>
    </Fragment>
  );
};

TabUsedBy.propTypes = {
  data: PropTypes.array,
};

const mapStateToProps = (state) => ({
  data: state.types.editData?.usedBy,
});

export default connect(mapStateToProps)(TabUsedBy);
