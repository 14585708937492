import {
  CLEAR_TASK_CHAT_MESSAGES,
  CREATE_CHAT,
  GET_CHAT_MESSAGES,
  GET_TASK_CHAT_MESSAGES,
  GET_USER_CHAT_LIST,
  IS_CREATING_CHAT,
  NEW_CHAT_MESSAGE_RECEIVED,
  SENDING_MESSAGE,
  SET_HAS_LOADED_CHAT_MESSAGES,
} from "../actions/types";

const initialState = {
  chatList: [],
  selectedChatMessages: [],
  selectedTaskChatMessages: [],
  hasLoadedChatMessages: false,
  sendingMessage: false,
  isCreatingChat: false,
};

export default function chat(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_CHAT_LIST:
      return { ...state, chatList: payload };

    case GET_CHAT_MESSAGES:
      return { ...state, selectedChatMessages: payload };

    case GET_TASK_CHAT_MESSAGES:
      return { ...state, selectedTaskChatMessages: payload };

    case SET_HAS_LOADED_CHAT_MESSAGES:
      return { ...state, hasLoadedChatMessages: payload };

    case CLEAR_TASK_CHAT_MESSAGES:
      return { ...state, selectedTaskChatMessages: [] };

    case SENDING_MESSAGE:
      return { ...state, sendingMessage: payload };

    case IS_CREATING_CHAT:
      return { ...state, isCreatingChat: payload };
    case CREATE_CHAT:
      return { ...state };

    case NEW_CHAT_MESSAGE_RECEIVED:
      const index = state.selectedChatMessages.findIndex(
        (item) => item._id === payload._id
      );

      if (index !== -1) return { ...state };

      return {
        ...state,
        selectedChatMessages: [
          ...new Set([...state.selectedChatMessages, payload]),
        ],
      };
    default:
      return state;
  }
}
