import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd component
import { Card, Form, Input, Button } from "antd";

// external functions
import { NewSiteUpdate } from "../../../../../actions/sites";

// actual componet
const SiteDetails = ({
  current,
  setCurrent,
  newSiteData,
  NewSiteUpdate,
  pageWidth,
}) => {
  const [formContact] = Form.useForm();
  const [formData, setFormData] = useState(null);

  const handleNext = () => {
    const data = formContact.getFieldsValue();

    data.name = data.name.trim();
    data.phone = data.phone.trim();
    data.email = data.email.toLowerCase().trim();

    let currentSiteData = { ...newSiteData };

    currentSiteData.site_contact = {
      ...data,
    };

    setCurrent(current + 1);
    setFormData(null);
    NewSiteUpdate(currentSiteData);
  };

  if (formData === null && newSiteData?.site_contact) {
    setFormData(newSiteData.site_contact);

    formContact.setFieldsValue({ name: newSiteData.site_contact.name });
    formContact.setFieldsValue({ phone: newSiteData.site_contact.phone });
    formContact.setFieldsValue({ email: newSiteData.site_contact.email });
  }

  const handelDefault = () => {
    formContact.setFieldsValue({ name: "Matthew Bezuidenhout" });
    formContact.setFieldsValue({ phone: "+27117927230" });
    formContact.setFieldsValue({ email: "matthew@kva.co.za" });
  };

  return (
    <Card
      title={
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Site Contact</p>
      }
      extra={[
        <Button style={{ margin: "0 8px" }} onClick={() => handelDefault()}>
          Default
        </Button>,
        <Button
          style={{ margin: "0 8px" }}
          onClick={() => setCurrent(current - 1)}
        >
          Previous
        </Button>,
        <Button
          type="primary"
          onClick={() => formContact.validateFields().then(() => handleNext())}
        >
          Next
        </Button>,
      ]}
      style={{ marginTop: pageWidth > 795 ? "15px" : "5px", width: "100%" }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 5 }}
        size="small"
        form={formContact}
        onValuesChange={(_, all) => setFormData(all)}
      >
        <Form.Item
          label={<span style={{ color: "black" }}>Contact Name</span>}
          name={"name"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please input a vaild name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Contact Phone Number</span>}
          name={"phone"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              message: "Please input a valid number!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "black" }}>Contact Email Address</span>}
          name={"email"}
          style={{ marginBottom: pageWidth > 795 ? "5px" : "0px" }}
          rules={[
            {
              required: true,
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
      </Form>
    </Card>
  );
};

SiteDetails.propTypes = {
  newSiteData: PropTypes.object,
  NewSiteUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  newSiteData: state.sites.newSiteData,
});

export default connect(mapStateToProps, { NewSiteUpdate })(SiteDetails);
