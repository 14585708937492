import React, { Fragment, useState, useEffect, useCallback } from "react";
import moment from "moment";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant d
import { Tabs, PageHeader, Button, Space, DatePicker, message } from "antd";
import {
  PlusCircleOutlined,
  CloudDownloadOutlined,
  ClearOutlined,
  BarChartOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import * as XLSX from "xlsx";

// external compoonet
import TabNew from "./TabNew";
import TabSaved from "./TabSaved";

// external functions
import {
  ClearReportGenData,
  CreateReportsRules,
  GetReportsRules,
  HandleGenerateReport,
  UpdateReportRule,
} from "../../../../../actions/Misc";

const { RangePicker } = DatePicker;

// actual componet
const CustomIndex = ({
  pageWidth,
  selectedTab,
  userClientID,
  usersData,
  GetReportsRules,
  recData,
  loadingBtn,
  CreateReportsRules,
  ClearReportGenData,
  HandleGenerateReport,
  UpdateReportRule,
}) => {
  const [dates, setDates] = useState(null);
  const [metersListing, setMetersList] = useState([]);
  const [tabSelection, setTabSelection] = useState("New");
  const [selectedMeter, setSelectedMeter] = useState([]);

  const [metersListLoading, setMetersListLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableAdditionalData, setTableAdditionalData] = useState([]);
  const [workbookName, setWorkbookName] = useState("");

  const HandleDateChange = (value) => {
    if (value) {
      // const d = new Date(value[1]._d);
      const d = moment(value[1]._d);
      // d.setHours(0, 0, 0, 0);
      let dateTO = d.toISOString();

      // const dt = new Date(value[0]._d);
      const dt = moment(value[0]._d);
      // dt.setHours(0, 0, 0, 0);
      let dateFrom = dt.toISOString();

      setDates({ dateTO, dateFrom });
    } else {
      setDates(null);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const HandleDownload = () => {
    const workbook = XLSX.utils.book_new();

    recData.map((allItems) => {
      const worksheet = XLSX.utils.aoa_to_sheet([
        [`Date From`, `${dates.dateFrom.split("T")[0]}`],
        [`Date To`, `${dates.dateTO.split("T")[0]}`],
        ["Meter", `${allItems.meterName}`],
        [],
        [`Used Energy`, `${allItems.stats?.used_energy}\n`],
        ...(allItems.fromRule
          ? [["Note: this is the total of a summation of meters"]]
          : [
              ["Average Power", `${allItems.stats?.avg_power}`],
              ["Average Current", `${allItems.stats?.avg_current}`],
            ]),
      ]);

      allItems.tableData.map((data) => delete data.key);

      XLSX.utils.sheet_add_json(worksheet, allItems.tableData, {
        origin: allItems.fromRule ? "A8" : "A9",
      });
      //   const worksheet = XLSX.utils.json_to_sheet(allItems.tableData);
      /*  column width */
      worksheet["!cols"] = [{ wch: 20 }];

      /* fix headers */
      XLSX.utils.sheet_add_aoa(worksheet, [["Consumed", "Date"]], {
        origin: allItems.fromRule ? "A8" : "A9",
      });

      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        allItems.meterName.substring(
          0,
          allItems.meterName.length > 30 ? 30 : allItems.meterName.length
        )
      );

      return true;
    });

    let fileName =
      workbookName !== ""
        ? `${workbookName}-${new Date().toISOString()}.xlsx`
        : `Selected Meters Power readings ${new Date().toISOString()}.xlsx`;

    /* create an XLSX file and try to save to .xlsx */
    XLSX.writeFile(workbook, fileName, { compression: true });
  };

  const HandleClear = () => {
    ClearReportGenData();
    setSelectedMeter([]);
    setTableData([]);
    setTableAdditionalData([]);
    setWorkbookName("");
    setIsUpdate(false);
  };

  const HandleSave = () => {
    const saveData = {
      selectedMeter,
      tableData,
      tableAdditionalData,
      workbookName,
      userClientID,
      usersData,
    };

    if (isUpdate !== false) {
      UpdateReportRule(isUpdate, saveData);
      setIsUpdate(false);
      HandleClear();
      setTabSelection("Saved");
    } else {
      CreateReportsRules(saveData);
    }
  };

  const GetMetersList = useCallback(async () => {
    try {
      setMetersListLoading(true);
      const rec = await axios.get(
        `https://noderedinternal.cloudbms.co.za/api/OADC/site/power_meter_names?site_id=6343b7d1cd3624022cff2312`
      );

      setMetersList(rec.data);
      setMetersListLoading(false);
    } catch (error) {
      console.log("GetMetersList, ", error);
      message.error("Meters list error");
      setMetersListLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedTab === "3") {
      GetMetersList();

      if (tabSelection === "Saved") GetReportsRules();
    }
    // clean up the effect by removing it completely
    return () => {
      // setSelectedMeter([]);
      ClearReportGenData();
    };
  }, [
    GetMetersList,
    selectedTab,
    tabSelection,
    GetReportsRules,
    ClearReportGenData,
  ]);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title="Custom Power reports"
        subTitle={
          selectedTab === "New" ? (
            "Select a date range and a meter, then press generate"
          ) : (
            <div>
              Select a date range and press on the{" "}
              <BarChartOutlined style={{ color: "green" }} />
              to generate a report
            </div>
          )
        }
        extra={[
          ...(recData && recData?.length !== 0
            ? [
                <Button
                  key="btnDownload"
                  type="default"
                  icon={<CloudDownloadOutlined />}
                  onClick={HandleDownload}
                >
                  {pageWidth >= 795 && "Download"}
                </Button>,
                ...(tabSelection === "Saved"
                  ? [
                      <Button
                        key="btnClear"
                        type="default"
                        danger
                        icon={<ClearOutlined />}
                        onClick={HandleClear}
                      >
                        {pageWidth >= 795 && "Clear"}
                      </Button>,
                    ]
                  : []),
              ]
            : []),
          ...(tabSelection === "New"
            ? [
                <Button
                  key="btnGenerate"
                  type="primary"
                  disabled={
                    dates !== null && selectedMeter.length > 0 ? false : true
                  }
                  icon={<PlusCircleOutlined />}
                  onClick={() =>
                    HandleGenerateReport(
                      dates,
                      selectedMeter,
                      tableData,
                      tableAdditionalData,
                      workbookName
                    )
                  }
                >
                  {pageWidth >= 795 && "Generate"}
                </Button>,
              ]
            : []),

          ...(tabSelection === "New"
            ? [
                <Button
                  key="Save"
                  type="default"
                  icon={<SaveOutlined />}
                  onClick={HandleSave}
                  disabled={
                    selectedMeter.length > 0 && workbookName !== ""
                      ? false
                      : true
                  }
                >
                  {pageWidth >= 795 && isUpdate !== false ? "Update" : "Save"}
                </Button>,
                <Button
                  key="btnClear"
                  type="default"
                  danger
                  icon={<ClearOutlined />}
                  onClick={HandleClear}
                >
                  {pageWidth >= 795 && "Clear"}
                </Button>,
              ]
            : []),
        ]}
      >
        <Space>
          <RangePicker
            onChange={HandleDateChange}
            disabled={loadingBtn}
            disabledDate={disabledDate}
          />
        </Space>
      </PageHeader>
      <Tabs
        onChange={(e) => setTabSelection(e)}
        defaultActiveKey="New"
        activeKey={tabSelection}
      >
        <Tabs.TabPane tab={"New"} key={"New"}>
          <TabNew
            metersListLoading={metersListLoading}
            recData={recData}
            metersListing={metersListing}
            selectedMeter={selectedMeter}
            setSelectedMeter={setSelectedMeter}
            tableData={tableData}
            setTableData={setTableData}
            workbookName={workbookName}
            setWorkbookName={setWorkbookName}
            tableAdditionalData={tableAdditionalData}
            setTableAdditionalData={setTableAdditionalData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Saved"} key={"Saved"}>
          <TabSaved
            metersListLoading={metersListLoading}
            recData={recData}
            setSelectedMeter={setSelectedMeter}
            setTableData={setTableData}
            setTableAdditionalData={setTableAdditionalData}
            setWorkbookName={setWorkbookName}
            setTabSelection={setTabSelection}
            date={dates}
            setIsUpdate={setIsUpdate}
          />
        </Tabs.TabPane>
      </Tabs>
    </Fragment>
  );
};

CustomIndex.propTypes = {
  pageWidth: PropTypes.number,
  usersData: PropTypes.string,
  userClientID: PropTypes.string,
  GetReportsRules: PropTypes.func.isRequired,
  ClearReportGenData: PropTypes.func.isRequired,
  CreateReportsRules: PropTypes.func.isRequired,
  UpdateReportRule: PropTypes.func.isRequired,
  HandleGenerateReport: PropTypes.func.isRequired,
  loadingBtn: PropTypes.bool,
  recData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  pageWidth: state.misc?.width,
  loadingBtn: state.misc?.reportGenLoading,
  recData: state.misc?.reportGenData,
  usersData: state.auth?.user?._id,
  userClientID: state.auth?.user?.client_id,
});

export default connect(mapStateToProps, {
  GetReportsRules,
  CreateReportsRules,
  ClearReportGenData,
  HandleGenerateReport,
  UpdateReportRule,
})(CustomIndex);
