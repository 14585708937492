import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Row, Col, Card, Statistic } from "antd";
import { FundViewOutlined } from "@ant-design/icons";
import { Fragment } from "react";

// actual component
const Stats = ({ userData, pageWidth }) => {
  const OtherStats = Object.keys(userData?.analytics).filter(
    (keyText) =>
      !keyText.includes("Clientzone") &&
      !keyText.match(/Last/g) &&
      !keyText.match(/last/g) &&
      !keyText.includes("Service Desk") &&
      !keyText.includes("successfullLogins") &&
      !keyText.includes("unsuccessfullLogins")
  );

  console.log(OtherStats);
  console.log(userData?.analytics);
  return (
    <Card
      title={
        <span>
          <FundViewOutlined /> User Stats
        </span>
      }
      bordered={false}
    >
      <div style={{ overflowY: "auto", height: "400px" }}>
        <Row>
          <Col xs={24} md={12}>
            <Statistic
              title="ClientZone successful logins"
              value={userData?.analytics?.successfullLogins}
            />
          </Col>
          <Col xs={24} md={12}>
            <Statistic
              title="Last ClientZone login"
              value={new Date(
                userData?.analytics?.lastSuccessfullLogin
              ).toLocaleString()}
            />
          </Col>
          <Col xs={24} md={12}>
            <Statistic
              title="ClientZone Failed logins"
              value={userData?.analytics?.unsuccessfullLogins}
            />
          </Col>
          <Col xs={24} md={12}>
            <Statistic
              title="ClientZone failed login"
              value={new Date(
                userData?.analytics?.lastUnsuccessfullLogin
              ).toLocaleString()}
            />
          </Col>
          {pageWidth >= 795 && (
            <Fragment>
              <Col span={24}>
                <Statistic
                  title="ClientZone logins this week"
                  value={userData?.analytics?.successfullLoginsWK}
                />
              </Col>
              <Col span={24}>
                <Statistic
                  title="ClientZone logins this month"
                  value={userData?.analytics?.successfullLoginsMth}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Service Desk Logins"
                  value={
                    userData?.analytics?.["Service Desk"] === undefined
                      ? 0
                      : userData?.analytics?.["Service Desk"]
                  }
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Last Service Desk Login"
                  value={new Date(
                    userData?.analytics?.["LastLoginService Desk"] === undefined
                      ? 0
                      : userData?.analytics?.["LastLoginService Desk"]
                  ).toLocaleString()}
                />
              </Col>
              {OtherStats &&
                OtherStats.map((otherStattext) => (
                  <Fragment>
                    <Col span={12}>
                      <Statistic
                        title={otherStattext}
                        value={
                          userData?.analytics?.[otherStattext] === undefined
                            ? 0
                            : userData?.analytics?.[otherStattext]
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title={"last " + otherStattext + " Login"}
                        value={new Date(
                          userData?.analytics?.["LastLoginService Desk"] ===
                          undefined
                            ? 0
                            : userData?.analytics?.["LastLoginService Desk"]
                        ).toLocaleString()}
                      />
                    </Col>
                  </Fragment>
                ))}
            </Fragment>
          )}
        </Row>
      </div>
    </Card>
  );
};

Stats.propTypes = {
  userData: PropTypes.object,
  pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  userData: state.users?.editData,
  pageWidth: state.misc?.width,
});

export default connect(mapStateToProps)(Stats);
