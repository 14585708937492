import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./auth";
import {
  SERVICES_CLEARALL,
  SERVICES_EDIT,
  SERVICES_GET_ALL,
  SERVICES_LOADING,
  SERVICES_MODAL_PERMADD_CLOSE,
  SERVICES_MODAL_PERMADD_OPEN,
} from "./types";

// get all the services
export const loadAllServices = () => async (dispatch) => {
  try {
    dispatch({
      type: SERVICES_LOADING,
      payload: true,
    });

    // get the services
    let res = await axios.get("/api/services");

    dispatch({
      type: SERVICES_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: SERVICES_LOADING,
      payload: false,
    });
  }
};

// clears all the store items for the services
export const clearServicesState = () => (dispatch) => {
  dispatch({
    type: SERVICES_CLEARALL,
  });
};

// view details of a specific service
export const EditViewService = (details) => (dispatch) => {
  dispatch({
    type: SERVICES_EDIT,
    payload: details,
  });
};

// updates a specific service, needs the service object id
export const UpdateSpecificService =
  (serviceID, serviceData) => async (dispatch) => {
    try {
      if (serviceID) {
        dispatch({
          type: SERVICES_LOADING,
          payload: true,
        });

        // make sure the token is in the auth header
        setAuthToken(localStorage.token);

        // update the specific service
        let res = await axios.put("/api/services/" + serviceID, {
          data: serviceData,
        });

        // get the services
        res = await axios.get("/api/services");

        dispatch(setAlert("Service details saved", "success"));

        dispatch({
          type: SERVICES_GET_ALL,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setAlert("Your session has expired, please log in", "error"));

        setTimeout(() => dispatch(logout()), 500);
      } else {
        dispatch(
          setAlert("An error occured, please refresh the page", "error")
        );
      }
      dispatch({
        type: SERVICES_LOADING,
        payload: false,
      });
    }
  };

// deletes a specific service, needs the service object id
export const DeleteSpecificService = (serviceID) => async (dispatch) => {
  try {
    if (serviceID) {
      dispatch({
        type: SERVICES_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the specific service
      let res = await axios.delete("/api/services/" + serviceID);

      // get all the services
      res = await axios.get("/api/services");

      dispatch(setAlert("Permission deleted successful", "success"));

      dispatch({
        type: SERVICES_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: SERVICES_LOADING,
      payload: false,
    });
  }
};

// open the modal to add a service
export const ModalAddServiceOpen = () => (dispatch) => {
  dispatch({
    type: SERVICES_MODAL_PERMADD_OPEN,
  });
};

// close the modal to add a service
export const ModalAddServiceClose = () => (dispatch) => {
  dispatch({
    type: SERVICES_MODAL_PERMADD_CLOSE,
  });
};

// Create a new service
export const NewService = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICES_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // Send the data to create a new one
    await axios.post("/api/services", {
      data,
    });

    // get all the services
    let res = await axios.get("/api/services");

    dispatch(setAlert("Service added", "success"));

    dispatch({
      type: SERVICES_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
    dispatch({
      type: SERVICES_LOADING,
      payload: false,
    });
  }
};
