import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd componetn
import { Table, Space, Popconfirm, Tooltip, Button } from "antd";
import { DeleteOutlined, RightOutlined } from "@ant-design/icons";

// external functions
import {
  EditViewPermission,
  DeleteSpecificPermission,
} from "../../../actions/permissions";

// actual Component
const PermissionsTable = ({
  permissions,
  isAdmin,
  data,
  loading,
  pageWidth,
  EditViewPermission,
  DeleteSpecificPermission,
}) => {
  const handleDelete = (record) => {
    DeleteSpecificPermission(record._id);
    EditViewPermission(null);
  };

  const handleEdit = (record) => {
    EditViewPermission(record);
  };

  const columns = [
    {
      title: "Permission",
      key: "group",
      width: "25%",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      responsive: ["lg"],
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "10%",
      align: "right",
      render: (text, record) => (
        <Space size="middle">
          <Fragment>
            {permissions?.delete | isAdmin ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record)}
              >
                <a href="#!" style={{ color: "red" }}>
                  <DeleteOutlined />
                </a>
              </Popconfirm>
            ) : (
              <></>
            )}
            <Tooltip title="Edit/view permission">
              <Button
                shape="circle"
                icon={<RightOutlined />}
                onClick={() => handleEdit(record)}
                size="small"
              />
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  // if we admin user we want more columns
  if (isAdmin && columns.length !== 4) {
    columns.splice(0, 0, {
      title: "Staff Only",
      key: "isStaff",
      width: "50px",
      dataIndex: "staffOnly",
      render: (text, record) => (text ? "Yes" : ""),
      responsive: ["xl"],
    });
  }

  return (
    <Table
      style={{ marginTop: pageWidth >= 768 ? "15px" : "1px" }}
      loading={loading}
      columns={columns}
      dataSource={data?.map((item) => {
        item.key = item._id;

        return item;
      })}
      size="small"
      pagination={false}
      bordered
      scroll={{
        x: "100%",
        y: pageWidth >= 768 ? "calc(100vh - 20em)" : "190px",
      }}
    ></Table>
  );
};

PermissionsTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  EditViewPermission: PropTypes.func.isRequired,
  DeleteSpecificPermission: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.permissions.data,
  loading: state.permissions.loading,
});

export default connect(mapStateToProps, {
  EditViewPermission,
  DeleteSpecificPermission,
})(PermissionsTable);
