import axios from "axios";
import { setAlert } from "./alert";
import {
  USERS_CLEARALL,
  USERS_EDIT,
  USERS_EDIT_CLEAR,
  USERS_EDIT_LOADING,
  USERS_GET_ALL,
  USERS_GET_PROFILEPIC,
  USERS_LOADING,
  USERS_MODAL_GROUPADD_CLOSE,
  USERS_MODAL_GROUPADD_OPEN,
  USERS_MODAL_PERMADD_CLOSE,
  USERS_MODAL_PERMADD_OPEN,
  USERS_MODAL_SITEADD,
  USERS_MODAL_SITEADD_TABLE,
  USERS_MODAL_SITEADD_TABLE_ALL,
  USERS_MODAL_SITESADD_CLOSE,
  USERS_MODAL_SITESADD_OPEN,
  USERS_NEWSITE_SAVE,
  USERS_NEWSITE_UPDATE,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { logout } from "./auth";

export const loadAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: USERS_LOADING,
      payload: true,
    });

    // get the basic user information
    let res = await axios.get("/api/users");

    dispatch({
      type: USERS_GET_ALL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: USERS_LOADING,
      payload: false,
    });
  }
};

export const clearUsersState = () => (dispatch) => {
  dispatch({
    type: USERS_CLEARALL,
  });
};

// clear the specfic user data
export const clearSpecificUsersState = () => (dispatch) => {
  dispatch({
    type: USERS_EDIT_CLEAR,
  });
};

// gets a specific users data, needs the user object id
export const GetSpecificUserDetails = (userID) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_EDIT_LOADING,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // get the basic user information
    let res = await axios.get("/api/users/" + userID);

    dispatch({
      type: USERS_EDIT,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("An error occured, please refresh the page", "error"));
    dispatch({
      type: USERS_EDIT_LOADING,
      payload: false,
    });
  }
};

// delets a specific users data, needs the user object id
export const DeleteSpecificUser = (userID) => async (dispatch) => {
  try {
    if (userID) {
      dispatch({
        type: USERS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.delete("/api/users/" + userID);

      // get the basic user information
      res = await axios.get("/api/users");

      dispatch(setAlert("User is deleted successful", "success"));
      dispatch({
        type: USERS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: USERS_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: USERS_LOADING,
        payload: false,
      });
    }
  }
};

// updates a specific users data, needs the user object id
export const UpdateSpecificUser = (userID, userData) => async (dispatch) => {
  try {
    if (userID) {
      dispatch({
        type: USERS_LOADING,
        payload: true,
      });

      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // delete the basic user information
      let res = await axios.put("/api/users/" + userID, { userData });

      // get the basic user information
      res = await axios.get("/api/users");
      dispatch(setAlert("User details saved", "success"));

      dispatch({
        type: USERS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("Error: ", error.response);
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      dispatch({
        type: USERS_LOADING,
        payload: false,
      });
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
      dispatch({
        type: USERS_LOADING,
        payload: false,
      });
    }
  }
};

// edits but does not save a users details
export const EditSpecificUser = (userData) => (dispatch) => {
  dispatch({
    type: USERS_EDIT,
    payload: userData,
  });
};

// Opens the modal to add permissions
export const ModalPermAddOpen = () => (dispatch) => {
  dispatch({
    type: USERS_MODAL_PERMADD_OPEN,
  });
};

// Closes the modal to add permissions
export const ModalPermAddClose = () => (dispatch) => {
  dispatch({
    type: USERS_MODAL_PERMADD_CLOSE,
  });
};

// Opens the modal to add groups
export const ModalGroupsAddOpen = () => (dispatch) => {
  dispatch({
    type: USERS_MODAL_GROUPADD_OPEN,
  });
};

// Closes the modal to add groups
export const ModalGroupsAddClose = () => (dispatch) => {
  dispatch({
    type: USERS_MODAL_GROUPADD_CLOSE,
  });
};

// Opens the modal to add sites to user
export const ModalSitesAddOpen = () => (dispatch) => {
  dispatch({
    type: USERS_MODAL_SITESADD_OPEN,
  });
};

// Closes the modal to add sites to user
export const ModalSitesAddClose = () => (dispatch) => {
  dispatch({
    type: USERS_MODAL_SITESADD_CLOSE,
  });
};

// on the add site to notification user modal, needs to be a way to know when the table is populated
export const AddNewSiteToUserTableLoaded = (data) => async (dispatch) => {
  dispatch({
    type: USERS_MODAL_SITEADD_TABLE,
    payload: data,
  });
};

// on the add site to notification user modal, needs to be a way to know when the table is populated
export const AddNewSiteToUserAllTableLoaded = (data) => async (dispatch) => {
  dispatch({
    type: USERS_MODAL_SITEADD_TABLE_ALL,
    payload: data,
  });
};

// add sites to list
export const AddNewSiteToUser = (data) => async (dispatch) => {
  dispatch({
    type: USERS_MODAL_SITEADD,
    payload: data,
  });
};

// get the profile picture of the person
export const GetUserProfilePicture = (userID) => async (dispatch) => {
  try {
    if (userID) {
      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // Get the data from the backend
      let res = await axios.get("/api/users/picture/" + userID);

      dispatch({
        type: USERS_GET_PROFILEPIC,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    }
  }
};

// on creation of new user update the store with the details
export const NewUserUpdate = (details) => (dispatch) => {
  dispatch({
    type: USERS_NEWSITE_UPDATE,
    payload: details,
  });
};

// Creates a new user
export const NewUserSave = (details) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_NEWSITE_SAVE,
      payload: true,
    });

    // make sure the token is in the auth header
    setAuthToken(localStorage.token);

    // send the data to the endpoint to get saved
    await axios.post("/api/users", { data: details });

    dispatch({
      type: USERS_CLEARALL,
    });
  } catch (error) {
    console.log("Error: ", error.response);
    dispatch({
      type: USERS_NEWSITE_SAVE,
      payload: false,
    });

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else if (error?.response?.status === 400) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else {
      dispatch(setAlert("An error occured, please refresh the page", "error"));
    }
  }
};

// Resets a users password
export const ResetUserPassword = (userID) => async (dispatch) => {
  try {
    if (userID) {
      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // Get the data from the backend
      await axios.put("/api/users/resetpass/" + userID);

      dispatch(setAlert("User password request sent, check email", "success"));
    }
  } catch (error) {
    console.log("ResetUserPassword, Error: ", error.response);

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured!! please check logs", "error"));
    }
  }
};

// Enables or disables a user
export const EnableDisableUser = (userID) => async (dispatch) => {
  try {
    if (userID) {
      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // toggle the isEnabled bit
      await axios.put("/api/users/enabledisable/" + userID);
      // get all the users information
      const res = await axios.get("/api/users");
      dispatch(setAlert("User details changed", "success"));

      dispatch({
        type: USERS_GET_ALL,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("EnableDisableUser, Error: ", error.response);

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured!! please check logs", "error"));
    }
  }
};

// Resend the verification email
export const ResendVerificationEmail = (userID) => async (dispatch) => {
  try {
    if (userID) {
      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // Get the data from the backend
      await axios.put("/api/users/resendcode/" + userID);

      dispatch(setAlert("User verification code sent, check email", "success"));
    }
  } catch (error) {
    console.log("ResendVerificationEmail, Error: ", error.response);

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured!! please check logs", "error"));
    }
  }
};

// Reset QR code
export const ResetUserQRCode = (userID) => async (dispatch) => {
  try {
    if (userID) {
      // make sure the token is in the auth header
      setAuthToken(localStorage.token);

      // Get the data from the backend
      await axios.get("/api/auth/resetQRcode/" + userID);

      dispatch(setAlert("User QR code is reset, ask user to login", "success"));
    }
  } catch (error) {
    console.log("ResetUserQRCode, Error: ", error.response);

    if (error?.response?.status === 401) {
      dispatch(setAlert("Your session has expired, please log in", "error"));
      setTimeout(() => dispatch(logout()), 500);
    } else {
      dispatch(setAlert("An error occured!! please check logs", "error"));
    }
  }
};
